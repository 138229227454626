import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd391fec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"100%","height":"100%","padding-bottom":"8px"} }
const _hoisted_2 = { style: {"display":"flex"} }
const _hoisted_3 = { style: {"margin-left":"5px"} }
const _hoisted_4 = {
  class: "statistics",
  style: {"text-align":"left"}
}
const _hoisted_5 = { style: {"margin-bottom":"-15px"} }
const _hoisted_6 = { class: "number" }
const _hoisted_7 = { class: "number" }
const _hoisted_8 = { class: "number" }
const _hoisted_9 = { class: "number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input_search = _resolveComponent("k-input-search")!
  const _component_k_object_tool_strip = _resolveComponent("k-object-tool-strip")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_tree_view = _resolveComponent("k-tree-view")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _directive_focus = _resolveDirective("focus")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    style: {"padding":"8px 0px","width":"100%","height":"100%"},
    ref: _ctx.$vm.refFolderTreeEl
  }, [
    _createVNode(_component_k_panel, { class: "panel-style" }, {
      header: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_k_input_search, {
              value: _ctx.$vm.queryCriteria,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.queryCriteria) = $event)),
              placeholder: "请输入需求名称",
              onSearch: _cache[1] || (_cache[1] = () => _ctx.$vm.searchProperty()),
              onChange: _cache[2] || (_cache[2] = () => _ctx.$vm.refreshQueryCriteria())
            }, null, 8, ["value"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_k_object_tool_strip, {
              ref: "refToolStrip",
              items: _ctx.$vm.toolStripItems,
              onItemClicked: _cache[3] || (_cache[3] = (event)=>_ctx.$vm.toolItemHandle(event))
            }, null, 8, ["items"])
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_k_tree_view, {
          ref: _ctx.$vm.refRequireTree,
          nodeKey: "id",
          onAfterSelect: _cache[4] || (_cache[4] = (event) => _ctx.afterSelect(event)),
          onBeforeSelect: _cache[5] || (_cache[5] = event => _ctx.$vm.beforeSelect(event)),
          defaultExpandedLevel: 0,
          autoSelectLastNode: false,
          autoSelectFirstNodeAfterDefaultExpand: false,
          onAfterMenuClicked: _cache[6] || (_cache[6] = (event)=>_ctx.$vm.toolClick(event)),
          loadData: (node) => _ctx.loadTreeData(node),
          onReady: _ctx.$vm.onReady
        }, {
          title: _withCtx(({ node }) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_k_icon, {
                title: _ctx.$vm.getTitle(node),
                type: _ctx.$vm.getProjectStatus(node)
              }, null, 8, ["title", "type"]),
              _createElementVNode("span", _hoisted_3, _toDisplayString(node.name), 1)
            ])
          ]),
          _: 1
        }, 8, ["loadData", "onReady"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createTextVNode(" 总计 "),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$vm.processData.total), 1),
            _createTextVNode(" 项需求 ")
          ]),
          _createElementVNode("div", null, [
            _createTextVNode(" 已关闭 "),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$vm.processData.end), 1),
            _createTextVNode(" 项，实现中 "),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$vm.processData.inProgress), 1),
            _createTextVNode(" 项，未开始 "),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$vm.processData.noStart), 1),
            _createTextVNode(" 项 ")
          ])
        ])
      ]),
      _: 1
    })
  ])), [
    [_directive_focus]
  ])
}