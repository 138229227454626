import { IntegrateApp, IntegrateMethod, IntegrateParameter } from './IntegrateApp'

export class integrateConfig {
  private static integrateConfigXml: string = `<?xml version="1.0" encoding="utf-8"?>
  <MBPCONFIG User="KM">
    <!-- EAP集成接口配置文件-->
    <IntegratePlugInManage>
      <FunctionType Name="AutoCad" AppCode="5">
        <FunctionItem Name="登录" ID="ID_APP_PDM_START" Assemble="AutoCADHandler" Method="login">
        </FunctionItem>
        <FunctionItem Name="注销" ID="ID_APP_PDM_EXIT" Assemble="AutoCADHandler" Method="exit">
        </FunctionItem>
        <FunctionItem Name="文档信息" ID="ID_APP_PDM_GETEXTTDOCINFO" Assemble="AutoCADHandler" Method="getDocInfo">
        </FunctionItem>
        <FunctionItem Name="文档检查" ID="ID_APP_PDM_VOLCHECKED" Assemble="AutoCADHandler" Method="docChecked">
        </FunctionItem>
        <FunctionItem Name="工作区" ID="ID_APP_PDM_WORKSPACE" Assemble="AutoCADHandler" Method="workspace">
        </FunctionItem>
        <FunctionItem Name="编辑" ID="ID_APP_PDM_EDIT" Assemble="AutoCADHandler" Method="edit">
        </FunctionItem>
        <FunctionItem Name="浏览" ID="ID_APP_PDM_BROWSE" Assemble="AutoCADHandler" Method="browser">
        </FunctionItem>
        <FunctionItem Name="下载" ID="ID_APP_PDM_DOWNLOAD" Assemble="AutoCADHandler" Method="download">
        </FunctionItem>
        <FunctionItem Name="检入" ID="ID_APP_PDM_CHECKIN" Assemble="AutoCADHandler" Method="checkin">
        </FunctionItem>
        <FunctionItem Name="取消检出" ID="ID_APP_PDM_UNDOCHECKOUT" Assemble="AutoCADHandler" Method="undoCheckout">
        </FunctionItem>
        <FunctionItem Name="属性" ID="ID_APP_PDM_OBJECTATTRIBUTES" Assemble="AutoCADHandler" Method="objectAttributes">
        </FunctionItem>
        <FunctionItem Name="结构" ID="ID_APP_PDM_STRUCTURE" Assemble="AutoCADHandler" Method="structure">
        </FunctionItem>
        <FunctionItem Name="查看批注" ID="ID_APP_PDM_VIEWREMARK" Assemble="AutoCADHandler" Method="viewRemark">
        </FunctionItem>
         <FunctionItem Name="更新" ID="ID_APP_PDM_UPDATEREQUEST" Assemble="AutoCADHandler" Method="updateRequest">
        </FunctionItem>
      </FunctionType>
      <FunctionType Name="Solidworks" AppCode="18">
        <FunctionItem Name="登录" ID="ID_APP_PDM_START" Assemble="SWHandler" Method="login">
        </FunctionItem>
        <FunctionItem Name="注销" ID="ID_APP_PDM_STOP" Assemble="SWHandler" Method="exit">
        </FunctionItem>
        <FunctionItem Name="文档信息" ID="ID_APP_PDM_GETEXTTDOCINFO" Assemble="SWHandler" Method="getDocInfo">
        </FunctionItem>
        <FunctionItem Name="文档检查" ID="ID_APP_PDM_VOlCHECKED" Assemble="SWHandler" Method="docChecked">
        </FunctionItem>
        <FunctionItem Name="工作区" ID="ID_APP_PDM_WORKSPACE" Assemble="SWHandler" Method="workspace">
        </FunctionItem>
        <FunctionItem Name="编辑" ID="ID_APP_PDM_EDIT" Assemble="SWHandler" Method="edit">
        </FunctionItem>
        <FunctionItem Name="浏览" ID="ID_APP_PDM_BROWSE" Assemble="SWHandler" Method="browser">
        </FunctionItem>
        <FunctionItem Name="下载" ID="ID_APP_PDM_DOWNLOAD" Assemble="SWHandler" Method="download">
        </FunctionItem>
        <FunctionItem Name="检入" ID="ID_APP_PDM_CHECKIN" Assemble="SWHandler" Method="checkin">
        </FunctionItem>
        <FunctionItem Name="取消检出" ID="ID_APP_PDM_UNDOCHECKOUT" Assemble="SWHandler" Method="undoCheckout">
        </FunctionItem>
        <FunctionItem Name="属性" ID="ID_APP_PDM_OBJECTATTRIBUTES" Assemble="SWHandler" Method="objectAttributes">
        </FunctionItem>
        <FunctionItem Name="结构" ID="ID_APP_PDM_STRUCTURE" Assemble="SWHandler" Method="structure">
        </FunctionItem>
        <FunctionItem Name="查看批注" ID="ID_APP_PDM_VIEWREMARK" Assemble="SWHandler" Method="viewRemark">
        </FunctionItem>
        <FunctionItem Name="本地工作目录" ID="ID_APP_PDM_LOCALWORKDIR" Assemble="SWHandler" Method="localWork">
        </FunctionItem>
        <FunctionItem Name="从模型库引入" ID="ID_APP_PDM_CHILDPRT_LOAD" Assemble="SWHandler" Method="childprtPoad">
        </FunctionItem>
        <FunctionItem Name="更新" ID="ID_APP_PDM_UPDATEREQUEST" Assemble="SWHandler" Method="updateRequest">
        </FunctionItem>
      </FunctionType>
      <FunctionType Name="CADENCE" AppCode="31">
        <FunctionItem Name="登录" ID="ID_APP_PDM_START" Assemble="CadenceHandler" Method="login">
        </FunctionItem>
        <FunctionItem Name="注销" ID="ID_APP_PDM_STOP" Assemble="CadenceHandler" Method="exit">
        </FunctionItem>
        <FunctionItem Name="检入" ID="ID_APP_PDM_AUTOMATICCHECKIN" Assemble="CadenceHandler" Method="checkin">
        </FunctionItem>
        <FunctionItem Name="检出" ID="ID_APP_PDM_CHECKOUTWORKSPACE" Assemble="CadenceHandler" Method="checkout">
        </FunctionItem>
        <FunctionItem Name="取消检出" ID="ID_APP_PDM_UNDOCHECKOUTWORKSPACE" Assemble="CadenceHandler" Method="undoCheckout">
        </FunctionItem>
        <FunctionItem Name="编辑" ID="ID_APP_PDM_OPENFROMSYSTEM" Assemble="CadenceHandler" Method="edit">
        </FunctionItem>
        <FunctionItem Name="下载" ID="ID_APP_PDM_DOWNLOAD" Assemble="CadenceHandler" Method="download">
        </FunctionItem>
        <FunctionItem Name="元器件同步" ID="ID_APP_PDM_UPDATELIB" Assemble="CadenceHandler" Method="updateLib">
        </FunctionItem>
        <FunctionItem Name="打开工作区" ID="ID_APP_PDM_OPENWORKSPACE" Assemble="CadenceHandler" Method="openWorkspace">
        </FunctionItem>
        <FunctionItem Name="更新" ID="ID_APP_PDM_UPDATEREQUEST" Assemble="CadenceHandler" Method="updateRequest">
        </FunctionItem>
        <FunctionItem Name="修订" ID="ID_APP_PDM_REVISE" Assemble="CadenceHandler" Method="revise">
        </FunctionItem>
        <FunctionItem Name="上传" ID="ID_APP_PDM_UPLOAD" Assemble="CadenceHandler" Method="upload">
        </FunctionItem>
        <FunctionItem Name="保存至工作区" ID="ID_APP_PDM_SAVETOWORKSPACE" Assemble="CadenceHandler" Method="save2Workspace">
        </FunctionItem>
      </FunctionType>
      <FunctionType Name="CADENCE_PCB" AppCode="32">
        <FunctionItem Name="登录" ID="ID_APP_PDM_START" Assemble="CadencePCBHandler" Method="login">
        </FunctionItem>
        <FunctionItem Name="注销" ID="ID_APP_PDM_STOP" Assemble="CadencePCBHandler" Method="exit">
        </FunctionItem>
        <FunctionItem Name="检入" ID="ID_APP_PDM_AUTOMATICCHECKIN" Assemble="CadencePCBHandler" Method="checkin">
        </FunctionItem>
        <FunctionItem Name="检出" ID="ID_APP_PDM_CHECKOUTWORKSPACE" Assemble="CadencePCBHandler" Method="checkout">
        </FunctionItem>
        <FunctionItem Name="取消检出" ID="ID_APP_PDM_UNDOCHECKOUTWORKSPACE" Assemble="CadencePCBHandler" Method="undoCheckout">
        </FunctionItem>
        <FunctionItem Name="编辑" ID="ID_APP_PDM_OPENFROMSYSTEM" Assemble="CadencePCBHandler" Method="edit">
        </FunctionItem>
        <FunctionItem Name="下载" ID="ID_APP_PDM_DOWNLOAD" Assemble="CadencePCBHandler" Method="download">
        </FunctionItem>
        <FunctionItem Name="元器件同步" ID="ID_APP_PDM_UPDATELIB" Assemble="CadencePCBHandler" Method="updateLib">
        </FunctionItem>
        <FunctionItem Name="打开工作区" ID="ID_APP_PDM_OPENWORKSPACE" Assemble="CadencePCBHandler" Method="openWorkspace">
        </FunctionItem>
        <FunctionItem Name="更新" ID="ID_APP_PDM_UPDATEREQUEST" Assemble="CadencePCBHandler" Method="updateRequest">
        </FunctionItem>
        <FunctionItem Name="修订" ID="ID_APP_PDM_REVISE" Assemble="CadencePCBHandler" Method="revise">
        </FunctionItem>
        <FunctionItem Name="上传" ID="ID_APP_PDM_UPLOAD" Assemble="CadencePCBHandler" Method="upload">
        </FunctionItem>
      </FunctionType>
    </IntegratePlugInManage>
  </MBPCONFIG>`

  public static getAppApi(): IntegrateApp[] {
    const xml = new DOMParser()
    const doc = xml.parseFromString(this.integrateConfigXml, 'text/xml')
    const nodeList = doc.querySelectorAll('FunctionType')

    const result: IntegrateApp[] = []
    for (const node of nodeList) {
      const id = node.getAttribute('AppCode')
      if (!id) continue

      const integrateApp: IntegrateApp = { id: Number.parseInt(id), methods: [] }

      const methodNodeList = node.querySelectorAll('FunctionItem')
      for (const methodNode of methodNodeList) {
        const integrateMethod: IntegrateMethod = {
          id: methodNode.getAttribute('ID')!,
          name: methodNode.getAttribute('Method')!,
          handler: methodNode.getAttribute('Assemble')!,
          parameters: []
        }

        const paramNodeList = methodNode.querySelectorAll('Parameter')
        for (const paramNode of paramNodeList) {
          const integrateParameter: IntegrateParameter = {
            name: paramNode.getAttribute('ID')!,
            direction: 0 //paramNode.querySelector('[direction]')?.nodeValue
          }

          integrateMethod.parameters.push(integrateParameter)
        }

        integrateApp.methods.push(integrateMethod)
      }

      result.push(integrateApp)
    }

    return result
  }
}
