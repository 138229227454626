import {
  BaseViewModel,
  DataGridLoadResult,
  IKTreeNode,
  IQuery,
  KDataGridViewModel,
  KTreeViewViewModel,
  TreeViewSelectEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KAllRequirementEmitsType, KAllRequirementPropType } from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'

/** KAllRequirement */
export default class KAllRequirementViewModel extends BaseViewModel<KAllRequirementEmitsType, KAllRequirementPropType> {
  /**树组件定义 */
  refTree = ref<KTreeViewViewModel>()
  /** 选中的节点 */
  selectNode = ref<IKTreeNode>()
  /** 网格组件 */
  refRequirementGrid = ref<KDataGridViewModel>()
  /** 选中的节点 */
  selectNodeType = ref<string>('1')
  constructor(options: ViewModelOptions<KAllRequirementPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 加载树
   */
  loadTreeData(): Promise<Array<IKTreeNode>> {
    return new Promise((resolve, reject) => {
      resolve([
        {
          id: 'root',
          name: '全部需求',
          leaf: false,
          iconType: 'Node_MyProjectTask',
          children: [
            {
              id: '1',
              name: '产品需求',
              leaf: true,
              type: '1',
              iconType: 'Node_MyProjectTask',
              children: []
            },
            {
              id: '2',
              name: '原始需求',
              leaf: true,
              type: '2',
              iconType: 'Node_MyProjectTask',
              children: []
            }
          ] as Array<IKTreeNode>
        }
      ])
    })
  }

  /**
   * 刷新网格
   */
  setSelectNode(event: TreeViewSelectEventArgs<any>) {
    this.selectNodeType.value = event.node.type
  }

  /**
   * 刷新
   */
  refersh() {
    this.refRequirementGrid.value?.refresh()
  }

  /**
   * 网格数据加载
   */
  loadData(query: IQuery): Promise<DataGridLoadResult> {
    const pageVO = {
      maxPageSize: 100,
      curPage: query.page?.pageIndex,
      pageSize: query.page?.pageSize,
      totalRows: 0,
      totalPages: 0,
      startIndex: 0,
      endIndex: 0
    }
    return new Promise((resolve, reject) => {
      if (!this.selectNode.value) {
        reject([])
        return
      }
      const param = { data: [pageVO] }
      Api.post('requirement', 'VerificationPlan', 'pageVerificationPlan', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          resolve({
            rows: res.data.data,
            total: res.data.page.totalRows
          })
        } else {
          resolve([])
        }
      })
    })
  }
}
