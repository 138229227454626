import { BaseViewModel, KNotification, request, ViewModelOptions } from '@kmsoft/upf-core'
import { KCustomerNeedsCreateWithFolderSelectorEmitsType, KCustomerNeedsCreateWithFolderSelectorPropType } from './interface'
import { ref } from 'vue'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import { Api, EnumWorkingState, FileClientSrv } from '@kmsoft/ebf-common'
import lodash from 'lodash'

/** KCustomerNeedsCreate */
export default class KCustomerNeedsCreateWithFolderSelectorViewModel extends BaseViewModel<
  KCustomerNeedsCreateWithFolderSelectorEmitsType,
  KCustomerNeedsCreateWithFolderSelectorPropType
> {
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  formData = ref<any>({
    number: '',
    name: '',
    description: '',
    category: '',
    secondary: [],
    folder: {
      id: ''
    }
  })

  constructor(options: ViewModelOptions<KCustomerNeedsCreateWithFolderSelectorPropType>) {
    super(options)
  }

  viewDidMount() {
    // 文件夹赋值
    if (this.props.formValue?.folder?.id) {
      this.formData.value.folder.id = this.props.formValue.folder?.id
    }
  }

  getValue() {
    const createObject = lodash.cloneDeep(this.refObjectCreatePanel.value?.getValue())
    return createObject
  }

  /** 校验 */
  validate() {
    return this.refObjectCreatePanel.value?.validateFormValue()
  }

  async save() {
    const createObject = this.getValue()

    if (createObject?.secondary) {
      const secondary = createObject.secondary.map((element: string) => {
        return { id: element }
      })
      createObject.secondary = secondary
    }

    const params = {
      data: [
        {
          ...createObject
        }
      ]
    }
    return await Api.post('requirement', 'CustomerNeeds', 'create', params)
  }
}
