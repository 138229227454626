import { connect, withInstall } from '@kmsoft/upf-core'
import KDocOptionalView from './KDocOptional.vue'
import KDocOptionalViewModel from './KDocOptionalViewModel'

const KDocOptional = connect(KDocOptionalView, KDocOptionalViewModel)

export default withInstall(KDocOptional)
export { KDocOptional, KDocOptionalView, KDocOptionalViewModel }

// 模板生成文件
// export * from './{{folderName}}'
