import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KMyProductNeedsPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KMyProductNeedsPropType = ViewPropsTypeExtract<typeof KMyProductNeedsPropOptions>

/** 事件 */
export const KMyProductNeedsEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KMyProductNeedsEmitsType = ViewEmitsTypeExtract<typeof KMyProductNeedsEventEmits>
