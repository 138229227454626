import { BaseViewModel, ViewModelOptions, KNotification, AppContext, request } from '@kmsoft/upf-core'
import { KOfficePreviewEmitsType, KOfficePreviewPropType } from './interface'
import { Api, EnumRequestCode, ObjectClientSrv } from '../../client-srv'
import { EnumDocDependencyType } from '../../types/enums'
import { ref, watch } from 'vue'

/** KOfficePreview */
export default class KOfficePreviewViewModel extends BaseViewModel<KOfficePreviewEmitsType, KOfficePreviewPropType> {
  url = ref<string>('')
  loading = ref<boolean>(true)
  /**loading文字*/
  loadingText = ref<string>('')
  constructor(options: ViewModelOptions<KOfficePreviewPropType>) {
    super(options)
    watch(
      () => options.props.objParam,
      () => {
        this.loading.value = true
        this.getFileUrl()
      },
      {
        immediate: true
      }
    )
  }

  async viewDidMount() {
    const task = await this.getTaskInfo(this.props.objParam.id, '')
    if (task && task.status == 2) {
      this.loadingText.value = '正在转换中，请等待'
    }
  }

  async getTaskInfo(docId: string, type?: number | string) {
    const reqParam = { data: [docId, type] }
    const result = await Api.post('task', 'Task', 'getByDocIdAndType', reqParam)

    if (result && result.code == EnumRequestCode.SUCCESS) {
      const taskInfo = result.data
      return taskInfo
    }
    return null
  }

  async getFileUrl() {
    const result = (await Api.post('doc', 'Document', 'get', { data: [this.props.objParam.id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const doc = result.data
      if (!doc.primary) {
        KNotification.info({
          message: '没有主文件'
        })
        this.loading.value = false
        return
      }
      const serverUrl = AppContext.current.getEnvironment().getProperty<string>('url.getLightweightUrl')
      const primaryFilename = doc?.primary[0]?.name as string
      const ext = primaryFilename
        .split('.')
        .pop()
        ?.toLowerCase()
      if (['sldasm', 'sldprt'].includes(ext as string)) {
        let taskId,
          fileId = ''
        doc.extAttrs.forEach((item: any) => {
          if (item.name == 'LightweightTaskId') {
            taskId = item.value
          }
          if (item.name == 'LightweightFileId') {
            fileId = item.value
          }
        })
        const host = window.location.origin
        if (host == serverUrl) {
          const requestUrl = serverUrl + '/basic/coremgr/geom/drawing/lightweight-status'
          const data = (await request.post(requestUrl, { task_id: taskId })) as any
          if (data && data?.datas?.file_lightweight_task_result?.status == 'SUCCESS') {
            const path = data?.datas?.file_lightweight_task_result?.lightweight_file_path
            this.url.value = serverUrl + '/' + 'ipdbasic/isclightweightxd/#/threeModel?path=' + path + '&fileId=' + fileId
          } else {
            this.url.value = serverUrl + '/' + 'ipdbasic/isclightweightxd/#/preview?taskId=' + taskId + '&fileId=' + fileId
          }
        } else {
          this.url.value = serverUrl + '/' + 'ipdbasic/isclightweightxd/#/preview?taskId=' + taskId + '&fileId=' + fileId
        }
        this.loading.value = false
      } else if (['slddrw', 'dwg', 'brd', 'dsn'].includes(ext as string)) {
        const fileData = await this.handlePdf(doc)
        if (fileData) {
          this.handleOtherType(fileData)
        } else {
          this.loading.value = false
          KNotification.error({
            title: '获取失败',
            content: '获取文档失败'
          })
        }
      } else {
        this.handleOtherType(doc)
      }
    } else {
      this.loading.value = false
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取文档失败',
        details: result.detail
      })
      return
    }
  }

  async handlePdf(doc: any): Promise<any> {
    //查询关联的浏览文档
    const reqParam = { data: [this.props.objParam.id, EnumDocDependencyType.BROWSE] }
    const result = await Api.post('doc', 'Document', 'getAttachmentDocumentByType', reqParam)
    if (result && result.data) {
      return result.data
    }
    return ''
  }

  async handleOtherType(doc: any) {
    const param = {
      data: [doc?.primary[0]?.id, doc?.primary[0]?.name?.replace(/\s/g, '')]
    }
    const result = (await Api.post('doc', 'Document', 'officePreview', param)) as any
    this.loading.value = false
    if (result && result.code == EnumRequestCode.SUCCESS) {
      if (result.data == 'File Type Not Supported') {
        KNotification.warn({
          message: '格式不支持',
          description: '此文件格式不支持预览，将自动下载文件'
        })
        ObjectClientSrv.primaryDownload(doc?.primary[0]?.id, doc?.primary[0]?.name, doc.rdmExtensionType)
      } else {
        this.url.value = result.data
      }
    } else {
      KNotification.error({
        title: '预览失败',
        content: result.message || '预览文档失败'
      })
    }
  }
}
