import {
  Api,
  CommonClientSrv,
  EnumDocumentType,
  EnumObjectPanelTabParamType,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  EnumRequestCode,
  EnumToolStripItemKeys,
  IObjectSelectorComponent,
  KObjectClassGridViewModel,
  KObjectDynamicCreatePanel,
  KObjectPanelPropType,
  KOfficeDocumentEditor,
  ObjBusinessParam,
  ObjectClientSrv
} from '@kmsoft/ebf-common'
import { EnumDocDependencyType, EnumDocType } from '@kmsoft/ebf-common/src/types/enums'
import { EnumKmVueFileType, KModelBrowser, KModelBrowserViewModel } from '@kmsoft/km-vue/src/components'
import {
  BaseViewModel,
  EnumDialogResult,
  EnumItemClickedCancelType,
  EnumToolStripCompType,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  ViewModelOptions,
  VNodeProps
} from '@kmsoft/upf-core'
import { ref, resolveComponent, watch } from 'vue'
import { DocClientSrv } from '../../client-srv'
import { DocStructureTreeNode } from '../doc-structure-tree'
import { KDocRelationObjEmitsType, KDocRelationObjPropType } from './interface'

/** KDocRelationObj */
export default class KDocRelationObjViewModel extends BaseViewModel<KDocRelationObjEmitsType, KDocRelationObjPropType> {
  refReferenceDataGrid = ref<KObjectClassGridViewModel>()
  refPartEDADocumentDataGrid = ref<KObjectClassGridViewModel>()
  refDescDataGrid = ref<KObjectClassGridViewModel>()
  refVersionHistoryDataGrid = ref<KObjectClassGridViewModel>()
  refAttachmentDataGrid = ref<KObjectClassGridViewModel>()
  refChangeLogDataGrid = ref<KObjectClassGridViewModel>()
  refManagedBaselineDataGrid = ref<KObjectClassGridViewModel>()
  refKModelBrowser = ref<KModelBrowserViewModel>()
  tabId = ref<string>()
  activeKey = ref<Array<string>>([this.props.tabId])
  bomSubsituteVisible = ref(false)
  showTree = ref<boolean>(false)
  rootObjParam = ref<ObjBusinessParam>()
  /** 流程任务对象参数 */
  objParam = ref<ObjBusinessParam>()
  toolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD,
      title: '添加',
      icon: 'file',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
      title: '移除',
      icon: 'delete',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'delete'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  attachmentDocToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW,
      title: '新建',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD,
      title: '添加',
      icon: 'file',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
      title: '移除',
      icon: 'delete',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'delete'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC,
      title: '浏览',
      icon: 'eye',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD_DOC,
      title: '下载',
      icon: 'download',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    }
  ]

  versionHistoryToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_RESTORE,
      title: '还原历史版本',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    }
  ]

  changeToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  baselineToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]
  showBrowser = ref<boolean>(true)

  constructor(options: ViewModelOptions<KDocRelationObjPropType>) {
    super(options)
    this.objParam.value = options.props.objParam
    this.tabId.value = this.props.tabId
    if (this.props.attachParams[EnumObjectPanelTabParamType.RootNode]) {
      const rootNode = this.props.attachParams[EnumObjectPanelTabParamType.RootNode] as DocStructureTreeNode
      this.rootObjParam.value = rootNode.docObjParam
    }
    watch(
      () => this.props.objParam,
      () => {
        //不加这一行切换会失败
        this.objParam.value = options.props.objParam
        this.refreshAll()
      },
      {
        immediate: true,
        deep: true
      }
    )
    // watch(
    //   () => this.props.refreshTip,
    //   () => {
    //     this.refreshAll()
    //   },
    //   {
    //     deep: true
    //   }
    // )
  }

  refreshAll() {
    this.refReferenceDataGrid.value?.refresh()
    this.refPartEDADocumentDataGrid.value?.refresh()
    this.refDescDataGrid.value?.refresh()
    this.refVersionHistoryDataGrid.value?.refresh()
    this.refAttachmentDataGrid.value?.refresh()
    const selectedNode = this.props.attachParams[EnumObjectPanelTabParamType.SelectedNode] as DocStructureTreeNode
    if (selectedNode) {
      const path = selectedNode.path
      this.refKModelBrowser.value?.setNodesHighlight(path)
    }
  }

  async viewDidMount() {
    const reqParam = { data: [this.props.objParam.id] }
    const result = await Api.post('doc', 'Document', 'get', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const doc = result.data
      const fileType = doc.primary ? doc.primary[0].name?.split('.') : null
      if (!this.props.attachParams[EnumObjectPanelTabParamType.NoShowTree] && fileType && !fileType[1].includes('PRT')) {
        this.showTree.value = true
      }
      if (!doc.primary) {
        this.showBrowser.value = false
      }
    }
  }

  /**
   * 获取文档
   */
  async getPart(id: string) {
    const result = (await Api.post('doc', 'Document', 'get', { data: [id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取文档失败',
        details: result.detail
      })
      return
    }
  }

  /**
   * 加载依赖关系数据
   */
  async loadReferenceData() {
    const reqParam = {
      data: [
        { id: this.objParam.value?.id },
        {
          maxPageSize: 1000,
          curPage: 1,
          pageSize: 20,
          totalRows: 0,
          totalPages: 0,
          startIndex: 0,
          endIndex: 0
        }
      ]
    }
    const result = await Api.post('part', 'PartReferenceLink', 'listPart', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return Promise.resolve({
        rows: result.data,
        total: result.data.length
      })
    }
    KNotification.error({
      title: '系统错误',
      content: result.message
    })
    return
  }

  /**
   * 加载描述关系数据
   */
  async loadDescData() {
    const reqParam = {
      data: [
        { id: this.objParam.value?.id },
        {
          maxPageSize: 1000,
          curPage: 1,
          pageSize: 20,
          totalRows: 0,
          totalPages: 0,
          startIndex: 0,
          endIndex: 0
        }
      ]
    }
    const result = await Api.post('part', 'PartDescribeLink', 'listPart', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return Promise.resolve({
        rows: result.data,
        total: result.data.length
      })
    }
    KNotification.error({
      title: '系统错误',
      content: result.message
    })
    return
  }

  titleClick(key: string) {
    this.activeKey.value.push(key)
  }

  /**
   * 加载历史版本数据
   */
  async loadVersionHistoryData() {
    const reqParam = {
      data: [
        { id: this.objParam.value?.id },
        {
          maxPageSize: 1000,
          curPage: 1,
          pageSize: 20,
          totalRows: 0,
          totalPages: 0,
          startIndex: 0,
          endIndex: 0
        }
      ]
    }
    const result = await Api.post('doc', 'Document', 'listAllVersions', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return Promise.resolve({
        rows: result.data,
        total: result.data.length
      })
    }
    return
  }

  /**
   * 变更记录
   */
  async listPartChange() {
    const id = this.objParam.value?.id as string
    // 获取零部件对象
    const data = await this.getPart(id)
    const branchId = data?.branch.id
    const param = {
      data: [
        {
          id,
          branchId
        }
      ]
    }

    const result = await Api.post('change', 'ChangeCommon', 'listChangeRecord', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return {
        rows: result.data,
        total: result.data.length
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  /**
   * 所属基线
   */
  async listManagedBaseline() {
    const id = this.objParam.value?.id
    const param = {
      data: [id]
    }

    const result = await Api.post('baseline', 'ManagedBaseline', 'listAssociatedBaseline', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return {
        rows: result.data,
        total: result.data.length
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  /**
   * 加载附属文档数据
   */
  async loadAttachmentDocData() {
    const reqParam = {
      data: [{ id: this.objParam.value?.id }]
    }
    const result = await Api.post('doc', 'Document', 'listDependenceDoc', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return Promise.resolve({
        rows: result.data,
        total: result.data.length
      })
    }
    return
  }

  async onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    const eventName = event.name
    console.log('onToolStripItemChange', eventName)
  }

  /**
   * 引用关系
   */
  async onReferenceToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onReferenceToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.doAddReferenceObj()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.doDelReferenceObj()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refReferenceDataGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /**
   * 描述关系
   */
  async onDescToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onDescToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.doAddDescObj()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.doDelDescObj()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refDescDataGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /**
   * 版本历史
   * @param event 事件
   */
  async onVersionHistoryToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onVersionHistoryToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refVersionHistoryDataGrid.value?.refresh()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_RESTORE:
        this.revertHistory()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      default:
        break
    }
  }

  /**
   * 变更记录
   * @param event 事件
   */
  async onChangeToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refChangeLogDataGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /**
   * 所属基线
   * @param event 事件
   */
  async onBaselineToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refManagedBaselineDataGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /**
   * 附属文档
   * @param event 事件
   */
  async onAttachmentToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onVersionHistoryToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW:
        this.doNewAttachmentObj()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.doAddAttachmentObj()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.doDelAttachmentObj()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refAttachmentDataGrid.value?.refresh()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC:
        this.doBrowse()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD_DOC:
        this.AttachmentDownload()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      default:
        break
    }
  }

  // async onAttachmentDataGridReady() {
  //   this.refAttachmentDataGrid.value?.hideColumn('code', true)
  // }

  async doBrowse() {
    const rows = this.refAttachmentDataGrid.value?.getSelectedRows()
    if (!rows || rows.length < 1) {
      KNotification.warning({
        title: '提示',
        content: '请选择一行数据',
        duration: 1
      })
      return
    }
    const docData = await DocClientSrv.getDoc(rows[0].id)
    const primary = docData.primary[0]
    if (!primary) {
      KNotification.warning({
        title: '提示',
        content: '主文件缺失',
        duration: 1
      })
      return
    }
    const fileName = primary.name
    const ext = fileName
      .split('.')
      .pop()
      ?.toLowerCase()
    switch (ext) {
      case 'docx':
      case 'doc':
      case 'xlsx':
      case 'xls':
      case 'pptx':
      case 'ppt':
      case 'pdf':
        {
          CommonClientSrv.openPage(
            `文件预览：${docData.number}`,
            KOfficeDocumentEditor,
            {
              modelName: 'Document',
              title: primary.name,
              id: primary.id,
              fileType: ext,
              mode: 'view'
            },
            docData.id
          )
        }
        break
      case 'scs':
        {
          /** 标签页标识 */
          const tabKey = `${docData.id}#${docData.documentType}#browse`
          CommonClientSrv.openPage(
            `文件预览：${docData.number}`,
            KModelBrowser,
            {
              objParam: this.objParam.value
            },
            tabKey
          )
        }
        break
      case 'slddrw':
        {
          //查询关联的浏览文档
          const reqParam = { data: [rows[0].id, EnumDocDependencyType.BROWSE] }
          const result = await Api.post('doc', 'Document', 'getAttachmentDocumentByType', reqParam)
          if (result && result.data) {
            CommonClientSrv.openPage(
              `文件预览：${docData.number}`,
              KOfficeDocumentEditor,
              {
                modelName: 'Document',
                title: primary.name,
                id: result.data.primary[0].id,
                fileType: result.data.primary[0].name
                  .split('.')
                  .pop()
                  ?.toLowerCase(),
                mode: 'view'
              },
              docData.id
            )
          } else {
            // 不支持，仅下载
            DocClientSrv.primaryDownload(primary.id, primary.name, docData.rdmExtensionType)
          }
        }
        break
      default:
        {
          // 不支持，仅下载
          DocClientSrv.primaryDownload(primary.id, primary.name, docData.rdmExtensionType)
        }
        break
    }
  }

  async AttachmentDownload() {
    const rows = this.refAttachmentDataGrid.value?.getSelectedRows()
    if (!rows || rows.length < 1) {
      KNotification.warning({
        title: '下载错误',
        content: '请选择一行数据',
        duration: 1
      })
      return
    }
    DocClientSrv.docDownload(rows[0].id)
  }

  /**
   * 移除依赖关系数据
   */
  async doDelReferenceObj() {
    const rows = this.refReferenceDataGrid.value?.getSelectedRows()
    if (!rows || rows.length < 1) {
      KNotification.warning({
        title: '移除失败',
        content: '请至少选择一行',
        duration: 1
      })
      return
    }
    console.log('doDelete', rows)
    const ids = rows?.map(item => {
      return item.partDocLinkId
    })
    const result = await Api.post('part', 'PartReferenceLink', 'batchDelete', { data: [{ ids: ids }] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success({
        title: '系统提示',
        content: '移除成功',
        duration: 1
      })
      this.refReferenceDataGrid.value?.refresh()
    } else {
      KNotification.error({
        title: '移除失败',
        content: result.message!
      })
      return
    }
  }

  /**
   * 移除描述关系数据
   */
  async doDelDescObj() {
    const rows = this.refDescDataGrid.value?.getSelectedRows()
    if (!rows || rows.length < 1) {
      KNotification.warning({
        title: '移除失败',
        content: '请至少选择一行',
        duration: 1
      })
      return
    }
    console.log('doDelete', rows)
    const ids = rows?.map(item => {
      return item.partDocLinkId
    })
    const result = await Api.post('part', 'PartDescribeLink', 'batchDelete', { data: [{ ids: ids }] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success({
        title: '系统提示',
        content: '移除成功',
        duration: 1
      })
      this.refDescDataGrid.value?.refresh()
    } else {
      KNotification.error({
        title: '移除失败',
        content: result.message!
      })
      return
    }
  }

  /**
   * 执行添加依赖关系
   */
  async doAddReferenceObj() {
    const doc = await DocClientSrv.getDoc(this.props.objParam?.id)
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Part']
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Part']
      },
      title: '选择零部件',
      getContainer: this.refDescDataGrid.value?.getContainer(),
      onClosing: async (event: KDialogClosingEvent<IObjectSelectorComponent>) => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
        const rows = event.viewInstance?.getSelectedRows()
        if (!rows || rows.length < 1) {
          KNotification.warning({
            title: '创建失败',
            content: '请至少选择一行',
            duration: 1
          })
          return
        }
        const refObjs = rows?.map(item => {
          return { source: { id: item.id }, target: { id: doc.master?.id } }
        })
        const result = await Api.post('part', 'PartReferenceLink', 'batchCreate', {
          data: [refObjs]
        })
        console.info(rows)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '添加依赖关系成功',
            duration: 1
          })
          this.refReferenceDataGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '添加依赖关系失败',
            content: result.message!
          })
          return
        }
      }
    })
  }

  /**
   * 执行添加描述关系
   */
  async doAddDescObj() {
    const sourceId = this.objParam.value?.id
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Part']
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Part']
      },
      title: '选择零部件',
      getContainer: this.refDescDataGrid.value?.getContainer(),
      onClosing: async (event: KDialogClosingEvent<IObjectSelectorComponent>) => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
        const rows = event.viewInstance?.getSelectedRows()
        if (!rows || rows.length < 1) {
          KNotification.warning({
            title: '创建失败',
            content: '请至少选择一行',
            duration: 1
          })
          return
        }
        const refObjs = rows?.map(item => {
          return { source: { id: item.id }, target: { id: sourceId } }
        })
        const result = await Api.post('part', 'PartDescribeLink', 'batchCreate', {
          data: [refObjs]
        })
        console.info(rows)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '添加描述关系成功',
            duration: 1
          })
          this.refDescDataGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '新建失败',
            content: result.message!
          })
          return
        }
      }
    })
  }

  /**
   * 还原历史版本
   */
  async revertHistory() {
    const rows = this.refVersionHistoryDataGrid.value?.getSelectedRows()
    if (!rows || rows.length < 1) {
      KNotification.error({
        title: '系统提示',
        content: '请选择一行记录'
      })
      return
    }
    if (rows.length > 1) {
      KNotification.error({
        title: '系统提示',
        content: '请只选择一行记录'
      })
      return
    }
    const param = {
      data: [
        {
          targetId: rows[0].id,
          currentId: this.props.objParam.id
        }
      ]
    }
    const result = await Api.post('doc', 'Document', 'revertHistory', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success({
        title: '系统提示',
        content: '还原版本成功',
        duration: 1
      })
      this.refVersionHistoryDataGrid.value?.refresh()
    } else {
      KNotification.error({
        title: '还原版本失败',
        content: result.message!
      })
    }
  }

  /**
   * 执行添加附属文档
   */
  async doAddAttachmentObj() {
    const doc = await DocClientSrv.getDoc(this.props.objParam?.id)
    let conditionValues = Object.values(EnumDocDependencyType)
    if (doc.documentType == EnumDocType.CADENCE) {
      conditionValues = [EnumDocDependencyType.CADENCE_NET_LIST]
    }
    if (doc.documentType == EnumDocType.CADENCE_PCB) {
      conditionValues = [
        EnumDocDependencyType.CADENCE_COORDINATE,
        EnumDocDependencyType.CADENCE_GERBER,
        EnumDocDependencyType.CADENCE_ODB_PLUS
      ]
    }
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document'],
        defaultSelectClsCode: 'Document',
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: [
            {
              conditionName: 'master.documentType',
              operator: EnumQueryConditionOperator.IN,
              conditionValues: conditionValues
            }
          ]
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Document'],
        defaultSelectClsCode: 'Document'
      },
      title: '选择文档',
      getContainer: this.refDescDataGrid.value?.getContainer(),
      onClosing: async (event: KDialogClosingEvent<IObjectSelectorComponent>) => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
        const rows = event.viewInstance?.getSelectedRows()
        if (!rows || rows.length < 1) {
          KNotification.warning({
            title: '创建失败',
            content: '请至少选择一行',
            duration: 1
          })
          return
        }
        const refObjs = rows?.map(item => {
          return { source: { id: doc?.id }, target: { id: item.id } }
        })
        const result = await Api.post('doc', 'Document', 'batchCreateDocumentDependencyLink', {
          data: [refObjs]
        })
        console.info(rows)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '添加依赖关系成功',
            duration: 1
          })
          this.refAttachmentDataGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '添加依赖关系失败',
            content: result.message!
          })
          return
        }
      }
    })
  }

  /**
   * 执行创建附属文档
   */
  async doNewAttachmentObj() {
    const doc = await DocClientSrv.getDoc(this.props.objParam?.id)
    KDialog.show({
      title: '创建对象',
      content: KObjectDynamicCreatePanel,
      props: {
        clsOptions: [],
        folderId: doc.folder?.id,
        showObjClsCodes: ['Document'],
        defaultSelectClsCode: 'Document'
      },
      size: { width: 1200, height: document.documentElement.clientHeight - 100 },
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewInstance as any
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }
        const result = await formViewModel.save()
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success('对象创建成功')
          const refObjs = [{ source: { id: doc.id }, target: { id: result.data[0].id } }]
          const createResult = await Api.post('doc', 'Document', 'batchCreateDocumentDependencyLink', {
            data: [refObjs]
          })
          if (createResult && createResult.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '添加依赖关系成功',
              duration: 1
            })
            this.refAttachmentDataGrid.value?.refresh()
          } else {
            KNotification.error({
              title: '添加依赖关系失败',
              content: createResult.message!
            })
            return
          }
        } else {
          if (result) {
            KNotification.error({
              title: '操作失败',
              content: result.message || '创建对象失败',
              details: result.detail
            })
          }
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 移除附属文档数据
   */
  async doDelAttachmentObj() {
    const rows = this.refAttachmentDataGrid.value?.getSelectedRows()
    if (!rows || rows.length < 1) {
      KNotification.warning({
        title: '移除失败',
        content: '请至少选择一行',
        duration: 1
      })
      return
    }
    KDialog.confirm({
      title: '确认要删除该对象？',
      onOk: async () => {
        console.log('doDelete', rows)
        const ids = rows?.map(item => {
          return item.linkId
        })
        const result = await Api.post('doc', 'Document', 'batchDeleteDocumentDependencyLink', { data: [ids] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '移除成功',
            duration: 1
          })
          this.refAttachmentDataGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '移除失败',
            content: result.message!
          })
          return
        }
      },
      onCancel: () => {}
    })
  }

  openAttachmentDoc() {
    const selectedRow = this.refAttachmentDataGrid.value?.getSelectedRow()
    if (selectedRow?.documentType == EnumDocDependencyType.SOLIDWORKS_2D) {
      const param = { id: selectedRow?.id, modelCode: 'Document', modelGroup: 'doc', masterId: selectedRow?.master.id }
      const name = selectedRow?.name
      const number = selectedRow?.number
      const version = selectedRow?.version + '.' + selectedRow?.iteration
      /** 标签页标识 */
      const tabKey = `${param.id}#${param.modelCode}`
      /** 标题 */
      const title = `${number}:${name}:${version}`
      /** 面板参数 */
      const panelProps: VNodeProps<KObjectPanelPropType> = { objParam: param }

      // 打开页面
      CommonClientSrv.openPage(title, () => resolveComponent('KObjectPanel'), panelProps, tabKey)
    }
  }

  onDoubleClick() {
    const selectedRow = this.refVersionHistoryDataGrid.value?.getSelectedRow()
    const param = { id: selectedRow?.id, modelCode: 'Document', modelGroup: 'doc', masterId: selectedRow?.master.id }
    const name = selectedRow?.name
    const number = selectedRow?.number
    //const partViewEnum = selectedRow?.partViewEnum === 'DESIGN' ? '设计' : '制造'
    const version = selectedRow?.version + '.' + selectedRow?.iteration
    const workingState =
      selectedRow?.workingState === 'INWORK' ? '工作中' : 'CHECKED_IN' ? '已检入' : 'CHECKED_OUT' ? '已检出' : '未知'

    /** 标签页标识 */
    const tabKey = `${param.id}#${param.modelCode}`
    /** 标题 */
    const title = `${number}` + ':' + `${name}` + ':' + `${version}` + ':' + `${workingState}`
    /** 面板参数 */
    const panelProps: VNodeProps<KObjectPanelPropType> = { objParam: param }

    // 打开页面
    CommonClientSrv.openPage(title, () => resolveComponent('KObjectPanel'), panelProps, tabKey)
  }

  async onEDAToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.addPartEDA()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.removePartEDA()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refPartEDADocumentDataGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /**
   * 加载电子元器件文档关系
   * @returns
   */
  loadEDADocumentData() {
    return this.listPartEDA()
  }

  /**
   * 查询零部件文档参考列表
   *
   * @returns
   */
  async listPartEDA() {
    const reqParam = {
      data: [{ id: this.objParam.value?.id }]
    }
    const result = await Api.post('part', 'EDADocumentLink', 'listPart', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return Promise.resolve({
        rows: result.data,
        total: result.data.length
      })
    }
    KNotification.error({
      title: '系统错误',
      content: result.message
    })
    return
  }

  /**
   * 添加零部件文档参考关系
   */
  addPartEDA() {
    const objParam = this.props.objParam
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Part']
      },
      objectSearchQueryParam: {
        isMultipleSelection: false,
        showObjClsCodes: ['Part']
      },
      title: '选择零部件',
      getContainer: this.refDescDataGrid.value?.getContainer(),
      onClosing: async (event: KDialogClosingEvent<IObjectSelectorComponent>) => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
        const rows = event.viewInstance?.getSelectedRows()
        if (!rows || rows.length < 1) {
          KNotification.warning({
            title: '创建失败',
            content: '请选择数据',
            duration: 1
          })
          return
        }
        const partParam = rows[0]
        const result = await Api.post('part', 'EDADocumentLink', 'create', {
          data: [
            {
              source: {
                id: partParam.id,
                clazz: partParam.rdmExtensionType
              },
              target: {
                id: objParam.id,
                clazz: objParam.modelCode
              }
            }
          ]
        })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '添加成功',
            duration: 1
          })
          this.refPartEDADocumentDataGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '添加失败',
            content: result.message!
          })
          return
        }
      }
    })
  }

  /**
   * 移除零部件文档参考关系
   */
  async removePartEDA() {
    const rows = this.refPartEDADocumentDataGrid.value?.getSelectedRows()
    if (!rows || rows.length < 1) {
      KNotification.warning({
        title: '移除失败',
        content: '请至少选择一行',
        duration: 1
      })
      return
    }
    const ids = rows?.map(item => {
      return item.partDocLinkId
    })
    const result = await Api.post('part', 'EDADocumentLink', 'batchDelete', { data: [{ ids: ids }] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success({
        title: '系统提示',
        content: '移除成功',
        duration: 1
      })
      this.refPartEDADocumentDataGrid.value?.refresh()
    } else {
      KNotification.error({
        title: '移除失败',
        content: result.message!
      })
      return
    }
  }

  updateToolStrip(value: string) {
    //固定进入后设置保存为Visible=false
    // this.refAttachmentDataGrid.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
    // switch (value) {
    //   case EnumWorkState.CHECKED_IN:
    //     this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT, true)
    //     this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN, true)
    //     this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT, false)
    //     break
    //   case EnumWorkState.CHECKED_OUT:
    //     this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT, true)
    //     this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN, true)
    //     this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT, true)
    //     this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, true)
    //     this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_FILE, true)
    //     this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW, true)
    //     this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE, true)
    //     this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_REMARK, true)
    //     this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE, true)
    //     break
    //   case EnumWorkState.INWORK:
    //     this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT, true)
    //     this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN, false)
    //     this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT, false)
    //     break
    //   default:
    //     break
    // }
  }
}
