import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KBaselineContentPropOptions = {
  ...BaseViewPropOptions,
  objParam: VuePropTypes.createType<Record<string, any>>().def()
}

/** 参数类型 **/
export type KBaselineContentPropType = ViewPropsTypeExtract<typeof KBaselineContentPropOptions>

/** 事件 */
export const KBaselineContentEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KBaselineContentEmitsType = ViewEmitsTypeExtract<typeof KBaselineContentEventEmits>
