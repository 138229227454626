import { enumFactory } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartMaterialFormPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KPartMaterialFormPropType = ViewPropsTypeExtract<typeof KPartMaterialFormPropOptions>

/** 事件 */
export const KPartMaterialFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartMaterialFormEmitsType = ViewEmitsTypeExtract<typeof KPartMaterialFormEventEmits>

export const MaterialProps = enumFactory({
  procurementtype: '采购类型',
  assemblyMode: '装配类型',
  phase: '阶段'
  /*   occurrence: '位号',
  quantity: '数量' */
})

/** 零部件来源 */
export enum EnumSource {
  /**
   * 自制
   */
  Make = '自制',
  /**
   * 采购
   */
  Buy = '采购',
  /**
   * 购买-单一供应源
   */
  Singlesource = '购买-单一供应源'
}

/** 采购类型 */
export enum EnumProcurementType {
  E= '自制',
  F= '外购',
  X= '自制&外购'
}


/** 装配模式 */
export enum EnumAssemblyMode {
  Separable = '可分离',
  Inseparable = '不可分离',
  Component = '组件'
}

/** 阶段 */
export enum EnumPhase {
  Design = '设计',
  Prototype = '原理样机',
  Engineering = '工程样机',
  Manufacturing = '量产'
}
