import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KCompareResultPropOptions = {
  ...BaseViewPropOptions,
  selectRows: VuePropTypes.array().def()
}

/** 参数类型 **/
export type KCompareResultPropType = ViewPropsTypeExtract<typeof KCompareResultPropOptions>

/** 事件 */
export const KCompareResultEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KCompareResultEmitsType = ViewEmitsTypeExtract<typeof KCompareResultEventEmits>
