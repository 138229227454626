import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_loading = _resolveComponent("k-loading")!
  const _component_k_tree_view = _resolveComponent("k-tree-view")!

  return (_ctx.$vm.loading)
    ? (_openBlock(), _createBlock(_component_k_loading, { key: 0 }))
    : (_openBlock(), _createBlock(_component_k_tree_view, {
        key: 1,
        dataSource: _ctx.$vm.treeNode,
        defaultExpandedLevel: 0
      }, {
        title: _withCtx(({node}) => [
          _createElementVNode("span", null, _toDisplayString(node.name), 1)
        ]),
        _: 1
      }, 8, ["dataSource"]))
}