import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPersonalElectronicDistributionPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KPersonalElectronicDistributionPropType = ViewPropsTypeExtract<typeof KPersonalElectronicDistributionPropOptions>

/** 事件 */
export const KPersonalElectronicDistributionEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPersonalElectronicDistributionEmitsType = ViewEmitsTypeExtract<typeof KPersonalElectronicDistributionEventEmits>

/**
 * 发放单记录状态枚举
 */
export const DistributionStatusEnum = [
  {
    label: '已读',
    value: 'READ'
  },
  {
    label: '未读',
    value: 'UNREAD'
  },
  {
    label: '归档',
    value: 'FILE_AWAY'
  }
]

/**
 * 生命周期状态枚举
 */
export const LifecycleStateEnum = [
  {
    label: '发布',
    value: 'Released'
  },
  {
    label: '审核中',
    value: 'UnderReview'
  },
  {
    label: '正在工作',
    value: 'InWork'
  }
]
