import { connect, withInstall } from '@kmsoft/upf-core'
import KMyCustomerNeedsView from './KMyCustomerNeeds.vue'
import KMyCustomerNeedsViewModel from './KMyCustomerNeedsViewModel'

const KMyCustomerNeeds = connect(KMyCustomerNeedsView, KMyCustomerNeedsViewModel)

export default withInstall(KMyCustomerNeeds)
export { KMyCustomerNeeds, KMyCustomerNeedsView, KMyCustomerNeedsViewModel }

// 模板生成文件
// export * from './{{folderName}}'
