import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KMySubscribedRequirementPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KMySubscribedRequirementPropType = ViewPropsTypeExtract<typeof KMySubscribedRequirementPropOptions>

/** 事件 */
export const KMySubscribedRequirementEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KMySubscribedRequirementEmitsType = ViewEmitsTypeExtract<typeof KMySubscribedRequirementEventEmits>
