
import { defineView, EnumDataGridAutoSizeColumnsMode, EnumDataGridRowModelType, EnumDividerType } from '@kmsoft/upf-core'
import { KAssociateClientGridPropOptions, KAssociateClientGridEventEmits } from './interface'
import KAssociateClientGridViewModel from './KAssociateClientGridViewModel'

export default defineView({
  name: 'KAssociateClientGrid',
  props: KAssociateClientGridPropOptions,
  emits: KAssociateClientGridEventEmits,
  viewModel: KAssociateClientGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      autoSizeColumnsMode: EnumDataGridAutoSizeColumnsMode.FILL,
      vertical: EnumDividerType.VERTICAL
    }
  }
})
