import { connect, withInstall } from '@kmsoft/upf-core'
import KBaselineContentView from './KBaselineContent.vue'
import KBaselineContentViewModel from './KBaselineContentViewModel'

const KBaselineContent = connect(KBaselineContentView, KBaselineContentViewModel)

export default withInstall(KBaselineContent)
export { KBaselineContent, KBaselineContentView, KBaselineContentViewModel }

// 模板生成文件
// export * from './{{folderName}}'
