import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_folder_tree = _resolveComponent("k-folder-tree")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_object_class_grid = _resolveComponent("k-object-class-grid")!
  const _component_k_split_container = _resolveComponent("k-split-container")!

  return (_openBlock(), _createBlock(_component_k_split_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_split_pane, {
        size: 15,
        minSize: 15,
        maxSize: 50
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_panel, null, {
            default: _withCtx(() => [
              _createVNode(_component_k_folder_tree, {
                ref: "refFolderTree",
                style: _normalizeStyle(_ctx.style),
                loadData: _ctx.$vm.loadTreeData,
                onAfterSelect: _ctx.$vm.afterSelect
              }, null, 8, ["style", "loadData", "onAfterSelect"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_k_split_pane, null, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(!_ctx.showToolStrip ? 'full part-obj-manage no-filter' : 'full part-obj-manage')
          }, [
            _createVNode(_component_k_object_class_grid, {
              ref: "refFolderObjGrid",
              modelCode: _ctx.modelCode,
              modelGroup: _ctx.modelGroup,
              schemaType: _ctx.schemaType,
              selectFirstRow: false,
              loadData: _ctx.$vm.loadGridData,
              showToolStrip: _ctx.showToolStrip,
              toolStripItems: _ctx.$vm.refToolStripItems,
              onToolStripItemClicked: _ctx.toolStripItemClicked,
              operatorColumn: _ctx.operatorColumn,
              showOperatorColumn: _ctx.showOperatorColumn,
              showContextMenu: false,
              pagination: { pageSize: 50 },
              onOperationClick: _ctx.operationClick,
              columnResolver: _ctx.$vm.columnResolver
            }, null, 8, ["modelCode", "modelGroup", "schemaType", "loadData", "showToolStrip", "toolStripItems", "onToolStripItemClicked", "operatorColumn", "showOperatorColumn", "onOperationClick", "columnResolver"])
          ], 2)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}