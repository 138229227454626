import {
  BaseViewModel,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  ViewModelOptions,
  KContainer,
  KControl,
  KSchema,
  KNotification
} from '@kmsoft/upf-core'
import { isFunction, isArray } from 'lodash'
import { computed, nextTick, ref, toRef, watch } from 'vue'
import { ClassMetaClientSrv, MetaClass, ObjBusinessBase, ObjectClientSrv } from '../../../../client-srv'
import { KObjectPropertyPanelViewModel } from '../../../object-property-panel'
import { KObjectToolStripViewModel, ObjectToolStripItem, defineToolStripData } from '../../../object-tool-strip'
import { KObjectPropertyEditPanelBaseEmitsType, KObjectPropertyEditPanelBasePropType } from './interface'
import { getToolStrip } from './toolStrip'
import { EnumToolStripItemKeys } from '../../../index'

/** 对象编辑面板 */
export default class KObjectPropertyEditPanelBaseViewModel extends BaseViewModel<
  KObjectPropertyEditPanelBaseEmitsType,
  KObjectPropertyEditPanelBasePropType
> {
  /** 对象工具栏 */
  refObjectToolStrip = ref<KObjectToolStripViewModel>()
  /** 属性面板 */
  refObjPropertyPanel = ref<KObjectPropertyPanelViewModel>()
  /** 是否正在加载 */
  isLoading = ref(true)
  /** 是否正在保存 */
  isSaving = ref(false)
  /** 工具栏定义 */
  toolStripItems = ref<Array<ObjectToolStripItem>>()
  /** 是否只读 */
  readonly = ref<boolean>(true)
  /** 是否启用编辑 */
  disabled = ref<boolean>(false)
  /** 是否取消编辑 */
  cancelEdit = ref<boolean>(false)
  isValidate = ref<boolean>(false)
  /** 是否可以编辑 */
  editable = computed(() => {
    return !this.disabled.value && !this.readonly.value
  })
  /** 对象类定义 */
  metaClass: MetaClass

  constructor(options: ViewModelOptions<KObjectPropertyEditPanelBasePropType>) {
    super(options)
    // 获取工具栏定义
    this.toolStripItems = this.props.showToolStripItems
      ? defineToolStripData(getToolStrip(false), {
          extraItems: [
            { items: toRef(this.props, 'toolStripItems'), appendFront: false },
            { items: toRef(this.props, 'toolStripItemsInsert'), appendFront: true }
          ]
        })
      : ref([])

    watch(
      () => this.props.objParam,
      (newValue, oldValue) => {
        this.init()
        if (newValue.modelCode !== oldValue.modelCode) {
          this.refresh()
        }
      }
    )
    watch(
      () => this.readonly.value,
      (newValue, oldValue) => {
        nextTick(() => {
          if (newValue) {
            this.refObjectToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_RESTORE, false)
          } else {
            this.refObjectToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_RESTORE, true)
          }
        })
      },
      {
        immediate: true
      }
    )
  }
  async init() {
    this.metaClass = (await ClassMetaClientSrv.getClassByClsCode(this.props.objParam.modelCode)) as MetaClass
  }

  /**
   * 设置只读
   * @param value 是否只读
   * @param updateState 是否更新状态
   */
  async setReadOnly(value: boolean, updateState: boolean = false) {
    this.readonly.value = value
  }

  /**
   * 设置禁用
   * @param value 是否禁用
   * @param updateState 是否更新状态
   */
  async setDisabled(value: boolean, updateState: boolean = false) {
    this.disabled.value = value
  }

  /**
   * 获取面板值
   * @returns
   */
  getValue() {
    return this.refObjPropertyPanel.value?.getValue()
  }

  /**
   * 刷新
   */
  async refresh() {
    await this.refObjPropertyPanel.value?.refresh()
  }

  /**
   * 获取布局
   */
  public getSchame() {
    return this.refObjPropertyPanel.value?.getSchame()
  }

  // //#region 私有
  // /**
  //  * 保存业务对象
  //  * @param params
  //  */
  // protected async saveBusinessObj(params: SaveObjBusinessParams): Promise<ObjectCreateFormSaveResult> {
  //   /** 保存结果 */
  //   const saveResult = await ObjectClientSrv.saveBusinessObj(params)

  //   if (saveResult) {
  //     return {
  //       id: saveResult.id,
  //       modelCode: saveResult.modelCode,
  //       modelGroup: this.props.objParam.modelGroup,
  //       objBusiness: saveResult,
  //       flowObjParam: undefined,
  //       cancel: false,
  //       success: true,
  //       errMsg: ''
  //     }
  //   }

  //   return {
  //     id: '',
  //     modelCode: '',
  //     modelGroup: this.props.objParam.modelGroup,
  //     objBusiness: undefined,
  //     flowObjParam: undefined,
  //     cancel: false,
  //     success: false,
  //     errMsg: ''
  //   }
  // }
  //#endregion

  //#region 事件
  /**
   * 工具栏点击事件
   * @param event
   * @returns
   */
  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    event.hostType = 'KObjectPanel'
    if (event.name == EnumToolStripItemKeys.TOOL_STRIP_ITEM_RESTORE) {
      this.refresh()
      this.readonly.value = true
      this.cancelEdit.value = true
      this.refObjectToolStrip?.value?.setEditItemValue(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, false)
      this.refObjectToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
    }
    // switch (event.name) {
    //   case EnumToolStripItemKeys.TOOL_STRIP_ITEM_RESTORE:
    //     this.refresh()
    //     this.readonly.value = true
    //     this.refObjectToolStrip?.value?.setEditItemValue(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, false)
    //     this.refObjectToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
    //     break
    //   default:
    //     break
    // }

    this.emit('toolStripItemClicked', event)
  }

  /**
   * 工具栏选项改变事件
   * @param event
   */
  onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    if (
      event.name == EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY &&
      !event.value &&
      !this.cancelEdit.value &&
      !this.validate()
    ) {
      this.isValidate.value = true
      this.refObjectToolStrip?.value?.setEditItemValue(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, true)
      return
    }
    if (event.name == EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY && !event.value && this.cancelEdit.value) {
      // 取消编辑时不需要触发change事件
      this.cancelEdit.value = false
      return
    }
    // 如果是校验导致编辑按钮改变，不做处理
    if (this.isValidate.value) {
      this.isValidate.value = false
      return
    }
    this.emit('toolStripItemChange', event)
  }

  /**
   * 加载回调
   * @returns
   */
  async onLoadData() {
    this.isLoading.value = true

    let objBusinessResult = {} as ObjBusinessBase
    if (isFunction(this.props.loadData)) {
      objBusinessResult = (this.props.loadData(this.props.objParam) as unknown) as ObjBusinessBase
    } else {
      objBusinessResult = (await ObjectClientSrv.getObjBusiness(this.props.objParam)) as ObjBusinessBase
    }
    this.isLoading.value = false
    return objBusinessResult
  }

  /**
   * 表单值更新事件
   */
  onFormChange() {
    // this.updateToolStripState()
  }
  //#endregion

  /**获取编辑面板修改后的值 */
  getModifiedValue() {
    return this.refObjPropertyPanel.value?.getModifiedValue()
  }
  setItemVisible(id: string, isVisible: boolean) {
    this.refObjectToolStrip.value?.setItemVisible(id, isVisible)
  }
  setItemDisabled(id: string, isVisible: boolean) {
    this.refObjectToolStrip.value?.setItemDisabled(id, isVisible)
  }

  openCategoryAttributes(id: string | null) {
    return this.refObjPropertyPanel.value?.openCategoryAttributes(id)
  }
  getContainer() {
    this.refObjPropertyPanel.value?.getContainer()
  }

  setEditItemValue(value: boolean) {
    if (value) {
      this.isValidate.value = true
      this.refObjectToolStrip?.value?.setEditItemValue(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, true)
    }
  }
  validate() {
    /** 修改值 */
    const value = this.getValue() as ObjBusinessBase
    /** 不符合要求的字段 */
    const nonComplianceProperties: Array<string> = []

    //#region 属性校验
    const schame = this.getSchame()

    //1.递归获取属性校验
    for (const key of Object.keys(value)) {
      const property = (this.getProperty(schame!, key) as unknown) as KControl

      if (property && (property.nullable || (property.nullable as any) == 1)) {
        continue
      }

      if (property && property.field! in value) {
        const field = property.field!
        //1.若存在值为对象的情况,则校验id
        //2.校验数组
        //3.校验字符串
        const type = typeof value[field] == 'object'
        const isExtend = field.includes('extendedAttributes')
        if (isExtend) {
          value.extAttrs.forEach((item: any) => {
            if (item.name === field.split('#')[1] && !item.value) {
              nonComplianceProperties.push(property.title)
            }
          })
        } else if (type && value[field] && 'id' in value[field] && value[field]['id'] === '') {
          nonComplianceProperties.push(property.title)
        } else if (isArray(value[field]) && value[field] && value[field].length == 0) {
          nonComplianceProperties.push(property.title)
        } else if (value[field] === '' || value[field] == undefined) {
          nonComplianceProperties.push(property.title)
        }
      }
    }

    // 如果有不符合要求的字段
    if (nonComplianceProperties.length > 0) {
      KNotification.warning({
        title: '属性空',
        content: `以下属性不能为空:\r\n${nonComplianceProperties.join(',')}`
      })
      return false
    }
    return true
  }
  /**获取schema 中的属性信息 */
  private getProperty(schema: KSchema | KContainer, key: string): KContainer | KControl | undefined {
    const newSchema = isArray(schema) ? schema : [schema]
    for (const control of newSchema) {
      if (((control as unknown) as KControl).field === key) {
        return (control as unknown) as KControl
      }
      if (control.controls && control.controls.length > 0) {
        const result = this.getProperty((control.controls as unknown) as KContainer, key)
        if (result) {
          return result
        }
      }
    }
    return undefined
  }

  /** 取消编辑 */
  cancelEditFun() {
    this.readonly.value = true
    this.cancelEdit.value = true
    this.refObjectToolStrip?.value?.setEditItemValue(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, false)
    this.refObjectToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
    this.emit('toolStripItemCancel', true)
  }
}
