import { BaseViewModel, ViewModelOptions, MemoryCacheFactory, KNotification } from '@kmsoft/upf-core'
import { Api, EnumRequestCode, UserInfoCache, EnumRequirementType, ObjectClientSrv } from '@kmsoft/ebf-common'
import { KrequirementKanbanEmitsType, KrequirementKanbanPropType } from './interface'
import { ref, computed } from 'vue'
import moment from 'moment'

/** KrequirementKanban */
export default class KrequirementKanbanViewModel extends BaseViewModel<KrequirementKanbanEmitsType, KrequirementKanbanPropType> {
  dateValue = ref<string>('month')
  datePicker = ref<string>('month')
  dateTimeValue = ref<string[]>([])
  dateTime = ref<string[]>([])
  format = ref<string>('YYYY-MM')
  trendData = ref<string[]>([])
  changeTrendData = ref<string[]>([])
  requirementTypeList = ref<any>(EnumRequirementType)
  userList = ref<any[]>([])
  startDate: string = moment()
    .startOf('month')
    .subtract(5, 'months')
    .format('YYYY-MM-DD')
  endDate: string = moment().format('YYYY-MM-DD')
  dateRange: string[] = []
  collectData = ref({})
  barXAxisData = ref<string[]>([])
  verificationPlanYAxis = ref<Record<string, any>>({
    type: 'category',
    data: []
  })
  verificationPlanSeries = ref<object[]>([])
  productNeedsTypeSeries = ref<Record<string, any>[]>([
    {
      type: 'pie',
      radius: ['50%', '70%'],
      label: {
        show: false
      },
      data: []
    }
  ])
  productNeedsTypeTitle = ref<Record<string, any>>({
    text: '',
    subtext: '产品需求总数',
    left: 'center',
    top: 'center',
    textStyle: {
      fontSize: 28,
      color: '#000'
    },
    subtextStyle: {
      fontSize: 14,
      color: 'gray'
    }
  })

  emptyVerificationPlan = ref<boolean>(true)
  xAxisData = computed(() => {
    const data = []
    switch (this.dateValue.value) {
      case 'date':
        for (let i = 6; i >= 0; i--) {
          data.push(
            moment()
              .subtract(i, 'days')
              .format('YYYY-MM-DD')
          )
        }
        break
      case 'week':
        for (let i = 4; i >= 0; i--) {
          data.push(
            moment()
              .subtract(i, 'weeks')
              .startOf('week')
              .format('YYYY-MM-DD')
          )
        }
        break
      case 'month':
        for (let i = 5; i >= 0; i--) {
          data.push(
            moment()
              .subtract(i, 'months')
              .startOf('month')
              .format('YYYY-MM')
          )
        }
        break
    }
    return data
  })

  newXAxisData = computed(() => {
    return this.xAxisData.value
  })
  constructor(options: ViewModelOptions<KrequirementKanbanPropType>) {
    super(options)
    moment.locale('en', {
      week: {
        dow: 1 // 设置周一为一周的开始
      }
    })
  }

  dateData = [
    { value: 'month', label: '月' },
    { value: 'week', label: '周' },
    { value: 'date', label: '日' }
  ]

  viewDidMount() {
    this.getRequirementType()
    this.getRequirementData('month')
    this.getUserData()
  }

  async getRequirementType() {
    const result = await ObjectClientSrv.getPropertyList('ProductNeeds', 'Category')
    if (result && result.length > 0) {
      const data: any = {}
      result.forEach((item: any) => {
        data[item.value] = item.label
      })
      this.requirementTypeList.value = data
    }
  }

  getUserData() {
    const cacheInstance = MemoryCacheFactory.get<UserInfoCache>(UserInfoCache.cacheKey)
    this.userList.value = cacheInstance.getUserOptionList()
  }

  changeDate(value: string) {
    this.dateTimeValue.value = []
    this.dateTime.value = []
    this.datePicker.value = value
    switch (value) {
      case 'month':
        this.startDate = moment()
          .startOf('month')
          .subtract(5, 'months')
          .format('YYYY-MM-DD')
        this.format.value = 'YYYY-MM'
        break
      case 'week':
        this.startDate = moment()
          .startOf('week')
          .subtract(5, 'weeks')
          .format('YYYY-MM-DD')
        this.format.value = 'YYYY-wo'
        break
      case 'date':
        this.startDate = moment(new Date())
          .subtract(6, 'days')
          .format('YYYY-MM-DD')
        this.format.value = 'YYYY-MM-DD'
        break
    }
    this.getRequirementData(value)
  }

  /** 获取日期范围内的所有日期点 */
  getDateRange(startDate: string, endDate: string): string[] {
    const dates: string[] = []
    const start = moment(startDate)
    const end = moment(endDate)

    switch (this.dateValue.value) {
      case 'date': {
        // 获取每天的日期
        const current = start.clone()
        while (current.isSameOrBefore(end)) {
          dates.push(current.format('YYYY-MM-DD'))
          current.add(1, 'day')
        }
        break
      }
      case 'week': {
        // 获取每周一的日期
        const current = start.clone().startOf('week')
        while (current.isSameOrBefore(end)) {
          dates.push(current.format('YYYY-MM-DD'))
          current.add(1, 'week')
        }
        break
      }
      case 'month': {
        // 获取每月1号的日期
        const current = start.clone().startOf('month')
        while (current.isSameOrBefore(end)) {
          dates.push(current.format('YYYY-MM'))
          current.add(1, 'month')
        }
        break
      }
    }
    return dates
  }

  searchRequirementData() {
    if (this.dateTimeValue.value.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择要查询的日期'
      })
      return
    }
    let startDate = ''
    let endDate = ''
    switch (this.dateValue.value) {
      case 'date':
        startDate = this.dateTimeValue.value[0]
        endDate = this.dateTimeValue.value[1]
        break
      case 'week': {
        const weekData = this.dateTimeValue.value.map(item => {
          const match = item?.split('-')
          if (match) {
            const year = Number(match[0])
            const week = Number(match[1].replace('周', ''))
            return moment()
              .year(year)
              .week(week)
              .startOf('week')
              .format('YYYY-MM-DD')
          }
          return item
        })
        startDate = weekData[0]
        endDate = weekData[1]
        break
      }
      case 'month': {
        const monthData = this.dateTimeValue.value.map(item => {
          return moment(item)
            .startOf('month')
            .format('YYYY-MM-DD')
        })
        startDate = monthData[0]
        endDate = monthData[1]
        break
      }
    }
    console.log(startDate, endDate)
    this.dateRange = this.getDateRange(startDate, endDate)

    this.getRequirementData(this.datePicker.value, [startDate, endDate])
  }

  async getRequirementData(type: string, date?: string[]) {
    const params = {
      timeType: type == 'date' ? 'DAY' : type.toUpperCase(),
      start: this.startDate,
      end: this.endDate
    }
    if (date) {
      params.start = date[0]
      params.end = date[1]
    }
    const result = (await Api.post('requirement', 'Requirement', 'dashboard', {
      data: [params]
    })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.collectData.value = {
        customerNeeds: result.data.unfinishedCustomerNeedsTotalNumber,
        planActivity: result.data.unfinishedProductNeedsSectionTotalNumber,
        productNeeds: result.data.unfinishedProductNeedsTotalNumber,
        verificationPlan: result.data.unfinishedVerificationPlanTotalNumber
      }
      // 合并没有指定人员的数据
      const verificationPlan: Record<string, any>[] = []
      result.data.finishedVerificationPlanStatistic.forEach((item: any) => {
        if (item.key) {
          verificationPlan.push(item)
        } else {
          const index = verificationPlan.findIndex(item => !item.key)
          if (index < 0) {
            verificationPlan.push(item)
          } else {
            verificationPlan[index].closedCount += item.closedCount
            verificationPlan[index].totalCount += item.totalCount
          }
        }
      })
      this.handleTrendAndChangeData(result.data.newAddedProductNeedsStatistic, result.data.newAddedProductNeedsChangeStatistic)
      this.handleVerificationPlan(
        verificationPlan
          .sort((a: any, b: any) => b.closedCount - a.closedCount)
          .slice(0, 5)
          .reverse()
      )
      this.handleProductNeedsType(result.data.productNeedsTypeStatistic)
    }
  }

  handleTrendAndChangeData(trendData: any, trendChangeData: any) {
    const dateRange = this.dateTimeValue.value.length > 0 ? this.dateRange : this.newXAxisData.value
    debugger
    this.trendData.value = dateRange.map(item => {
      const data = trendData.find((value: Record<string, unknown>) => value.key == item)
      return data ? data['totalCount'] : 0
    })
    this.changeTrendData.value = dateRange.map(item => {
      const data = trendChangeData.find((value: Record<string, unknown>) => value.key == item)
      return data ? data['totalCount'] : 0
    })
    if (this.dateValue.value == 'week') {
      this.barXAxisData.value = dateRange.map(item => {
        const momentDate = moment(item)
        const year = momentDate.week() === 1 && momentDate.month() === 11 ? momentDate.year() + 1 : momentDate.year()
        return year + '-' + momentDate.week() + '周'
      })
    } else {
      this.barXAxisData.value = dateRange
    }
  }

  handleVerificationPlan(data: any) {
    this.emptyVerificationPlan.value = data.length == 0
    this.verificationPlanYAxis.value.data = data.map((item: any) =>
      item.key ? this.userList.value.find((user: any) => user.value == item.key)?.label?.split('@')[0] : '其他'
    )
    const closeCount = data.map((item: any) => Number(item.closedCount))
    const unCloseCount = data.map((item: any) => Number(item.totalCount) - Number(item.closedCount))
    this.verificationPlanSeries.value = [
      {
        name: '已完成',
        type: 'bar',
        stack: 'total',
        color: '#82cf7e',
        data: closeCount
      },
      {
        name: '未完成',
        type: 'bar',
        stack: 'total',
        color: 'gray',
        data: unCloseCount
      }
    ]
  }

  handleProductNeedsType(data: any) {
    const total = data.reduce((sum: number, item: any) => sum + Number(item.totalCount), 0)
    this.productNeedsTypeTitle.value.text = total
    this.productNeedsTypeSeries.value[0].data = data.map((item: any) => ({
      value: item.totalCount,
      name: item.key
    }))
  }

  formatter(params: any) {
    const percentage = ((params[0].value / (params[0].value + params[1].value)) * 100).toFixed() + '%'
    const text = `
      总数：${params[0].value + params[1].value}<br/>
      ${params[0].marker}已完成：${params[0].value}，${percentage}
    `
    return text
  }

  productNeedsTypeFormatter(params: any) {
    const tip = `${this.requirementTypeList.value[params.name] || '其他'}</br>${params.marker}${params.value}项，${
      params.percent
    }%`
    return tip
  }

  onCalendarChange(val: string[]) {
    if (this.dateValue.value == 'week') {
      const weekData = val.map(item => {
        const match = item?.split('-')
        if (match) {
          const year = Number(match[0])
          const week = Number(match[1].replace('周', ''))
          return moment()
            .year(year)
            .week(week)
            .startOf('week')
            .format('YYYY-MM-DD')
        }
        return item
      })
      this.dateTime.value = weekData
    } else {
      this.dateTime.value = val
    }
  }

  disabledDate(current: any) {
    let type = 'months'
    let startNum = 14
    let endNum = -13
    switch (this.dateValue.value) {
      case 'date':
        type = 'days'
        startNum = 14
        endNum = -13
        break
      case 'week':
        startNum = 104
        endNum = -97
        type = 'days'
        break
      case 'month':
        startNum = 14
        endNum = -13
        type = 'months'
        break
    }
    if (!this.dateTime.value || (this.dateTime.value as any).length === 0) {
      return false
    }
    const startDate = moment(this.dateTime.value[0])
    const endDate = moment(this.dateTime.value[1])
    const tooLate = this.dateTime.value[0] && current.diff(startDate, type) > startNum
    const tooEarly = this.dateTime.value[1] && current.diff(endDate, type) < endNum
    return tooEarly || tooLate
  }
}
