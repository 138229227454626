
import { defineView, EnumDataGridAutoSizeColumnsMode } from '@kmsoft/upf-core'
import { KCompareResultPropOptions, KCompareResultEventEmits } from './interface'
import KCompareResultViewModel from './KCompareResultViewModel'

export default defineView({
  name: 'KCompareResult',
  props: KCompareResultPropOptions,
  emits: KCompareResultEventEmits,
  viewModel: KCompareResultViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { autoSizeColumnsMode: EnumDataGridAutoSizeColumnsMode.FILL }
  }
})
