import { BaseViewModel, ViewModelOptions, KNotification, AppContext, ValueChangeEventArgs } from '@kmsoft/upf-core'
import { KPartReportMakeEmitsType, KPartReportMakePropType } from './interface'
import { ref, watch } from 'vue'
import { Api, EnumRequestCode, loadingHandle, ObjBusinessParam } from '@kmsoft/ebf-common'
import dayjs from 'dayjs'
import _ from 'lodash'
import { KPartSelectorViewModel } from '../../../../controls/part-selector'
import { Devision, Factory, MaterialType, ProcurementType, SalesOrganization } from '../interface'

/** KPartReportMake */
export default class KPartReportMakeViewModel extends BaseViewModel<KPartReportMakeEmitsType, KPartReportMakePropType> {
  refPartSelector = ref<KPartSelectorViewModel>()
  objParam = ref<ObjBusinessParam>()

  constructor(options: ViewModelOptions<KPartReportMakePropType>) {
    super(options)
    this.objParam.value = options.props.objParam

    watch(
      () => this.props.objParam,
      newValue => newValue && this.init(newValue),
      {
        immediate: true
      }
    )
  }

  /**根节点*/
  root = ref<any>()
  /**报表数据*/
  data = ref<any>([])
  /**报表表头信息*/
  info = ref<any>({})
  /** 报表零部件名称 */
  partName = ref<any>({})

  viewDidMount() {}

  async init(newValue: ObjBusinessParam) {
    const result = await Api.post('part', 'Part', 'get', {
      data: [newValue.id]
    })
    // const part = result.data
    // this.partName.value = part.number + part.name
    this.root.value = result.data
    this.refPartSelector.value?.setValue(result.data)

    this.loadData()
  }

  changeSelect(value: ValueChangeEventArgs) {
    this.root.value = value.newValue
  }

  async loadData() {
    //this.root.value = this.refPartSelector.value?.getValue()
    this.partName.value = this.root.value.number + this.root.value.name

    if (!this.root.value) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择产品'
      })
      return
    }

    const currentUser = AppContext.current.getIdentity()?.name.split(' ')[0]
    this.info.value = { currentUserName: currentUser, currentDate: dayjs().format('YYYY-MM-DD') }
    loadingHandle.show(this.props.container)
    const result = (await Api.post('part', 'Part', 'reportSelfMadePartSummary', {
      data: [
        {
          root: { id: this.root.value.id, clazz: this.root.value.rdmExtensionType }
        }
      ]
    })) as any
    loadingHandle.remove(this.props.container)
    if (!(result && result.code == EnumRequestCode.SUCCESS)) {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取零部件详情失败',
        details: result.detail
      })
      return
    }
    this.data.value = result.data as any
  }

  getExtAttrValue(attrs: Array<any>, name: string) {
    const attr = _.find(attrs, attr => attr.name == name)
    if (attr) {
      switch (name) {
        case 'Classification':
          return !!attr.value?.name ? attr.value?.name : '--'
        case 'procurementtype':
          return !!attr.value ? ProcurementType[attr.value] || attr.value : '--'
        case 'factory':
          return !!attr.value ? Factory[attr.value] || attr.value : '--'
        case 'MaterialType':
          return !!attr.value ? MaterialType[attr.value] || attr.value : '--'
        case 'Devision':
          return !!attr.value ? Devision[attr.value] || attr.value : '--'
        case 'salesOrganization':
          return !!attr.value ? SalesOrganization[attr.value] || attr.value : '--'
        default:
          return attr.value != null && attr.value != '' ? attr.value : '--'
      }
    }
    return '--'
  }
}
