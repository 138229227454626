import {
  BaseViewModel,
  DataGridLoadResult,
  IKTreeNode,
  IQuery,
  KDataGridViewModel,
  KTreeViewViewModel,
  TreeViewSelectEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KMyRequirementEmitsType, KMyRequirementPropType } from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'

/** KMyRequirement */
export default class KMyRequirementViewModel extends BaseViewModel<KMyRequirementEmitsType, KMyRequirementPropType> {
  /**树组件定义 */
  refTree = ref<KTreeViewViewModel>()
  /** 选中的节点 */
  selectNode = ref<IKTreeNode>()
  /** 网格组件 */
  refRequirementGrid = ref<KDataGridViewModel>()
  /** 选中的节点 */
  selectNodeType = ref<string>('1.1')
  constructor(options: ViewModelOptions<KMyRequirementPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 加载树
   */
  loadTreeData(): Promise<Array<IKTreeNode>> {
    return new Promise((resolve, reject) => {
      resolve([
        {
          id: 'root',
          name: '我的需求',
          leaf: false,
          iconType: 'Node_MyProjectTask',
          children: [
            {
              id: '1',
              name: '我创建的需求',
              leaf: false,
              type: '1',
              iconType: 'Node_MyProjectTask',
              children: [
                {
                  id: '1.1',
                  name: '产品需求',
                  leaf: true,
                  type: '1.1',
                  iconType: 'Node_MyProjectTask',
                  children: []
                },
                {
                  id: '1.2',
                  name: '原始需求',
                  leaf: true,
                  type: '1.2',
                  iconType: 'Node_MyProjectTask',
                  children: []
                }
              ]
            },
            {
              id: '2',
              name: '我订阅的需求',
              leaf: true,
              type: '2',
              iconType: 'Node_MyProjectTask',
              children: []
            }
          ] as Array<IKTreeNode>
        }
      ])
    })
  }

  /**
   * 刷新网格
   */
  setSelectNode(event: TreeViewSelectEventArgs<any>) {
    this.selectNodeType.value = event.node.type
  }
}
