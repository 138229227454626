import { connect, withInstall } from '@kmsoft/upf-core'
import KAllRequirementView from './KAllRequirement.vue'
import KAllRequirementViewModel from './KAllRequirementViewModel'

const KAllRequirement = connect(KAllRequirementView, KAllRequirementViewModel)

export default withInstall(KAllRequirement)
export { KAllRequirement, KAllRequirementView, KAllRequirementViewModel }

// 模板生成文件
// export * from './{{folderName}}'
