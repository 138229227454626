import {
  BaseViewModel,
  EnumItemClickedCancelType,
  EnumToolStripCompType,
  KDataGridViewModel,
  KDialog,
  KNotification,
  SelectOption,
  ToolStripItemClickedEventArgs,
  ViewModelOptions,
  MemoryCacheFactory,
  KDialogClosingEvent,
  EnumDialogResult
} from '@kmsoft/upf-core'
import { KPlanValidationMetricsGridEmitsType, KPlanValidationMetricsGridPropType } from './interface'
import { ref } from 'vue'
import {
  Api,
  EnumRequestCode,
  EnumToolStripItemKeys,
  EnumValidationMetrics,
  KObjectToolStripViewModel,
  ObjBusinessParam,
  ObjectClientSrv,
  UserInfoCache
} from '@kmsoft/ebf-common'
import { KEditForm, KEditFormViewModel } from '../../validation-board/edit-form'
import { KValidationMetricsManage, KValidationMetricsManageViewModel } from '../../validation-metrics-manage'
import { KMetricsEditPanel } from '../../common-validation-metrics/metrics-edit-panel'

/** KPlanValidationMetricsGrid */
export default class KPlanValidationMetricsGridViewModel extends BaseViewModel<
  KPlanValidationMetricsGridEmitsType,
  KPlanValidationMetricsGridPropType
> {
  /** 对象 */
  objParam = ref<ObjBusinessParam>()
  mainContainer = ref<HTMLElement>()
  /** 验证指标网格 */
  refValidationMetricsGrid = ref<KDataGridViewModel>()
  /** 验证指标工具栏 */
  refMetricsToolStrip = ref<KObjectToolStripViewModel>()
  /** 指标类型候选值 */
  validationMetricsTypeOptions = ref<Array<SelectOption>>([])
  /** 人员列表 */
  userList = ref<Record<string, any>[]>([])
  /** 指标验证结果 */
  validationResultOptions = ref<any[]>([
    { value: 'NOT_EXECUTED', label: '未执行' },
    { value: 'EXECUTING', label: '执行中' },
    { value: 'BLOCKED', label: '阻塞' },
    { value: 'SUCCESS', label: '成功' },
    { value: 'FAILED', label: '失败' }
  ])
  productNeed = ref<Record<string, any>>()
  oldRefMetricsParams = ref<Record<string, any>[]>([])
  actionDisabled = ref<boolean>(false)

  constructor(options: ViewModelOptions<KPlanValidationMetricsGridPropType>) {
    super(options)
    if (!this.props.isDesigner) {
      const model = this.props.api.getCurrentPanelViewModel() as any
      this.objParam.value = {
        id: model?.stateValue.value.id,
        modelCode: model?.stateValue.value.rdmExtensionType
      }
    }
    this.getUserData()
  }

  viewDidMount() {
    this.getProductNeed()
  }

  async getList() {
    const list = await ObjectClientSrv.getPropertyList('ValidationMetrics', 'Type')
    this.validationMetricsTypeOptions.value = list
  }

  getTypeLabel(value: string) {
    const data = this.validationMetricsTypeOptions.value.find(item => item.value == value)
    return data?.label || '--'
  }

  /** 加载指标网格 */
  async loadMetricsData() {
    if (this.props.isDesigner) return []
    await this.getList()
    const obj = this.objParam.value
    const params = {
      data: [obj?.id]
    }
    const result = await Api.post('requirement', 'ValidationMetrics', 'listByPlanId', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.oldRefMetricsParams.value = result.data
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取验证指标失败',
        details: result.detail
      })
      return
    }
  }

  async getProductNeed() {
    const obj = this.objParam.value
    const params = {
      data: [obj?.id]
    }
    const result = await Api.post('requirement', 'VerificationPlan', 'getAssociatedRequirement', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.productNeed.value = result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取需求失败',
        details: result.detail
      })
      return
    }
  }

  getUserData() {
    const cacheInstance = MemoryCacheFactory.get<UserInfoCache>(UserInfoCache.cacheKey)
    this.userList.value = cacheInstance.getUserOptionList()
  }

  /** 指标操作按钮 */
  metricsToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD,
      title: '添加',
      icon: 'plus-circle',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
      title: '移除',
      icon: 'delete',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'delete'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  /** 指标操作 */
  async onMetricsToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    if (this.props.isDesigner) return
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.add()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.removeMetrics()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refValidationMetricsGrid.value?.refresh()
        break
      default:
        break
    }
  }

  add() {
    KDialog.show({
      title: '添加验证指标',
      size: { width: 900, height: 650 },
      props: {
        showToolStrip: false,
        objParam: {
          id: this.productNeed.value?.id,
          modelCode: this.productNeed.value?.rdmExtensionType
        },
        oldRefMetricsParams: this.oldRefMetricsParams.value
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      getContainer: this.mainContainer.value,
      content: KValidationMetricsManage,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KValidationMetricsManageViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const metricsRows = formViewModel.getValue()
        if (!metricsRows) {
          event.cancel = true
          return
        }
        // 关联指标
        const reqAndMetricsLinks = metricsRows.map(item => {
          return {
            id: item.id,
            clazz: item.rdmExtensionType
          }
        })
        // 验证计划
        const plan = {
          id: this.objParam.value?.id,
          clazz: this.objParam.value?.modelCode
        }
        // 添加到验证计划
        const result = await Api.post('requirement', 'VerificationPlan', 'addToPlan', { data: [reqAndMetricsLinks, plan] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refValidationMetricsGrid.value?.refresh()
          KNotification.success('添加成功！')
        } else {
          KNotification.error({
            title: '添加失败',
            content: result.message
          })
          return
        }
      }
    })
  }

  /** 从计划移除指标 */
  removeMetrics() {
    const rows = this.refValidationMetricsGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认移除吗？移除后不可恢复!',
      onOk: async () => {
        // 获取对象id
        const rowIds = rows!.map(row => row.id)
        const reqParam = { data: [rowIds] }

        Api.post('requirement', 'ValidationMetrics', 'batchRemoveFromPlan', reqParam).then(result => {
          if (result && result.code == EnumRequestCode.SUCCESS) {
            // 刷新网格
            this.refValidationMetricsGrid.value?.removeSelectedRows()
            //this.refValidationMetricsGrid.value?.refresh()
            KNotification.success({
              title: '移除成功',
              content: result.message
            })
            return Promise.resolve({
              rows: result.data,
              total: result.data.length
            })
          } else {
            KNotification.error({
              title: '移除失败',
              content: result.message,
              details: result.detail
            })
            return
          }
        })
      }
    })
  }

  /** 打开指标对象 */
  async openMetricsTab(row: any) {
    const param = {
      id: row?.validationMetrics.id,
      modelCode: row?.validationMetrics.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row?.validationMetrics.className!)
    }
    ObjectClientSrv.openObj(param)
    // const tabKey = `${param.id}#${param.modelCode}`
    // const objResult = (await ObjectClientSrv.getObjBusiness(param)) as Record<string, any>
    // const title = clsCodeMap.get(objResult.rdmExtensionType) + ':' + ObjectClientSrv.getObjBusinessDesc(objResult)
    // PageManager.openPage(tabKey, title, KMetricsEditPanel, { objParam: param })
  }

  /** 指标通过 */
  async passMetrics(row: any) {
    if (row.result == 'PASS') {
      KNotification.warn({
        message: '系统提示',
        description: '当前指标已通过, 不可重复操作'
      })
      return
    }
    const reqParam = {
      data: [[row?.id]]
    }
    const result = await Api.post('requirement', 'ValidationMetrics', 'passMetricsWithPlan', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.refValidationMetricsGrid.value?.refresh()
      KNotification.success({
        title: '操作成功',
        content: result.message
      })
      return result.data
    } else {
      KNotification.error({
        title: '操作失败',
        content: result.message || '指标通过失败',
        details: result.detail
      })
      return
    }
  }

  /** 编辑 */
  edit(row: any) {
    if (this.actionDisabled.value) {
      return
    }
    KDialog.show({
      title: '修改验证结果',
      size: { width: 400, height: 200 },
      props: {
        formData: { id: row.id, result: row.result }
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KEditForm,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const formViewModel = event.viewModel as KEditFormViewModel
        const formData = formViewModel.getValue()
        if (!formData) {
          KNotification.warn({
            message: '系统提示',
            description: '验证结果不能为空'
          })
          event.cancel = true
          return
        }
        const params = {
          data: [[{ id: row.id, result: formData }]]
        }
        const result = await Api.post('requirement', 'ValidationMetrics', 'switchVerificationPlanAndMetricsResult', params)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refValidationMetricsGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '系统提示',
            content: result.message || '操作失败',
            details: result.detail
          })
          event.cancel = true
        }
      }
    })
  }

  setItemDisabled() {
    this.refMetricsToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD, true)
    this.refMetricsToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE, true)
    this.refMetricsToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD, true)
    this.actionDisabled.value = true
  }
}
