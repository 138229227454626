import { connect, withInstall } from '@kmsoft/upf-core'
import KValidationPlanTabView from './KValidationPlanTab.vue'
import KValidationPlanTabViewModel from './KValidationPlanTabViewModel'

const KValidationPlanTab = connect(KValidationPlanTabView, KValidationPlanTabViewModel)

export default withInstall(KValidationPlanTab)
export { KValidationPlanTab, KValidationPlanTabView, KValidationPlanTabViewModel }

// 模板生成文件
// export * from './{{folderName}}'
