import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { style: {"padding":"8px 0px","width":"100%","height":"100%"} }
const _hoisted_2 = { class: "layout-content-wrapper" }
const _hoisted_3 = { style: {"width":"100%","height":"100%","padding-bottom":"8px","display":"flex","min-height":"40px"} }
const _hoisted_4 = { style: {"position":"absolute","right":"10px"} }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { style: {"margin-left":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tree_view = _resolveComponent("k-tree-view")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_input_search = _resolveComponent("k-input-search")!
  const _component_k_checkbox = _resolveComponent("k-checkbox")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_lifecycle_state_tag = _resolveComponent("k-lifecycle-state-tag")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_data_grid_check_box_column = _resolveComponent("k-data-grid-check-box-column")!
  const _component_k_data_grid_date_time_column = _resolveComponent("k-data-grid-date-time-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_split_container = _resolveComponent("k-split-container")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_split_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_k_split_pane, {
            size: 15,
            minSize: 15,
            maxSize: 50
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_panel, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_k_tree_view, {
                      ref: _ctx.$vm.refTree,
                      "default-expanded-level": 0,
                      unselectablePredicate: (node)=>node!.id=='root',
                      autoSelectFirstNode: true,
                      loadData: _ctx.$vm.loadTreeData,
                      onAfterSelect: _cache[0] || (_cache[0] = (event) => _ctx.$vm.setSelectNode(event))
                    }, null, 8, ["unselectablePredicate", "loadData"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_k_split_pane, null, {
            default: _withCtx(() => [
              _createVNode(_component_k_split_container, { horizontal: true }, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_k_panel, null, {
                      header: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          (!_ctx.$vm.showBox)
                            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                                key: 0,
                                icon: "plus",
                                name: "create",
                                title: "创建",
                                onClick: _cache[1] || (_cache[1] = () => _ctx.$vm.create())
                              }))
                            : _createCommentVNode("", true),
                          (!_ctx.$vm.showBox)
                            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                                key: 1,
                                icon: "delete",
                                name: "delete",
                                title: "删除",
                                onClick: _cache[2] || (_cache[2] = () => _ctx.$vm.delete())
                              }))
                            : _createCommentVNode("", true),
                          (!_ctx.$vm.showBox)
                            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                                key: 2,
                                icon: "sync",
                                name: "withdraw",
                                title: "撤回",
                                onClick: _cache[3] || (_cache[3] = () => _ctx.$vm.withdraw())
                              }))
                            : _createCommentVNode("", true),
                          (!_ctx.$vm.showBox)
                            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                                key: 3,
                                icon: "AddToFlow",
                                name: "addWorkflow",
                                title: "加入流程",
                                onClick: _cache[4] || (_cache[4] = () => _ctx.$vm.addWorkflow())
                              }))
                            : _createCommentVNode("", true),
                          (!_ctx.$vm.showBox)
                            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                                key: 4,
                                icon: "ViewFlowObj",
                                name: "viewWorkflow",
                                title: "查看流程",
                                onClick: _cache[5] || (_cache[5] = () => _ctx.$vm.viewWorkflow())
                              }))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_k_input_search, {
                              value: _ctx.$vm.queryCriteria,
                              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.$vm.queryCriteria) = $event)),
                              placeholder: "请输入名称或编码",
                              onSearch: _cache[7] || (_cache[7] = () => _ctx.$vm.search()),
                              style: {"width":"300px","padding":"0 20px 0 0"}
                            }, null, 8, ["value"]),
                            (_ctx.$vm.showBox)
                              ? (_openBlock(), _createBlock(_component_k_checkbox, {
                                  key: 0,
                                  checked: _ctx.$vm.showRead,
                                  "onUpdate:checked": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.$vm.showRead) = $event)),
                                  style: {"padding":"0 30px"},
                                  onChange: _cache[9] || (_cache[9] = () => _ctx.$vm.changShowReadValue())
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" 显示已读 ")
                                  ]),
                                  _: 1
                                }, 8, ["checked"]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_k_data_grid, {
                          rowModelType: _ctx.rowModelType,
                          class: "distribution-grid",
                          rowKey: "id",
                          name: "distributionGrid",
                          pagination: _ctx.pagination,
                          ref: _ctx.$vm.refDistributionGrid,
                          loadData: _ctx.$vm.loadData
                        }, {
                          columns: _withCtx(() => [
                            _createVNode(_component_k_data_grid_text_box_column, {
                              id: "name",
                              name: "name",
                              dataPropertyName: "name",
                              headerText: "名称",
                              align: "center"
                            }),
                            _createVNode(_component_k_data_grid_template_column, {
                              id: "number",
                              name: "number",
                              dataPropertyName: "number",
                              headerText: "编码",
                              align: "center"
                            }, {
                              default: _withCtx(({ row }) => [
                                _createElementVNode("a", {
                                  href: "javascript:void(0)",
                                  onClick: () => _ctx.$vm.openDistribution(row)
                                }, [
                                  _createElementVNode("span", _hoisted_6, _toDisplayString(row.number), 1)
                                ], 8, _hoisted_5)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_k_data_grid_template_column, {
                              id: "lifecycleState",
                              name: "lifecycleState",
                              dataPropertyName: "lifecycleState",
                              headerText: "生命周期状态",
                              width: "120",
                              align: "center"
                            }, {
                              default: _withCtx(({ row }) => [
                                _createVNode(_component_k_lifecycle_state_tag, { row: row }, null, 8, ["row"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_k_data_grid_combo_box_column, {
                              id: "status",
                              name: "status",
                              dataPropertyName: "status",
                              headerText: "接收状态",
                              width: "110",
                              options: _ctx.$vm.distributionStatusOptions
                            }, null, 8, ["options"]),
                            _createVNode(_component_k_data_grid_check_box_column, {
                              id: "withdrawn",
                              name: "withdrawn",
                              dataPropertyName: "withdrawn",
                              headerText: "撤回状态",
                              align: "center",
                              width: "110"
                            }),
                            _createVNode(_component_k_data_grid_date_time_column, {
                              id: "receivingTime",
                              name: "receivingTime",
                              dataPropertyName: "receivingTime",
                              headerText: "接收时间",
                              align: "center"
                            }),
                            _createVNode(_component_k_data_grid_date_time_column, {
                              id: "startTime",
                              name: "startTime",
                              dataPropertyName: "startTime",
                              headerText: "开始时间",
                              align: "center"
                            }),
                            _createVNode(_component_k_data_grid_date_time_column, {
                              id: "endTime",
                              name: "endTime",
                              dataPropertyName: "endTime",
                              headerText: "结束时间",
                              align: "center"
                            }),
                            _createVNode(_component_k_data_grid_date_time_column, {
                              id: "createTime",
                              name: "createTime",
                              dataPropertyName: "createTime",
                              headerText: "创建时间",
                              align: "center"
                            })
                          ]),
                          _: 1
                        }, 8, ["rowModelType", "pagination", "loadData"])
                      ]),
                      _: 1
                    })
                  ])), [
                    [_directive_focus]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}