import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KLifecycleStateTagEmitsType, KLifecycleStateTagPropType } from './interface'
import { EnumLifecycleState, ObjectClientSrv } from '../../index'

/** KLifecycleStateTag */
export default class KLifecycleStateTagViewModel extends BaseViewModel<KLifecycleStateTagEmitsType, KLifecycleStateTagPropType> {
  constructor(options: ViewModelOptions<KLifecycleStateTagPropType>) {
    super(options)
  }

  viewDidMount() {}
  public displayValue() {
    const data = this.props.row
    let displayValue = '--'
    let lifecycleState = null
    let lifecycleStateCode = null
    if (data && Object.keys(data).length) {
      lifecycleState = data.lifecycleState
      lifecycleStateCode = data.lifecycleStateCode
      if (lifecycleState) {
        displayValue = lifecycleState.name
        displayValue
      } else {
        const options = EnumLifecycleState._list
        const value = lifecycleStateCode
        displayValue = options.find((item: { value: any }) => item.value === value)?.label
      }
    }
    return displayValue
  }
  lifecycleStateTags() {
    const data = this.props.row
    let lifecycleState = null
    let lifecycleStateCode = null
    if (data && Object.keys(data).length) {
      lifecycleState = data.lifecycleState
      lifecycleStateCode = data.lifecycleStateCode
      if (lifecycleState) {
        const phase = ObjectClientSrv.getLifeCycleStatePhase(lifecycleState.internalName)
        return phase
      } else {
        return lifecycleStateCode
      }
    }
    return ''
  }
}
