import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_folder_manage = _resolveComponent("k-folder-manage")!

  return (_openBlock(), _createBlock(_component_k_folder_manage, {
    ref: _ctx.$vm.refCommonFolderManage,
    loadData: _ctx.$vm.loadTreeData,
    modelCode: "Electroniccomponents",
    modelGroup: "part",
    schemaType: "Electroniccomponents_folder",
    isLayout: true,
    operationClick: _ctx.$vm.onOperationClick
  }, null, 8, ["loadData", "operationClick"]))
}