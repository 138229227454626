import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../../../object-tool-strip'
import { EnumToolStripItemKeys } from '../../types'
import { EnumClassTemplate } from '../../../../client-srv'
import { EnumPurviewCode } from '../../../../common'

export const toolStripItemStructManage: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE,
  title: '结构管理',
  icon: 'file-protect',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+p',
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  supportedObjClsTempletID: [EnumClassTemplate.CUSTOMER_NEEDS]
}

export const toolStripItemCreateProductNeeds: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_PRODUCT_NEEDS,
  title: '创建产品需求',
  icon: 'file-protect',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+p',
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  supportedObjClsTempletID: [EnumClassTemplate.CUSTOMER_NEEDS],
  disabled: true
}

export const toolStripItemBegin: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEGIN,
  title: '开始',
  icon: 'file-protect',
  visible: false,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+p',
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  supportedObjClsTempletID: [EnumClassTemplate.CUSTOMER_NEEDS]
}

export const toolStripItemEnd: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_END,
  title: '完成',
  icon: 'file-protect',
  visible: false,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+p',
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  supportedObjClsTempletID: [EnumClassTemplate.CUSTOMER_NEEDS]
}

export const toolStripItemCreateChangeRequest: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_REQUIREMENT_CHANGE,
  title: '需求变更',
  icon: 'file-protect',
  visible: false,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+p',
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  supportedObjClsTempletID: [EnumClassTemplate.PRODUCT_NEEDS]
}

export const toolStripItemSubscribe: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SUBSCRIBE_REQUIREMENT,
  title: '订阅',
  icon: 'file-protect',
  visible: false,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+p',
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  supportedObjClsTempletID: [EnumClassTemplate.CUSTOMER_NEEDS]
}

export const toolStripItemUnsubscribe: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_UNSUBSCRIBE_REQUIREMENT,
  title: '取消订阅',
  icon: 'file-protect',
  visible: false,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+p',
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  supportedObjClsTempletID: [EnumClassTemplate.CUSTOMER_NEEDS]
}

export const toolStripMoreOperation: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_MORE,
  title: '更多操作',
  icon: 'more',
  visible: true,
  compType: EnumToolStripCompType.CONTEXT_MENU,
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  items: [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW,
      title: '加入流程',
      icon: 'AddToFlow',
      visible: true,
      purviewId: EnumPurviewCode.AddWorkflow,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW,
      title: '查看流程',
      icon: 'ViewFlowObj',
      visible: true,
      purviewId: EnumPurviewCode.ViewWorkflow,
      compType: EnumToolStripCompType.BUTTON
    }
  ]
}

export const toolStripItemAddFlow: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW,
  title: '需求发布',
  icon: 'AddToFlow',
  visible: true,
  purviewId: EnumPurviewCode.AddWorkflow,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjNumMode: EnumSupportObjectNumMode.Single
}

export const toolStripItemViewFlow: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW,
  title: '查看流程',
  icon: 'ViewFlowObj',
  visible: true,
  purviewId: EnumPurviewCode.ViewWorkflow,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjNumMode: EnumSupportObjectNumMode.Single
}

export const getCustomerNeedsOperationToolStripItems = (): Array<ObjectToolStripItem> => {
  return [
    toolStripItemStructManage,
    // toolStripMoreOperation,
    toolStripItemAddFlow,
    toolStripItemViewFlow,
    toolStripItemBegin,
    toolStripItemEnd,
    toolStripItemCreateProductNeeds,
    toolStripItemCreateChangeRequest,
    toolStripItemSubscribe,
    toolStripItemUnsubscribe,
  ]
}
