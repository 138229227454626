import { BaseViewModel, KFormViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { IFormProp, KEditFormEmitsType, KEditFormPropType } from './interface'
import { ref } from 'vue'

/** KEditForm */
export default class KEditFormViewModel extends BaseViewModel<KEditFormEmitsType, KEditFormPropType> {
  refForm = ref<KFormViewModel<IFormProp>>()
  selectValue = ref<string>('')
  productOption = ref<any[]>([
    { value: 'NOT_EXECUTED', label: '未执行' },
    { value: 'EXECUTING', label: '执行中' },
    { value: 'BLOCKED', label: '阻塞' },
    { value: 'SUCCESS', label: '成功' },
    { value: 'FAILED', label: '失败' }
  ])
  formData = ref<IFormProp>({
    id: '',
    result: ''
  })
  /** 校验规则 */
  rules = {
    name: [{ required: true, message: '产品库名称不能为空', trigger: 'blur' }]
  }
  constructor(options: ViewModelOptions<KEditFormPropType>) {
    super(options)
    if (this.props.formData) {
      this.formData.value = this.props.formData
      this.selectValue.value = this.props.formData.result
    }
  }

  viewDidMount() {}

  getValue() {
    return this.selectValue.value
  }
}
