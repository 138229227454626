import { connect, withInstall } from '@kmsoft/upf-core'
import KMyProductNeedsView from './KMyProductNeeds.vue'
import KMyProductNeedsViewModel from './KMyProductNeedsViewModel'

const KMyProductNeeds = connect(KMyProductNeedsView, KMyProductNeedsViewModel)

export default withInstall(KMyProductNeeds)
export { KMyProductNeeds, KMyProductNeedsView, KMyProductNeedsViewModel }

// 模板生成文件
// export * from './{{folderName}}'
