import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tree_select = _resolveComponent("k-tree-select")!

  return (_ctx.$vm.readonly)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$vm.labelName), 1)
      ]))
    : (_openBlock(), _createBlock(_component_k_tree_select, _mergeProps({ key: 1 }, _ctx.$props, {
        value: _ctx.$vm.stateValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.stateValue) = $event)),
        showSearch: true,
        style: {"width":"100%"},
        "dropdown-style": { maxHeight: '400px', overflow: 'auto' },
        placeholder: "请选择分类",
        "allow-clear": "",
        disabled: _ctx.$vm.disabled,
        "tree-default-expand-all": "",
        "tree-data": _ctx.$vm.treeData,
        "tree-node-filter-prop": "label",
        onSelect: _cache[1] || (_cache[1] = (value, node, extra) => _ctx.$vm.selectChange(value, node, extra)),
        onClear: _cache[2] || (_cache[2] = () => _ctx.$vm.clear())
      }), {
        title: _withCtx(({ label }) => [
          _createElementVNode("span", null, _toDisplayString(label), 1)
        ]),
        _: 1
      }, 16, ["value", "disabled", "tree-data"]))
}