
import { defineView, exposeComponentEvents, KSelectEventEmits } from '@kmsoft/upf-core'
import { KLifecycleStateEditorPropOptions, KLifecycleStateEditorEventEmits } from './interface'
import KLifecycleStateEditorViewModel from './KLifecycleStateEditorViewModel'
import { EnumLifecycleState, EnumLifecycleStateColor } from '../../../../client-srv'

export default defineView({
  name: 'KLifecycleStateEditor',
  props: KLifecycleStateEditorPropOptions,
  emits: KLifecycleStateEditorEventEmits,
  viewModel: KLifecycleStateEditorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      if (vm.stateReadonly.value) {
        return (
          <div style="width:100%">
            <div class="k-render-dropdown-text" title="displayValue" style="display:flex">
              {vm.hasValue.value ? (
                <k-tag
                  style="flex:1;text-align:center;width:56px;max-width:60px"
                  color={EnumLifecycleStateColor[vm.lifecycleStateTags()]}
                >
                  {vm.displayValue()}
                </k-tag>
              ) : (
                '--'
              )}
            </div>
          </div>
        )
      } else {
        return <k-input ref={vm.refSelect.value} {...props} value={vm.displayValue()} />
      }
    }
  }
})
