
import { defineView, EnumDataGridRowModelType, KGridPagination } from '@kmsoft/upf-core'
import { KAllCustomerNeedsPropOptions, KAllCustomerNeedsEventEmits } from './interface'
import KAllCustomerNeedsViewModel from './KAllCustomerNeedsViewModel'
import { clsCodeMap } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KAllCustomerNeeds',
  props: KAllCustomerNeedsPropOptions,
  emits: KAllCustomerNeedsEventEmits,
  viewModel: KAllCustomerNeedsViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      pagination: { pageIndex: 1, pageSize: 100 } as KGridPagination,
      clsCodeMap
    }
  }
})
