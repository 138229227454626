import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam } from '@kmsoft/ebf-common'

/** 参数 **/
export const KCustomerNeedsCreateWithFolderSelectorPropOptions = {
  ...BaseViewPropOptions,
  formValue: VuePropTypes.createType<any>().def(),
  showFolderSelector: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KCustomerNeedsCreateWithFolderSelectorPropType = ViewPropsTypeExtract<
  typeof KCustomerNeedsCreateWithFolderSelectorPropOptions
>

/** 事件 */
export const KCustomerNeedsCreateWithFolderSelectorEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KCustomerNeedsCreateWithFolderSelectorEmitsType = ViewEmitsTypeExtract<
  typeof KCustomerNeedsCreateWithFolderSelectorEventEmits
>
