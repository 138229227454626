import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3dbd39f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = { class: "title flex-center" }
const _hoisted_3 = { class: "grid" }
const _hoisted_4 = {
  key: 0,
  class: "mr5"
}
const _hoisted_5 = {
  key: 1,
  class: "mr5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_lifecycle_state_tag = _resolveComponent("k-lifecycle-state-tag")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.isReference ? `参考基线：${_ctx.title}` : `对比基线：${_ctx.title}`), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_k_data_grid, {
        dataSource: _ctx.dataSource,
        getRowClass: _ctx.getRowClass,
        getCellClass: _ctx.getCellClass,
        isMultipleSelection: false
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_template_column, {
            headerText: "对象编码",
            dataPropertyName: "number",
            align: "center"
          }, {
            default: _withCtx(({row}) => [
              (row.diffType == 'propertyDiff')
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                    _createVNode(_component_k_icon, {
                      type: "baseline_propertyDiff_cover",
                      color: "#f5222d"
                    })
                  ]))
                : _createCommentVNode("", true),
              (row.diffType == 'recordAdd')
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    _createVNode(_component_k_icon, {
                      type: "baseline_recordAdd_cover",
                      color: "#52c41a"
                    })
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(row.number), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            headerText: "对象名称",
            dataPropertyName: "name",
            align: "center",
            width: "150"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            headerText: "实体类型",
            dataPropertyName: "classAndView",
            align: "center",
            width: "150"
          }, {
            default: _withCtx(({ cellValue}) => [
              _createTextVNode(_toDisplayString(cellValue), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            headerText: "版本号",
            dataPropertyName: "versionInfo",
            align: "center",
            width: "100"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "lifecycleState",
            name: "lifecycleState",
            dataPropertyName: "lifecycleState",
            headerText: "状态",
            width: "120",
            align: "center"
          }, {
            default: _withCtx(({ row }) => [
              _createVNode(_component_k_lifecycle_state_tag, { row: row }, null, 8, ["row"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["dataSource", "getRowClass", "getCellClass"])
    ])
  ]))
}