import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam } from '@kmsoft/ebf-common'

/** 参数 **/
export const KProductNeedsCreateWithFolderSelectorPropOptions = {
  ...BaseViewPropOptions,
  customerNeeds: VuePropTypes.createType<any>().def(),
  formValue: VuePropTypes.createType<any>().def()
}

/** 参数类型 **/
export type KProductNeedsCreateWithFolderSelectorPropType = ViewPropsTypeExtract<
  typeof KProductNeedsCreateWithFolderSelectorPropOptions
>

/** 事件 */
export const KProductNeedsCreateWithFolderSelectorEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KProductNeedsCreateWithFolderSelectorEmitsType = ViewEmitsTypeExtract<
  typeof KProductNeedsCreateWithFolderSelectorEventEmits
>
