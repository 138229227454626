import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  LayoutRenderElement,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KDataElementTree, KDesignerControl } from '../../../types'
import { BizContextItem, KBizContext } from '../../../types'
import { DEFAULT_BIZ_CONTEXT } from '../../../config'

//#region
/** 页面配置区中的标签页枚举 */
export enum EnumViewConfigTabKey {
  /** 属性 */
  PROPERTY = 'property',
  /** 布局 */
  LAYOUT = 'layout',
  /** 事件 */
  EVENT = 'evnet'
}

export enum EnumCustomElementType {
  /* 日期时间 */
  DATETIME = 'date-time',
  /* 下拉 */
  COMBOBOX = 'combobox',
  /* grid网格 */
  GRID = 'grid',
  /* 数字 */
  NUMBER = 'number',
  /* 筛选按钮 */
  FILTER_BUTTON = 'filter-button'
}
//#endregion

export type ContextItemsLoader = () => Array<BizContextItem>

/** KViewConfig 事件 **/
export const KViewConfigEventEmits = {
  ...BaseViewEventEmits,
  elementChange: (controlName: string, name: string, type: string, value: any) => true
}

/** KViewConfig 参数 **/
export const KViewConfigPropOptions = {
  ...BaseViewPropOptions,
  /** 控件信息 */
  control: VuePropTypes.createType<KDesignerControl>()
    .setRequired()
    .def(),
  /** 当前元素 */
  element: VuePropTypes.createType<LayoutRenderElement>()
    .setRequired()
    .def(),
  /** 业务上下文 */
  bizContext: VuePropTypes.func<() => KBizContext>().def(() => DEFAULT_BIZ_CONTEXT),
  /** 控件类型定义 */
  designerMetaControl: VuePropTypes.createType<Array<KDesignerControl>>().def(),
  /** 数据元素 */
  dataSource: VuePropTypes.createType<KDataElementTree>().def(),
  belongClsCode: VuePropTypes.string().def()
}

/** KViewConfig 参数类型 **/
export type KViewConfigPropType = ViewPropsTypeExtract<typeof KViewConfigPropOptions>

/** KViewConfig事件协议*/
export type KViewConfigEventEmitType = ViewEmitsTypeExtract<typeof KViewConfigEventEmits>
