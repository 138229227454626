import { connect, withInstall } from '@kmsoft/upf-core'
import KLogManageView from './KLogManage.vue'
import KLogManageViewModel from './KLogManageViewModel'

const KLogManage = connect(KLogManageView, KLogManageViewModel)

export default withInstall(KLogManage)
export { KLogManage, KLogManageView, KLogManageViewModel }

// 模板生成文件
// export * from './{{folderName}}'
