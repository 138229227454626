import {
  Api,
  EnumPurviewCode,
  EnumRequestCode,
  KObjectToolStripViewModel,
  ObjectToolStripItem,
  OperationOptionClickEvent
} from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  TreeViewLoadDataFunc,
  DataGridLoadFunction,
  TreeViewSelectEventArgs,
  EnumToolStripCompType,
  KTreeNode,
  ToolStripButtonItem,
  ToolStripItem,
  ToolStripItemClickedEventArgs
} from '@kmsoft/upf-core'
import { IFolder, FolderTreeNode, EnumToolStripItemKeys } from '../folder-manage/folder-tree/interface'

/** 参数 **/
export const KCommonFolderManagePropOptions = {
  ...BaseViewPropOptions,
  /** 文件夹类型 */
  folderType: VuePropTypes.string().def(),
  /** 是否显示工具栏 */
  showToolStrip: VuePropTypes.bool().def(true),

  /** 展开节点时加载右边网格的方法 **/
  loadGridData: VuePropTypes.func<DataGridLoadFunction>().def(),

  /** 工具栏点击事件 */
  toolStripItemClicked: VuePropTypes.func().def,

  /** 网格布局分组 */
  modelGroup: VuePropTypes.string().def(),
  /** 网格布局对象 */
  modelCode: VuePropTypes.string().def(),
  /** 网格布局对象 */
  schemaType: VuePropTypes.string().def(),
  /** 显示操作列 */
  showOperatorColumn: VuePropTypes.bool().def(true),
  /** 操作列 */
  operatorColumn: VuePropTypes.array().def([]),
  /** 操作列事件 */
  operationClick: VuePropTypes.func().def(),
  style: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KCommonFolderManagePropType = ViewPropsTypeExtract<typeof KCommonFolderManagePropOptions>

/** 事件 */
export const KCommonFolderManageEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 工具栏点击事件
   * @param event
   * @returns
   */
  toolStripItemClicked: (event: ToolStripItemClickedEventArgs) => true,
  /**操作项点击事件 */
  operationClick: (event: OperationOptionClickEvent) => true
}

/** 事件类型 **/
export type KCommonFolderManageEmitsType = ViewEmitsTypeExtract<typeof KCommonFolderManageEventEmits>

export class CommonTreeNode extends KTreeNode {
  /**
   * 编码
   */
  nameEn: string
  /**
   * 图标
   */
  iconType: string
  /**
   * 备注
   */
  description: string
  /**
   * 创建人id
   */
  creator: string

  /***
   * 创建时间
   */
  createTime: string

  /**
   * 类型
   */
  type: string

  /**
   * 父id
   */
  parentId: string
  /**
   * 根节点类型
   */
  rootNodeType?: string

  constructor(data: IFolder) {
    super()
    this.id = data.id
    this.nameEn = data.nameEn
    this.name = data.name
    this.leaf = data.leafFlag
    this.iconType = 'folder'
    this.description = data.description
    this.createTime = data.createTime
    this.creator = data.creator
    this.type = data.type
    this.parentId = data.parentId
    this.rootNodeType = data.type
  }
  async populateChildren(populateType: number): Promise<Array<KTreeNode>> {
    const newChild = [] as Array<KTreeNode>
    if (this.leaf) {
      return newChild
    }
    const param = {
      data: [
        this.id,
        {
          rdmPageVO: {
            maxPageSize: 1000,
            curPage: 1,
            pageSize: 1000,
            totalRows: 0,
            totalPages: 0,
            startIndex: 0,
            endIndex: 0
          }
        }
      ]
    }
    const result = await Api.post('folder', 'Folder', 'listChildFolder', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      for (const item of result.data!) {
        item.rootNodeType = this.type
        const childNode = new FolderTreeNode(item as any)
        newChild.push(childNode)
      }
    }
    return newChild
  }
  /**
   * 获取右键菜单
   * @returns 菜单列表
   */
  getContextMenus(): Array<ToolStripItem> {
    const workSpaceRootToolStripItem = [
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.NEW,
        /** 文字 */
        title: '新建',
        /** 是否可见  */
        visible: true,
        purviewId: EnumPurviewCode.CreateFolder,
        icon: 'folder-add',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.REFRESH,
        /** 文字 */
        title: '刷新',
        /** 是否可见  */
        visible: true,
        icon: 'loading-3-quarters',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem
    ] as Array<ToolStripItem & { purviewId?: string }>
    return workSpaceRootToolStripItem
  }
}
