
import { defineView, EnumDataGridRowModelType } from '@kmsoft/upf-core'
import { clsCodeMap, validationResultColor, EnumValidationResult } from '@kmsoft/ebf-common'
import { KValidationBoardPropOptions, KValidationBoardEventEmits } from './interface'
import KValidationBoardViewModel from './KValidationBoardViewModel'
import KChangeStatistics from '../requirement-kanban/change-statistics'

export default defineView({
  name: 'KValidationBoard',
  props: KValidationBoardPropOptions,
  emits: KValidationBoardEventEmits,
  components: { KChangeStatistics },
  viewModel: KValidationBoardViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { clsCodeMap, validationResultColor, EnumValidationResult, rowModelType: EnumDataGridRowModelType.SERVER_SIDE }
  }
})
