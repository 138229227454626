import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e51d73a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-start-center" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_date_time_column = _resolveComponent("k-data-grid-date-time-column")!
  const _component_k_divider = _resolveComponent("k-divider")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_k_tool_strip, { name: "workspaceToolStrip" }, {
          default: _withCtx(() => [
            _createVNode(_component_k_tool_strip_button_item, {
              type: "primary",
              name: "createBtn",
              ref: "refCreateBtn",
              icon: "plus",
              title: "新建",
              onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.create())
            }, null, 512)
          ]),
          _: 1
        }),
        _createVNode(_component_k_button, {
          onClick: _ctx.$vm.search
        }, {
          default: _withCtx(() => [
            _createTextVNode("查询")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        rowModelType: _ctx.rowModelType,
        class: "product-workspace-grid",
        rowKey: "id",
        name: "workspaceGrid",
        pagination: { pageIndex: 1, pageSize: 50 },
        ref: _ctx.$vm.refGrid,
        loadData: _ctx.$vm.loadData,
        autoSizeColumnsMode: _ctx.autoSizeColumnsMode
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "clientName",
            name: "clientName",
            dataPropertyName: "clientName",
            headerText: "客户端名称",
            width: "200",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "clientIP",
            name: "clientIp",
            dataPropertyName: "clientIP",
            headerText: "客户端IP",
            width: "200",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_date_time_column, {
            id: "createTime",
            name: "createTime",
            dataPropertyName: "createTime",
            headerText: "创建时间",
            width: "200",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "action",
            name: "action",
            fixed: "right",
            headerText: "操作",
            width: "200",
            align: "center"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("a", {
                class: "row-operation-item",
                onClick: (e) => _ctx.$vm.update(row)
              }, " 编辑 ", 8, _hoisted_2),
              _createVNode(_component_k_divider, { type: _ctx.vertical }, null, 8, ["type"]),
              _createElementVNode("a", {
                class: "row-operation-item",
                onClick: (e) => _ctx.$vm.delete(row.id)
              }, " 删除 ", 8, _hoisted_3)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["rowModelType", "loadData", "autoSizeColumnsMode"])
    ]),
    _: 1
  }))
}