
import { defineView } from '@kmsoft/upf-core'
import { KInfoMessagePropOptions, KInfoMessageEventEmits } from './interface'
import KInfoMessageViewModel from './KInfoMessageViewModel'

export default defineView({
  name: 'KInfoMessage',
  props: KInfoMessagePropOptions,
  emits: KInfoMessageEventEmits,
  viewModel: KInfoMessageViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})
