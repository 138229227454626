import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e0715a7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-start-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_date_time = _resolveComponent("k-date-time")!
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_date_time_column = _resolveComponent("k-data-grid-date-time-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_k_tool_strip, { name: "workspaceToolStrip" }, {
          default: _withCtx(() => [
            _createVNode(_component_k_tool_strip_button_item, {
              type: "primary",
              name: "createBtn",
              ref: "refCreateBtn",
              icon: "plus",
              title: "客户端关联",
              onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.associateClient())
            }, null, 512)
          ]),
          _: 1
        }),
        _createVNode(_component_k_select, {
          class: "searchClass",
          allowClear: false,
          placeholder: "模块",
          value: _ctx.$vm.models,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.models) = $event)),
          options: _ctx.$vm.modelList,
          "max-tag-count": 1,
          mode: "multiple",
          onChange: _ctx.$vm.search
        }, null, 8, ["value", "options", "onChange"]),
        _createVNode(_component_k_select, {
          class: "searchClass",
          allowClear: false,
          placeholder: "功能",
          value: _ctx.$vm.features,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.features) = $event)),
          options: _ctx.$vm.featureList,
          mode: "multiple",
          "max-tag-count": 1,
          onChange: _ctx.$vm.search
        }, null, 8, ["value", "options", "onChange"]),
        _createVNode(_component_k_select, {
          class: "searchClass",
          allowClear: false,
          placeholder: "客户端名称",
          value: _ctx.$vm.clientIPs,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$vm.clientIPs) = $event)),
          options: _ctx.$vm.clientIpList,
          "max-tag-count": 1,
          mode: "multiple",
          onChange: _ctx.$vm.search
        }, null, 8, ["value", "options", "onChange"]),
        _createVNode(_component_k_select, {
          class: "searchClass",
          allowClear: false,
          placeholder: "用户名称",
          value: _ctx.$vm.userIds,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.$vm.userIds) = $event)),
          options: _ctx.$vm.userList,
          "max-tag-count": 1,
          mode: "multiple",
          onChange: _ctx.$vm.search
        }, null, 8, ["value", "options", "onChange"]),
        _createVNode(_component_k_date_time, {
          type: "range",
          class: "searchClass",
          value: _ctx.$vm.dateTime,
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.$vm.dateTime) = $event)),
          onChange: _ctx.$vm.search
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_k_input, {
          class: "searchClass",
          value: _ctx.$vm.operationUri,
          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.$vm.operationUri) = $event)),
          placeholder: "操作资源",
          allowClear: false
        }, null, 8, ["value"]),
        _createVNode(_component_k_button, {
          onClick: _ctx.$vm.search
        }, {
          default: _withCtx(() => [
            _createTextVNode("查询")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        rowModelType: _ctx.rowModelType,
        class: "product-workspace-grid",
        rowKey: "id",
        name: "workspaceGrid",
        pagination: { pageIndex: 1, pageSize: 50 },
        ref: _ctx.$vm.refGrid,
        loadData: _ctx.$vm.loadData,
        autoSizeColumnsMode: _ctx.autoSizeColumnsMode
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "model",
            name: "model",
            dataPropertyName: "model",
            headerText: "模块",
            width: "200",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "feature",
            name: "feature",
            dataPropertyName: "feature",
            headerText: "功能",
            width: "200",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "operationUri",
            name: "operationUri",
            dataPropertyName: "operationUri",
            headerText: "操作资源",
            width: "300",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "clientName",
            name: "clientName",
            dataPropertyName: "clientName",
            headerText: "客户端名称",
            width: "200",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "clientIP",
            name: "clientIp",
            dataPropertyName: "clientIP",
            headerText: "客户端IP",
            width: "200",
            align: "center"
          }, {
            default: _withCtx(({ cellValue }) => [
              _createElementVNode("span", null, _toDisplayString(cellValue.split(',')[0]), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "userName",
            name: "userName",
            dataPropertyName: "userName",
            headerText: "用户名",
            width: "200",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_date_time_column, {
            id: "createTime",
            name: "createTime",
            dataPropertyName: "createTime",
            headerText: "创建时间",
            width: "200",
            align: "center"
          })
        ]),
        _: 1
      }, 8, ["rowModelType", "loadData", "autoSizeColumnsMode"])
    ]),
    _: 1
  }))
}