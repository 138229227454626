import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b583770"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border1 h100p" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { style: {"margin-left":"5px"} }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_tag = _resolveComponent("k-tag")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_bottom_bar = _resolveComponent("k-bottom-bar")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_collapse = _resolveComponent("k-collapse")!

  return (_openBlock(), _createBlock(_component_k_collapse, {
    activeKey: _ctx.activeKey,
    "onUpdate:activeKey": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.activeKey) = $event)),
    ghost: "",
    class: _normalizeClass([_ctx.activeKey.length ? 'h100p' : ''])
  }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createVNode(_component_k_data_grid, {
          ref: "refDataGrid",
          dataSource: _ctx.signingHistoryList,
          "row-key": "processInstanceId",
          isMultipleSelection: true,
          onRowChanged: _ctx.$vm.rowChanged,
          selectFirstRow: false
        }, {
          columns: _withCtx(() => [
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "流程名称",
              dataPropertyName: "processName",
              id: "processName",
              name: "processName",
              align: "center",
              width: "350"
            }, {
              default: _withCtx(({ cellValue,row }) => [
                _createElementVNode("a", {
                  href: _ctx.$vm.openProcessDetail(row),
                  target: "_blank"
                }, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(cellValue), 1)
                ], 8, _hoisted_2)
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              id: "state",
              dataPropertyName: "state",
              headerText: "流程状态",
              align: "center",
              width: "auto"
            }, {
              default: _withCtx(({ cellValue }) => [
                cellValue
                  ? (_openBlock(), _createBlock(_component_k_tag, {
                      key: 0,
                      style: {"flex":"1","text-align":"center","width":"56px","max-width":"60px"},
                      color: _ctx.ProcessStateColor[cellValue as keyof typeof ProcessStateColor]
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.ProcessState[cellValue as keyof typeof ProcessState]), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              id: "startTime",
              headerText: "开始时间",
              dataPropertyName: "startTime",
              align: "center",
              width: "120"
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              id: "endTime",
              headerText: "结束时间",
              dataPropertyName: "endTime",
              align: "center",
              width: "120"
            }),
            (_ctx.showExport)
              ? (_openBlock(), _createBlock(_component_k_data_grid_template_column, {
                  key: 0,
                  id: "action",
                  name: "action",
                  align: "left",
                  headerText: "操作",
                  width: "100"
                }, {
                  default: _withCtx(({row}) => [
                    _createElementVNode("a", {
                      class: "row-operation-item",
                      onClick: (e) => _ctx.$vm.exportPdf(row)
                    }, "导出pdf", 8, _hoisted_4)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          bottomBar: _withCtx(() => [
            _createVNode(_component_k_bottom_bar, {
              enableExcelExport: "",
              onRefresh: _cache[0] || (_cache[0] = () => _ctx.$vm.refresh(true)),
              onExcelSelectExport: _cache[1] || (_cache[1] = () => _ctx.$vm.exportSelectAsExcel()),
              onExcelExport: _cache[2] || (_cache[2] = () => _ctx.$vm.exportAsExcel())
            })
          ]),
          _: 1
        }, 8, ["dataSource", "onRowChanged"])
      ])
    ]),
    _: 1
  }, 8, ["activeKey", "class"]))
}