import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../../../object-tool-strip'
import { EnumToolStripItemKeys } from '../../types'
import { EnumClassTemplate } from '../../../../client-srv'
import { EnumPurviewCode } from '../../../../common'


export const getRequirementChangeRequestOperationToolStripItems = (): Array<ObjectToolStripItem> => {
  return [{
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW,
    title: '加入流程',
    icon: 'AddToFlow',
    visible: true,
    purviewId: EnumPurviewCode.AddWorkflow,
    compType: EnumToolStripCompType.BUTTON
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW,
    title: '查看流程',
    icon: 'ViewFlowObj',
    visible: true,
    purviewId: EnumPurviewCode.ViewWorkflow,
    compType: EnumToolStripCompType.BUTTON
  }]
}
