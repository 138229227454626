import { BaseViewModel, ViewModelOptions, KDataGridViewModel } from '@kmsoft/upf-core'
import { KChangeTracingEmitsType, KChangeTracingPropType } from './interface'
import { ref, Ref } from 'vue'
import { Api, EnumRequestCode, ObjectClientSrv } from '@kmsoft/ebf-common'
import moment from 'moment'
import { debounce } from 'lodash'

/** KChangeTracing */
export default class KChangeTracingViewModel extends BaseViewModel<KChangeTracingEmitsType, KChangeTracingPropType> {
  constructor(options: ViewModelOptions<KChangeTracingPropType>) {
    super(options)
  }

  refLogicflow: Ref<any> = ref()
  refDataGrid = ref<KDataGridViewModel>()
  allData: Record<string, any>[] = []
  flowData: Record<string, any>
  showFlow = ref<boolean>(false)
  dateTime = ref<[string, string]>([
    moment(new Date())
      .subtract(6, 'days')
      .format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD')
  ])
  lifecycleStateColor: Record<string, string> = {
    Released: '#52c41a',
    UnderReview: '#faad14',
    InWork: '#00a9f1'
  }

  selectValue = ref<string>('ACTIVITY')
  inputValue = ref<string>('')
  selectOptions = [
    { label: '变更活动', value: 'ACTIVITY' },
    { label: '变更单', value: 'ORDER' },
    { label: '变更请求', value: 'REQUEST' },
    { label: '变更问题报告', value: 'ISSUE' }
  ]

  config = {
    stopZoomGraph: false,
    stopScrollGraph: true
  }

  queryData = debounce(() => {
    this.showFlow.value = false
    this.refDataGrid.value?.clearSelectedRows()
    this.refDataGrid.value?.refresh()
  }, 300)

  async loadData() {
    const result = (await Api.post('change', 'ChangeCommon', 'trace', {
      data: [
        {
          itemType: this.selectValue.value,
          start: this.dateTime.value[0],
          end: this.dateTime.value[1],
          searchMsg: this.inputValue.value
        }
      ]
    })) as any

    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.allData = result.data
      return result.data
    }
  }

  flowLoadData(): Record<string, any> {
    return this.flowData
  }

  rowClick(data: Record<string, any>) {
    let value = []
    if (data.data.issueNumber) {
      value = this.allData.filter((item: Record<string, any>) => item.issueNumber == data.data.issueNumber)
      this.flowData = this.buildIssueTree(value)
    } else if (data.data.requestNumber) {
      value = this.allData.filter((item: Record<string, any>) => item.requestNumber == data.data.requestNumber)
      this.flowData = this.buildRequestTree(value)
    } else if (data.data.orderNumber) {
      value = this.allData.filter((item: Record<string, any>) => item.orderNumber == data.data.orderNumber)
      this.flowData = this.buildOrderTree(value)
    } else {
      this.flowData = {
        id: `ac-${data.data.activityNumber}`,
        number: data.data.activityNumber,
        type: '变更活动',
        person: data.data.activityCreator,
        date: data.data.activityCreateTime,
        lifecycleState: data.data.activityLifecycleStateCode
      }
    }
    if (!this.showFlow.value) this.showFlow.value = true
    this.refLogicflow.value?.refresh()
  }

  buildIssueTree(items: Record<string, any>[]): Record<string, any> {
    const map: Record<string, any> = {}

    items.forEach((item: Record<string, any>) => {
      const issueId = item.issueNumber
      const requestId = item.requestNumber
      const orderId = item.orderNumber
      const activityId = item.activityNumber

      // 创建issue节点（如果不存在）
      if (!map[`is-${issueId}`]) {
        map[`is-${issueId}`] = {
          id: `is-${issueId}`,
          number: issueId,
          type: '问题报告',
          person: item.issueCreator,
          date: item.issueCreateTime,
          lifecycleState: item.issueLifecycleStateCode,
          children: []
        }
      }
      if (!requestId) return
      // 创建request节点（如果不存在）
      let request = map[`is-${issueId}`].children.find((c: any) => c.id === `re-${requestId}`)
      if (!request) {
        request = {
          id: `re-${requestId}`,
          number: requestId,
          type: '变更申请',
          person: item.requestCreator,
          date: item.requestCreateTime,
          lifecycleState: item.requestLifecycleStateCode,
          children: []
        }
        map[`is-${issueId}`].children.push(request)
      }
      if (!orderId) return
      // 创建order节点（如果不存在）
      let order = request.children.find((c: any) => c.id === `or-${orderId}`)
      if (!order) {
        order = {
          id: `or-${orderId}`,
          number: orderId,
          type: '变更单',
          person: item.orderCreator,
          date: item.orderCreateTime,
          lifecycleState: item.orderLifecycleStateCode,
          children: []
        }
        request.children.push(order)
      }
      if (!activityId) return
      // 创建activity节点
      const activity = {
        id: `ac-${activityId}`,
        number: activityId,
        type: '变更活动',
        person: item.activityCreator,
        date: item.activityCreateTime,
        lifecycleState: item.activityLifecycleStateCode
      }
      order.children.push(activity)
    })

    return Object.values(map)[0]
  }

  buildRequestTree(items: Record<string, any>[]): Record<string, any> {
    const map: Record<string, any> = {}

    items.forEach((item: Record<string, any>) => {
      const requestId = item.requestNumber
      const orderId = item.orderNumber
      const activityId = item.activityNumber

      // 创建request节点（如果不存在）
      if (!map[`re-${requestId}`]) {
        map[`re-${requestId}`] = {
          id: `re-${requestId}`,
          number: requestId,
          type: '变更申请',
          person: item.requestCreator,
          date: item.requestCreateTime,
          lifecycleState: item.requestLifecycleStateCode,
          children: []
        }
      }
      if (!orderId) return
      // 创建order节点（如果不存在）
      let order = map[`re-${requestId}`].children.find((c: any) => c.id === `or-${orderId}`)
      if (!order) {
        order = {
          id: `or-${orderId}`,
          number: orderId,
          type: '变更单',
          person: item.orderCreator,
          date: item.orderCreateTime,
          lifecycleState: item.orderLifecycleStateCode,
          children: []
        }
        map[`re-${requestId}`].children.push(order)
      }
      if (!activityId) return
      // 创建activity节点
      const activity = {
        id: `ac-${activityId}`,
        number: activityId,
        type: '变更活动',
        person: item.activityCreator,
        date: item.activityCreateTime,
        lifecycleState: item.activityLifecycleStateCode
      }
      order.children.push(activity)
    })

    return Object.values(map)[0]
  }

  buildOrderTree(items: Record<string, any>[]): Record<string, any> {
    const map: Record<string, any> = {}

    items.forEach((item: Record<string, any>) => {
      const orderId = item.orderNumber
      const activityId = item.activityNumber

      // 创建order节点（如果不存在）
      if (!map[`or-${orderId}`]) {
        map[`or-${orderId}`] = {
          id: `or-${orderId}`,
          number: orderId,
          type: '变更单',
          person: item.orderCreator,
          date: item.orderCreateTime,
          lifecycleState: item.orderLifecycleStateCode,
          children: []
        }
      }
      if (!activityId) return
      // 创建activity节点
      const activity = {
        id: `ac-${activityId}`,
        number: activityId,
        type: '变更活动',
        person: item.activityCreator,
        date: item.activityCreateTime,
        lifecycleState: item.activityLifecycleStateCode
      }
      map[`or-${orderId}`].children.push(activity)
    })

    return Object.values(map)[0]
  }

  firstDataRendered() {
    const data: Record<string, any>[] | undefined = this.refDataGrid.value?.getSelectedRows()
    if (data) {
      this.rowClick({ data: data[0] })
    }
  }

  viewDidMount() {}

  async openObjTab(id: string, modelCode: string) {
    const param = {
      id,
      modelCode,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(modelCode)
    }
    ObjectClientSrv.openObj(param)
  }
}
