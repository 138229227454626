
import { defineView } from '@kmsoft/upf-core'
import { KBaselineFormPropOptions, KBaselineFormEventEmits } from './interface'
import KBaselineFormViewModel from './KBaselineFormViewModel'

export default defineView({
  name: 'KBaselineForm',
  props: KBaselineFormPropOptions,
  emits: KBaselineFormEventEmits,
  viewModel: KBaselineFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
