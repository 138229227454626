import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"padding":"8px 0px","width":"100%","height":"100%"} }
const _hoisted_2 = { class: "layout-content-wrapper" }
const _hoisted_3 = {
  key: 0,
  style: {"width":"100%","height":"100%"}
}
const _hoisted_4 = {
  key: 1,
  style: {"width":"100%","height":"100%"}
}
const _hoisted_5 = {
  key: 2,
  style: {"width":"100%","height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tree_view = _resolveComponent("k-tree-view")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_KMyProductNeeds = _resolveComponent("KMyProductNeeds")!
  const _component_KMyCustomerNeeds = _resolveComponent("KMyCustomerNeeds")!
  const _component_KMySubscribedRequirement = _resolveComponent("KMySubscribedRequirement")!
  const _component_k_split_container = _resolveComponent("k-split-container")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_split_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_k_split_pane, {
            size: 15,
            minSize: 15,
            maxSize: 50
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_panel, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_k_tree_view, {
                      ref: _ctx.$vm.refTree,
                      "default-expanded-level": 1,
                      unselectablePredicate: (node)=>node!.id=='root',
                      autoSelectFirstNode: true,
                      loadData: _ctx.$vm.loadTreeData,
                      onAfterSelect: _cache[0] || (_cache[0] = (event) => _ctx.$vm.setSelectNode(event))
                    }, null, 8, ["unselectablePredicate", "loadData"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_k_split_pane, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.$vm.selectNodeType == '1.1')
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_KMyProductNeeds)
                    ])), [
                      [_directive_focus]
                    ])
                  : _createCommentVNode("", true),
                (_ctx.$vm.selectNodeType == '1.2')
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_KMyCustomerNeeds)
                    ])), [
                      [_directive_focus]
                    ])
                  : _createCommentVNode("", true),
                (_ctx.$vm.selectNodeType == '2')
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_KMySubscribedRequirement)
                    ])), [
                      [_directive_focus]
                    ])
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}