import { Agent } from '@kmsoft/ebf-common'
import { List } from 'lodash'
import { PropertyCheck } from './config'
import { DocCheckNode, DocIntegrationConfigDto, FileApiUrlDto, UploadFileParamDto } from './GetFilePropertyModel'
import { DocProperty, FileProperty } from './midFile/FileProperty'
import { EnumSoft } from '../EnumSoft'

export class DdbClientSrv extends Agent.AgentInstanceObject {
  protected static type: string = 'KMSoft.Components.Ddb.DdbClientSrv,KMSoft.Components.Ddb'

  /**
   * 请求代理 提取文件属性
   */
  public static async GetFileProperty(
    /** 要提取属性的文件 检入时仅需要根节点文档的文件 */
    filePathList: string[],
    /** 搜索路径 检入时搜索路径仅需要 选中文件所在路径 */
    searchDicPathList: string[],
    /** 是否级联  取界面勾选 */
    isCascade: boolean,
    /** 要检入的文档节点 从文档结构树上取要检入的文档数据 */
    checkInDocList: DocCheckNode[],
    /** 要检入的文档节点 从文档结构树上取不检入的文档数据 */
    notCheckInDocList: DocCheckNode[],
    /** 文档集成配置  从服务端Api取 */
    integrationConfig: DocIntegrationConfigDto,
    /** 服务端请求用到的token */
    token: string,
    /** 文件操作所用到的api地址 */
    fileApiUrl: FileApiUrlDto,
    /** 文件上传参数 */
    uploadFileParam: UploadFileParamDto
  ): Promise<FileProperty> {
    return this.invokeStaticMethod(
      'GetFileProperty',
      JSON.stringify({
        filePathList,
        searchDicPathList,
        isCascade,
        checkInDocList,
        notCheckInDocList,
        integrationConfig,
        token,
        fileApiUrl,
        uploadFileParam
      })
    )
  }

  /* 
  请求代理 调用Ddb
  暂时废弃
  public static async Execute(
    argDdbMode: string,
    argSoftCode: string,
    argDdbConfigFilePath: string,
    argUpdateFilePath: string,
    argSourcePara: string,
    argOutputFilePath: string
  ): Promise<boolean> {
    return this.invokeStaticMethod(
      'Execute',
      argDdbMode,
      argSoftCode,
      argDdbConfigFilePath,
      argUpdateFilePath,
      argSourcePara,
      argOutputFilePath
    )
  }
  */

  /**
   * 请求代理 查找附属文件
   */
  public static async FindAttachFileBySameNameRule(
    /** 要检入的文档节点 从文档结构树上取要检入的文档数据 */
    checkInDocList: DocCheckNode[],
    /** 文档集成配置  从服务端Api取 */
    integrationConfig: DocIntegrationConfigDto,
    /** 服务端请求用到的token */
    token: string,
    /** 文件操作所用到的api地址 */
    fileApiUrl: FileApiUrlDto
  ): Promise<DocProperty[]> {
    return this.invokeStaticMethod(
      'FindAttachFileBySameNameRule',
      JSON.stringify({
        checkInDocList,
        integrationConfig,
        token,
        fileApiUrl
      })
    )
  }

  /**
   * 请求代理 调用DdbExec关闭文件
   * @param argSoftCode 软件类型
   * @param argFilePath 文件路径
   * @returns boolean
   */
  public static async CloseFile(argSoftCode: string, argFilePath: string): Promise<boolean> {
    //const argSourcePara = DdbParamSrv.getCloseFileParam(argFilePath)
    return this.invokeStaticMethod('CloseFile', argSoftCode, argFilePath)
  }

  /**
   *
   * @param argSoftCode 集成软件类型
   * @param argFilePath  文件路径
   * @param argDdbConfigFilePath ddb地址
   * @param argPropertyDic
   * @param argProComparisons
   * @returns
   */
  public static async WriteFileProperty(
    argSoftCode: EnumSoft,
    argFilePath: string,
    argDdbConfigFilePath: string,
    argPropertyDic: Record<string, string>,
    argProComparisons: Record<string, List<PropertyCheck>>
  ): Promise<Boolean> {
    return this.invokeStaticMethod(
      'WriteProperty',
      JSON.stringify({
        argSoftCode: argSoftCode,
        argFilePath: argFilePath,
        argDdbConfigFilePath: argDdbConfigFilePath,
        argPropertyDic: argPropertyDic,
        argProComparisons: argProComparisons
      })
    )
  }
}
