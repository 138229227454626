import { BaseViewModel, IKTreeNode, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KBaselineContentEmitsType, KBaselineContentPropType } from './interface'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { ref } from 'vue'

/** KBaselineContent */
export default class KBaselineContentViewModel extends BaseViewModel<KBaselineContentEmitsType, KBaselineContentPropType> {
  treeNode = ref<IKTreeNode[]>([])
  loading = ref<boolean>(true)
  constructor(options: ViewModelOptions<KBaselineContentPropType>) {
    super(options)
  }

  viewDidMount() {
    this.getTreeNode()
  }

  async getTreeNode() {
    const result = (await Api.post('requirement', 'Requirement', 'getBaselineTreeData', {
      data: [this.props.objParam]
    })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const treeRoot = result.data.filter((item: any) => !item.parentId)
      if (treeRoot) {
        this.treeNode.value = this.buildTree(result.data, treeRoot.id)
        console.log(this.treeNode.value)
      }
    } else {
      KNotification.error({
        title: '系统提示',
        content: result.message!
      })
    }
    this.loading.value = false
  }

  /**
   * 构建树
   */
  buildTree(array: Array<IKTreeNode>, parentId: string): Array<IKTreeNode> {
    const result = [] as Array<IKTreeNode>
    array.forEach(item => {
      if (item.parentId == parentId) {
        item.children = this.buildTree(array, item.id)
        result.push(item)
      }
    })
    return result
  }
}
