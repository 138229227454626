import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tag = _resolveComponent("k-tag")!

  return (_ctx.row)
    ? (_openBlock(), _createBlock(_component_k_tag, {
        key: 0,
        color: _ctx.EnumLifecycleStateColor[_ctx.$vm.lifecycleStateTags()],
        style: {"flex":"1","text-align":"center","width":"56px","max-width":"60px"}
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$vm.displayValue()), 1)
        ]),
        _: 1
      }, 8, ["color"]))
    : (_openBlock(), _createElementBlock("span", _hoisted_1, "--"))
}