import { connect, withInstall } from '@kmsoft/upf-core'
import KAllProductNeedsView from './KAllProductNeeds.vue'
import KAllProductNeedsViewModel from './KAllProductNeedsViewModel'

const KAllProductNeeds = connect(KAllProductNeedsView, KAllProductNeedsViewModel)

export default withInstall(KAllProductNeeds)
export { KAllProductNeeds, KAllProductNeedsView, KAllProductNeedsViewModel }

// 模板生成文件
// export * from './{{folderName}}'
