import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_lifecycle_state_tag = _resolveComponent("k-lifecycle-state-tag")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_bottom_bar = _resolveComponent("k-bottom-bar")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        rowModelType: _ctx.rowModelType,
        class: "part-collect-grid",
        rowKey: "id",
        name: "partCollectGrid",
        pagination: false,
        ref: _ctx.$vm.refPartCollectGrid,
        loadData: _ctx.$vm.loadData
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "number",
            name: "number",
            dataPropertyName: "number",
            headerText: "零部件编码",
            align: "left"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "name",
            name: "name",
            dataPropertyName: "name",
            headerText: "零部件名称",
            align: "left"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "lifecycleState",
            name: "lifecycleState",
            dataPropertyName: "lifecycleState",
            headerText: "生命周期状态",
            width: "120",
            align: "center"
          }, {
            default: _withCtx(({ row }) => [
              _createVNode(_component_k_lifecycle_state_tag, { row: row }, null, 8, ["row"])
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_combo_box_column, {
            id: "workingState",
            name: "workingState",
            dataPropertyName: "workingState",
            headerText: "工作状态",
            options: _ctx.$vm.workingStateOptions
          }, null, 8, ["options"]),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "description",
            name: "description",
            dataPropertyName: "description",
            headerText: "描述",
            align: "left"
          })
        ]),
        bottomBar: _withCtx(() => [
          _createVNode(_component_k_bottom_bar, {
            enableExcelExport: "",
            onRefresh: _cache[0] || (_cache[0] = () => _ctx.$vm.refresh()),
            onExcelSelectExport: _cache[1] || (_cache[1] = () => _ctx.$vm.exportSelectAsExcel()),
            onExcelExport: _cache[2] || (_cache[2] = () => _ctx.$vm.exportAsExcel())
          })
        ]),
        _: 1
      }, 8, ["rowModelType", "loadData"])
    ]),
    _: 1
  }))
}