
import {
  EnumDataGridRowModelType,
  defineView,
  KDataGridRowSelectedEvent,
  EnumDataGridAutoSizeColumnsMode
} from '@kmsoft/upf-core'
import { KValidationMetricsManagePropOptions, KValidationMetricsManageEventEmits } from './interface'
import KValidationMetricsManageViewModel from './KValidationMetricsManageViewModel'
import {
  clsCodeMap,
  EnumValidationMetrics,
  EnumVerification,
  validationResultColor,
  EnumValidationResult,
  ObjectClientSrv
} from '@kmsoft/ebf-common'

export default defineView({
  name: 'KValidationMetricsManage',
  props: KValidationMetricsManagePropOptions,
  emits: KValidationMetricsManageEventEmits,
  viewModel: KValidationMetricsManageViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      clsCodeMap,
      EnumValidationMetrics,
      EnumVerification,
      validationResultColor,
      EnumValidationResult,
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      autosizeColumnsMode: EnumDataGridAutoSizeColumnsMode.FILL
    }
  }
})
