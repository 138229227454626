
import { defineView, exposeComponentEvents, KSelectEventEmits } from '@kmsoft/upf-core'
import { KSelectWrapperPropOptions, KSelectWrapperEventEmits } from './interface'
import KSelectWrapperViewModel from './KSelectWrapperViewModel'

export default defineView({
  name: 'KSelectWrapper',
  props: KSelectWrapperPropOptions,
  emits: KSelectWrapperEventEmits,
  viewModel: KSelectWrapperViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    /** 继承事件 */
    const exposedEvents = exposeComponentEvents(vm.refSelect, Object.keys(KSelectEventEmits), emit)
    const selectProps = {
      ref: vm.refSelect.value,
      ...props,
      options: vm.options.value,
      ...exposedEvents
    }

    return () => {
      if (vm.stateReadonly.value) {
        return (
          <div style="width:100%">
            <div class="k-render-dropdown-text" title="displayValue">
              {vm.displayValue()}
            </div>
          </div>
        )
      } else {
        // return <k-select {...selectProps} />
        return (
          <k-select
            ref={vm.refSelect.value}
            {...props}
            disabled={vm.disabled.value}
            vModel={[vm.stateValue.value, 'value']}
            options={vm.options.value}
            filterOption={(inputValue: string, option: any) => vm.filterOption(inputValue, option)}
            onChange={vm.onChange}
            onSelect={vm.onSelect}
          />
        )
      }
    }
  }
})
