import { KAttributeValueItem } from '../../../../../types'
import { ControlCollection, KDataElementTree, KDesignerControl } from '../../../../../types/metadata'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  EnumDataType,
  EnumType,
  LayoutRenderElement,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** KViewPropertyConfigPanel 事件 **/
export const KViewPropertyConfigPanelEventEmits = {
  ...BaseViewEventEmits,
  elementPropertyChange: (configItemName: string, type: string, value: any) => true
}

/** KViewPropertyConfigPanel 参数 **/
export const KViewPropertyConfigPanelPropOptions = {
  ...BaseViewPropOptions,
  /** 控件信息 */
  control: VuePropTypes.createType<KDesignerControl>()
    .setRequired()
    .def(),
  /** 当前元素 */
  element: VuePropTypes.createType<LayoutRenderElement>()
    .setRequired()
    .def(),
  /** 控件元数据 */
  designerMetaControl: VuePropTypes.createType<Array<KDesignerControl>>().def([]),
  /** 控件属性的值集合 */
  attributeValueItem: VuePropTypes.createType<KAttributeValueItem>().def({} as KAttributeValueItem),
  /** 数据元素 */
  dataSource: VuePropTypes.createType<KDataElementTree>().def(),
  belongClsCode: VuePropTypes.string().def()
}

/** KViewPropertyConfigPanel 参数类型 **/
export type KViewPropertyConfigPanelPropType = ViewPropsTypeExtract<typeof KViewPropertyConfigPanelPropOptions>

/** KViewPropertyConfigPanel 事件协议*/
export type KViewPropertyConfigPanelEventEmitsTypes = ViewEmitsTypeExtract<typeof KViewPropertyConfigPanelEventEmits>

/**控件 */
export enum EnumColumnControl {
  /**文本列 */
  INPUT = 'KDataGridTextBoxColumn',
  /**多文本列 */
  TEXT_AREA = 'KDataGridTextAreaColumn',
  /**日期列 */
  DATE_TIME = 'KDataGridDateTimeColumn',
  /**数字列 */
  NUMBER = 'KDataGridNumberColumn',
  /**复选框列 */
  CHECKBOX = 'KDataGridCheckBoxColumn',
  /**下拉列表列 */
  SELECT = 'KDataGridComboBoxColumn',
  /**版本列*/
  VERSION = 'KDataGridBusinessVersionColumn',
  /**人员名称文本列*/
  CREATORTEXT = 'KDataGridCreatorTextColumn',
  /**人员名称下拉列*/
  CREATORSELECT = 'KDataGridUserSelectorColumn',
  /**超链接列*/
  HYPERLINK = 'KDataGridHyperLinkColumn',
  /**生命周期列*/
  LIFECYCLESTATE = 'KDataGridLifecycleStateColumn',
  /**实体类型列*/
  OBJECTDISPLAY = 'KDataGridObjectDisplayColumn',
  /**自定义列*/
  CUSTOM = 'custom'
}

/**控件候选项 */
export const columnControlOptions = [
  {
    displayValue: '文本列',
    actualValue: EnumColumnControl.INPUT
  },
  {
    displayValue: '多文本列',
    actualValue: EnumColumnControl.TEXT_AREA
  },
  {
    displayValue: '日期列',
    actualValue: EnumColumnControl.DATE_TIME
  },
  {
    displayValue: '数字列',
    actualValue: EnumColumnControl.NUMBER
  },
  {
    displayValue: '复选框列',
    actualValue: EnumColumnControl.CHECKBOX
  },
  {
    displayValue: '下拉列表列',
    actualValue: EnumColumnControl.SELECT
  },
  {
    displayValue: '版本列',
    actualValue: EnumColumnControl.VERSION
  },
  {
    displayValue: '人员名称文本列',
    actualValue: EnumColumnControl.CREATORTEXT
  },
  {
    displayValue: '人员名称下拉列',
    actualValue: EnumColumnControl.CREATORSELECT
  },
  {
    displayValue: '超链接列',
    actualValue: EnumColumnControl.HYPERLINK
  },
  {
    displayValue: '生命周期列',
    actualValue: EnumColumnControl.LIFECYCLESTATE
  },
  {
    displayValue: '实体类型列',
    actualValue: EnumColumnControl.OBJECTDISPLAY
  },
  {
    displayValue: '自定义列',
    actualValue: EnumColumnControl.CUSTOM
  }
]
