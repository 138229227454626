import {
  BaseViewModel,
  EnumDialogResult,
  EnumItemClickedCancelType,
  EnumToolStripCompType,
  KDataGridViewModel,
  KDialogClosingEvent,
  KDialog,
  KNotification,
  MemoryCacheFactory,
  SelectOption,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KValidationPlanTabEmitsType, KValidationPlanTabPropType } from './interface'
import {
  Api,
  EnumRequestCode,
  EnumToolStripItemKeys,
  EnumVerification,
  KObjectToolStripViewModel,
  ObjBusinessParam,
  ObjectClientSrv,
  UserInfoCache
} from '@kmsoft/ebf-common'
import { ref, watch } from 'vue'
import KPlanCreateFormViewModel from '../verification-plan/plan-create-form/KPlanCreateFormViewModel'
import { KPlanCreateForm } from '../verification-plan/plan-create-form'

/** KValidationPlanTab */
export default class KValidationPlanTabViewModel extends BaseViewModel<KValidationPlanTabEmitsType, KValidationPlanTabPropType> {
  /** 对象 */
  objParam = ref<ObjBusinessParam>()
  /** 验证计划工具栏 */
  refPlanToolStrip = ref<KObjectToolStripViewModel>()
  /** 验证计划网格 */
  refVerificationPlanGrid = ref<KDataGridViewModel>()
  /** 验证类型候选值 */
  verificationPlanTypeOptions = ref<Array<SelectOption>>(EnumVerification._list)
  /** 人员列表 */
  userList = ref<Record<string, any>[]>([])
  /**包含子项 */
  includeChild = ref<boolean>(false)
  constructor(options: ViewModelOptions<KValidationPlanTabPropType>) {
    super(options)
    this.objParam.value = options.props.objParam
    this.getUserData()
    watch(
      () => this.props.objParam,
      newValue => {
        if (newValue && newValue.id) {
          this.objParam.value = newValue
          this.refVerificationPlanGrid.value?.clearSelectedRows()
          this.refVerificationPlanGrid.value?.refresh()
        }
      }
    )
    watch(
      () => this.props.refreshTip,
      () => {
        this.refVerificationPlanGrid.value?.refresh()
      },
      {
        deep: true
      }
    )
    watch(
      () => this.includeChild.value,
      newValue => {
        this.refVerificationPlanGrid.value?.clearSelectedRows()
        this.refVerificationPlanGrid.value?.refresh()
      }
    )
  }

  /** 计划操作按钮 */
  planToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW,
      title: '新建',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT,
      title: '编辑',
      icon: 'edit',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE,
      title: '保存',
      icon: 'save',
      visible: false,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
      title: '移除',
      icon: 'delete',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'delete'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
    // {
    //   name: EnumToolStripItemKeys.TOOL_STIP_ITEM_INCLUDE_CHILD,
    //   title: '查看子项',
    //   icon: 'sync',
    //   visible: true,
    //   compType: EnumToolStripCompType.BUTTON
    // },
    // {
    //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_RESET,
    //   title: '重置',
    //   icon: 'sync',
    //   visible: true,
    //   compType: EnumToolStripCompType.BUTTON
    // }
  ]

  viewDidMount() {}

  getUserData() {
    const cacheInstance = MemoryCacheFactory.get<UserInfoCache>(UserInfoCache.cacheKey)
    this.userList.value = cacheInstance.getUserOptionList()
  }

  /** 计划操作 */
  async onPlanToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onPlanToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW:
        this.createPlanWithOutMetrics()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT:
        this.editPlan()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE:
        this.savePlan()
        this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, true)
        this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.removePlan()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refVerificationPlanGrid.value?.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STIP_ITEM_INCLUDE_CHILD:
        this.includeChild.value = true
        this.refVerificationPlanGrid.value?.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_RESET:
        this.includeChild.value = false
        this.refVerificationPlanGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /** 加载计划网格 */
  async loadPlanData() {
    const obj = this.objParam.value
    const params = {
      data: [{ id: obj?.id, clazz: obj?.modelCode }, this.includeChild.value]
    }
    const result = await Api.post('requirement', 'VerificationPlan', 'listByReqId', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取验证计划失败',
        details: result.detail
      })
      return
    }
  }

  /** 创建无指标的验证计划 */
  createPlanWithOutMetrics() {
    const obj = this.objParam.value
    // 关联需求
    const req = {
      id: obj?.id,
      clazz: obj?.modelCode
    }
    this.createPlan(req, [])
  }

  /** 创建验证计划 */
  createPlan(req: any, reqAndMetricsLinks: any) {
    KDialog.show({
      title: '新建验证计划',
      size: { width: 900, height: 650 },
      props: {},
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KPlanCreateForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KPlanCreateFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }
        const result = await formViewModel.save(req, reqAndMetricsLinks)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refVerificationPlanGrid.value?.refresh()
          KNotification.success({
            title: '系统提示',
            content: '新建成功'
          })
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '新建失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /** 编辑计划网格 */
  editPlan() {
    this.refVerificationPlanGrid.value?.refresh()
    // 修改工具栏状态
    this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
    this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
    // 网格开启编辑
    this.refVerificationPlanGrid.value?.beginEdit()
  }

  /** 保存计划网格 */
  savePlan() {
    this.refVerificationPlanGrid.value?.endEdit(true)
    setTimeout(async () => {
      const changedRows = this.refVerificationPlanGrid.value?.getChangedRows()
      const modifiedRows = changedRows?.modifiedRows

      if (modifiedRows && modifiedRows.length > 0) {
        // const newRows = modifiedRows.map(x => {
        //   return this.refVerificationPlanGrid.value?.getRow(x.id)
        // })
        const updateDataList = modifiedRows!.map(element => {
          return {
            id: element.id,
            type: element.type,
            title: element.title,
            description: element.description,
            executor: element.executor
          }
        })
        //更新数据
        const result = await Api.post('requirement', 'VerificationPlan', 'batchUpdate', { data: [updateDataList] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success('修改成功！')
          this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, true)
          this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
          this.refVerificationPlanGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '修改失败',
            content: result.message
          })
          this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
          this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
          this.refVerificationPlanGrid.value?.beginEdit()
          return
        }
      }
    }, 40)
  }

  /** 移除计划 */
  removePlan() {
    const rows = this.refVerificationPlanGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认移除吗？移除后不可恢复!',
      onOk: async () => {
        // 获取对象id
        const rowIds = rows!.map(row => row.id)
        const reqParam = {
          data: [rowIds]
        }

        Api.post('requirement', 'VerificationPlan', 'batchDelete', reqParam).then(result => {
          if (result && result.code == EnumRequestCode.SUCCESS) {
            // 刷新网格
            this.refVerificationPlanGrid.value?.removeSelectedRows()
            //this.refVerificationPlanGrid.value?.refresh()
            KNotification.success({
              title: '移除成功',
              content: result.message
            })
            return Promise.resolve({
              rows: result.data,
              total: result.data.length
            })
          } else {
            KNotification.error({
              title: '移除失败',
              content: result.message,
              details: result.detail
            })
            return
          }
        })
      }
    })
  }

  filterOption(inputValue: string, option: any) {
    return (
      option.label
        ?.toString()
        ?.toLowerCase()
        ?.indexOf(inputValue.toLowerCase()) >= 0 ||
      option.value
        ?.toString()
        ?.toLowerCase()
        ?.indexOf(inputValue.toLowerCase()) >= 0
    )
  }

  getValue() {
    const selectRows = this.refVerificationPlanGrid.value?.getSelectedRows()
    if (!selectRows || selectRows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    return selectRows
  }

  /** 打开计划对象 */
  async openPlanTab(row: any) {
    const param = {
      id: row?.id,
      productNeedsId: this.props.objParam.id,
      modelCode: row!.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row?.className!)
    }
    ObjectClientSrv.openObj(param)
  }
}
