import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b285a746"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "full part-validation-metrics" }
const _hoisted_2 = { style: {"display":"flex","justify-content":"space-between","align-items":"center"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { style: {"margin-left":"5px"} }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_tool_strip = _resolveComponent("k-object-tool-strip")!
  const _component_k_checkbox = _resolveComponent("k-checkbox")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_lifecycle_state_tag = _resolveComponent("k-lifecycle-state-tag")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showToolStrip)
        ? (_openBlock(), _createBlock(_component_k_object_tool_strip, {
            key: 0,
            ref: _ctx.$vm.refPlanToolStrip,
            items: _ctx.$vm.planToolStripItems,
            onItemClicked: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onPlanToolStripItemClicked(event))
          }, null, 8, ["items"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createVNode(_component_k_checkbox, {
          class: "createNext",
          checked: _ctx.$vm.includeChild,
          "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.includeChild) = $event))
        }, {
          default: _withCtx(() => [
            _createTextVNode("显示子项")
          ]),
          _: 1
        }, 8, ["checked"])
      ])
    ]),
    _createVNode(_component_k_data_grid, {
      class: "verification-plan-grid",
      rowKey: "id",
      name: "refVerificationPlanGrid",
      ref: _ctx.$vm.refVerificationPlanGrid,
      pagination: false,
      rowModelType: _ctx.rowModelType,
      loadData: _ctx.$vm.loadPlanData,
      autosizeColumnsMode: _ctx.autosizeColumnsMode
    }, {
      columns: _withCtx(() => [
        _createVNode(_component_k_data_grid_template_column, {
          id: "number",
          name: "number",
          dataPropertyName: "number",
          headerText: "编码",
          align: "center"
        }, {
          default: _withCtx(({ cellValue,row }) => [
            _createElementVNode("a", {
              href: "javascript:void(0)",
              onClick: () => _ctx.$vm.openPlanTab(row)
            }, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(cellValue), 1)
            ], 8, _hoisted_3)
          ]),
          _: 1
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          id: "title",
          name: "title",
          dataPropertyName: "title",
          headerText: "标题",
          readonly: false,
          align: "center"
        }),
        _createVNode(_component_k_data_grid_template_column, {
          id: "breakdown",
          name: "breakdown",
          dataPropertyName: "breakdown",
          headerText: "所属需求",
          align: "center"
        }, {
          default: _withCtx(({ cellValue}) => [
            cellValue
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(cellValue.title), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, "- -"))
          ]),
          _: 1
        }),
        _createVNode(_component_k_data_grid_combo_box_column, {
          id: "type",
          name: "type",
          dataPropertyName: "type",
          headerText: "验证类型",
          readonly: false,
          options: _ctx.$vm.verificationPlanTypeOptions,
          width: "120",
          align: "center"
        }, null, 8, ["options"]),
        _createVNode(_component_k_data_grid_template_column, {
          id: "lifecycleState",
          name: "lifecycleState",
          dataPropertyName: "lifecycleState",
          headerText: "生命周期状态",
          readonly: true,
          width: "120",
          align: "center"
        }, {
          default: _withCtx(({ row }) => [
            _createVNode(_component_k_lifecycle_state_tag, { row: row }, null, 8, ["row"])
          ]),
          _: 1
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          id: "planStartDate",
          name: "planStartDate",
          dataPropertyName: "planStartDate",
          headerText: "计划开始时间",
          readonly: true,
          align: "center"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          id: "planEndDate",
          name: "planEndDate",
          dataPropertyName: "planEndDate",
          headerText: "计划完成时间",
          readonly: true,
          align: "center"
        }),
        _createVNode(_component_k_data_grid_combo_box_column, {
          id: "executor",
          name: "executor",
          dataPropertyName: "executor",
          headerText: "执行者",
          options: _ctx.$vm.userList,
          showSearch: true,
          filterOption: _ctx.$vm.filterOption,
          align: "center"
        }, null, 8, ["options", "filterOption"]),
        _createVNode(_component_k_data_grid_text_box_column, {
          id: "description",
          name: "description",
          dataPropertyName: "description",
          headerText: "描述",
          readonly: false,
          align: "center"
        }),
        _createVNode(_component_k_data_grid_template_column, {
          id: "creator",
          name: "creator",
          dataPropertyName: "creator",
          headerText: "创建者",
          align: "center"
        }, {
          default: _withCtx(({ cellValue}) => [
            _createTextVNode(_toDisplayString(cellValue?.split(' ')[0]), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          id: "createTime",
          name: "createTime",
          dataPropertyName: "createTime",
          headerText: "创建时间",
          readonly: true,
          align: "center"
        }),
        (_ctx.showToolStrip)
          ? (_openBlock(), _createBlock(_component_k_data_grid_template_column, {
              key: 0,
              id: "action",
              name: "action",
              fixed: "right",
              align: "center",
              headerText: "操作",
              width: "auto"
            }, {
              default: _withCtx(({row}) => [
                _createElementVNode("a", {
                  class: "row-operation-item",
                  onClick: (e) => _ctx.$vm.openPlanTab(row)
                }, "查看", 8, _hoisted_7)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["rowModelType", "loadData", "autosizeColumnsMode"])
  ]))
}