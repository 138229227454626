import { connect, withInstall } from '@kmsoft/upf-core'
import KAssociateClientGridView from './KAssociateClientGrid.vue'
import KAssociateClientGridViewModel from './KAssociateClientGridViewModel'

const KAssociateClientGrid = connect(KAssociateClientGridView, KAssociateClientGridViewModel)

export default withInstall(KAssociateClientGrid)
export { KAssociateClientGrid, KAssociateClientGridView, KAssociateClientGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'
