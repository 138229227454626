import { EnumDialogResult, KDataGridViewModel, KDialog, KDialogClosingEvent } from '@kmsoft/upf-core'
import { KDocBatchCreate } from '../controls/doc-batch-create'
import { EnumRequestCode } from '@kmsoft/ebf-common'
import { KDocBatchCreateViewModel, KInfoMessage } from '../controls'
import { Ref, ref, createVNode } from 'vue'

export class DocManageClientSrv {
  static batchCreateDocuments(folderId: string | undefined, refresh: Function) {
    const kDialogResult = KDialog.show({
      title: createVNode(KInfoMessage),
      size: { width: 1000, height: 700 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KDocBatchCreate,
      showFooter: false,
      props: {
        dialog: () => kDialogResult,
        folderId
      },
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        if (event.dialogResult == 'complete') {
          refresh && refresh()
        }
      }
    })
  }
}
