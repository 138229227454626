
import { defineView } from '@kmsoft/upf-core'
import { KDocOptionalPropOptions, KDocOptionalEventEmits } from './interface'
import KDocOptionalViewModel from './KDocOptionalViewModel'

export default defineView({
  name: 'KDocOptional',
  props: KDocOptionalPropOptions,
  emits: KDocOptionalEventEmits,
  viewModel: KDocOptionalViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
