import lodash from 'lodash'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KObjectClassTreeBasePropOptions } from '../object-class-tree'
import { KObjectClassGridEventEmits, KObjectClassGridPropOptions } from '../object-class-grid'

//#region
/** 对象类管理参数 */
export type ObjectClassManageQueryParam = Partial<KObjectClassManagePropType> & {}
//#endregion

/** 参数 **/
export const KObjectClassManagePropOptions = {
  ...BaseViewPropOptions,
  ...KObjectClassTreeBasePropOptions,
  ...KObjectClassGridPropOptions,
  /** 是否启用网格多选 */
  isMultipleSelection: VuePropTypes.bool().def(true),
  /** 是否显示树组件 默认显示 */
  isShowTree: VuePropTypes.bool().def(true),
  /** 要查询的状态 */
  showLifecycleState: VuePropTypes.createType<Record<string, string[]>>().def()
}

/** 参数类型 **/
export type KObjectClassManagePropType = ViewPropsTypeExtract<typeof KObjectClassManagePropOptions>

/** 事件 */
export const KObjectClassManageEventEmits = {
  ...BaseViewEventEmits,
  ...KObjectClassGridEventEmits,
  /** 对象类Id改变事件 */
  objClsCodeChange: (objClsID: string) => true
}

/** 事件类型 **/
export type KObjectClassManageEmitsType = ViewEmitsTypeExtract<typeof KObjectClassManageEventEmits>
