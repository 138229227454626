import { AgentStaticObject } from '../../AgentStaticObject'

/**哈希工具集。*/
export class FileUtil extends AgentStaticObject {
  protected static type: string = 'KMSoft.Agent.FileUtil'

  /**
   * 计算文件 Hash 值。
   * @param argFile 文件。
   * @returns Hash 值。*/
  public static async GetFileMd5(argFile: string): Promise<string> {
    return this.invokeStaticMethod('GetFileMd5', argFile)
  }

  /**
   * 检查指定的文件是否被占用。
   * @param path 要检查的文件的完整路径。
   * @returns 如果文件被占用返回 true，否则返回 false。
   * @throws 如果文件不存在或者调用方没有所需的权限，将会抛出错误。
   */
  public static async IsFileLocked(path: string): Promise<boolean> {
    try {
      return this.invokeStaticMethod('IsFileInUse', path)
    } catch (error) {
      throw new Error(`检查文件锁定状态失败: ${error}`)
    }
  }

  public static async awaitFiles(filePaths: string[]): Promise<boolean> {
    const maxWaitTime = 8000 // 最大等待时间 8 秒
    const checkInterval = 1000 // 检查间隔时间 1000 毫秒
    let elapsedTime = 0 // 已经等待的时间

    while (elapsedTime < maxWaitTime) {
      const lockedFiles: string[] = []

      // 检查每个文件是否被占用
      for (const filePath of filePaths) {
        if (await FileUtil.IsFileLocked(filePath)) {
          lockedFiles.push(filePath)
        }
      }

      // 如果没有被占用的文件，返回 true
      if (lockedFiles.length === 0) {
        return true
      }

      // 等待一段时间再重新检查
      await new Promise(resolve => setTimeout(resolve, checkInterval))
      elapsedTime += checkInterval
    }

    // 如果超时仍有文件被占用，返回 false
    return false
  }
}
