import { IKEditorAPI, ObjBusinessParam, ObjectCreatePanelAttachParams, ObjectLoadDataProvider } from '@kmsoft/ebf-common'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KDocFormPropOptions = {
  ...BaseViewPropOptions,
  formValue: VuePropTypes.record().def({}),
  modelCode: VuePropTypes.createType<string>().def('Document'),
  /** 面板值加载 */
  loadData: VuePropTypes.func<ObjectLoadDataProvider>()
    .setRequired()
    .def(() =>
      Promise.resolve(({
        modelCode: 'Document',
        modelGroup: 'doc',
        documentType: '',
        number: '',
        name: '',
        version: 'A',
        lifecycleStateCode: 'InWork',
        description: '',
        primary: [],
        secondary: []
      } as any) as ObjBusinessParam)
    ),
  isTemplate: VuePropTypes.createType<boolean>().def(false),
  isAgent: VuePropTypes.createType<boolean>().def(false),
  fileName: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KDocFormPropType = ViewPropsTypeExtract<typeof KDocFormPropOptions>

/** 事件 */
export const KDocFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KDocFormEmitsType = ViewEmitsTypeExtract<typeof KDocFormEventEmits>

/**
 *
 * 文档详情
 */

export class DocDetailState {
  /**文档类型 */
  documentType: string
  /**编码 */
  number: string
  /**名称 */
  name: string
  /**版本 */
  version: string
  /**生命周期状态 */
  lifecycleState: ObjReference
  /**描述 */
  description: string
  /**文件夹 */
  primary: ObjReference[]
  /**附件 */
  secondary: ObjReference[]
}

export class ObjReference {
  id?: string
  clazz?: string
  name?: string
}
