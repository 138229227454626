import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_col = _resolveComponent("k-col")!
  const _component_k_row = _resolveComponent("k-row")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    name: "baseForm",
    ref: _ctx.$vm.refForm,
    rules: _ctx.$vm.rules,
    model: _ctx.$vm.formData,
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
    autocomplete: "off"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_row, { span: "24" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_col, { span: "24" }, {
            default: _withCtx(() => [
              _createVNode(_component_k_form_item, {
                style: {"margin-top":"20px"},
                label: "验证结果",
                name: "result"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_select, {
                    value: _ctx.$vm.selectValue,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.selectValue) = $event)),
                    allowClear: false,
                    options: _ctx.$vm.productOption
                  }, null, 8, ["value", "options"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["rules", "model"]))
}