import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    style: {"padding":"0 15px"},
    ref: "refBaselineForm",
    model: _ctx.$vm.formData,
    "label-col": { span: 4 },
    "wrapper-col": { span: 20 },
    autocomplete: "off"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_form_item, {
        style: {"margin-top":"10px"},
        label: "基线名称",
        name: "baselineName",
        "has-feedback": "",
        rules: [
        { required: true, message: '请输入' },
        { pattern: /^[^@;]+$/, message: '不能包含@和;字符' }
      ]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            value: _ctx.$vm.formData.baselineName,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formData.baselineName) = $event)),
            style: {"width":"100%"}
          }, null, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}