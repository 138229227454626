import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KChangeActivityUpdateCompareEmitsType, KChangeActivityUpdateComparePropType } from './interface'
import { computed, ref } from 'vue'
import { GridDataInter, PropertyDiffInfoInter } from './change-activity-update-compare-grid/interface'
import lodash from 'lodash'
import { GridDiffDataInter } from './change-activity-update-compare-grid-diff/interface'
import { EnumLifecycleState, clsCodeMap } from '@kmsoft/ebf-common'

/** KChangeActivityUpdateCompare */
export default class KChangeActivityUpdateCompareViewModel extends BaseViewModel<
  KChangeActivityUpdateCompareEmitsType,
  KChangeActivityUpdateComparePropType
> {
  searchData = ref<{ objCls: string[]; filterType: number }>({
    objCls: [],
    filterType: 1
  })

  sourceDataSource = ref<GridDataInter[]>([])
  targetDataSource = ref<GridDataInter[]>([])

  /** 差异表格实际数据 **/
  gridDiffDataDatasource = computed<GridDataInter[]>(() => {
    const sourceDataSource = this.dataSourceFilter.value(this.sourceDataSource.value)
    const targetDataSource = this.dataSourceFilter.value(this.targetDataSource.value)

    const result: GridDiffDataInter[] = []

    // 对比比参考少数据
    const map1 = new Map()
    sourceDataSource.forEach(sourceItem => {
      const sourceUnionKey = `${sourceItem.number}_${sourceItem.name}`

      map1.set(sourceUnionKey, sourceItem)

      // 参考有，对比没有的数据，比较结果为减少
      if (sourceItem.diffType == 'recordAdd') {
        result.push({
          id: lodash.uniqueId(),
          path: `${sourceItem.number}：${sourceItem.name}`,
          compareResult: '减少',
          sourceData: `${sourceItem.number}：${sourceItem.name}`,
          targetData: ``
        })
      }
    })

    // 对比比参考多数据
    targetDataSource.forEach(targetItem => {
      if (targetItem.diffType == 'recordAdd') {
        result.push({
          id: lodash.uniqueId(),
          path: `${targetItem.number}：${targetItem.name}`,
          compareResult: '增加',
          sourceData: ``,
          targetData: `${targetItem.number}：${targetItem.name}`
        })
      }
    })

    // 对比和参考差异数据
    targetDataSource.forEach(targetItem => {
      if (targetItem.diffType == 'propertyDiff') {
        const targetUnionKey = `${targetItem.number}_${targetItem.name}`
        const sourceItem = map1.get(targetUnionKey)

        const propertyDiffInfo = targetItem.propertyDiffInfo || []
        const sourceData = propertyDiffInfo.reduce((str: string, cur: PropertyDiffInfoInter) => {
          str += `${cur.text}：${sourceItem[cur.propertyName]}；`
          return str
        }, '')

        const targetData = propertyDiffInfo.reduce((str: string, cur: PropertyDiffInfoInter) => {
          str += `${cur.text}：${targetItem[cur.propertyName]}；`
          return str
        }, '')

        result.push({
          id: lodash.uniqueId(),
          path: `${targetItem.number}：${targetItem.name}`,
          compareResult: '属性差异',
          sourceData,
          targetData
        })
      }
    })

    return result
  })

  /** 表格实际数据 **/
  dataSourceFilter = computed(() => {
    return (dataSource: any[] = []) => {
      // 1、过滤对象类筛选
      let result = []
      result = dataSource.filter((item: any) => this.searchData.value.objCls.includes(item.className!)) || []

      // 2、过滤差异
      result = result.filter((item: any) => (this.searchData.value.filterType == 2 ? item.diffType != 'normal' : true))
      result.forEach(item => {
        item.state = item.lifecycleState ? item.lifecycleState.name : EnumLifecycleState[item.lifecycleStateCode]
      })
      return result
    }
  })
  constructor(options: ViewModelOptions<KChangeActivityUpdateComparePropType>) {
    super(options)
  }

  viewDidMount() {
    this.sourceDataSource.value = lodash.cloneDeep(this.props.sourceData)
    this.targetDataSource.value = lodash.cloneDeep(this.props.targetData)
    this.checkObjCls()
    this.addDiffInfo()
  }

  /** 选中对象类 **/
  checkObjCls() {
    const allData = [...this.sourceDataSource.value, ...this.targetDataSource.value]
    //默认全部选中
    this.searchData.value.objCls = [...new Set([...allData.map((item: any) => item.className)])] as string[]
  }

  /** 添加差异 **/
  addDiffInfo() {
    //差异对比
    // 1、标记参考多的数据
    const map1 = new Map()
    this.sourceDataSource.value.forEach((item: GridDataInter) => {
      item.versionInfo = `${item.version}.${item.iteration}`
      item.state = item.lifecycleState ? item.lifecycleState.name : EnumLifecycleState[item.lifecycleStateCode]
      item.classAndView = clsCodeMap.get(item.className!) + this.getViewName(item)
      const sourceUnionKey = `${item.number}_${item.name}_${item.rdmExtensionType}`
      map1.set(sourceUnionKey, item)
    })

    const map2 = new Map()
    this.targetDataSource.value.forEach((item: GridDataInter) => {
      item.versionInfo = `${item.version}.${item.iteration}`
      item.state = item.lifecycleState ? item.lifecycleState.name : EnumLifecycleState[item.lifecycleStateCode]
      item.classAndView = clsCodeMap.get(item.className!) + this.getViewName(item)
      const sourceUnionKey = `${item.number}_${item.name}_${item.rdmExtensionType}`
      map2.set(sourceUnionKey, item)
    })

    map1.forEach((item: GridDataInter, key: string) => {
      if (map2.has(key)) {
        item.diffType = 'normal'
      } else {
        item.diffType = 'recordAdd'
      }
    })

    // 2、标记对比多的数据
    map2.forEach((item: GridDataInter, key: string) => {
      if (map1.has(key)) {
        item.diffType = 'normal'
      } else {
        item.diffType = 'recordAdd'
      }
    })

    // 3、找到对比的属性差异, 参考和对比数据都记录属性差异，参考不显示属性差异，对比显示属性差异
    map2.forEach((targetItem: GridDataInter, key: string) => {
      // 检查正常记录中，key相同，属性不同的数据
      if (targetItem.diffType == 'normal') {
        const sourceItem = map1.get(key)
        //差异信息
        const propertyDiffInfo: PropertyDiffInfoInter[] = []
        if (this.getVersionInfo(sourceItem) != this.getVersionInfo(targetItem)) {
          propertyDiffInfo.push({ propertyName: 'versionInfo', text: '版本号' })
        }
        if (sourceItem.state != targetItem.state) {
          propertyDiffInfo.push({ propertyName: 'state', text: '生命周期状态' })
        }
        if (sourceItem.classAndView != targetItem.classAndView) {
          propertyDiffInfo.push({ propertyName: 'classAndView', text: '实体类型' })
        }

        if (propertyDiffInfo.length) {
          targetItem.diffType = 'propertyDiff'
          targetItem.propertyDiffInfo = propertyDiffInfo

          sourceItem.diffType = 'propertyDiff'
          sourceItem.propertyDiffInfo = propertyDiffInfo
        }
      }
    })
  }

  getVersionInfo(item: any) {
    return `${item.version}.${item.iteration}`
  }

  getViewName(item: Record<string, any>) {
    if (item.partView && item.partView.description) {
      return `(${item.partView.description})`
    } else {
      return ''
    }
  }

  onCancel() {
    this.emit('cancel')
  }
}
