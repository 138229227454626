import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_col = _resolveComponent("k-col")!
  const _component_k_row = _resolveComponent("k-row")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    style: {"margin-top":"10px"},
    name: "baseForm",
    ref: _ctx.$vm.refBasePropForm,
    rules: _ctx.$vm.rules,
    model: _ctx.$vm.formData,
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
    autocomplete: "off"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_row, { span: "24" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_col, { span: "24" }, {
            default: _withCtx(() => [
              _createVNode(_component_k_form_item, {
                label: "客户端名称",
                name: "clientName"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_input, {
                    name: "name",
                    value: _ctx.$vm.formData!.clientName,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formData!.clientName) = $event)),
                    maxlength: 64
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_k_row, { span: "24" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_col, { span: "24" }, {
            default: _withCtx(() => [
              _createVNode(_component_k_form_item, {
                label: "客户端IP",
                name: "clientIP"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_input, {
                    name: "name",
                    value: _ctx.$vm.formData!.clientIP,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.formData!.clientIP) = $event)),
                    maxlength: 64,
                    disabled: _ctx.$vm.disabledClientIP
                  }, null, 8, ["value", "disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["rules", "model"]))
}