import {
  BaseViewModel,
  EnumDialogResult,
  EnumToolStripCompType,
  IQuery,
  KDataGridViewModel,
  KDialogClosingEvent,
  KNotification,
  SelectOption,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KMySubscribedRequirementEmitsType, KMySubscribedRequirementPropType } from './interface'
import {
  Api,
  EnumQueryConditionOperator,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectToolStripViewModel,
  ObjectClientSrv,
  ObjectToolStripItem,
  processCellCallback,
  ToolStripHelper
} from '@kmsoft/ebf-common'
import { ref } from 'vue'
import _ from 'lodash'
import { WorkingStateDesc, WorkingStateEnum } from '../../../../../ebf-folder/src/pages/folder-manage/interface'

/** KMySubscribedRequirement */
export default class KMySubscribedRequirementViewModel extends BaseViewModel<
  KMySubscribedRequirementEmitsType,
  KMySubscribedRequirementPropType
> {
  /** 文件夹列表网格 */
  refFolderObjGrid = ref<KDataGridViewModel>()
  /** 工具栏 */
  refToolStrip = ref<KObjectToolStripViewModel>()
  /** 工具栏数据 */
  toolStripItems = ref<Array<ObjectToolStripItem>>([
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SUBSCRIBE_REQUIREMENT,
      title: '添加',
      icon: 'file-protect',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'alt+o'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_UNSUBSCRIBE_REQUIREMENT,
      title: '移除',
      icon: 'file-protect',
      visible: true,
      supportedObjClsTempletID: ['ProductNeeds', 'CustomNeeds'],
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'alt+o'
    },
    {
      name: EnumToolStripItemKeys.REFRESH,
      title: '刷新',
      icon: 'file-protect',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'alt+o'
    }
  ])
  inputNumber = ref<string>()

  /**工作状态候选值 */
  workingStateOptions = ref<Array<SelectOption>>(WorkingStateEnum)

  constructor(options: ViewModelOptions<KMySubscribedRequirementPropType>) {
    super(options)
  }

  viewDidMount() {}

  queryData() {
    this.refresh()
  }

  /**
   * 加载网格数据
   */
  async loadData(query: IQuery) {
    const param = {
      data: [
        {
          numberOrName: this.inputNumber.value,
          pageVO: {
            maxPageSize: 1000,
            curPage: query.page?.pageIndex,
            pageSize: query.page?.pageSize,
            totalRows: 0,
            totalPages: 0,
            startIndex: 0,
            endIndex: 0
          }
        }
      ]
    }
    const result = await Api.post('requirement', 'Requirement', 'listMySubscription', param)
    if (result && result.code == EnumRequestCode.SUCCESS && result.data.data) {
      return {
        rows: result.data.data,
        total: result.data.page.totalRows
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  /**
   * 工具栏点击事件
   */
  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SUBSCRIBE_REQUIREMENT:
        this.subscribe()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_UNSUBSCRIBE_REQUIREMENT:
        this.unsubscribe()
        break
      case EnumToolStripItemKeys.REFRESH:
        this.refFolderObjGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /**
   * 订阅
   */
  async subscribe() {
    const objectClassTreeProps = {
      showObjClsCodes: ['ProductNeeds', 'CustomerNeeds'],
      defaultSelectClsCode: 'ProductNeeds'
    }
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      isMultipleSelection: true,
      objectClassManageQueryParam: objectClassTreeProps,
      objectSearchQueryParam: objectClassTreeProps,
      size: { width: 1300, height: 850 },
      onClosing: async (event: KDialogClosingEvent) => {
        if (!event.viewInstance) {
          return
        }

        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }

        const objParams = event.viewInstance.getSelectedObjParams()

        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const modelGroup = await ObjectClientSrv.getModelGroupByCode(objParams[0]!.modelCode)
        const objIds = objParams.map((obj: { id: any }) => obj.id)
        // 获取对象信息
        const query = {
          modelCode: objParams[0]!.modelCode,
          modelGroup: modelGroup,
          filter: {
            conditions: [
              {
                conditionName: 'rdmExtensionType',
                operator: EnumQueryConditionOperator.EQUAL,
                conditionValues: [objParams[0]!.extensionType]
              },
              {
                conditionName: 'id',
                operator: EnumQueryConditionOperator.IN,
                conditionValues: objIds
              }
            ],
            joiner: 'and'
          },
          page: {
            pageIndex: 1,
            pageSize: 1000
          }
        }
        const objs = await ObjectClientSrv.listObjects(query)
        if (objs.rows.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '未查询到对象信息'
          })
          event.cancel = true
          return
        }
        // 根据对象中是否有branch对象判断是否是版本对象，若为版本对象则取branch.id
        const objList = objs.rows.map(obj => {
          return {
            objId: obj.originalNumber,
            objClass: obj.rdmExtensionType
          }
        })
        const result = await Api.post('notify', 'DmeMessageSubscribeService', 'subscribe', { data: [objList] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '订阅成功'
          })
          this.refresh()
          return
        } else {
          KNotification.error({
            title: '系统提示',
            content: result.message!
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 取消订阅
   */
  async unsubscribe() {
    const rows = this.refFolderObjGrid.value?.getSelectedRows()
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }
    const objList = rows.map(row => {
      return {
        objId: row.originalNumber,
        objClass: row.rdmExtensionType
      }
    })
    const result = await Api.post('notify', 'DmeMessageSubscribeService', 'unsubscribe', { data: [objList] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success({
        title: '系统提示',
        content: '取消订阅成功'
      })
      this.refresh()
      return
    } else {
      KNotification.error({
        title: '系统提示',
        content: result.message!
      })
      return
    }
  }

  /**
   * 打开新标签页展示对象
   */
  async openObjTab(row: any) {
    const param = {
      id: row?.id,
      modelCode: row!.rdmExtensionType,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.rdmExtensionType)
    }
    ObjectClientSrv.openObj(param)
  }

  /**
   * 刷新对象
   */
  refresh(noClear?: boolean) {
    if (!noClear) {
      this.refFolderObjGrid.value?.clearSelectedRows()
    }
    this.refFolderObjGrid.value?.refresh()
  }

  exportSelectAsExcel() {
    const data = this.refFolderObjGrid.value?.getSelectedRows()
    if (data && data.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择要导出的数据'
      })
      return
    }
    const params = {
      onlySelected: true,
      columnKeys: this.refFolderObjGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id),
      processCellCallback: (params: any) => {
        const self = this
        return processCellCallback(params, self.refFolderObjGrid.value)
      }
    }
    this.refFolderObjGrid.value?.exportDataAsExcel(params)
  }
  exportAsExcel() {
    const params = {
      columnKeys: this.refFolderObjGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id),
      processCellCallback: (params: any) => {
        const self = this
        return processCellCallback(params, self.refFolderObjGrid.value)
      }
    }
    this.refFolderObjGrid.value?.exportDataAsExcel(params)
  }

  getProjectStatus(row: any) {
    if (row.workingState === 'CHECKED_IN') {
      return 'lock-in'
    } else if (row.workingState === 'INWORK' || row.workingState === 'CHECKED_OUT') {
      return 'lock-out'
    }
  }

  getIconClass(row: any) {
    if (row.workingState === 'INWORK') {
      return 'unlock-self'
    } else if (row.workingState === 'CHECKED_IN') {
      return 'lock-in'
    }
    return 'unlock-out'
  }

  getStatusOption(row: any) {
    return WorkingStateDesc.find(it => it.value === row.workingState)?.label
  }
}
