import { connect, withInstall } from '@kmsoft/upf-core'
import KCompareResultView from './KCompareResult.vue'
import KCompareResultViewModel from './KCompareResultViewModel'

const KCompareResult = connect(KCompareResultView, KCompareResultViewModel)

export default withInstall(KCompareResult)
export { KCompareResult, KCompareResultView, KCompareResultViewModel }

// 模板生成文件
// export * from './{{folderName}}'
