import { Api, EnumRequestCode, ObjBusinessParam, ObjectClientSrv } from '@kmsoft/ebf-common'
import { BaseViewModel, IQuery, KDataGridViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import { KDocStructureListEmitsType, KDocStructureListPropType } from './interface'

/** KDocStructureList */
export default class KDocStructureListViewModel extends BaseViewModel<KDocStructureListEmitsType, KDocStructureListPropType> {
  refDataGrid = ref<KDataGridViewModel>()
  objParam = ref<ObjBusinessParam>()

  constructor(options: ViewModelOptions<KDocStructureListPropType>) {
    super(options)
    this.objParam.value = options.props.objParam

    watch(
      () => this.props.objParam,
      () => {
        //不加这一行切换会失败
        this.objParam.value = options.props.objParam
        this.refDataGrid.value?.refresh()
      },
      {
        immediate: true,
        deep: true
      }
    )
    // watch(
    //   () => this.props.refreshTip,
    //   () => {
    //     this.refDataGrid.value?.refresh()
    //   },
    //   {
    //     deep: true
    //   }
    // )
  }

  viewDidMount() {}

  async loadData(query: IQuery): Promise<any> {
    const data = [] as any
    const reqParam = {
      data: [{ id: this.objParam.value?.id }]
    }
    const result = await Api.post('doc', 'Document', 'listStruct', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      for (let index = 0; index < result.data.length; index++) {
        data.push(result.data[index])
      }
    }
    return data
  }
  getHierarchyPath(row: Record<string, any>) {
    const hierarchy = [] as Array<string>
    this.getHierarchy(row, hierarchy)
    console.log(`开始获取${row.name}的结构`, JSON.stringify(hierarchy))
    return hierarchy
  }
  getHierarchy(row: Record<string, any>, hierarchy: Array<string>) {
    if (row.parentId == 'null' || row.parentId == null) {
      hierarchy.unshift(row.code)
    } else {
      const parentRow = this.refDataGrid.value?.getRow(row.parentId)
      if (parentRow) {
        hierarchy.unshift(row.code)
        this.getHierarchy(parentRow, hierarchy)
      }
    }
  }

  onDoubleClick() {
    const rows = this.refDataGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    // 获取对象id
    const rowIds = rows!.map(row => row.id)
    const param = { id: rowIds[0], modelCode: 'Document', modelGroup: 'doc' }
    ObjectClientSrv.openObj(param)
  }
}
