import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KInfoMessagePropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KInfoMessagePropType = ViewPropsTypeExtract<typeof KInfoMessagePropOptions>

/** 事件 */
export const KInfoMessageEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KInfoMessageEmitsType = ViewEmitsTypeExtract<typeof KInfoMessageEventEmits>
