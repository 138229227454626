import { KTreeNode, SimpleViewModel, TreeViewCheckedEventArgs, TreeViewSelectEventArgs, ViewModelOptions } from '@kmsoft/upf-core'
import { isArray } from 'lodash'
import { nextTick, ref, watch } from 'vue'
import KTreeNodeObjClass from '../../../object-class-tree/node/KTreeNodeObjClass'
import { KBaseEditorViewModel } from '../../base'
import { KObjectClassTreeSelectorEmitsType, KObjectClassTreeSelectorPropType } from './interface'
import { clsCodeMap } from '../../../../client-srv'

/** KObjectClassTreeSelector */
export default class KObjectClassTreeSelectorViewModel extends KBaseEditorViewModel<
  KObjectClassTreeSelectorEmitsType,
  KObjectClassTreeSelectorPropType,
  string | Array<string>
> {
  refClassInput = ref<SimpleViewModel>()
  constructor(options: ViewModelOptions<KObjectClassTreeSelectorPropType>) {
    super(options)
    this.expand.value = options.props.defaultOpen

    watch(
      this.stateValue,
      (newValue, oldValue) => {
        this.init()
      },
      { immediate: true }
    )

    watch(
      () => this.props.defaultSelectClsCode,
      newValue => {
        if (newValue) {
          nextTick(() => {
            const label = clsCodeMap.get(newValue)
            this.refClassInput.value?.setValue(label)
          })
        }
      },
      { immediate: true }
    )
  }

  /** 对象类名称 */
  objClassName = ref<string>('')
  /** 选择的树节点 */
  selectedNode = ref<KTreeNode>()
  /**选择的树节点-多选 */
  checkedNode = ref<Array<KTreeNode>>([])
  /** 模态框状态 */
  expand = ref(false)
  /** 是否禁用确认按钮 */
  isDisableConfirm = ref(false)

  /**
   * 初始化
   * @description
   */
  init() {
    /** 如果没有值 设置默认值 */
    if (!this.stateValue.value && this.props.showDefaultSelect) {
      // TODO 暂未实现
      /** 从用户缓存获取上传选择的对象类 */
    }

    if (this.stateValue.value) {
      this.stateValue.value = isArray(this.stateValue.value) ? this.stateValue.value : [this.stateValue.value]

      /** 查询对象类 */
      //TODO:移除元数据依赖，又后端提供接口获取对象信息
      const objClsAttribs = this.stateValue.value.map(x => {
        return [] as Array<Record<string, any>> //待修改
      })

      if (objClsAttribs) {
        // 拼接对象类名称
        this.objClassName.value = objClsAttribs.map((y: any) => y?.name).join(',')
      }
    } else {
      this.objClassName.value = ''
    }
  }

  /**
   * 根据节点信息更新数据
   * @description
   */
  updateValueByNode() {
    // 如果不是多选
    if (!this.props.isMultipleSelection) {
      // 如果选择了节点
      if (this.selectedNode.value) {
        this.objClassName.value = (this.selectedNode.value as KTreeNodeObjClass).name!
        this.stateValue.value = (this.selectedNode.value as KTreeNodeObjClass).code
      }
    } else {
      if (this.checkedNode.value && this.checkedNode.value.length > 0) {
        this.objClassName.value = this.checkedNode.value.map((x: any) => x.code).join(',')
        this.stateValue.value = this.checkedNode.value.map((x: any) => x.code)
      }
    }

    // 返回值
    this.emit('update:objClsName', this.stateValue.value)
    this.emit('update:value', this.stateValue.value)
    this.refClassInput.value?.setValue(this.objClassName.value!)
  }

  /** 清空 */
  onClear() {
    this.stateValue.value = ''
    this.emit('update:objClsName', this.objClassName.value)
    this.setValue('')
  }

  /**
   * 选择节点以后
   * @param event
   */
  onAfterSelect(event: TreeViewSelectEventArgs) {
    if (!this.props.isMultipleSelection) {
      this.selectedNode.value = event.node as KTreeNode

      // 如果允许选择类型为class，禁用确定按钮
      if (this.props.type === 'class' && !(this.selectedNode.value instanceof KTreeNodeObjClass)) {
        this.isDisableConfirm.value = true
      } else {
        this.isDisableConfirm.value = false
      }
    }
  }

  /**
   * 选择节点后-多选
   * @param event
   */
  onAfterCheck(event: TreeViewCheckedEventArgs) {
    if (event.checked) {
      this.checkedNode.value.push(...(event.nodes as any))
    } else {
      const ids = event.nodes.map(x => x.id)
      this.checkedNode.value = this.checkedNode.value.filter(x => !ids.includes(x.id))
    }

    // 如果允许选择类型为class，禁用确定按钮
    if (this.props.type === 'class' && !(event.nodes[0] instanceof KTreeNodeObjClass)) {
      this.isDisableConfirm.value = true
    } else {
      this.isDisableConfirm.value = false
    }
  }

  /** 树组件就绪事件 */
  onTreeReady() {
    if (!this.stateValue.value) {
      this.updateValueByNode()
    }
  }

  /** 确认选择 */
  onConfirm() {
    // 不显示模态框
    this.expand.value = false

    this.updateValueByNode()
  }
}
