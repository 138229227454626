import {
  BaseViewModel,
  DataGridLoadResult,
  EnumDialogResult,
  EnumToolStripCompType,
  IQuery,
  KDataGridRowData,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KIcon,
  KModal,
  KNotification,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KMyProductNeedsEmitsType, KMyProductNeedsPropType } from './interface'
import {
  KObjectToolStripViewModel,
  ObjectToolStripItem,
  EnumToolStripItemKeys,
  ObjectClientSrv,
  processCellCallback,
  Api,
  EnumRequestCode,
  EnumQueryConditionOperator,
  LoginClientSrv
} from '@kmsoft/ebf-common'
import { createVNode, ref } from 'vue'
import { WorkingStateDesc } from '../../../../../ebf-folder/src/pages/folder-manage/interface'
import {
  KProductNeedsCreateWithFolderSelector,
  KProductNeedsCreateWithFolderSelectorViewModel
} from '../../product-needs/product-needs-create-with-folder-selector'
import _ from 'lodash'

/** KMyProductNeeds */
export default class KMyProductNeedsViewModel extends BaseViewModel<KMyProductNeedsEmitsType, KMyProductNeedsPropType> {
  /** 文件夹列表网格 */
  refFolderObjGrid = ref<KDataGridViewModel>()
  /** 工具栏 */
  refToolStrip = ref<KObjectToolStripViewModel>()
  /** 工具栏数据 */
  toolStripItems = ref<Array<ObjectToolStripItem>>([
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW,
      title: '创建',
      icon: 'file-protect',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'alt+o'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE,
      title: '删除',
      icon: 'file-protect',
      visible: true,
      supportedObjClsTempletID: ['ProductNeeds'],
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'alt+o'
    },
    {
      name: EnumToolStripItemKeys.REFRESH,
      title: '刷新',
      icon: 'file-protect',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'alt+o'
    }
  ])
  inputNumber = ref<string>()
  inputName = ref<string>()
  constructor(options: ViewModelOptions<KMyProductNeedsPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 工具栏点击事件
   */
  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW:
        this.create()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE:
        this.batchDelete()
        break
      case EnumToolStripItemKeys.REFRESH:
        this.refFolderObjGrid.value?.refresh()
        break
      default:
        break
    }
  }

  queryData() {
    this.refresh()
  }

  /**
   * 网格数据加载
   */
  loadData(query: IQuery): Promise<DataGridLoadResult> {
    const user = LoginClientSrv.getUserIdentity()
    const userId = user!.name
    const finalConditons = [
      ...(this.inputNumber.value
        ? [
            {
              conditionName: 'number',
              operator: EnumQueryConditionOperator.LIKE,
              conditionValues: [this.inputNumber.value]
            }
          ]
        : []),
      ...(this.inputName.value
        ? [
            {
              conditionName: 'title',
              operator: EnumQueryConditionOperator.LIKE,
              conditionValues: [this.inputName.value]
            }
          ]
        : [])
    ]
    finalConditons.push({
      conditionName: 'creator',
      operator: EnumQueryConditionOperator.EQUAL,
      conditionValues: [userId]
    })

    const params = {
      data: [
        {
          sort: 'DESC',
          orderBy: 'createTime',
          filter: {
            conditions: finalConditons,
            joiner: 'and'
          },
          isNeedTotal: true
        },
        {
          currentPage: query.page?.pageIndex,
          pageSize: query.page?.pageSize
        }
      ]
    }
    return new Promise((resolve, reject) => {
      Api.post('requirement', 'ProductNeeds', 'pageLatest', params).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS && res.data.data) {
          const data = {
            rows: res.data.data,
            total: res.data.page.totalRows
          }
          resolve(data)
        } else {
          resolve({
            rows: [],
            total: 0
          })
        }
      })
    })
  }

  /**
   *  创建产品需求
   */
  async create() {
    const data = {}
    const props: any = {
      customerNeeds: data
    }

    KDialog.show({
      title: '创建产品需求',
      size: { width: 1000, height: 800 },
      props: {
        showFolderSelector: true
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KProductNeedsCreateWithFolderSelector,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formViewModel = event.viewModel as KProductNeedsCreateWithFolderSelectorViewModel
        const validateResult = await formViewModel.validate()

        if (!validateResult) {
          event.cancel = true
          return
        }

        const result = await formViewModel.save()
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '新建成功'
          })
          this.refFolderObjGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '新建失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 批量删除对象
   */
  async batchDelete() {
    const rows = this.refFolderObjGrid.value?.getSelectedRows()
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一个对象'
      })
      return
    }

    // 删除校验
    const ids = rows.map((row: any) => {
      return row.id
    })
    const showDialog = KDialog.info({ content: '正在校验删除对象，请稍后...', title: '提示', showOk: false })
    const check = await Api.post('requirement', 'ProductNeeds', 'batchDeleteVerification', { data: [ids] })
    showDialog.destroy()
    // 接口调用失败
    if (!(check && check.code == EnumRequestCode.SUCCESS)) {
      KNotification.error({
        title: '系统错误',
        content: check.message
      })
      return
    }

    // 删除校验失败
    if (!check.data.deletable) {
      KNotification.error({
        title: '删除校验失败',
        content: check.data.message
      })
      return
    }
    // 删除
    KModal.delete({
      title: '删除提示',
      icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
      content: `您正在进行删除操作${check.data.message ? '(' + check.data.message + ')' : ''}，请确认是否删除`,
      onOk: async () => {
        const result = await Api.post('requirement', 'ProductNeeds', 'batchDelete', { data: [ids] })
        if (!(result && result.code == EnumRequestCode.SUCCESS)) {
          KNotification.error({
            title: '对象删除失败',
            content: result.message!
          })
          return
        }
        KNotification.success('对象删除成功')
        this.refFolderObjGrid.value?.clearSelectedRows()
        this.refFolderObjGrid.value?.refresh()
        return
      }
    })
  }

  /**
   * 打开新标签页展示对象
   */
  async openObjTab(row: any) {
    const param = {
      id: row?.id,
      modelCode: row!.rdmExtensionType,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.rdmExtensionType)
    }
    ObjectClientSrv.openObj(param)
  }

  /**
   * 刷新对象
   */
  refresh(noClear?: boolean) {
    if (!noClear) {
      this.refFolderObjGrid.value?.clearSelectedRows()
    }
    this.refFolderObjGrid.value?.refresh()
  }

  exportSelectAsExcel() {
    const data = this.refFolderObjGrid.value?.getSelectedRows()
    if (data && data.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择要导出的数据'
      })
      return
    }
    const params = {
      onlySelected: true,
      columnKeys: this.refFolderObjGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id),
      processCellCallback: (params: any) => {
        const self = this
        return processCellCallback(params, self.refFolderObjGrid.value)
      }
    }
    this.refFolderObjGrid.value?.exportDataAsExcel(params)
  }
  exportAsExcel() {
    const params = {
      columnKeys: this.refFolderObjGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id),
      processCellCallback: (params: any) => {
        const self = this
        return processCellCallback(params, self.refFolderObjGrid.value)
      }
    }
    this.refFolderObjGrid.value?.exportDataAsExcel(params)
  }

  getProjectStatus(row: any) {
    if (row.workingState === 'CHECKED_IN') {
      return 'lock-in'
    } else if (row.workingState === 'INWORK' || row.workingState === 'CHECKED_OUT') {
      return 'lock-out'
    }
  }

  getIconClass(row: any) {
    if (row.workingState === 'INWORK') {
      return 'unlock-self'
    } else if (row.workingState === 'CHECKED_IN') {
      return 'lock-in'
    }
    return 'unlock-out'
  }

  getStatusOption(row: any) {
    return WorkingStateDesc.find(it => it.value === row.workingState)?.label
  }
}
