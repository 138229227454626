import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KLogManagePropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KLogManagePropType = ViewPropsTypeExtract<typeof KLogManagePropOptions>

/** 事件 */
export const KLogManageEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KLogManageEmitsType = ViewEmitsTypeExtract<typeof KLogManageEventEmits>
