import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KAssociateClientGridPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KAssociateClientGridPropType = ViewPropsTypeExtract<typeof KAssociateClientGridPropOptions>

/** 事件 */
export const KAssociateClientGridEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KAssociateClientGridEmitsType = ViewEmitsTypeExtract<typeof KAssociateClientGridEventEmits>
