
import {
  defineView,
  EnumDataGridAutoSizeColumnsMode,
  EnumDataGridRowModelType,
  IKTreeNode,
  TreeViewSelectEventArgs
} from '@kmsoft/upf-core'
import { KAllRequirementPropOptions, KAllRequirementEventEmits } from './interface'
import KAllRequirementViewModel from './KAllRequirementViewModel'
import { KAllCustomerNeeds } from './all-customer-needs'
import { KAllProductNeeds } from './all-product-needs'

export default defineView({
  name: 'KAllRequirement',
  props: KAllRequirementPropOptions,
  emits: KAllRequirementEventEmits,
  viewModel: KAllRequirementViewModel,
  components: {
    KAllProductNeeds,
    KAllCustomerNeeds
  },
  setup(props, { emit, slots, attrs, vm }) {
    return {
      pagination: { pageIndex: 1, pageSize: 100 },
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      autosizeColumnsMode: EnumDataGridAutoSizeColumnsMode.FILL
    }
  }
})
