import {
  Api,
  ClipBoard,
  ClipboardObjectData,
  CommonClientSrv,
  ComponentClientSrv,
  defineToolStripData,
  EBF_IOC_KEY,
  EnumClipBoarbType,
  EnumClipBoardDataType,
  EnumPhaseState,
  EnumRequestCode,
  EnumToolStripEnvironment,
  EnumToolStripItemKeys,
  KObjectToolStripViewModel,
  loadingHandle,
  ObjBusinessParam,
  ObjectClientSrv,
  TREE_VIEW_ROOT_NODE_KEY
} from '@kmsoft/ebf-common'
import {
  AppContext,
  BaseViewModel,
  EnumDialogResult,
  EnumItemClickedCancelType,
  EnumTreeViewNodeRefreshType,
  IKTreeNode,
  KDialog,
  KDialogClosingEvent,
  KDialogResult,
  KNotification,
  KTreeViewViewModel,
  request,
  ToolStripItemClickedEventArgs,
  TreeViewMenuEventArgs,
  TreeViewSelectEventArgs,
  utils,
  ViewModelOptions,
  KModal
} from '@kmsoft/upf-core'
import { EnumToolStripCompType } from '@kmsoft/upf-core/src'
import lodash from 'lodash'
import { ref, watch } from 'vue'
import { KBaselineCreate } from '../../../../ebf-baseline/src'
import { KBaselineCreateViewModel } from '../../../../ebf-baseline/src/pages/baseline-create'
import {
  KChangePromoteActivity,
  KChangePromoteActivityViewModel
} from '../../../../ebf-change-manage/src/pages/change-promote-activity/change-promote-activity'
import {
  EnumPartNodeChildExpandMode,
  PartClientSrv,
  PartStructureClientSrv,
  PartStructureNodeParam,
  SortChildPartItem
} from '../../client-srv'
import { KPartBomCompare } from '../../pages/part-manage/part-bom-compare'
import { KPartDocBomCompare } from '../../pages/part-manage/part-doc-bom-compare'
import { KPartReport } from '../../pages/part-manage/part-report'
import { KPartAssociationForm } from '../part-association-form'
import { KPartCollectForm } from '../part-collect-form'
import { KPartCreateBaseline, KPartCreateBaselineViewModel } from '../part-create-baseline'
import { KPartCreatePromote, KPartCreatePromoteViewModel } from '../part-create-promote'
import { KPartExcelExport, KPartExcelExportViewModel } from '../part-excel-export'
import { KPartSearchForm } from '../part-search-form'
import { EnumPartToolStripItemKeys } from '../toolstrip'
import { KPartStructureTreeEmitsType, KPartStructureTreePropType, PartStructureQueryRule } from './interface'
import { PartStructureTreeNode } from './nodes'
import { getToolStrip } from './toolStrip'
import { KPartCreateViewVersionForm } from '../part-create-view-version-form'
import KPartCreateViewVersionFormViewModel from '../part-create-view-version-form/KPartCreateViewVersionFormViewModel'

export default class KPartStructureTreeViewModel extends BaseViewModel<KPartStructureTreeEmitsType, KPartStructureTreePropType> {
  /** 工具栏 */
  refToolStrip = ref<KObjectToolStripViewModel>()
  /** 树控件引用 */
  refTreeView = ref<KTreeViewViewModel>()
  /** 右键菜单 */
  toolStripItems = defineToolStripData(getToolStrip())
  /** 是否是多选 */
  isMultipleSelection = ref<boolean>(false)
  /** 选择严格受控 */
  notCheckStrictly = ref<boolean>(true)
  /** 根节点 */
  rootNode: PartStructureTreeNode
  /** 根节点 */
  highlightNodes: Array<PartStructureTreeNode> = []
  /** 排序方式 */
  sortMode: string = ''
  /** 当前操作的节点 */
  operateNode: PartStructureTreeNode

  /** 查询索引 */
  queryIndex = ref<number>(0)
  /** 查询结果 */
  queryData = ref<Array<any>>([])
  /** 基线Id */
  baselineId = ref<string>('')

  /** 查询条件 */
  queryRule: PartStructureQueryRule = { type: 'LATEST_WITH_WORKING_COPY' }
  /** 视图 */
  viewName: string = 'Design'
  refContainer = ref<HTMLElement>()
  diaResult = ref<KDialogResult>()
  rootNodeId = ref<string | undefined>(this.props.objParam?.id)

  constructor(options: ViewModelOptions<KPartStructureTreePropType>) {
    super(options)
    if (options.props.viewName) {
      this.viewName = options.props.viewName
    }
    watch(
      () => options.props.partStructureViewMode,
      newValue => {
        if (newValue?.viewName == '时间有效性' && !newValue?.datedValue) return
        this.refTreeView.value?.refresh()
      },
      {
        deep: true
      }
    )
    watch(
      () => this.notCheckStrictly.value,
      (newValue, oldValue) => {
        if (newValue) {
          //非严格模式下父节点勾选时将子节点自动勾选
          setTimeout(() => {
            const checkNodes = this.refTreeView.value?.getCheckedNodes()
            const checkKeys = this.getCheckKeysFromNodes(checkNodes!)
            this.refTreeView.value?.setCheckedKeys(checkKeys!)
          }, 200)
        }
      }
    )
  }

  /** 根据当前节点选中状态获取父节点和子节点 */
  getCheckKeysFromNodes(node: Record<string, any>[]) {
    const keys: string[] = []
    node.forEach((item: any) => {
      if (item.children && item.children.length > 0) {
        const childKeys = this.getCheckKeysFromNodes(item.children)
        keys.push(item.key, ...childKeys)
      } else {
        keys.push(item.key)
      }
      const parentNode = this.refTreeView.value?.getParentNode(item.key)
      if (parentNode && !keys.includes(parentNode.key) && parentNode.children?.every(item => item.checked)) {
        keys.push(parentNode.key)
      }
    })
    return Array.from(new Set(keys))
  }

  get treeView() {
    return this.refTreeView.value
  }

  viewDidMount() {
    this.init()
    AppContext.current
      .getIocContainer()
      .registerSingleton(
        EBF_IOC_KEY.SET_NODE_HIGH_LIGHT,
        { setSelectNode: this.setSelectNodes.bind(this) },
        { name: EBF_IOC_KEY.SET_NODE_HIGH_LIGHT.toString(), allowReplace: true }
      )
  }

  private init() {
    // 在界面初始化时就隐藏操作下的复制粘贴，防止和零部件复制粘贴冲突
    // 隐藏操作中的复制粘贴
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COPY, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_PASTE, false)
  }

  async setSelectNodes(event: any) {
    if (event.container != 'partObjManage') return
    if (event.environment != 'Part') return
    const path = event.path as string[]
    if (path) {
      const ids = [] as string[]
      const rootNode = this.refTreeView.value?.getRootNodes()[0] as PartStructureTreeNode
      const childPath = path.filter(item => item != 'root').map(item => ({ docId: item.split('_')[0] }))
      const reqParam = {
        data: [childPath]
      }

      const result = await Api.post('part', 'PartDescribeLink', 'swap2PartPath', reqParam)
      if (result && result.code == EnumRequestCode.SUCCESS) {
        result.data.forEach((element: { findNumber: string; partId: any }) => {
          ids.push(element.partId)
        })
      } else {
        KNotification.warn({ message: '找不到对应的零部件' })
      }
      // 获取bomLink 路径
      if (ids && ids.length > 0) {
        const reqParam = {
          data: [{ id: rootNode.partObjParam.id, clazz: 'Part' }, ids]
        }
        const result = await Api.post('part', 'Part', 'findPathByPartPath', reqParam)
        if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
          this.refTreeView.value?.expandByPath(rootNode!, rootNode.id + ',' + result.data, true)
        }
      }
    }
  }

  /**
   * 重载节点
   * @param node 节点
   * @param refreshType 刷新类型
   * @param properties 属性
   */
  async refreshNode(node: PartStructureTreeNode, refreshType: EnumTreeViewNodeRefreshType, properties?: Record<string, any>) {
    // 如果刷新根节点 直接刷新整棵树
    if (node.id == TREE_VIEW_ROOT_NODE_KEY) {
      await this.refTreeView.value?.refresh(undefined, true, true)
      this.refTreeView.value?.setSelectedNode(TREE_VIEW_ROOT_NODE_KEY)
      return
    }

    // 刷新节点
    await this.refTreeView.value?.refreshNode(node, refreshType)

    /** 拼接节点路径 */
    if (refreshType == EnumTreeViewNodeRefreshType.NODE_CHILD || refreshType == EnumTreeViewNodeRefreshType.NODE_CHILD_AND_SELF) {
      // 如果是叶子节点 则不展开子
      if (node.leaf) {
        return
      }
      const nodePath = node.id + (properties ? ',' + properties['StructId'] : '')
      await this.refTreeView.value?.expandByPath(node, nodePath)
      return
    }

    if (refreshType == EnumTreeViewNodeRefreshType.NODE_PARENT) {
      if (node.parent) {
        const nodePath = node.parent.id + ',' + node.id
        await this.refTreeView.value?.expandByPath(node.parent!, nodePath)
      }
    }
  }

  /**
   * 刷新节点，自己和子节点
   * @param node
   */
  async refreshStructureNode(node: PartStructureTreeNode) {
    await this.refreshNode(node, EnumTreeViewNodeRefreshType.NODE_CHILD_AND_SELF)
  }

  /** 刷新树 */
  async refreshTree() {
    // 刷新树
    await this.refTreeView.value?.refresh(undefined, true)
    // 刷新后
    this.emit('refresh')
  }

  /** 清除高亮节点 */
  clearHighlight(): void {
    if (this.highlightNodes.length == 0) {
      return
    }
    this.highlightNodes.forEach(node => (node.highLight = false))
  }

  /**
   * 根据路径高亮
   * @param path
   */
  async setHighlightByPath(path: string) {
    /** 展开节点 */
    const resultNode = (await this.refTreeView.value?.expandByPath(this.rootNode, path, false)) as
      | PartStructureTreeNode
      | undefined

    if (resultNode) {
      this.setHighlightNodes(resultNode, true)
    }
  }

  /**
   * 设置高亮节点
   * @param nodes
   * @returns
   */
  setHighlightNodes(nodes: PartStructureTreeNode | Array<PartStructureTreeNode>, isClear: boolean): void {
    // 清除所有高亮节点
    if (isClear) {
      this.clearHighlight()
    }

    if (!nodes) {
      return
    }

    if (!lodash.isArray(nodes)) {
      nodes = [nodes]
    }

    const datas = nodes.map(a => {
      return {
        key: a.key,
        highLight: true
      }
    })

    // 标记高亮
    this.refTreeView.value?.modifyNode(datas)

    // 滚动到节点
    this.refTreeView.value?.scrollTo(datas[0].key, 'auto')

    this.highlightNodes = nodes
  }

  /**
   * 获取操作节点的零部件对象参数
   * @param operationNodeKey 当前右键菜单操作的零部件节点的Key
   * @returns
   */
  async getOperationNodeObjParams(operationNodeKey: string): Promise<Array<ObjBusinessParam>> {
    let objParams = [] as Array<ObjBusinessParam>

    // if (this.isMultipleSelection.value) {
    //   const nodes = this.refTreeView.value?.getCheckedNodes() as Array<PartStructureTreeNode>
    //   objParams = nodes.map<ObjBusinessParam>(m => {
    //     return m.partObjParam
    //   })

    //   if (this.notCheckStrictly.value) {
    //     //获取未展开节点
    //     const unExpandNodes = nodes.filter(_ => {
    //       return !_.leaf && _.children == undefined
    //     })
    //     // API
    //     // if (unExpandNodes && unExpandNodes.length > 0) {
    //     //   const unExpandNodeObjParams = await partSrv.getPartAllChild({
    //     //     partChildParams: unExpandNodes.map(_ => {
    //     //       return {
    //     //         partParam: { objClsID: _.partObjClsId, objID: _.partId },
    //     //         expandMode: _.currentExtendMode,
    //     //         searchCondition: _.searchCondition,
    //     //         sortMode: _.sortMode
    //     //       }
    //     //     })
    //     //   })
    //     //   objParams.push(...unExpandNodeObjParams)
    //     // }
    //   }
    // }
    if (operationNodeKey) {
      const node = this.refTreeView.value?.getNode(operationNodeKey)! as PartStructureTreeNode
      objParams = [node.partObjParam]
    } else {
      const node = this.refTreeView.value?.getSelectedNode() as PartStructureTreeNode
      objParams = node ? [node.partObjParam] : []
    }

    return objParams
  }

  /**是否多选 */
  getIsMultipleSelection(): boolean {
    return this.isMultipleSelection.value
  }

  /**获取排序方式 */
  getSortMode(): string {
    return this.sortMode
  }

  /** 是否是基线 */
  getIsBaselineView() {
    return !!this.props.partStructureViewMode?.baselineContents
  }

  /**
   * 查找
   * @param node
   */
  find(node: PartStructureTreeNode) {}

  /** 更新右键菜单状态 */
  async updateToolStripState(
    node: PartStructureTreeNode,
    operationConfig: {
      isMultiSelect: boolean
      selectedNodes: Array<PartStructureTreeNode>
    }
  ) {
    /** 节点Id */
    const nodeId = node.id
    /** 是否是根节点 */
    const isRoot = nodeId == TREE_VIEW_ROOT_NODE_KEY
    /**是否显示复选框 */
    const isShowCheckBox = this.isMultipleSelection.value
    /**是否多选 */
    const isBatchSelect = operationConfig.selectedNodes.length > 1
    /**是否可被替换 */
    const isSwap = node.isSwap
    /** 是否基线 */
    const isBaselineView: boolean = this.getIsBaselineView()
    /** 展开模式 */
    const currentExtendMode = PartClientSrv.getPartNodeChildExpandMode(this.props.viewName)
    /** 物理视图 */
    const isDefaultView = currentExtendMode == EnumPartNodeChildExpandMode.Default
    /**最新视图 */
    const isLatestVersionView = currentExtendMode == EnumPartNodeChildExpandMode.LatestVersion
    /** 是否有当前零部件的编辑权 */
    // const isCurrentNodeCanEdit = (await purviewSrv.judgeObjectPurview(node.partObjParam, EnumObjectPurviewType.Edit)).hasPurview
    const isCurrentNodeCanEdit = true

    const parentNode = node.parent ? (node.parent as PartStructureTreeNode) : undefined

    /** 是否有父零部件的编辑权 */
    let isCanEditParent = false

    if (parentNode) {
      isCanEditParent = true
      // API
      // isCanEditParent = (await purviewSrv.judgeObjectPurview(parentNode.partParam, EnumObjectPurviewType.Edit)).hasPurview
    }

    /** 当前对象是否有版本替换权限 */
    const isCurVersionReplace = true
    // const isCurVersionReplace = (await purviewSrv.judgeObjectPurview(node.partParam, EnumObjectPurviewType.PartVersionRelpace))
    //   .hasPurview

    // 是否只被当前父零部件引用
    const isOnlyParent = true

    // API
    const choice: number = 0
    //const obj = await partStructureSrv.getStructureBusiness(nodeId)
    // const choice = obj?.properties?.CCHOICE

    //1、首先需要过滤掉对象类节点的非法菜单按钮
    const partClsCode = operationConfig.selectedNodes[0].partObjParam.modelCode

    await this.refToolStrip.value?.updateState({
      enablePreview: true,
      modelCode: partClsCode,
      environment: EnumToolStripEnvironment.PartStructureManager,
      selectedDatas: operationConfig.selectedNodes
      // getObjectData: (row: Record<string, any>) => {
      //   const node = row as PartStructureTreeNode
      //   return {
      //     objParam: { objID: node.partId!, objClsID: node.partObjClsId! }
      //   }
      // },
      // getObjectPurviewData: (row: Record<string, any>) => {
      //   const node = row as PartStructureTreeNode
      //   return {
      //     objParam: { objID: node.partId!, objClsID: node.partObjClsId! },
      //     creator: node.partNodeProp?.creator,
      //     owner: node.partNodeProp?.owner,
      //     state: node.partNodeProp?.state,
      //     flowStatus: node.partNodeProp?.paramInFlow
      //   }
      // }
    })

    //流程相关
    // this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_EXIST_FLOW, !isBaselineView)
    // this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_BATCH_ADD_TO_FLOW, !isBaselineView)
    // this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_BATCH_TASK, !isBaselineView)
    // this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_FLOW_OBJ, !isBaselineView)

    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_GROUP_ADD_OLD_BRO_PART,
      !isSwap && isCanEditParent && !isShowCheckBox && !isBatchSelect && (isDefaultView || isLatestVersionView) && !isBaselineView
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_GROUP_ADD_YOUNG_BRO_PART,
      !isSwap && isCanEditParent && !isShowCheckBox && !isBatchSelect && (isDefaultView || isLatestVersionView) && !isBaselineView
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_GROUP_REPLACE_PART,
      !isSwap &&
        isCanEditParent &&
        !isShowCheckBox &&
        !isRoot &&
        !isBatchSelect &&
        (isDefaultView || isLatestVersionView) &&
        !isBaselineView
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_PART,
      !isSwap &&
        isCanEditParent &&
        !isRoot &&
        (isDefaultView || isLatestVersionView) &&
        isOnlyParent &&
        !isBaselineView &&
        !isShowCheckBox
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_OLD_BRO_PART_NEW,
      !isRoot && !isShowCheckBox
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_INSERT_OLD_BRO_PART_NEW,
      !isRoot && !isShowCheckBox
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE_PART,
      !isSwap && isCanEditParent && !isRoot && (isDefaultView || isLatestVersionView) && !isBaselineView && !isShowCheckBox
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_CUT_PART,
      !isSwap && isCanEditParent && !isRoot && (isDefaultView || isLatestVersionView) && !isShowCheckBox
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_COPY_PART,
      !isSwap && !isRoot && !isBaselineView && !isShowCheckBox
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_EXCEL_EXPORT,
      !isSwap && isRoot && !isBaselineView && !isShowCheckBox
    )

    //3、根据当前节点上下文信息隐藏相关按钮，如：复制/粘贴等
    if (ClipBoard.contains(EnumClipBoardDataType.PartStructNode) || ClipBoard.contains(EnumClipBoardDataType.ObjList)) {
      this.refToolStrip.value?.setItemVisible(
        EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_PASTE_PART,
        !isSwap &&
          isCurrentNodeCanEdit &&
          !isShowCheckBox &&
          !isBatchSelect &&
          (isDefaultView || isLatestVersionView) &&
          !isBaselineView
      )
    } else {
      this.refToolStrip.value?.setItemVisible(EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_PASTE_PART, false)
    }

    //3.1 如果当前节点是叶子节点，那么隐藏排序和调整位置顺序两个选项
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_CHANGE_PART_POSITION,
      !isSwap &&
        isCurrentNodeCanEdit &&
        !node.leaf &&
        !isBatchSelect &&
        (isDefaultView || isLatestVersionView) &&
        !isBaselineView &&
        !isShowCheckBox
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_SORT_PART,
      !isSwap &&
        isCurrentNodeCanEdit &&
        !node.leaf &&
        !isBatchSelect &&
        (isDefaultView || isLatestVersionView) &&
        !isBaselineView &&
        !isShowCheckBox
    )

    // 当为物理视图时显示更换版本
    // 有父零部件编辑权限或者本零部件版本替换权限的用户均可使用此菜单。
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_MENU_CHANGE_VERSION,
      !isSwap &&
        (isCanEditParent || isCurVersionReplace) &&
        !isShowCheckBox &&
        !isRoot &&
        !isBatchSelect &&
        isDefaultView &&
        !isBaselineView
    )

    // 替换件菜单
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_SWAP_BORROW,
      isCanEditParent && !isRoot && !isShowCheckBox && isDefaultView
    )

    // 转为
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_MENU_CHANGE_STATE,
      !isSwap && isCanEditParent && !isShowCheckBox && !isRoot && !isBatchSelect && isDefaultView && !isBaselineView
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_CHOOSE_NOT_CHECK,
      !isSwap &&
        isCanEditParent &&
        !isShowCheckBox &&
        !isRoot &&
        !isBatchSelect &&
        isDefaultView &&
        !isBaselineView &&
        choice != -1
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_CHOOSE_AND_CHECK,
      !isSwap &&
        isCanEditParent &&
        !isShowCheckBox &&
        !isRoot &&
        !isBatchSelect &&
        isDefaultView &&
        !isBaselineView &&
        choice != 0
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_MUST_CHOOSE,
      !isSwap &&
        isCanEditParent &&
        !isShowCheckBox &&
        !isRoot &&
        !isBatchSelect &&
        isDefaultView &&
        !isBaselineView &&
        choice != 1
    )

    // 批量操作
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SHOW_CHECKBOX, !isShowCheckBox)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_HIDE_CHECKBOX, isShowCheckBox)

    // 主文档对象
    this.refToolStrip.value?.setItemVisible(EnumPartToolStripItemKeys.TOOL_STRIP_MAIN_DOC_OPERATION, !isShowCheckBox)

    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_GROUP_ADD_CHILD_PART,
      !isSwap &&
        isCurrentNodeCanEdit &&
        !isShowCheckBox &&
        !isBatchSelect &&
        (isDefaultView || isLatestVersionView) &&
        !isBaselineView
    )
    this.refToolStrip.value?.setItemDisabled(
      EnumPartToolStripItemKeys.TOOL_STRIP_CREATE_PROMOTE,
      node.partObjParam.lifecycleStateCode != 'RELEASED'
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_CREATE_PROMOTE,
      !(isShowCheckBox || ['Standardparts', 'Electroniccomponents', 'Material'].includes(node.partObjParam.modelCode))
    )

    // 其他
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_GROUP_OPERATION, !isBaselineView && !isShowCheckBox)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_OPEN_OBJ, !isBaselineView && !isShowCheckBox)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EXPORT_EXCEL, !isShowCheckBox)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_REPORT, !isShowCheckBox)
    this.refToolStrip.value?.setItemVisible(EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_CHILD_PART_NEW, !isShowCheckBox)
    this.refToolStrip.value?.setItemVisible(EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_CHILD_PART_FIND, !isShowCheckBox)
    this.refToolStrip.value?.setItemVisible(EnumPartToolStripItemKeys.TOOL_STRIP_CREATE_BASELINE, !isShowCheckBox)
    this.refToolStrip.value?.setItemVisible(EnumPartToolStripItemKeys.TOOL_STRIP_BOM_COMPARISON, !isShowCheckBox)
    this.refToolStrip.value?.setItemVisible(EnumPartToolStripItemKeys.TOOL_STRIP_BOM_DOC_COMPARISON, !isShowCheckBox)

    //只有在多选模式下才显示汇集到列表按钮
    this.refToolStrip.value?.setItemVisible(EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_SEARCH_PART, isShowCheckBox)
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_REMARK_NODE_ADD_TO_GRID,
      isShowCheckBox && this.refTreeView.value!.getCheckedNodes().length > 0
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_SEARCH_RELATED_OBJECTS,
      isShowCheckBox && this.refTreeView.value!.getCheckedNodes().length > 0
    )
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_PART_VERSION_VIEW,
      isShowCheckBox && this.refTreeView.value!.getCheckedNodes().length > 0
    )
    this.refToolStrip.value?.setItemVisible(EnumPartToolStripItemKeys.TOOL_BATCH_UPDATE_BOM, !isShowCheckBox)
    this.refToolStrip.value?.setItemVisible(
      EnumPartToolStripItemKeys.TOOL_BATCH_UPDATE_MATERIAL,
      !isShowCheckBox || (isShowCheckBox && this.refTreeView.value!.getCheckedNodes().length > 0)
    )
    this.refToolStrip.value?.setItemVisible(EnumPartToolStripItemKeys.TOOL_STRIP_BOM_COMPARISON_GROUP, !isShowCheckBox)
  }

  /**
   * 点击事件
   * @param event
   */
  async onToolStripClicked(event: ToolStripItemClickedEventArgs) {
    /** 获取操作的节点 */
    const operationConfig = ComponentClientSrv.getOperationTreeNodes<PartStructureTreeNode>(this.refTreeView.value, event)
    /** 当前右键菜单按钮操作的节点 */
    const selectedNodes = operationConfig.selectedNodes
    this.operateNode = selectedNodes[0]

    // 如果没有选中任何节点
    if (selectedNodes.length == 0) {
      return
    }

    const nodeKey = event.attachParams?.node?.key
    const objParams = await this.getOperationNodeObjParams(nodeKey)

    /** 点击事件 */
    const partStructureTreeMenuItemClickedEvent = {
      ...event,
      objParams: objParams
    }
    this.emit('contextMenuItemClicked', partStructureTreeMenuItemClickedEvent)

    if (partStructureTreeMenuItemClickedEvent.itemCancelType == EnumItemClickedCancelType.CancelItemClick) {
      return
    }

    switch (event.name) {
      // 结构树查找
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_SEARCH_PART: {
        this.closeCheckStrictly()
        this.diaResult.value = KDialog.show({
          title: '结构树查找',
          size: { width: 500, height: 370 },
          props: {
            onClose: () => this.closeDialog(),
            onFindPrevious: (params: any, reFind: boolean) => this.findPrevious(params, reFind),
            onFindNext: (params: any, reFind: boolean) => this.findNext(params, reFind),
            onSelectAll: (params: any, reFind: boolean, onSuccess?: () => void) => this.selectAll(params, reFind, onSuccess),
            onCancelSelectAll: () => this.cancelSelectAll(),
            onRefershData: () => this.refershSearchData(),
            onFindTop: (params: any, reFind: boolean) => this.findTop(params, reFind),
            onFindBottom: (params: any, reFind: boolean) => this.findBottom(params, reFind)
          },
          showApply: false,
          maximizeBox: false,
          minimizeBox: false,
          showCancel: false,
          showConfirm: false,
          floatFooter: true,
          content: KPartSearchForm,
          onClosing: async (event: KDialogClosingEvent) => {
            // 清除查询节点
            this.props.clearQueryNode()
            return
          }
        })
        break
      }

      // 汇总到列表
      case EnumPartToolStripItemKeys.TOOL_STRIP_REMARK_NODE_ADD_TO_GRID: {
        const checkedNodes = this.refTreeView.value?.getCheckedNodes()
        if (checkedNodes && checkedNodes.length > 0) {
          const partIds = checkedNodes.map(node => node?.partObjParam.id)
          const result = await Api.post('part', 'Part', 'batchGetById', { data: [partIds] })
          if (result && result.code == EnumRequestCode.SUCCESS) {
            const data = result.data
            KDialog.show({
              title: '汇总到列表',
              size: { width: 1100, height: 700 },
              props: { parts: data },
              showApply: false,
              showCancel: false,
              maximizeBox: false,
              minimizeBox: false,
              getContainer: this.refContainer.value,
              content: KPartCollectForm,
              onClosing: async (event: KDialogClosingEvent) => {
                if (event.dialogResult == EnumDialogResult.Cancel) return
                if (event.dialogResult == EnumDialogResult.Close) return
              }
            })
          } else {
            KNotification.error({
              title: '汇总失败',
              content: '获取零部件异常：' + result.message,
              details: result.detail
            })
          }
        } else {
          KNotification.warn({
            message: '系统提示',
            description: '没有需要汇总的数据'
          })
        }
        break
      }

      // 查找关联对象
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_SEARCH_RELATED_OBJECTS: {
        const checkedNodes = this.refTreeView.value?.getCheckedNodes()
        if (checkedNodes && checkedNodes.length > 0) {
          const partRef = checkedNodes.map(node => {
            return {
              id: node?.partObjParam.id,
              clazz: node?.partObjParam.modelCode
            }
          })
          const result = await Api.post('part', 'Part', 'listDocumentByParts', { data: [partRef] })
          if (result && result.code == EnumRequestCode.SUCCESS) {
            const data = result.data
            if (data.length == 0) {
              KNotification.warn({
                message: '系统提示',
                description: '查找数据暂无关联对象'
              })
              return
            }
            KDialog.show({
              title: '查找关联对象',
              size: { width: 1100, height: 700 },
              props: { partAssociation: data },
              showApply: false,
              showCancel: false,
              maximizeBox: false,
              minimizeBox: false,
              getContainer: this.refContainer.value,
              content: KPartAssociationForm,
              onClosing: async (event: KDialogClosingEvent) => {
                if (event.dialogResult == EnumDialogResult.Cancel) return
                if (event.dialogResult == EnumDialogResult.Close) return
              }
            })
          } else {
            KNotification.error({
              title: '汇总失败',
              content: '获取零部件相关文档异常：' + result.message,
              details: result.detail
            })
          }
        } else {
          KNotification.warn({
            message: '系统提示',
            description: '没有需要查找的数据'
          })
        }
        break
      }

      // 创建视图版本
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_PART_VERSION_VIEW: {
        const checkedNodes = this.refTreeView.value?.getCheckedNodes()
        if (checkedNodes && checkedNodes.length > 0) {
          const versionIds = checkedNodes.map(node => {
            return node?.partObjParam.id
          })
          const partResult = (await Api.post('part', 'Part', 'get', { data: [versionIds[0]] })) as any
          if (partResult && partResult.code == EnumRequestCode.SUCCESS) {
            const part = partResult.data
            const viewId = part.partView.id
            const listResult = await Api.post('part', 'PartView', 'listChild', { data: [viewId] })
            if (listResult && listResult.code == EnumRequestCode.SUCCESS) {
              const listData = listResult.data
              if (listData.length == 0) {
                KNotification.warn({
                  message: '该视图版本无子类，不可再创建视图版本'
                })
                return
              }
              const viewList = listData.map((elem: any) => {
                return {
                  value: { id: elem.id, clazz: elem.rdmExtensionType },
                  label: elem.description
                }
              })
              KDialog.show({
                title: '创建视图版本',
                size: { width: 400, height: 200 },
                props: { viewProps: viewList },
                showApply: false,
                maximizeBox: false,
                minimizeBox: false,
                content: KPartCreateViewVersionForm,
                onClosing: async (event: KDialogClosingEvent) => {
                  if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
                    return
                  }
                  const formViewModel = event.viewModel as KPartCreateViewVersionFormViewModel
                  const formData = formViewModel.getValue() as any
                  const check = (await Api.post('part', 'Part', 'checkBeforeBatchCreatePartVersionView', {
                    data: [
                      {
                        versionIds: versionIds,
                        partView: formData
                      }
                    ]
                  })) as any
                  if (!(check && check.code == EnumRequestCode.SUCCESS)) {
                    KNotification.error({
                      title: '系统错误',
                      content: check.message
                    })
                    return
                  }
                  KModal.delete({
                    title: '系统提示',
                    content: `校验通过数量：${check.data}，是否开始创建`,
                    onOk: async () => {
                      // 创建
                      loadingHandle.show()
                      const result = (await Api.post('part', 'Part', 'batchCreatePartVersionView', {
                        data: [
                          {
                            versionIds: versionIds,
                            partView: formData
                          }
                        ]
                      })) as any
                      loadingHandle.remove()
                      if (result && result.code == EnumRequestCode.SUCCESS) {
                        KNotification.success('创建成功！')
                      } else {
                        KNotification.error({
                          title: '创建失败',
                          content: result.message || '创建零部件视图版本失败',
                          details: result.detail
                        })
                        return
                      }
                    }
                  })
                }
              })
            } else {
              KNotification.error({
                title: '获取失败',
                content: listResult.message || '获取视图列表失败',
                details: listResult.detail
              })
              return
            }
          } else {
            KNotification.error({
              title: '获取失败',
              content: partResult.message || '获取零部件失败',
              details: partResult.detail
            })
            return
          }
        } else {
          KNotification.warn({
            message: '系统提示',
            description: '没有需要创建得视图版本'
          })
        }
        break
      }

      // 隐藏复选框
      // 显示复选框
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_HIDE_CHECKBOX:
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SHOW_CHECKBOX: {
        // 显示复选框
        //如果已经显示复选框
        if (this.isMultipleSelection.value) {
          //隐藏隐藏复选框
          this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_HIDE_CHECKBOX, false)
          this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SHOW_CHECKBOX, true)
          this.isMultipleSelection.value = false
          // 清除勾选的节点
          this.refTreeView.value?.clearChecked()
        } else {
          this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_HIDE_CHECKBOX, true)
          this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SHOW_CHECKBOX, false)
          this.isMultipleSelection.value = true
        }
        break
      }
      // 刷新
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH: {
        this.refreshStructureNode(selectedNodes[0])
        break
      }
      // 复制
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_COPY_PART: {
        // 父节点是否相同
        const parentIds = PartClientSrv.isSameParent(selectedNodes)
        if (parentIds.length > 1) {
          KDialog.info({
            content: '批量选中的节点不再同一层级，不能执行批量操作。'
          })
          return
        }
        //判断节点是否可复制
        if (!PartClientSrv.isCanCopy(selectedNodes)) {
          return
        }
        const partData = PartClientSrv.getPartObjBusinessParam(selectedNodes)
        const parentId = parentIds[0] as string
        const parentNode = selectedNodes[0].parent
        const clipBoardData = new ClipboardObjectData(partData, parentId, parentNode, EnumClipBoardDataType.PartStructNode)
        // 浅拷贝
        clipBoardData.clone = lodash.identity
        ClipBoard.copy(clipBoardData, false)
        PartClientSrv.clipBoardTips(selectedNodes)
        break
      }

      // 剪切
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_CUT_PART: {
        // 父节点是否相同
        const parentIds = PartClientSrv.isSameParent(selectedNodes)
        if (parentIds.length > 1) {
          KDialog.info({
            content: '批量选中的节点不再同一层级，不能执行批量操作。'
          })
          return
        }
        if (!PartClientSrv.isCanCut(selectedNodes)) {
          return
        }
        // 尝试检出父节点
        const parentObjParam = selectedNodes[0].parentPartObjParam
        const checkoutResult = await Api.post('part', 'Part', 'tryCheckout', { data: [parentObjParam?.id] })
        if (checkoutResult && checkoutResult.code == EnumRequestCode.SUCCESS && checkoutResult.data !== null) {
          const partData = PartClientSrv.getPartObjBusinessParam(selectedNodes)
          const data = checkoutResult.data
          const checkoutId = data.id
          const parentNode = selectedNodes[0].parent
          const clipBoardData = new ClipboardObjectData(
            partData,
            checkoutId,
            parentNode,
            EnumClipBoardDataType.PartStructNode,
            () => {
              this.refTreeView.value?.removeNode(selectedNodes.map(a => a.key))
            }
          )
          // 浅拷贝
          clipBoardData.clone = lodash.identity
          ClipBoard.cut(clipBoardData, false)
          PartClientSrv.clipBoardTips(selectedNodes, EnumClipBoarbType.Cut)
          await this.refreshStructureNode(selectedNodes[0].parent as PartStructureTreeNode)
        } else {
          KNotification.error({
            title: '检出失败',
            content: '父节点尝试检出失败，无法剪切：' + checkoutResult.message,
            details: checkoutResult.detail
          })
        }
        break
      }

      // 粘贴
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_PASTE_PART: {
        let data: ClipboardObjectData<any> | undefined
        //判断剪切板数据类型
        if (ClipBoard.getData<Array<ObjBusinessParam>>(EnumClipBoardDataType.ObjList)) {
          data = ClipBoard.getData<Array<ObjBusinessParam>>(EnumClipBoardDataType.ObjList)
        } else if (ClipBoard.getData<Array<PartStructureNodeParam>>(EnumClipBoardDataType.PartStructNode)) {
          data = ClipBoard.getData<Array<PartStructureNodeParam>>(EnumClipBoardDataType.PartStructNode)
        }
        if (!data || !data.dataObject) {
          KDialog.info({
            content: '无可粘贴数据'
          })
          return
        }

        //粘贴节点
        const pasteNode = this.refTreeView.value?.getNode(selectedNodes[0].key)! as PartStructureTreeNode
        // 展开方式
        const currentExtendMode = PartClientSrv.getPartNodeChildExpandMode(this.props.viewName)
        pasteNode.currentExtendMode = currentExtendMode
        //是否可粘贴
        if (!PartClientSrv.isCanPaste(pasteNode)) {
          KDialog.info({
            content: '当前节点不能粘贴'
          })
          return
        }

        if (data.dataObject && lodash.some(data.dataObject, elem => elem.objParam.id == pasteNode.partObjParam.id)) {
          KDialog.info({
            content: '当前节点不能粘贴，不能粘贴到自己'
          })
          return
        }

        /** 是否是剪切 */
        const isCut = data.isCut

        /** 源父节点(已检出) */
        const sourceParentId = data.parentId as string
        const sourceParentNode = data.parentNode as PartStructureTreeNode

        // 尝试检出粘贴节点
        const objParam = pasteNode.partObjParam
        const checkoutResult = await Api.post('part', 'Part', 'tryCheckout', { data: [objParam?.id] })
        if (checkoutResult && checkoutResult.code == EnumRequestCode.SUCCESS && checkoutResult.data !== null) {
          const checkoutData = checkoutResult.data
          const checkoutParam = { ...pasteNode.partObjParam, id: checkoutData.id, workingState: checkoutData.workingState }
          /** 结果 */
          const result = await PartStructureClientSrv.pasteWorkingCopyPart({
            sourceObj: (data.dataObject as Array<PartStructureNodeParam>).map(item => {
              return {
                modelCode: 'SYS_BOM_LINK',
                id: item.structureId,
                group: 'Part'
              }
            }),
            targetObj: checkoutParam,
            sourceParentId: sourceParentId,
            isCut: isCut
          })
          // 刷新粘贴节点
          await this.refreshStructureNode(pasteNode as PartStructureTreeNode)
          // 刷新源父节点
          await this.refreshStructureNode(sourceParentNode as PartStructureTreeNode)
          data?.complete()
        } else {
          KNotification.error({
            title: '检出失败',
            content: '父节点尝试检出失败，无法粘贴：' + checkoutResult.message,
            details: checkoutResult.detail
          })
        }
        break
      }
      //批量更新物料属性
      case EnumPartToolStripItemKeys.TOOL_BATCH_UPDATE_MATERIAL: {
        let operateNodes
        if (this.isMultipleSelection.value) {
          operateNodes = this.refTreeView.value?.getCheckedNodes()
        } else {
          operateNodes = selectedNodes
        }
        PartClientSrv.batchUpdateMateriaProp(operateNodes!).then(res => {
          this.emit('nodeRefresh')
        })
        break
      }

      //BOM属性批量维护
      case EnumPartToolStripItemKeys.TOOL_BATCH_UPDATE_BOM:
        PartClientSrv.batchUpdateBom(this.operateNode.partObjParam).then(res => {
          this.emit('nodeRefresh')
        })
        break
      // BOM比较
      case EnumPartToolStripItemKeys.TOOL_STRIP_BOM_COMPARISON: {
        CommonClientSrv.openPage(
          'BOM比较',
          KPartBomCompare,
          {
            leftPart: {
              id: selectedNodes[0].partObjParam.id,
              className: selectedNodes[0].partObjParam.modelCode,
              extensionType: selectedNodes[0].partObjParam.modelCode,
              view: selectedNodes[0].view
            },
            applyToRoot: true
          },
          utils.uuid()
        )
        break
      }
      // 文档BOM比较
      case EnumPartToolStripItemKeys.TOOL_STRIP_BOM_DOC_COMPARISON: {
        CommonClientSrv.openPage(
          '文档BOM比较',
          KPartDocBomCompare,
          {
            leftPart: {
              id: selectedNodes[0].partObjParam.id,
              className: selectedNodes[0].partObjParam.modelCode,
              extensionType: selectedNodes[0].partObjParam.modelCode,
              view: selectedNodes[0].view
            },
            rightPart: {
              id: selectedNodes[0].partObjParam.id,
              className: selectedNodes[0].partObjParam.modelCode,
              extensionType: selectedNodes[0].partObjParam.modelCode
            },
            applyToRoot: true
          },
          utils.uuid()
        )
        break
      }
      // 打开
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_OPEN_OBJ: {
        ObjectClientSrv.openObj({ ...selectedNodes[0].partObjParam, modelGroup: this.props.objParam!.modelGroup })
        break
      }
      // 发布
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_PUBLISH: {
        this.doRelease(selectedNodes[0])
        break
      }
      // 弃用
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_FROZEN: {
        this.doDisuse(selectedNodes[0])
        break
      }
      // 调整位置顺序
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_CHANGE_PART_POSITION: {
        /** 节点 */
        const node = selectedNodes[0]

        if (!node.leaf && !node.children) {
          await node.expand()
        }

        /** 子 */
        const children = (node.children || []) as Array<PartStructureTreeNode>
        /** 子零件 */
        const childParts = children.map(a => {
          return { id: a.id, name: a.name, orderId: a.orderId } as SortChildPartItem
        })
        /** 排序结果 */
        const result = await PartStructureClientSrv.sortChildParts({
          objParam: node.partObjParam,
          childParts: childParts
        })

        if (result) {
          await this.refreshNode(selectedNodes[0], EnumTreeViewNodeRefreshType.NODE_PARENT)
        }
        break
      }
      // 查找
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_FIND: {
        this.find(selectedNodes[0])
        break
      }

      // 移除零部件
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE_PART: {
        // 尝试检出父节点
        const parentObjParam = selectedNodes[0].parentPartObjParam
        const checkoutResult = await Api.post('part', 'Part', 'tryCheckout', { data: [parentObjParam?.id] })
        if (checkoutResult && checkoutResult.code == EnumRequestCode.SUCCESS && checkoutResult.data !== null) {
          const result = await PartStructureClientSrv.removeWorkingCopyChild(selectedNodes[0].bomLinkParam, checkoutResult.data)
          this.refreshStructureNode(selectedNodes[0].parent as PartStructureTreeNode)
        } else {
          KNotification.error({
            title: '检出失败',
            content: '父节点尝试检出失败，无法移除：' + checkoutResult.message,
            details: checkoutResult.detail
          })
        }
        break
      }

      // 删除零部件
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_PART: {
        // 尝试检出父节点
        const parentObjParam = selectedNodes[0].parentPartObjParam
        const checkoutResult = await Api.post('part', 'Part', 'tryCheckout', { data: [parentObjParam?.id] })
        if (checkoutResult && checkoutResult.code == EnumRequestCode.SUCCESS && checkoutResult.data !== null) {
          const result = await PartStructureClientSrv.deleteWorkingCopyChild(selectedNodes[0].bomLinkParam, checkoutResult.data)
          this.refreshStructureNode(selectedNodes[0].parent as PartStructureTreeNode)
        } else {
          KNotification.error({
            title: '检出失败',
            content: '父节点尝试检出失败，无法删除：' + checkoutResult.message,
            details: checkoutResult.detail
          })
        }
        break
      }

      // 查找-添加子零件
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_CHILD_PART_FIND: {
        // 尝试检出
        const objParam = selectedNodes[0].partObjParam
        const node = selectedNodes[0]
        const checkoutResult = await Api.post('part', 'Part', 'tryCheckout', { data: [objParam?.id] })
        if (checkoutResult && checkoutResult.code == EnumRequestCode.SUCCESS && checkoutResult.data !== null) {
          const data = checkoutResult.data
          const checkoutParam = {
            ...node.partObjParam,
            id: data.id,
            workingState: data.workingState
          }
          const result = await PartStructureClientSrv.selectAddChildPart(
            {
              /** 零部件对象参数 */
              objParam: checkoutParam,
              partView: data.partView.name
            },
            this.refContainer.value,
            async () => {
              await this.refreshStructureNode(node)
            }
          )
        } else {
          KNotification.error({
            title: '检出失败',
            content: '父节点尝试检出失败，无法添加：' + checkoutResult.message,
            details: checkoutResult.detail
          })
        }
        break
      }
      // 查找-添加兄零件
      // 查找-添加弟零件
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_OLD_BRO_PART_FIND:
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_YOUNG_BRO_PART_FIND: {
        /** 当前节点 */
        const node = selectedNodes[0]
        /** 当前位置 */
        const currentIndex = node.parent!.children!.findIndex(a => a.key == node.key)
        let targetIndex = 0

        // 如果是添加兄
        if (event.name == EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_OLD_BRO_PART_FIND) {
          targetIndex = currentIndex - 1
        } else {
          targetIndex = currentIndex + 1
        }

        // 如果是第一个
        if (currentIndex < 0) {
          targetIndex = 0
        }

        // 如果是最后一个
        if (currentIndex >= node.parent!.children!.length - 1) {
          targetIndex = node.parent!.children!.length
        }

        const targetNode = node.parent!.children![targetIndex] as PartStructureTreeNode

        const result = await PartStructureClientSrv.selectAddChildPart(
          {
            objParam: node.partObjParam,
            parentObjParam: node.parentPartObjParam,
            orderId: node.orderId
          },
          this.refContainer.value,
          async () => {
            await this.refreshNode(selectedNodes[0], EnumTreeViewNodeRefreshType.NODE_PARENT)
          }
        )
        break
      }
      // 创建-添加子零件
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_CHILD_PART_NEW: {
        // 尝试检出
        const objParam = selectedNodes[0].partObjParam
        const checkoutResult = await Api.post('part', 'Part', 'tryCheckout', { data: [objParam?.id] })
        if (checkoutResult && checkoutResult.code == EnumRequestCode.SUCCESS && checkoutResult.data !== null) {
          const data = checkoutResult.data
          const checkoutParam = { ...selectedNodes[0].partObjParam, id: data.id, workingState: data.workingState }
          const result = await PartStructureClientSrv.createAddChildPart(
            {
              /** 零部件对象参数 */
              objParam: checkoutParam,
              /** 父零部件对象参数 */
              parentObjParam: checkoutParam,
              partView: data.partView.name
            },
            async () => {
              await this.refreshStructureNode(selectedNodes[0])
            }
          )
        } else {
          KNotification.error({
            title: '检出失败',
            content: '父节点尝试检出失败，无法创建：' + checkoutResult.message,
            details: checkoutResult.detail
          })
        }
        break
      }
      // 创建-添加兄零件
      // 创建-添加弟零件
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_OLD_BRO_PART_NEW:
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_INSERT_OLD_BRO_PART_NEW: {
        /** 当前节点 */
        const node = selectedNodes[0]

        /** 当前位置 */
        const currentIndex = node.parent!.children!.findIndex(a => a.key == node.key)

        // 尝试检出父节点
        const parentObjParam = selectedNodes[0].parentPartObjParam
        const checkoutResult = await Api.post('part', 'Part', 'tryCheckout', { data: [parentObjParam?.id] })
        if (checkoutResult && checkoutResult.code == EnumRequestCode.SUCCESS && checkoutResult.data !== null) {
          const data = checkoutResult.data
          const checkoutParam = { ...selectedNodes[0].parentPartObjParam, id: data.id, workingState: data.workingState }
          if (event.name == EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_OLD_BRO_PART_NEW) {
            PartStructureClientSrv.createAddChildPart(
              {
                /** 零部件对象参数 */
                objParam: selectedNodes[0].partObjParam,
                /** 父零部件对象参数 */
                parentObjParam: checkoutParam,
                orderId: selectedNodes[0].orderId,
                partView: data.partView.name
              },
              async () => {
                await this.refreshStructureNode(selectedNodes[0].parent as PartStructureTreeNode)
              }
            )
          } else {
            const result = await PartStructureClientSrv.selectAddChildPart(
              {
                objParam: checkoutParam,
                // parentObjParam: node.parentPartObjParam,
                orderId: selectedNodes[0].orderId,
                partView: data.partView.name
              },
              this.refContainer.value,
              async () => {
                await this.refreshStructureNode(selectedNodes[0].parent as PartStructureTreeNode)
              }
            )
          }
        } else {
          KNotification.error({
            title: '检出失败',
            content: '父节点尝试检出失败，无法添加：' + checkoutResult.message,
            details: checkoutResult.detail
          })
        }
        break
      }
      //新建并更换零部件
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_REPLACE_PART_NEW: {
        // 尝试检出父节点
        const parentObjParam = selectedNodes[0].parentPartObjParam
        const checkoutResult = await Api.post('part', 'Part', 'tryCheckout', { data: [parentObjParam?.id] })
        if (checkoutResult && checkoutResult.code == EnumRequestCode.SUCCESS && checkoutResult.data !== null) {
          const data = checkoutResult.data
          const checkoutParam = { ...selectedNodes[0].parentPartObjParam, id: data.id, workingState: data.workingState }
          PartStructureClientSrv.replaceCreateWorkingCopyChild(
            {
              /** 零部件对象参数 */
              objParam: selectedNodes[0].partObjParam,
              /** 父零部件对象参数 */
              parentObjParam: checkoutParam,
              bomLinkParam: selectedNodes[0].bomLinkParam,
              partView: data.partView.name
            },
            async () => {
              const selectNode = selectedNodes[0].parent as PartStructureTreeNode
              await this.refreshStructureNode(selectNode)
              this.refTreeView.value?.setSelectedNode(selectNode?.key)
            }
          )
        } else {
          KNotification.error({
            title: '检出失败',
            content: '父节点尝试检出失败，无法替换：' + checkoutResult.message,
            details: checkoutResult.detail
          })
        }
        break
      }

      //查找并更换零部件
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_REPLACE_PART_FIND: {
        // 尝试检出父节点
        const parentObjParam = selectedNodes[0].parentPartObjParam
        const checkoutResult = await Api.post('part', 'Part', 'tryCheckout', { data: [parentObjParam?.id] })
        if (checkoutResult && checkoutResult.code == EnumRequestCode.SUCCESS && checkoutResult.data !== null) {
          const data = checkoutResult.data
          const checkoutParam = { ...selectedNodes[0].parentPartObjParam, id: data.id, workingState: data.workingState }
          PartStructureClientSrv.replaceWorkingCopyChild(
            {
              /** 零部件对象参数 */
              objParam: selectedNodes[0].partObjParam,
              /** 父零部件对象参数 */
              parentObjParam: checkoutParam,
              bomLinkParam: selectedNodes[0].bomLinkParam,
              partView: data.partView.name
            },
            async () => {
              //await this.refreshNode(selectedNodes[0].parent as PartStructureTreeNode, EnumTreeViewNodeRefreshType.SELF)
              const selectNode = selectedNodes[0].parent as PartStructureTreeNode
              await this.refreshStructureNode(selectNode)
              this.refTreeView.value?.setSelectedNode(selectNode?.key)
            }
          )
        } else {
          KNotification.error({
            title: '检出失败',
            content: '父节点尝试检出失败，无法替换：' + checkoutResult.message,
            details: checkoutResult.detail
          })
        }
        break
      }

      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_CHILD_PART_SELECT_TEMPLATE:
        KNotification.error({ title: '系统提示', content: '功能开发中' })
        break
      case EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_OLD_BRO_PART_SELECT_TEMPLATE:
        KNotification.error({ title: '系统提示', content: '功能开发中' })
        break
      /** 创建基线 **/
      case EnumPartToolStripItemKeys.TOOL_STRIP_CREATE_BASELINE: {
        const collectedParts = await this.collectChildrensForBaselineCreate(selectedNodes[0])
        const isincludeWorkingcopy = await this.checkIncludeWorkingCopy(collectedParts)
        if (!isincludeWorkingcopy) {
          this.createBaseline(objParams, collectedParts)
        }
        break
      }
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REPORT:
        this.doReport(selectedNodes[0])
        break
      /** 创建转阶段活动 */
      case EnumPartToolStripItemKeys.TOOL_STRIP_CREATE_PROMOTE:
        this.createPromote(selectedNodes[0])
        break
      /** 导出 */
      case EnumPartToolStripItemKeys.TOOL_STRIP_EXCEL_EXPORT:
        this.exportExcel(selectedNodes[0])
        break
      default:
        break
    }
  }

  async collectChildrensForBaselineCreate(node: PartStructureTreeNode) {
    let queryRule
    if (this.props.partStructureViewMode!.baselineObj) {
      queryRule = {
        type: 'BASELINE',
        baseline: {
          id: this.rootNode.baselineId,
          clazz: 'ManagedBaseline'
        }
      }
    } else if (this.props.partStructureViewMode!.viewName == '最新版本') {
      queryRule = { type: 'LATEST_WITH_WORKING_COPY' }
    } else if (this.props.partStructureViewMode!.viewName == '最新发布版本') {
      queryRule = { type: 'LATEST_RELEASED' }
    } else if (this.props.partStructureViewMode!.viewName == '时间有效性') {
      queryRule = {
        type: 'DATED_EFFECTIVITY',
        datedValue: this.rootNode.datedValue
      }
    } else {
      queryRule = { type: 'LATEST' }
    }
    const param = {
      data: [
        {
          root: {
            id: node.partObjParam.id,
            clazz: node.partObjParam.modelCode
          },
          expandType: 'FORWARD_EXPANSION',
          queryRule: queryRule,
          view: node.view,
          maxLevel: 99
        }
      ]
    } as any

    const result = await Api.post('part', 'Part', 'listPart', param)
    if (!(result && result.code == EnumRequestCode.SUCCESS)) {
      KNotification.error({
        title: '创建基线失败',
        content: '收集当前节点及子节点信息失败：' + result.message,
        details: result.detail
      })
      return []
    } else {
      return result.data
    }
  }

  async checkIncludeWorkingCopy(treeNodeList: Array<any>) {
    if (treeNodeList.length > 0) {
      const node = treeNodeList.filter((data: any) => data.workingState == 'INWORK')
      if (node && node.length > 0) {
        KNotification.warn({
          message: '系统提示',
          description: `当前节点及其子节点中存在未检入的零部件【` + node[0].name + '】'
        })
        return true
      }
    }
    return false
  }

  async exportExcel(node: PartStructureTreeNode) {
    KDialog.show({
      title: '选择导出数据',
      size: { width: 350, height: 540 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      showConfirm: true,
      getContainer: this.refContainer.value,
      content: KPartExcelExport,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formViewModel = event.viewModel as KPartExcelExportViewModel
        const data = formViewModel.getValue()
        const rootNode = this.refTreeView.value?.getRootNodes()[0] as PartStructureTreeNode
        let queryRule = { type: 'LATEST_WITH_WORKING_COPY' } as any
        switch (rootNode?.currentExtendMode) {
          case 0:
            queryRule = { type: 'LATEST_WITH_WORKING_COPY' }
            break
          case 1:
            break
          case 2:
            queryRule = { type: 'LATEST_RELEASED' }
            break
          case 3:
            queryRule = { type: 'LATEST_WITH_WORKING_COPY' }
            break
          case 5:
          case 6:
            queryRule = {
              type: 'BASELINE',
              baseline: {
                id: rootNode.baselineId,
                clazz: 'ManagedBaseline'
              }
            }
            break
          case 7:
            queryRule = {
              type: 'DATED_EFFECTIVITY',
              datedValue: this.props.datedValue
            }
            break
        }
        const param = {
          data: [{ root: { id: node.partObjParam.id, clazz: node.partObjParam.modelCode }, columns: data, queryRule }]
        }
        const res = await Api.post('part', 'Part', 'exportPartStructure', param)
        if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
          // 下载模板
          const params = {
            modelName: 'Document',
            attributeName: 'primary',
            fileIds: res.data
          }
          const result = await request.post('/kmsaasFileApi/download', params, {
            headers: {
              'Content-Type': 'application/json'
            },
            responseType: 'blob'
          })
          if (result?.status == EnumRequestCode.SUCCESS && result.data) {
            const bolb = new Blob([result.data as any], { type: 'application/octet-stream,charset=UTF-8' })
            const url = URL.createObjectURL(bolb)
            const link = document.createElement('a')
            link.href = url
            link.download = 'export.xlsx'
            link.click()
          } else {
            KNotification.error({
              title: '下载失败',
              content: '导出文件下载失败'
            })
          }
        } else {
          KNotification.error({
            title: '导出失败',
            content: res.message || '导出失败',
            details: res.detail
          })
        }
      }
    })
  }

  async createPromote(node: PartStructureTreeNode) {
    const part = await Api.post('part', 'Part', 'get', { data: [node.partObjParam.id] })
    if (
      part &&
      part.code == EnumRequestCode.SUCCESS &&
      part.data.phase &&
      (part.data.phase == 'Design' || part.data.phase == 'Manufacturing')
    ) {
      KNotification.warn({
        message: '系统提示',
        description: part.data.phase == 'Design' ? '设计阶段不能进行转阶段活动' : '当前阶段已到达量产阶段'
      })
      return
    }

    KDialog.show({
      title: '选择当前阶段',
      size: { width: 400, height: 250 },
      props: {
        phase: part?.data?.phase
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      showConfirm: true,
      getContainer: this.refContainer.value,
      content: KPartCreatePromote,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formViewModel = event.viewModel as KPartCreatePromoteViewModel
        const { currentPhase, targetingPhase } = formViewModel.getValue()
        const param = { data: [{ id: node.partObjParam.id, clazz: node.partObjParam.modelCode }, currentPhase] }
        const result = await Api.post('part', 'Part', 'collectPart', param)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          if (result.data.length == 0) {
            KNotification.warn({
              message: '系统提示',
              description: `未收集到发布且阶段为${EnumPhaseState[currentPhase]}的数据`
            })
          }
          this.openCreatePromotePanel(result.data, currentPhase, targetingPhase)
        }
      }
    })
  }

  openCreatePromotePanel(partParam: any, currentPhase: string, targetingPhase: string) {
    KDialog.show({
      title: '创建转阶段活动',
      size: { width: 1000, height: 800 },
      props: {
        inBomCreate: true,
        formValue: {
          folder: {
            id: this.props.objParam!.folderId
          }
        },
        changeBeforeData: partParam,
        extAttrs: [
          {
            name: 'CurrentPhase',
            value: currentPhase
          },
          {
            name: 'TargetingPhase',
            value: targetingPhase
          }
        ]
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      getContainer: this.refContainer.value,
      content: KChangePromoteActivity,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formViewModel = event.viewModel as KChangePromoteActivityViewModel
        const validateResult = await formViewModel.validate()

        if (!validateResult) {
          event.cancel = true
          return
        }

        const result = await formViewModel.save()
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '新建成功'
          })
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '新建失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /** 创建基线 **/
  createBaseline(objParams: any[], collectedParts: any) {
    console.log('partStructureTreeMenuItemClickedEvent', objParams)
    KDialog.show({
      title: '创建基线选项',
      size: { width: 400, height: 250 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      showConfirm: true,
      getContainer: this.refContainer.value,
      content: KPartCreateBaseline,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formViewModel = event.viewModel as KPartCreateBaselineViewModel
        const partParam = {
          partReference: {
            id: objParams[0].id,
            clazz: objParams[0].modelCode
          },
          ...formViewModel.getValue()
        }
        this.openCreateBaselinePanel(partParam, collectedParts)
      }
    })
  }

  openCreateBaselinePanel(partParam: any, collectedParts: any) {
    KDialog.show({
      title: '创建基线',
      size: { width: 900, height: 800 },
      props: {
        showAddButton: false,
        partParam,
        collectedParts
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      showConfirm: true,
      getContainer: this.refContainer.value,
      content: KBaselineCreate,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formViewModel = event.viewModel as KBaselineCreateViewModel

        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }

        const result = await formViewModel.save()
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success('基线创建成功')
          this.emit('toolStripClicked', EnumPartToolStripItemKeys.TOOL_STRIP_CREATE_BASELINE)
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '创建对象失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 弃用
   * @param node 节点
   */
  doDisuse(node: PartStructureTreeNode) {
    const partObjParam = node.partObjParam
    // ObjectClientSrv.disuse(partObjParam)
    KNotification.success('弃用成功')
  }

  /**发布 */
  doRelease(node: PartStructureTreeNode) {
    const partObjParam = node.partObjParam
    // ObjectClientSrv.release(partObjParam, () => {
    //   KNotification.success('发布成功')
    // })
  }

  doReport(node: PartStructureTreeNode) {
    CommonClientSrv.openPage('报表', KPartReport, { objParam: node.partObjParam }, node.id)
  }

  /**
   * 节点展开之后
   */
  onAfterExpand(event: any) {
    const checkKeys = this.refTreeView.value?.getCheckedKeys()
    if (this.notCheckStrictly.value && event.node.checked && event.node.children && event.node.children.length > 0) {
      const childKeys = event.node.children.map((item: any) => item.key)
      this.refTreeView.value?.setCheckedKeys(Array.from(new Set([...checkKeys!, ...childKeys])))
    }
  }

  /**
   * 菜单弹出之前
   * @param event
   */
  async onBeforeMenuPopup(event: TreeViewMenuEventArgs<PartStructureTreeNode>) {
    /** 节点 */
    const node = event.attachParams.node
    /** 获取操作的节点 */
    const operationConfig = ComponentClientSrv.getOperationTreeNodes<PartStructureTreeNode>(this.refTreeView.value, event)

    // 更新工具栏状态
    if (node.disabled) {
      this.toolStripItems.value = [
        {
          name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE_PART,
          title: '移除零部件',
          icon: 'logout',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        }
      ]
    } else {
      this.toolStripItems.value = getToolStrip()
      await this.updateToolStripState(node, operationConfig)
    }

    // 抛出事件
    this.emit('beforeContextMenuPopup', { ...event, node: node })
  }

  /**
   * 节点选择后事件
   * @param event
   */
  async onAfterSelect(event: TreeViewSelectEventArgs<PartStructureTreeNode>) {
    if (!event.node) {
      return
    }
    // 更新工具栏状态
    await this.updateToolStripState(event.node, {
      isMultiSelect: false,
      selectedNodes: [event.node]
    })
    // 发出事件
    this.emit('afterSelect', event)
  }

  /**
   * 不可被选中的节点
   */
  unselectablePredicate(data: IKTreeNode | null): boolean {
    return data?.disabled ? true : false
  }

  /**
   * 容器点击事件
   * @param e
   */
  onContainerClick(e: MouseEvent) {
    // 按下指定按键才能打开
    if (e.shiftKey && e.ctrlKey) {
      this.refToolStrip.value?.openToolStripManage()
    }
  }
  onReady() {
    this.refTreeView.value?.setSelectedNode(TREE_VIEW_ROOT_NODE_KEY)
  }

  /**
   * 加载根数据
   * @returns
   */
  async onLoadData() {
    const partResult = await Api.post('part', 'Part', 'get', { data: [this.rootNodeId.value] })
    const part = partResult.data
    // const viewName = part.partView?.name
    const viewName = this.props.partViewName

    let result = null
    if (this.props.partStructureViewMode!.baselineObj) {
      result = (await Api.post('part', 'Part', 'getBaselinePartLiteTreeNode', {
        data: [this.rootNodeId.value, this.props.partStructureViewMode!.baselineObj.id]
      })) as any
    } else if (this.props.partStructureViewMode!.viewName == '最新版本') {
      result = (await Api.post('part', 'Part', 'getPartLiteTreeNodeByMaster', {
        data: [part.master.id, { type: 'LATEST_WITH_WORKING_COPY' }, viewName]
      })) as any
    } else if (this.props.partStructureViewMode!.viewName == '最新发布版本') {
      result = (await Api.post('part', 'Part', 'getPartLiteTreeNodeByMaster', {
        data: [part.master.id, { type: 'LATEST_RELEASED' }, viewName]
      })) as any
    } else if (this.props.partStructureViewMode!.viewName == '时间有效性') {
      result = (await Api.post('part', 'Part', 'getPartLiteTreeNodeByMaster', {
        data: [part.master.id, { type: 'DATED_EFFECTIVITY', datedValue: this.props.partStructureViewMode?.datedValue }, viewName]
      })) as any
    } else {
      result = (await Api.post('part', 'Part', 'getPartLiteTreeNode', { data: [this.rootNodeId.value] })) as any
    }

    if (!(result && result.code == EnumRequestCode.SUCCESS)) {
      return []
    }

    if (!result.data) {
      return []
    }

    const nodeData = result.data || part
    this.rootNode = new PartStructureTreeNode()
    this.rootNode.key = TREE_VIEW_ROOT_NODE_KEY
    this.rootNode.id = TREE_VIEW_ROOT_NODE_KEY
    this.rootNode.iconType = this.rootNode.getIconType(nodeData.userObject.workingState)
    this.rootNode.class = this.rootNode.getRootIconClass(nodeData.userObject.workingState)
    this.rootNode.name = nodeData.name
    this.rootNode.view = viewName
    this.rootNode.partObjParam = {
      modelCode: nodeData.userObject.part.clazz,
      id: nodeData.userObject.part.id,
      masterId: nodeData.userObject.partMaster.id,
      modelGroup: 'part',
      kiaguid: part.kiaguid,
      partView: viewName,
      lifecycleStateCode: nodeData.userObject.lifecycleState
    }
    this.rootNode.parentPartObjParam = this.rootNode.partObjParam // 根节点的父就是自己
    this.rootNode.leaf = !nodeData.hasChildren // API
    // this.rootNode.queryRule = this.queryRule
    this.rootNode.currentExtendMode = this.props.partStructureViewMode!.expandMode
    this.rootNode.isBaseLine = false // API
    if (this.props.partStructureViewMode!.baselineObj) {
      this.rootNode.baselineId = this.props.partStructureViewMode!.baselineObj.id
    }

    if (this.props.partStructureViewMode?.viewName == '时间有效性') {
      this.rootNode.isDated = true
      this.rootNode.datedValue = this.props.partStructureViewMode!.datedValue || ''
    }
    return [this.rootNode]
  }

  // 找上一处
  findPrevious(params: any, reFind: boolean) {
    this.props.findPrevious(params, reFind)
  }

  // 最上
  findTop(params: any, reFind: boolean) {
    this.props.findTop(params, reFind)
  }

  // 找下一处
  findNext(params: any, reFind: boolean) {
    this.props.findNext(params, reFind)
  }

  // 最下
  findBottom(params: any, reFind: boolean) {
    this.props.findBottom(params, reFind)
  }

  // 选中全部
  selectAll(params: any, reFind: boolean, onSuccess?: () => void) {
    this.props.selectAll(params, reFind, onSuccess)
  }

  // 取消选中全部
  cancelSelectAll() {
    this.props.cancelSelectAll()
  }

  // 关闭搜索窗
  closeDialog() {
    // 清除查询节点
    this.props.clearQueryNode()
    this.diaResult.value?.close()
  }

  // 关闭级联
  closeCheckStrictly() {
    this.notCheckStrictly.value = false
  }

  // 清洗数据
  refershSearchData() {
    this.props.refershSearchData()
  }
}
