import { TreeViewSelectEventArgs, VNodeProps, defineView, exposeComponentEvents } from '@kmsoft/upf-core'
import { createVNode } from 'vue'
import { ClassMetaClientSrv, ObjectClientSrv } from '../../client-srv'
import { KObjectClassGridEventEmits, KObjectClassGridEventEmitsType, KObjectClassGridPropType } from '../object-class-grid'
import {
  KObjectClassTree,
  KObjectClassTreeEventEmitsTypes,
  KObjectClassTreePropType,
  TreeObjClassEnvironment
} from '../object-class-tree'
import KObjectClassManageViewModel from './KObjectClassManageViewModel'
import { KObjectClassManageEventEmits, KObjectClassManagePropOptions } from './interface'

export default defineView({
  name: 'KObjectClassManage',
  props: KObjectClassManagePropOptions,
  emits: KObjectClassManageEventEmits,
  viewModel: KObjectClassManageViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    /**
     * 树节点选择事件
     * @param event
     */
    const onTreeNodeSelected = async (event: TreeViewSelectEventArgs) => {
      // 停止网格编辑状态
      vm.refObjectClassGrid.value?.endEdit(true)
      vm.refObjectClassGrid.value?.setSearchParam(undefined)

      vm.modelCode.value = event.node.code
      vm.modelGroup.value = await ObjectClientSrv.getModelGroupByCode(event.node.code!)

      emit('objClsCodeChange', vm.modelCode.value)
    }

    /** 导出事件 */
    const exposedEvents = exposeComponentEvents(vm.refObjectClassGrid, Object.keys(KObjectClassGridEventEmits), emit)

    return () => {
      /** 渲染网格 */
      const renderGrid = () => {
        if (!vm.modelCode.value || vm.modelCode.value == 'ChangeItem') {
          return <k-empty description="请选择对象类" />
        }

        /** 对象类网格参数 */
        const objectClassGridProps: VNodeProps<KObjectClassGridPropType, KObjectClassGridEventEmitsType> = {
          ref: vm.refObjectClassGrid,
          ...props,
          ...exposedEvents,
          class: 'class-manage-grid',
          modelCode: vm.modelCode.value,
          modelGroup: vm.modelGroup.value,
          filter: vm.filter.value,
          pagination: { pageIndex: 1, pageSize: 50 },
          onRowDoubleClick: vm.onRowDoubleClick,
          editorIsPopup: true
        }
        return <k-object-class-grid {...objectClassGridProps} />
      }

      /** 渲染树 */
      const renderTree = () => {
        /** 树参数 */
        const treeProps: VNodeProps<KObjectClassTreePropType, KObjectClassTreeEventEmitsTypes> = {
          ref: vm.refObjectClassTree,
          class: 'class-manage-tree',
          defaultSelectClsCode: props.defaultSelectClsCode,
          classTemplates: props.classTemplates,
          showObjClsCodes: props.showObjClsCodes,
          hideObjClsCodes: props.hideObjClsCodes,
          modelCategory: props.modelCategory,
          environment: TreeObjClassEnvironment.ObjectClassTreeEnvironment,
          autoWrapRoot: props.autoWrapRoot,
          onAfterSelect: onTreeNodeSelected
        }

        return createVNode(KObjectClassTree, treeProps)
      }

      return (
        <div class={['k-object-class-manage', attrs.class]}>
          <k-split-container>
            <k-split-pane size={20} minSize={15} maxSize={50} v-show={props.isShowTree}>
              {renderTree()}
            </k-split-pane>
            <k-split-pane>
              <div class="class-manage-container">{renderGrid()}</div>
            </k-split-pane>
          </k-split-container>
        </div>
      )
    }
  }
})
