import { BaseViewModel, KDataGridViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KChangeRankingEmitsType, KChangeRankingPropType } from './interface'
import { computed, ref, watch } from 'vue'

/** KChangeRanking */
export default class KChangeRankingViewModel extends BaseViewModel<KChangeRankingEmitsType, KChangeRankingPropType> {
  constructor(options: ViewModelOptions<KChangeRankingPropType>) {
    super(options)
    watch(
      () => this.props.dataSource,
      newValue => {
        this.refDataGrid.value?.refresh()
      }
    )
  }
  refDataGrid = ref<KDataGridViewModel>()

  gridDataSource = computed(() => {
    return this.props.dataSource
  })

  loadData() {
    return this.props.dataSource
  }

  viewDidMount() {}
}
