import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_require_manage = _resolveComponent("k-require-manage")!

  return (_openBlock(), _createBlock(_component_k_require_manage, {
    loadData: _ctx.$vm.loadTreeData,
    objData: _ctx.objParam,
    onRefetchRootNode: _ctx.$vm.refetchRootNode
  }, null, 8, ["loadData", "objData", "onRefetchRootNode"]))
}