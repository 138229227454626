import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KProductNeedsCreateWithFolderSelectorEmitsType, KProductNeedsCreateWithFolderSelectorPropType } from './interface'
import { ref } from 'vue'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import { Api, EnumWorkingState } from '@kmsoft/ebf-common'
import lodash from 'lodash'

/** KProductNeedsCreate */
export default class KProductNeedsCreateWithFolderSelectorViewModel extends BaseViewModel<
  KProductNeedsCreateWithFolderSelectorEmitsType,
  KProductNeedsCreateWithFolderSelectorPropType
> {
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  refContainer = ref<HTMLElement>()
  formData = ref<any>({
    number: '',
    name: '',
    description: '',
    category: '',
    secondaryIds: null,
    folder: {
      id: ''
    }
  })

  constructor(options: ViewModelOptions<KProductNeedsCreateWithFolderSelectorPropType>) {
    super(options)
  }

  viewDidMount() {
    // 文件夹赋值
    if (this.props.formValue?.folder?.id) {
      this.formData.value.folder.id = this.props.formValue.folder?.id
    }
    // 文件夹赋值
    if (this.props.customerNeeds?.folder?.id) {
      this.formData.value.folder.id = this.props.customerNeeds.folder?.id
    }
  }

  loaded() {
    const element = this.refContainer.value!.querySelector('[data-name="segment#si"]') as HTMLElement
    if (element) {
      element.style.display = 'none'
    }
  }

  getValue() {
    const createObject = lodash.cloneDeep(this.refObjectCreatePanel.value?.getValue())
    return createObject
  }

  /** 校验 */
  validate() {
    return this.refObjectCreatePanel.value?.validateFormValue()
  }

  async save() {
    const createObject = this.getValue()

    // 如果是从原始需求创建而来，添加关联关系
    if (this.props.customerNeeds) {
      createObject!.customerNeeds = {
        id: this.props.customerNeeds.id,
        rdmExtensionType: this.props.customerNeeds.rdmExtensionType,
        clazz: this.props.customerNeeds.rdmExtensionType
      }
    }

    if (createObject?.secondary) {
      const secondary = createObject.secondary.map((element: string) => {
        return { id: element }
      })
      createObject.secondary = secondary
    }

    const params = {
      data: [
        {
          ...createObject
        }
      ]
    }
    return await Api.post('requirement', 'ProductNeeds', 'create', params)
  }
}
