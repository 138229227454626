import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KAllProductNeedsPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KAllProductNeedsPropType = ViewPropsTypeExtract<typeof KAllProductNeedsPropOptions>

/** 事件 */
export const KAllProductNeedsEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KAllProductNeedsEmitsType = ViewEmitsTypeExtract<typeof KAllProductNeedsEventEmits>
