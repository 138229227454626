import {
  BaseViewModel,
  EnumDialogResult,
  EnumItemClickedCancelType,
  EnumToolStripCompType,
  KDataGridRowSelectedEvent,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  PageManager,
  SelectOption,
  ToolStripItemClickedEventArgs,
  ViewModelOptions,
  MemoryCacheFactory
} from '@kmsoft/upf-core'
import { KValidationMetricsManageEmitsType, KValidationMetricsManagePropType } from './interface'
import { ref, watch } from 'vue'
import {
  Api,
  EnumQueryConditionOperator,
  EnumRequestCode,
  EnumToolStripItemKeys,
  EnumValidationMetrics,
  EnumVerification,
  KObjectToolStripViewModel,
  ObjBusinessParam,
  ObjectClientSrv,
  MetricsTypeCache,
  UserInfoCache,
  FolderTypeEnum
} from '@kmsoft/ebf-common'
import { KCreateForm, KCreateFormViewModel } from '../common-validation-metrics/create-form'
import { KPlanCreateForm, KPlanCreateFormViewModel } from '../verification-plan/plan-create-form'
import { KCommonFolderManage, KCommonFolderManageViewModel } from '../../../../ebf-folder/src/pages/common-folder-manage'
import { KEditFormViewModel } from '../validation-board/edit-form'
import { KEditForm } from '../validation-board/edit-form'
import { KValidationPlanTab, KValidationPlanTabViewModel } from '../validation-plan-tab'

/** KValidationMetricsManage */
export default class KValidationMetricsManageViewModel extends BaseViewModel<
  KValidationMetricsManageEmitsType,
  KValidationMetricsManagePropType
> {
  /** 对象 */
  objParam = ref<ObjBusinessParam>()
  mainContainer = ref<HTMLElement>()
  /** 验证指标网格 */
  refValidationMetricsGrid = ref<KDataGridViewModel>()
  /** 验证指标工具栏 */
  refMetricsToolStrip = ref<KObjectToolStripViewModel>()
  /** 验证计划网格 */
  // refVerificationPlanGrid = ref<KDataGridViewModel>()
  // /** 验证计划工具栏 */
  // refPlanToolStrip = ref<KObjectToolStripViewModel>()
  /** 指标类型候选值 */
  validationMetricsTypeOptions = ref<Array<SelectOption>>([])
  /** 验证类型候选值 */
  verificationPlanTypeOptions = ref<Array<SelectOption>>(EnumVerification._list)
  /** 人员列表 */
  userList = ref<Record<string, any>[]>([])
  oldRefMetricsParams = ref<Record<string, any>[]>()
  /**包含子项 */
  includeChild = ref<boolean>(false)

  constructor(options: ViewModelOptions<KValidationMetricsManagePropType>) {
    super(options)
    this.objParam.value = options.props.objParam
    this.oldRefMetricsParams.value = options.props.oldRefMetricsParams
    this.getList()
    this.getUserData()
    watch(
      () => this.props.objParam,
      newValue => {
        if (newValue && newValue.id) {
          this.objParam.value = newValue
          this.refValidationMetricsGrid.value?.clearSelectedRows()
          this.refValidationMetricsGrid.value?.refresh()
          // this.refVerificationPlanGrid.value?.refresh()
        }
      }
    )
    watch(
      () => this.props.oldRefMetricsParams,
      newValue => {
        if (newValue) {
          this.oldRefMetricsParams.value = newValue
        }
      }
    )
    watch(
      () => this.includeChild.value,
      newValue => {
        this.refValidationMetricsGrid.value?.clearSelectedRows()
        this.refValidationMetricsGrid.value?.refresh()
      }
    )
  }

  viewDidMount() {
    this.initMetricsToolStrip()
  }

  async getList() {
    const cacheInstance = MemoryCacheFactory.get<MetricsTypeCache>(MetricsTypeCache.cacheKey)
    this.validationMetricsTypeOptions.value = cacheInstance.geMetricsTypeList() as Array<SelectOption>
  }

  /** 加载指标网格 */
  async loadMetricsData() {
    const obj = this.objParam.value
    const params = {
      data: [
        {
          id: obj?.id,
          clazz: obj?.modelCode
        },
        this.includeChild.value
      ]
    }
    const result = await Api.post('requirement', 'ValidationMetrics', 'listByRequirementId', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取验证指标失败',
        details: result.detail
      })
      return
    }
  }

  /** 加载计划网格 */
  async loadPlanData() {
    const obj = this.objParam.value
    const params = {
      data: [{ id: obj?.id, clazz: obj?.modelCode }]
    }
    const result = await Api.post('requirement', 'VerificationPlan', 'listByReqId', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取验证计划失败',
        details: result.detail
      })
      return
    }
  }

  getUserData() {
    const cacheInstance = MemoryCacheFactory.get<UserInfoCache>(UserInfoCache.cacheKey)
    this.userList.value = cacheInstance.getUserOptionList()
  }

  /**
   * 初始化指标工具栏
   */
  initMetricsToolStrip() {
    this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_VERIFICATION_PLAN, false)
    this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_VERIFICATION_PLAN, false)
  }

  /** 指标操作按钮 */
  metricsToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW,
      title: '新建',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD,
      title: '添加',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT,
      title: '编辑',
      icon: 'edit',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE,
      title: '保存',
      icon: 'save',
      visible: false,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
      title: '移除',
      icon: 'delete',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'delete'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    },
    // {
    //   name: EnumToolStripItemKeys.TOOL_STIP_ITEM_INCLUDE_CHILD,
    //   title: '查看子项',
    //   icon: 'sync',
    //   visible: true,
    //   compType: EnumToolStripCompType.BUTTON
    // },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_VERIFICATION_PLAN,
      title: '创建验证计划',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_VERIFICATION_PLAN,
      title: '添加到验证计划',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    }
    // {
    //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_RESET,
    //   title: '重置',
    //   icon: 'sync',
    //   visible: true,
    //   compType: EnumToolStripCompType.BUTTON
    // }
  ]

  /** 计划操作按钮 */
  planToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW,
      title: '新建',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT,
      title: '编辑',
      icon: 'edit',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE,
      title: '保存',
      icon: 'save',
      visible: false,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
      title: '移除',
      icon: 'delete',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'delete'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  /**
   * 改变指标工具栏
   */
  async changeMetricsToolItem(event: KDataGridRowSelectedEvent<any>) {
    const selectMetricsRows = this.refValidationMetricsGrid.value?.getSelectedRows()
    // const selectPlanRows = this.refVerificationPlanGrid.value?.getSelectedRows()
    if (!selectMetricsRows || selectMetricsRows.length == 0 || this.includeChild.value) {
      this.initMetricsToolStrip()
      return
    }
    // } else {
    //   if (selectPlanRows && selectPlanRows.length == 1) {
    //     this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_VERIFICATION_PLAN, true)
    //   } else {
    //     this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_VERIFICATION_PLAN, false)
    //   }
    // }
    this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_VERIFICATION_PLAN, true)
    this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_VERIFICATION_PLAN, true)
  }

  /**
   * 改变计划工具栏
   */
  // async changePlanToolItem(event: KDataGridRowSelectedEvent<any>) {
  //   const selectPlanRows = this.refVerificationPlanGrid.value?.getSelectedRows()
  //   const selectMetricsRows = this.refValidationMetricsGrid.value?.getSelectedRows()
  //   if (selectPlanRows && selectMetricsRows && selectPlanRows.length == 1 && selectMetricsRows.length != 0) {
  //     this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_VERIFICATION_PLAN, true)
  //     return
  //   }
  //   this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_VERIFICATION_PLAN, false)
  // }

  /** 指标操作 */
  async onMetricsToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onMetricsToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW:
        this.createMetrics()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.addMetrics()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT:
        this.editMetrics()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE:
        this.saveMetrics()
        this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, true)
        this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.removeMetrics()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refValidationMetricsGrid.value?.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STIP_ITEM_INCLUDE_CHILD:
        this.includeChild.value = true
        this.refValidationMetricsGrid.value?.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_VERIFICATION_PLAN:
        this.createPlanWithMetrics()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_VERIFICATION_PLAN:
        this.addToPlan()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_RESET:
        this.includeChild.value = false
        this.refValidationMetricsGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /** 计划操作 */
  // async onPlanToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
  //   switch (event.name) {
  //     case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW:
  //       this.createPlanWithOutMetrics()
  //       event.itemCancelType = EnumItemClickedCancelType.CancelAll
  //       break
  //     case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT:
  //       this.editPlan()
  //       event.itemCancelType = EnumItemClickedCancelType.CancelAll
  //       break
  //     case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE:
  //       this.savePlan()
  //       this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, true)
  //       this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
  //       event.itemCancelType = EnumItemClickedCancelType.CancelAll
  //       break
  //     case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
  //       this.removePlan()
  //       event.itemCancelType = EnumItemClickedCancelType.CancelAll
  //       break
  //     case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
  //       this.refVerificationPlanGrid.value?.refresh()
  //       break
  //     default:
  //       break
  //   }
  // }

  /** 创建验证指标 */
  async createMetrics() {
    const obj = this.objParam.value
    const requirement = {
      id: obj?.id,
      clazz: obj?.modelCode
    }
    KDialog.show({
      title: '新建指标',
      size: { width: 900, height: 650 },
      props: { isGeneral: false },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KCreateForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KCreateFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }

        const result = await formViewModel.saveMetrics(requirement)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refValidationMetricsGrid.value?.refresh()
          KNotification.success({
            title: '系统提示',
            content: '新建成功'
          })
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '新建失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  selectDisabel(row: any): boolean {
    return !!this.oldRefMetricsParams.value?.some(param => param.validationMetrics.id === row.target.id)
  }

  /** 添加验证指标 */
  addMetrics() {
    const obj = this.objParam.value
    const source = {
      id: obj?.id,
      clazz: obj?.modelCode
    }
    KDialog.show({
      title: '选择对象',
      size: { width: 1300, height: 800 },
      props: {
        modelCode: 'ValidationMetrics',
        modelGroup: 'requirement',
        showToolStrip: false,
        showOperatorColumn: false,
        style: { padding: '0!important' },
        folderType: FolderTypeEnum.COMMON_VALIDATION_METRICS_LIBRARY
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      getContainer: this.props.container || this.mainContainer.value,
      content: KCommonFolderManage,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KCommonFolderManageViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const selectRow = formViewModel.getSelectedRows()
        if (!selectRow || selectRow.length <= 0) {
          KNotification.warn({
            message: '系统提示',
            description: '请至少选择一条数据'
          })
          event.cancel = true
          return
        }
        const targets = selectRow.map(obj => {
          return {
            id: obj.id,
            clazz: obj.targetExtensionType,
            className: obj.targetExtensionType
          }
        })
        const params = {
          data: [
            {
              source: source,
              targets: targets
            }
          ]
        }
        const result = await Api.post('requirement', 'ValidationMetrics', 'addValidationMetrics', params)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          // 刷新网格
          this.refValidationMetricsGrid.value?.refresh()
          KNotification.success({
            title: '系统提示',
            content: '对象添加成功'
          })
        } else {
          KNotification.error({
            title: '系统提示',
            content: result.message!
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /** 编辑指标网格 */
  editMetrics() {
    this.refValidationMetricsGrid.value?.refresh()
    // 修改工具栏状态
    this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
    this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
    // 网格开启编辑
    this.refValidationMetricsGrid.value?.beginEdit()
  }

  /** 保存指标网格 */
  async saveMetrics() {
    this.refValidationMetricsGrid.value?.endEdit(true)
    setTimeout(async () => {
      const changedRows = this.refValidationMetricsGrid.value?.getChangedRows()
      const modifiedRows = changedRows?.modifiedRows

      if (modifiedRows && modifiedRows.length > 0) {
        const newRows = modifiedRows.map(x => {
          return this.refValidationMetricsGrid.value?.getRow(x.id)
        })
        const updateDataList = newRows!.map(element => {
          return {
            id: element!.validationMetrics.id,
            type: element!.validationMetrics.type,
            title: element!.validationMetrics.title,
            description: element!.validationMetrics.description,
            executor: element!.validationMetrics.executor
          }
        })
        //更新数据
        const result = await Api.post('requirement', 'ValidationMetrics', 'batchUpdate', { data: [updateDataList] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success('修改成功！')
          this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, true)
          this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
          this.refValidationMetricsGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '修改失败',
            content: result.message
          })
          this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
          this.refMetricsToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
          this.refValidationMetricsGrid.value?.beginEdit()
          return
        }
      }
    }, 40)
  }

  /** 移除指标 */
  removeMetrics() {
    const rows = this.refValidationMetricsGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认移除吗？移除后不可恢复!',
      onOk: async () => {
        // 获取对象id
        const rowIds = rows!.map(row => row.id)
        const reqParam = {
          data: [rowIds]
        }

        Api.post('requirement', 'ValidationMetrics', 'batchRemove', reqParam).then(result => {
          if (result && result.code == EnumRequestCode.SUCCESS) {
            // 刷新网格
            this.refValidationMetricsGrid.value?.removeSelectedRows()
            //this.refValidationMetricsGrid.value?.refresh()
            KNotification.success({
              title: '移除成功',
              content: result.message
            })
            return Promise.resolve({
              rows: result.data,
              total: result.data.length
            })
          } else {
            KNotification.error({
              title: '移除失败',
              content: result.message,
              details: result.detail
            })
            return
          }
        })
      }
    })
  }

  /** 创建有指标的验证计划 */
  createPlanWithMetrics() {
    const rows = this.refValidationMetricsGrid.value?.getSelectedRows() as any
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }
    // 关联指标
    const reqAndMetricsLinks = rows.map((elem: { id: any; rdmExtensionType: any }) => {
      return {
        id: elem.id,
        clazz: elem.rdmExtensionType
      }
    })

    const obj = this.objParam.value
    // 关联需求
    const req = {
      id: obj?.id,
      clazz: obj?.modelCode
    }
    this.createPlan(req, reqAndMetricsLinks)
  }

  /** 添加到验证计划 */
  async addToPlan() {
    const metricsRows = this.refValidationMetricsGrid.value?.getSelectedRows() as any
    if (metricsRows && metricsRows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条验证指标数据'
      })
      return
    }
    KDialog.show({
      title: '添加到验证计划',
      size: { width: 900, height: 650 },
      props: {
        showToolStrip: false,
        objParam: this.props.objParam
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      getContainer: this.mainContainer.value,
      content: KValidationPlanTab,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KValidationPlanTabViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const planRows = formViewModel.getValue()
        if (!planRows) {
          event.cancel = true
          return
        }
        // 关联指标
        const reqAndMetricsLinks = metricsRows.map((elem: { id: any; rdmExtensionType: any }) => {
          return {
            id: elem.id,
            clazz: elem.rdmExtensionType
          }
        })
        // 验证计划
        const planRow = planRows[0]
        const plan = {
          id: planRow.id,
          clazz: planRow.rdmExtensionType
        }
        // 添加到验证计划
        const result = await Api.post('requirement', 'VerificationPlan', 'addToPlan', { data: [reqAndMetricsLinks, plan] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.props.onDataUpdated!()
          this.refValidationMetricsGrid.value?.refresh()
          KNotification.success('添加成功！')
        } else {
          KNotification.error({
            title: '添加失败',
            content: result.message
          })
          return
        }
      }
    })
  }

  /** 创建无指标的验证计划 */
  createPlanWithOutMetrics() {
    const obj = this.objParam.value
    // 关联需求
    const req = {
      id: obj?.id,
      clazz: obj?.modelCode
    }
    this.createPlan(req, [])
  }

  /** 创建验证计划 */
  createPlan(req: any, reqAndMetricsLinks: any) {
    KDialog.show({
      title: '新建验证计划',
      size: { width: 900, height: 650 },
      props: {},
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KPlanCreateForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KPlanCreateFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }
        const result = await formViewModel.save(req, reqAndMetricsLinks)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.props.onDataUpdated!()
          this.refValidationMetricsGrid.value?.refresh()
          KNotification.success({
            title: '系统提示',
            content: '新建成功'
          })
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '新建失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  editResult(row: any) {
    if (!row.verificationPlanAndMetrics) {
      return
    }
    KDialog.show({
      title: '编辑验证结果',
      size: { width: 400, height: 200 },
      props: {
        formData: { id: row.verificationPlanAndMetrics.id, result: row.verificationPlanAndMetrics.result }
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KEditForm,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const formViewModel = event.viewModel as KEditFormViewModel
        const formData = formViewModel.getValue()
        if (!formData) {
          KNotification.warn({
            message: '系统提示',
            description: '验证结果不能为空'
          })
          event.cancel = true
          return
        }
        const params = {
          data: [[{ id: row.verificationPlanAndMetrics.id, result: formData }]]
        }
        const result = await Api.post('requirement', 'ValidationMetrics', 'switchVerificationPlanAndMetricsResult', params)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refValidationMetricsGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '系统提示',
            content: result.message || '修改失败',
            details: result.detail
          })
          event.cancel = true
        }
      }
    })
  }

  /** 编辑计划网格 */
  // editPlan() {
  //   this.refVerificationPlanGrid.value?.refresh()
  //   // 修改工具栏状态
  //   this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
  //   this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
  //   // 网格开启编辑
  //   this.refVerificationPlanGrid.value?.beginEdit()
  // }

  /** 保存计划网格 */
  // savePlan() {
  //   this.refVerificationPlanGrid.value?.endEdit(true)
  //   setTimeout(async () => {
  //     const changedRows = this.refVerificationPlanGrid.value?.getChangedRows()
  //     const modifiedRows = changedRows?.modifiedRows

  //     if (modifiedRows && modifiedRows.length > 0) {
  //       // const newRows = modifiedRows.map(x => {
  //       //   return this.refVerificationPlanGrid.value?.getRow(x.id)
  //       // })
  //       const updateDataList = modifiedRows!.map(element => {
  //         return {
  //           id: element.id,
  //           type: element.type,
  //           title: element.title,
  //           description: element.description,
  //           executor: element.executor
  //         }
  //       })
  //       //更新数据
  //       const result = await Api.post('requirement', 'VerificationPlan', 'batchUpdate', { data: [updateDataList] })
  //       if (result && result.code == EnumRequestCode.SUCCESS) {
  //         KNotification.success('修改成功！')
  //         this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, true)
  //         this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
  //         this.refVerificationPlanGrid.value?.refresh()
  //       } else {
  //         KNotification.error({
  //           title: '修改失败',
  //           content: result.message
  //         })
  //         this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
  //         this.refPlanToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
  //         this.refVerificationPlanGrid.value?.beginEdit()
  //         return
  //       }
  //     }
  //   }, 40)
  // }

  /** 移除计划 */
  // removePlan() {
  //   const rows = this.refVerificationPlanGrid.value?.getSelectedRows()
  //   if (rows && rows.length == 0) {
  //     KNotification.warn({
  //       message: '系统提示',
  //       description: '请至少选择一条数据'
  //     })
  //     return
  //   }

  //   KDialog.confirm({
  //     title: '确认移除吗？移除后不可恢复!',
  //     onOk: async () => {
  //       // 获取对象id
  //       const rowIds = rows!.map(row => row.id)
  //       const reqParam = {
  //         data: [rowIds]
  //       }

  //       Api.post('requirement', 'VerificationPlan', 'batchDelete', reqParam).then(result => {
  //         if (result && result.code == EnumRequestCode.SUCCESS) {
  //           // 刷新网格
  //           this.refVerificationPlanGrid.value?.removeSelectedRows()
  //           //this.refVerificationPlanGrid.value?.refresh()
  //           KNotification.success({
  //             title: '移除成功',
  //             content: result.message
  //           })
  //           return Promise.resolve({
  //             rows: result.data,
  //             total: result.data.length
  //           })
  //         } else {
  //           KNotification.error({
  //             title: '移除失败',
  //             content: result.message,
  //             details: result.detail
  //           })
  //           return
  //         }
  //       })
  //     }
  //   })
  // }

  filterOption(inputValue: string, option: any) {
    return (
      option.label
        ?.toString()
        ?.toLowerCase()
        ?.indexOf(inputValue.toLowerCase()) >= 0 ||
      option.value
        ?.toString()
        ?.toLowerCase()
        ?.indexOf(inputValue.toLowerCase()) >= 0
    )
  }

  getValue() {
    const selectRows = this.refValidationMetricsGrid.value?.getSelectedRows()
    if (!selectRows || selectRows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }
    return selectRows
  }

  /** 打开指标对象 */
  async openMetricsTab(row: any) {
    const param = {
      id: row?.validationMetrics.id,
      modelCode: row?.validationMetrics.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row?.validationMetrics.className!)
    }
    ObjectClientSrv.openObj(param)
    // const tabKey = `${param.id}#${param.modelCode}`
    // const objResult = (await ObjectClientSrv.getObjBusiness(param)) as Record<string, any>
    // const title = clsCodeMap.get(objResult.rdmExtensionType) + ':' + ObjectClientSrv.getObjBusinessDesc(objResult)
    // PageManager.openPage(tabKey, title, KMetricsEditPanel, { objParam: param })
  }

  /** 打开计划对象 */
  async openPlanTab(row: any) {
    const param = {
      id: row?.id,
      modelCode: row!.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row?.className!)
    }
    ObjectClientSrv.openObj(param)
  }
}
