
import {
  defineView,
  EnumDataGridAutoSizeColumnsMode,
  EnumDataGridRowModelType,
  IKTreeNode,
  TreeViewSelectEventArgs
} from '@kmsoft/upf-core'
import { KMyRequirementPropOptions, KMyRequirementEventEmits } from './interface'
import KMyRequirementViewModel from './KMyRequirementViewModel'
import { KMyProductNeeds } from './my-product-needs'
import { KMyCustomerNeeds } from './my-customer-needs'
import { KMySubscribedRequirement } from './my-subscribed-requirement'

export default defineView({
  name: 'KMyRequirement',
  props: KMyRequirementPropOptions,
  emits: KMyRequirementEventEmits,
  viewModel: KMyRequirementViewModel,
  components: {
    KMyProductNeeds,
    KMyCustomerNeeds,
    KMySubscribedRequirement
  },
  setup(props, { emit, slots, attrs, vm }) {
    return {
      pagination: { pageIndex: 1, pageSize: 100 },
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      autosizeColumnsMode: EnumDataGridAutoSizeColumnsMode.FILL
    }
  }
})
