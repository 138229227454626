import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"padding-bottom":"5px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      (!(_ctx.operateType == _ctx.EnumOperateType.Revise || _ctx.operateType == _ctx.EnumOperateType.Priview))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_k_button, {
              onClick: _ctx.$vm.createCode
            }, {
              default: _withCtx(() => [
                _createTextVNode("批量生成编码")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_k_data_grid, {
        rowKey: "id",
        name: "docEchoGrid",
        ref: "refDataGrid",
        style: _normalizeStyle(
        !(_ctx.operateType == _ctx.EnumOperateType.Revise || _ctx.operateType == _ctx.EnumOperateType.Priview) ? 'height: calc(100% - 40px)' : ''
      ),
        autoIndex: false,
        disabled: false,
        dataSource: _ctx.dataSource,
        isMultipleSelection: false,
        getCellEditable: (params)=>_ctx.$vm.getCellEditable(params)
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_text_box_column, {
            dataPropertyName: "index",
            headerText: "序号",
            readonly: "true",
            align: "left",
            width: "50"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            headerText: "类别",
            dataPropertyName: "type",
            readonly: "true",
            hide: "true",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            headerText: "类型",
            align: "center",
            dataPropertyName: "rdmExtensionType",
            readonly: _ctx.operateType == _ctx.EnumOperateType.Revise || _ctx.operateType == _ctx.EnumOperateType.SaveAs,
            cellTemplateSelector: (params)=>_ctx.$vm.rdmExtensionTypeCellTemplateSelector(params),
            cellEditingTemplateSelector: (params)=>_ctx.$vm.rdmExtensionTypeCellEditingTemplateSelector(params),
            width: "100"
          }, null, 8, ["readonly", "cellTemplateSelector", "cellEditingTemplateSelector"]),
          _createVNode(_component_k_data_grid_template_column, {
            dataPropertyName: "number",
            headerText: "编码",
            readonly: _ctx.operateType == _ctx.EnumOperateType.Revise || _ctx.operateType == _ctx.EnumOperateType.Priview,
            align: "center",
            cellTemplateSelector: (params)=>_ctx.$vm.cellTemplateSelector(params),
            cellEditingTemplateSelector: (params)=>_ctx.$vm.cellEditingTemplateSelector(params),
            width: "200"
          }, null, 8, ["readonly", "cellTemplateSelector", "cellEditingTemplateSelector"]),
          _createVNode(_component_k_data_grid_text_box_column, {
            dataPropertyName: "name",
            headerText: "名称",
            readonly: _ctx.operateType == _ctx.EnumOperateType.Revise || _ctx.operateType == _ctx.EnumOperateType.Priview,
            align: "center",
            width: "150"
          }, null, 8, ["readonly"]),
          _createVNode(_component_k_data_grid_text_box_column, {
            dataPropertyName: "version",
            headerText: "版本",
            readonly: "true",
            align: "center",
            width: "50"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            dataPropertyName: "folder",
            headerText: "文件夹",
            hide: _ctx.operateType == _ctx.EnumOperateType.Revise,
            align: "center",
            cellTemplateSelector: (params)=>_ctx.$vm.folderCellTemplateSelector(params),
            cellEditingTemplateSelector: (params)=>_ctx.$vm.folderCellEditingTemplateSelector(params),
            width: "200"
          }, null, 8, ["hide", "cellTemplateSelector", "cellEditingTemplateSelector"]),
          _createVNode(_component_k_data_grid_template_column, {
            headerText: "操作",
            align: "center",
            dataPropertyName: "operate",
            cellTemplateSelector: (params)=>_ctx.$vm.operationCellTemplateSelector(params),
            cellEditingTemplateSelector: (params)=>_ctx.$vm.operationCellEditingTemplateSelector(params),
            width: "100"
          }, null, 8, ["cellTemplateSelector", "cellEditingTemplateSelector"])
        ]),
        _: 1
      }, 8, ["style", "dataSource", "getCellEditable"])
    ]),
    _: 1
  }))
}