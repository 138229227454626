import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KDocOptionalEmitsType, KDocOptionalPropType } from './interface'

/** KDocOptional */
export default class KDocOptionalViewModel extends BaseViewModel<KDocOptionalEmitsType, KDocOptionalPropType> {
  model = ref<Record<string, boolean>>({
    relatedPart: false
  })

  constructor(options: ViewModelOptions<KDocOptionalPropType>) {
    super(options)
  }

  viewDidMount() {}

  getOptional() {
    return this.model.value
  }
}
