import {
  Agent,
  Api,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  EnumRequestCode,
  FileClientSrv,
  LoginClientSrv,
  ObjectClientSrv
} from '@kmsoft/ebf-common'
import { AgentManager, AgentRequestModel, FileUtil } from '@kmsoft/ebf-common/src/client-agent'
import { EnumDocType } from '@kmsoft/ebf-common/src/types/enums'
import {
  AppContext,
  EnumDialogResult,
  EnumDialogState,
  IIdentityStorage,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  request,
  UPF_IOC_KEYS
} from '@kmsoft/upf-core'
import { KCADWorkspace, KPersonalWorkspaceViewModel } from '../../../../../../../ebf-folder/src'
import { DocClientSrv } from '../../../../../client-srv'
import { FileDataInter, Progress } from '../../../../../controls/doc-batch-create/doc-select-files/interface'
import { EnumWorkState } from '../../../../../controls/doc-edit-panel/interface'
import { AddFilePropertyReadOption } from '../../../../../controls/doc-object-create-panel'
import { KFileComparison, KFileComparisonViewModel } from '../../../../../controls/file-comparison'
import { AgentDdb, KDocForm } from '../../../../../index'
import { EnumSoft } from '../../EnumSoft'
import { DdbClientSrv } from '../../ddb'
import { DpMsgClientSrv } from '../DpMsgClientSrv'
import { DpMsgXml } from '../DpMsgXml'
import { EnumExApp } from '../EnumExApp'
import { Application, IOTypeEnum } from '../Parameter'
import { DpMsgHandler } from './DpMsgHandler'

export class CadenceHandler {
  public static readonly srcAppID: EnumExApp = EnumExApp.CADENCE

  private static initConditions() {
    const user = LoginClientSrv.getUserIdentity()
    const conditions = [
      {
        conditionName: 'master.documentType',
        operator: EnumQueryConditionOperator.EQUAL,
        conditionValues: [EnumDocType.CADENCE]
      },
      {
        conditionName: 'latestVersion',
        operator: EnumQueryConditionOperator.EQUAL,
        conditionValues: ['true']
      },
      {
        conditionName: 'latestIteration',
        operator: EnumQueryConditionOperator.EQUAL,
        conditionValues: ['true']
      },
      {
        conditions: [
          {
            conditionName: 'workingState',
            conditionValues: ['CHECKED_IN'],
            operator: EnumQueryConditionOperator.EQUAL
          },
          {
            conditions: [
              {
                conditionName: 'workingState',
                conditionValues: ['CHECKED_OUT'],
                operator: EnumQueryConditionOperator.EQUAL
              },
              {
                conditions: [
                  {
                    conditionName: 'checkOutUserName',
                    conditionValues: [''],
                    operator: EnumQueryConditionOperator.IS_NULL
                  },
                  {
                    conditionName: 'checkOutUserName',
                    conditionValues: [user?.name || ''],
                    operator: EnumQueryConditionOperator.NOT_EQUAL
                  }
                ],
                joiner: EnumQueryConditionJoiner.Or
              }
            ],
            joiner: EnumQueryConditionJoiner.And
          },
          {
            conditions: [
              {
                conditionName: 'workingState',
                conditionValues: ['INWORK'],
                operator: EnumQueryConditionOperator.EQUAL
              },
              {
                conditionName: 'checkOutUserName',
                conditionValues: [user?.name || ''],
                operator: EnumQueryConditionOperator.EQUAL
              }
            ],
            joiner: EnumQueryConditionJoiner.And
          }
        ],
        joiner: EnumQueryConditionJoiner.Or
      }
    ]
    return conditions
  }

  /**登录操作处理 */
  public static async login(request: AgentRequestModel) {
    const mask = document.getElementById('maskLayer') as HTMLElement
    if (mask) {
      mask.style.display = 'none'
    }

    // 注册登录请求到DpMsgHandler
    DpMsgHandler.registerLoginRequest('Cadence', request, async (token: string) => {
      const srcAppID = request.Request.split('.')[0]
      await DpMsgClientSrv.Token(token)
      await CadenceHandler.SendMsgLoginResult(parseInt(srcAppID), 'true')
      const result = DpMsgXml.createXmlResult(request.Parameters, true, { cDocSymbol: '1', ErrInfo: '' })
      await AgentManager.pushResponse<any>(request.Id, result)
    })
  }

  /**退出处理 */
  public static async exit(request: AgentRequestModel) {
    console.log('exit', request)
    /** 同步返回结果*/
    AppContext.current
      .getIocContainer()
      .getBean<IIdentityStorage>(UPF_IOC_KEYS.IDENTITY_STORAGE)
      .clear()
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   * 工作区
   */
  public static async openWorkspace(request: AgentRequestModel) {
    const param = request.Parameters
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    //根据检出路径查询文档
    KDialog.show({
      title: '工作区',
      state: EnumDialogState.Maximum,
      closable: false,
      maximizeBox: false,
      minimizeBox: false,
      rootClassName: 'agent',
      showFooter: false,
      props: {
        agent: true
      },
      showApply: false,
      content: KCADWorkspace,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KPersonalWorkspaceViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        //TODO do something
        await AgentManager.pushResponse<any>(request.Id, result)
      },
      onClosed: async () => {
        await AgentManager.pushResponse<any>(request.Id, result)
      }
    })
  }

  /**
   * 编辑
   */
  public static async edit(request: AgentRequestModel) {
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
    const user = LoginClientSrv.getUserIdentity()
    if (!user) {
      return
    }
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'As_Path', IOTypeEnum.IN) as string
    let currentDocId = undefined
    if (fileFullPath) {
      fileFullPath.replaceAll('/', '\\')
      const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', {
        data: [{ fileFullPath: fileFullPath.toUpperCase() }]
      })
      if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
        currentDocId = res.data.id
      }
    }
    const conditions = CadenceHandler.initConditions()
    if (currentDocId) {
      conditions.push({
        conditionName: 'id',
        operator: EnumQueryConditionOperator.NOT_EQUAL,
        conditionValues: [currentDocId]
      })
    }
    //1）打开对象选择
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      state: EnumDialogState.Maximum,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document'],
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: conditions
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Document'],
        documentType: EnumDocType.CADENCE,
        defaultSelectClsCode: 'Document'
      },
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const parameters = DpMsgXml.parseXml(request.Parameters)
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]
        //检出并下载
        const doc = await DocClientSrv.getDoc(targetParam?.id)
        // 判断文件状态
        const workingState = doc.workingState
        let docRes = undefined
        if (workingState == EnumWorkState.CHECKED_IN) {
          const activatedWorkspace = await DocClientSrv.getActivatedWorkspace(doc?.branch?.folder?.id)
          doc.checkoutPath = activatedWorkspace?.localCachePosition
            ? activatedWorkspace?.localCachePosition
            : `C:\\KMSOFT\\temp\\Documents`
          docRes = await DocClientSrv.checkOutDoc(doc)
        }
        if (!docRes && workingState != EnumWorkState.INWORK) {
          KDialog.warning({ title: '提示', content: '文档已被他人检出，不能编辑' })
          event.cancel = true
          return
        }

        if (workingState == EnumWorkState.INWORK) {
          const checkoutPath = doc.checkoutPath
          docRes = { data: [doc] }
          if (!checkoutPath) {
            KDialog.warning({ title: '提示', content: '文档已检出，但未找到检出路径' })
            event.cancel = true
            return
          }
          const isExists = await Agent.File.Exists(checkoutPath)
          if (!isExists) {
            // 下载文件
            // 获取全路径的文件名和文件夹路径
            const path = checkoutPath.substring(0, checkoutPath.lastIndexOf('\\'))
            const fileName = checkoutPath.substring(checkoutPath.lastIndexOf('\\') + 1)
            await FileClientSrv.downloadFilesByAgentPost(
              [
                {
                  id: docRes.data[0].primary.id,
                  fileName: fileName,
                  modelCode: 'Document'
                }
              ],
              path
            )
          }
        }
        if (docRes) {
          //3）给插件发消息打开目录下的文件
          await CadenceHandler.openFile(CadenceHandler.srcAppID, docRes.data[0].checkoutPath)
        } else {
          event.cancel = true
        }
      },
      onClosed: async () => {
        await AgentManager.pushResponse<any>(request.Id, result)
      }
    })
  }

  /**
   * 下载
   */
  public static async download(request: AgentRequestModel) {
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
    console.log('download', request)
    //1）打开对象选择
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      state: EnumDialogState.Maximum,
      closable: false,
      maximizeBox: false,
      minimizeBox: false,
      rootClassName: 'agent',
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document'],
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: CadenceHandler.initConditions()
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Document'],
        documentType: EnumDocType.CADENCE,
        defaultSelectClsCode: 'Document'
      },
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]

        //2）下载文档
        const fullFilePath = await DocClientSrv.docDownload(targetParam!.id)
      },
      onClosed: async () => {
        await AgentManager.pushResponse<any>(request.Id, result)
      }
    })
  }

  /**
   * 检入
   */
  public static async checkin(request: AgentRequestModel) {
    console.log('checkin', request)
    /** 同步返回结果*/
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN) as string
    const checkoutPath = fileFullPath?.replaceAll('/', '\\')
    //根据检出路径查询文档
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', {
      data: [{ fileFullPath: checkoutPath.toUpperCase() }]
    })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      const data = res.data
      //给插件发消息关闭目录下的文件
      await DdbClientSrv.CloseFile(EnumSoft.CADENCE.toString(), checkoutPath)
      const result = await DocClientSrv.checkInCadenceDoc(data, true, checkoutPath)
      if (result) {
        await new Promise(resolve => {
          KDialog.info({
            title: '提示',
            content: '检入成功',
            onOk: () => resolve(null)
          })
        })
      } else {
        await new Promise(resolve => {
          KDialog.info({
            title: '提示',
            content: '检入失败',
            onOk: () => resolve(null)
          })
        })
      }
    } else {
      await new Promise(resolve => {
        KDialog.warning({
          title: '提示',
          content: '文档不存在或已检入',
          onOk: () => resolve(null)
        })
      })
    }
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   * 取消检出
   */
  public static async undoCheckout(request: AgentRequestModel) {
    console.log('undoCheckout', request)
    /** 同步返回结果*/
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN) as string
    const checkoutPath = fileFullPath?.replaceAll('/', '\\')
    //根据检出路径查询文档
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', {
      data: [{ fileFullPath: checkoutPath.toUpperCase() }]
    })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      const data = res.data
      await DocClientSrv.docUnCheckOut(data!.id)
      //给插件发消息关闭目录下的文件
      await DdbClientSrv.CloseFile(EnumSoft.CADENCE.toString(), checkoutPath)
    } else {
      await new Promise(resolve => {
        KDialog.warning({
          title: '提示',
          content: '文档不存在或已检入',
          onOk: () => resolve(null)
        })
      })
    }
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   * 修订
   */
  public static async revise(request: AgentRequestModel) {
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
    const user = LoginClientSrv.getUserIdentity()
    if (!user) {
      return
    }
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'As_Path', IOTypeEnum.IN) as string
    let currentDocId = undefined
    if (fileFullPath) {
      fileFullPath.replaceAll('/', '\\')
      const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', {
        data: [{ fileFullPath: fileFullPath.toUpperCase() }]
      })
      if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
        currentDocId = res.data.id
      }
    }
    const conditions = CadenceHandler.initConditions()
    if (currentDocId) {
      conditions.push({
        conditionName: 'id',
        operator: EnumQueryConditionOperator.NOT_EQUAL,
        conditionValues: [currentDocId]
      })
    }
    //1）打开对象选择
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      state: EnumDialogState.Maximum,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document'],
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: conditions
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Document'],
        documentType: EnumDocType.CADENCE,
        defaultSelectClsCode: 'Document'
      },
      showLifecycleState: { Document: ['Released'] },
      onClosing: async event => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          if (!event.viewInstance) {
            resolve()
            return
          }
          if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
            resolve()
            return
          }
          const parameters = DpMsgXml.parseXml(request.Parameters)
          const objParams = event.viewInstance.getSelectedRows()
          if (objParams.length <= 0) {
            event.cancel = true
            return
          }
          const targetParam = objParams[0]
          //检出并下载
          const doc = await DocClientSrv.getDoc(targetParam?.id)
          // 判断文件状态
          const lifecycleStateCode = doc.lifecycleStateCode
          let docRes = undefined
          if (lifecycleStateCode == 'Released') {
            // doc.checkoutPath = `C:\\KMSOFT\\temp\\Documents`
            docRes = await DocClientSrv.docRevise(doc.id, doc.branch.folder.id).catch(() => {
              resolve()
              event.cancel = true
            })
          } else {
            KDialog.warning({ title: '提示', content: '只有发布状态的文档才能检出' })
            event.cancel = true
            resolve()
            return
          }
          //检出文档
          docRes = await DocClientSrv.getDoc(docRes.data.id)
          const activatedWorkspace = await DocClientSrv.getActivatedWorkspace(docRes?.branch?.folder?.id)
          docRes.checkoutPath = activatedWorkspace?.localCachePosition
            ? activatedWorkspace?.localCachePosition
            : `C:\\KMSOFT\\temp\\Documents`
          docRes = await DocClientSrv.checkOutDoc(docRes)
          const checkoutPath = docRes.data[0].checkoutPath
          if (!checkoutPath) {
            KDialog.warning({ title: '提示', content: '文档已检出，但未找到检出路径' })
            event.cancel = true
            resolve()
            return
          }
          const isExists = await Agent.File.Exists(checkoutPath)
          if (!isExists) {
            // 下载文件
            // 获取全路径的文件名和文件夹路径
            const path = checkoutPath.substring(0, checkoutPath.lastIndexOf('\\'))
            const fileName = checkoutPath.substring(checkoutPath.lastIndexOf('\\') + 1)
            await FileClientSrv.downloadFilesByAgentPost(
              [
                {
                  id: docRes.data[0].primary.id,
                  fileName: fileName,
                  modelCode: 'Document'
                }
              ],
              path
            )
          }
          if (docRes) {
            //3）给插件发消息打开目录下的文件

            console.info('docRes.data[0].checkoutPath', docRes.data[0].checkoutPath)
            await CadenceHandler.openFile(CadenceHandler.srcAppID, docRes.data[0].checkoutPath)
          } else {
            event.cancel = true
          }
          resolve()
        })
      },
      onClosed: async () => {
        await AgentManager.pushResponse<any>(request.Id, result)
      }
    })
  }

  /**
   *上传
   */
  public static async upload(request: AgentRequestModel) {
    console.log('upload', request)
    /** 同步返回结果*/
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN) as string
    const checkoutPath = fileFullPath?.replaceAll('/', '\\')
    await DdbClientSrv.CloseFile(EnumSoft.CADENCE.toString(), checkoutPath)
    const folderData = await DocClientSrv.getActivatedWorkspace()
    const folderId = folderData.docLibraryPosition.split('/')?.filter((id: any) => id)
    const partFolderId = folderData.bomLibraryPosition.split('/')?.filter((id: any) => id)

    //根据检出路径查询文档
    //给插件发消息关闭目录下的文件
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    KDialog.show({
      title: '新建文档',
      size: { width: 800, height: 600 },
      props: {
        formValue: {
          classCode: 'Document',
          documentType: EnumDocType.CADENCE,
          folder: {
            id: {
              doc: folderId?.pop(),
              part: partFolderId?.pop()
            }
          }
        },
        loadData: () => {
          return {
            documentType: EnumDocType.CADENCE,
            version: 'A',
            lifecycleStateCode: 'InWork'
          }
        },
        isAgent: true,
        fileName: checkoutPath,
        modelCode: 'Document'
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KDocForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewInstance as any
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }
        const result = await formViewModel.save()
        if (result && result.code == EnumRequestCode.SUCCESS) {
          await CadenceHandler.cadDataAddFolder(result.data, folderData.id)
          KNotification.success('对象创建成功')
        } else {
          if (result) {
            KNotification.error({
              title: '操作失败',
              content: result.message || '创建对象失败',
              details: result.detail
            })
          }
          event.cancel = true
          return
        }
      },
      onClosed: async () => {
        await AgentManager.pushResponse<any>(request.Id, result)
      }
    })
  }

  public static cadDataAddFolder(cadData: any, folderId: string) {
    const objList = cadData.map((row: any) => {
      if (row.branch && row.branch.id) {
        return {
          id: row.branch.id,
          clazz: row.branch.rdmExtensionType
        }
      } else {
        return {
          id: row.id,
          clazz: row.rdmExtensionType
        }
      }
    })
    const params = {
      data: [
        {
          folderId: folderId,
          objList: objList
        }
      ]
    }
    return Api.post('folder', 'Folder', 'batchCreateFolderObjLink', params)
  }

  /** 提取文件属性 **/
  async getFileProperty(fileData: FileDataInter, options: AddFilePropertyReadOption) {
    const argIntegrationConfig = await DocClientSrv.getIntegrationConfig('Cadence')
    if (argIntegrationConfig && argIntegrationConfig.data) {
      fileData.extractProgress = Progress.processing
      const fileProperty = await AgentDdb.DdbClientSrv.GetFileProperty(
        [fileData.filePath as string],
        [],
        false,
        [],
        [],
        argIntegrationConfig.data,
        LoginClientSrv.getUserIdentity()?.token!,
        {
          downloadUrl: FileClientSrv.getApiUrl('upload'),
          checkMd5Url: '',
          copyUrl: '',
          uploadUrl: FileClientSrv.getApiUrl('upload')
        },
        {
          location: '',
          chunkSize: ''
        }
      )

      if (fileProperty && fileProperty.docList && fileProperty.docList.length > 0) {
        fileData.extractProgress = Progress.complete
        // //找到根节点
        const root = fileProperty.docList.find(a => a.isRoot == true)

        fileData.number = root?.code
        fileData.name = root?.name
        fileData.docList = fileProperty.docList
        fileData.docStructList = fileProperty.docStructList
      } else {
        fileData.extractProgress = Progress.fail
      }
    }
  }

  /**
   *上传
   */
  public static async save2Workspace(request: AgentRequestModel) {
    console.log('save2Workspace', request)

    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   * 更新
   */
  public static async updateRequest(agentRequest: AgentRequestModel) {
    const param = agentRequest.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN) as string
    const checkoutPath = fileFullPath?.replaceAll('/', '\\')
    const agentResult = DpMsgXml.createXmlResult(agentRequest.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    const dialog = KDialog.info({ content: '正在查询数据，请稍后...', title: '提示', showOk: false })

    const activatedWorkspace = await DocClientSrv.getActivatedWorkspace()
    if (!activatedWorkspace) {
      await new Promise(resolve => {
        KDialog.info({
          title: '提示',
          content: '未激活CAD工作区，无法更新！',
          onOk: () => resolve(null)
        })
      })
      await AgentManager.pushResponse<any>(agentRequest.Id, agentResult)
      return
    }

    //获取激活的工作区的对象

    if (!activatedWorkspace.localCachePosition) {
      await new Promise(resolve => {
        KDialog.info({
          title: '提示',
          content: '该工作区未设置本地缓存目录，无法更新',
          onOk: () => resolve(null)
        })
      })
      await AgentManager.pushResponse<any>(agentRequest.Id, agentResult)
      return
    }
    const localCachePath = activatedWorkspace.localCachePosition

    const req = {
      data: [
        {
          folderId: activatedWorkspace.id,
          pageVO: {
            maxPageSize: 1000,
            curPage: 1,
            pageSize: 1000,
            totalRows: 0,
            totalPages: 0,
            startIndex: 0,
            endIndex: 0
          }
        }
      ]
    }
    dialog.update('正在对比文件')
    const result = await Api.post('folder', 'Folder', 'listFolderLink', req)
    if (!result || result.code != EnumRequestCode.SUCCESS || result.data.data.length == 0) {
      await new Promise(resolve => {
        KDialog.info({
          title: '提示',
          content: '该工作区没有需要更新的数据',
          onOk: () => resolve(null)
        })
      })
      await AgentManager.pushResponse<any>(agentRequest.Id, agentResult)
      return
    }
    const cadencePcbList = result.data.data.filter((item: any) => item.documentType == EnumDocType.CADENCE)
    if (cadencePcbList.length == 0) {
      await new Promise(resolve => {
        KDialog.info({
          title: '提示',
          content: '该工作区没有需要更新的数据',
          onOk: () => resolve(null)
        })
      })
      await AgentManager.pushResponse<any>(agentRequest.Id, agentResult)
      return
    }
    const cadencePcbDocResult = await Api.post('doc', 'Document', 'batchGet', {
      data: [cadencePcbList.map((item: any) => item.id)]
    })

    if (fileFullPath.startsWith(localCachePath)) {
      await DdbClientSrv.CloseFile(EnumSoft.CADENCE.toString(), checkoutPath)
    }
    dialog.destroy()
    const compareResult = await CadenceHandler.compareFile(cadencePcbDocResult.data, localCachePath)
    if (compareResult) {
      // 调用代理打开文件目录
      // 打开目录
      // 打开文件夹并选中
      const process = await Agent.Process.create()
      const startinfo = await process.StartInfo()
      //await startinfo.FileName(fullFilePath)
      await startinfo.Arguments('/select,' + localCachePath)
      await startinfo.UseShellExecute(true)
      await startinfo.FileName('explorer')
      await process.Start()
      await AgentManager.pushResponse<any>(agentRequest.Id, agentResult)
    }
  }

  public static async compareFile(cadencePcbDocData: any[], localPath: string) {
    //   获取系统文件的MD5值
    let systemFileMd5 = [] as any[]
    const fileIds = Array.from(
      new Set(
        cadencePcbDocData
          .map(item => item.primary?.[0]?.id) // 处理 primary 为空的情况
          .filter(id => id) // 过滤掉空值
      )
    ).join(',')
    const systemFileMd5Res = ((await request.get('/kmsaasFileApi/fileHashValue/list', { fileIds })) as unknown) as any[]

    if (systemFileMd5Res && systemFileMd5Res.length > 0) {
      systemFileMd5 = systemFileMd5Res
    }

    // TODO 根据item.primary[0].name去重
    const uniqueStructureData = Array.from(new Map(cadencePcbDocData.map(item => [item?.primary?.[0]?.name, item])).values())

    await Promise.all(
      uniqueStructureData.map(async item => {
        if (item?.primary) {
          const mainFile = item.primary[0]
          const filename = mainFile?.name
          const fullPath = `${localPath}\\${filename}`
          //文件名
          item.filename = filename
          //文件路径
          item.filePath = fullPath
          const systemFileHash = systemFileMd5.find((fileMd5: { fileId: any }) => fileMd5.fileId == mainFile?.id)
          if (systemFileHash) {
            item.systemFileHash = systemFileHash.hashValue
          } else {
            item.systemFileHash = '--'
          }
          const isExists = await Agent.File.Exists(fullPath)
          if (isExists) {
            //当前文件hash
            item.localFileHash = await FileUtil.GetFileMd5(fullPath)
            //md5是否一致
            const isMatch = item.localFileHash == item.systemFileHash
            item.isMatch = isMatch ? '是' : '否'
            item.operateOption = isMatch ? ['UnUpdate'] : ['Update', 'Ignore']
            item.operate = item.operateOption[0]
          } else {
            item.isMatch = '否'
            item.operateOption = ['Update', 'Ignore']
            item.operate = item.operateOption[0]
          }
        }
      })
    )
    // 显示对话框并处理结果
    return await new Promise(res => {
      KDialog.show({
        state: EnumDialogState.Maximum,
        closable: false,
        maximizeBox: false,
        minimizeBox: false,
        rootClassName: 'agent',
        title: '更新文档',
        props: { comparisonResult: uniqueStructureData },
        content: KFileComparison,
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult === EnumDialogResult.Cancel || event.dialogResult === EnumDialogResult.Close) {
            res(false)
            return
          }
          //TODO 增加提示：更新覆盖本地文件
          const formViewModel = event.viewModel as KFileComparisonViewModel
          const updateRows = formViewModel.getUpdatedRows()!
          if (updateRows.length > 0) {
            const commonParams = {
              modelCode: 'Document',
              modelGroup: 'doc',
              location: ''
            }
            // 并行处理所有请求
            const downloadPromises = updateRows.map(async item => {
              const downLoadParam = {
                ...commonParams,
                fileId: item.primary[0].id,
                id: '',
                fileName: item.primary[0].name
              }
              return await DocClientSrv.downloadDocsByAgent([downLoadParam], localPath)
            })
            await Promise.all(downloadPromises)
            await new Promise(resolve => {
              KDialog.info({
                content: '更新成功',
                onOk: () => {
                  resolve(true)
                }
              })
            })
          } else {
            await new Promise(resolve => {
              KDialog.info({
                content: '没有要更新的数据',
                onOk: () => {
                  resolve(true)
                }
              })
            })
          }
          res(true)
        }
      })
    })
  }

  /**
   * 元器件同步
   */
  public static async updateLib(request: AgentRequestModel) {
    console.log('updateLib', request)
    /** 同步返回结果*/
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    const response = await Api.post('doc', 'Document', 'listAllEDALibraryFiles', {
      data: []
    })
    if (response && response.code == EnumRequestCode.SUCCESS && response.data) {
      const downloadList: any[] = response.data
      const dialog = new Agent.FolderBrowserDialog()
      dialog.state = EnumDialogState.Maximum
      if ((await dialog.ShowDialog()) === Agent.DialogResult.OK) {
        // alert(dialog.FileName)
        const selectedFilePath = dialog.SelectedPath
        const showDialog = KDialog.info({ content: '正在下载文件，请稍后...', title: '提示', showOk: false })
        await FileClientSrv.downloadFilesByAgentPost(
          downloadList.map(downloadData => ({
            id: downloadData.fileId,
            fileName: downloadData.fileName,
            modelCode: downloadData.modelCode
          })),
          selectedFilePath
        )
        showDialog.destroy()
        await new Promise(resolve => {
          KDialog.info({ title: '提示', content: '下载完成', onOk: () => resolve(null) })
        })
        // 调用代理打开文件目录
        // 打开目录
        // 打开文件夹并选中
        const process = await Agent.Process.create()
        const startinfo = await process.StartInfo()
        //await startinfo.FileName(fullFilePath)
        await startinfo.Arguments('/select,' + selectedFilePath + '\\' + downloadList[0].fileName)
        await startinfo.UseShellExecute(true)
        await startinfo.FileName('explorer')
        await process.Start()
      }
    } else {
      KDialog.warning('获取元器件列表失败')
    }
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /** 发送消息,登录结果*/
  public static async SendMsgLoginResult(appID: EnumExApp, isLogined: string) {
    const res = isLogined == 'true' ? 'OK' : 'CANCEL'
    // const paramters: ActualParameter[] = [
    //   { FormalParameterID: 'Res', IOType: 'In', DataType: 'string', IsArray: '0', Value: res },
    //   { FormalParameterID: 'SoftCaption', IOType: 'In', DataType: 'string', IsArray: '0', Value: 'saas' },
    //   { FormalParameterID: 'UserName', IOType: 'In', DataType: 'string', IsArray: '0', Value: 'One的账号' }
    // ]
    const application: Application = {
      IntefaceName: 'ID_PDM_APP_ISLOGIN',
      InterfaceFunName: '',
      ActualParameters: {
        ActualParameter: [
          {
            FormalParameterID: 'Res',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: 'OK'
          },
          {
            FormalParameterID: 'SoftCaption',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: 'saas'
          },
          {
            FormalParameterID: 'UserName',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: '系统管理员'
          },
          {
            FormalParameterID: 'ErrInfo',
            IOType: 'Out',
            DataType: 'String',
            IsArray: 'False',
            Value: ''
          }
        ]
      },
      ReturnParameter: {
        FormalParameterID: 'Result',
        DataType: 'String',
        Value: ''
      }
    }
    const result = DpMsgXml.objectToXML(application, 'Application')
    await DpMsgClientSrv.Send(appID, 'ID_PDM_APP_ISLOGIN', result, 3)
  }

  /**
   * 打开文件
   */
  public static async openFile(enumExApp: EnumExApp, fileFulPath: string) {
    const application: Application = {
      IntefaceName: 'ID_PDM_APP_OPENFILE',
      InterfaceFunName: '',
      ActualParameters: {
        ActualParameter: [
          {
            FormalParameterID: 'cDocList',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: fileFulPath
          },
          {
            FormalParameterID: 'ErrInfo',
            IOType: 'Out',
            DataType: 'String',
            IsArray: 'False',
            Value: ''
          }
        ]
      },
      ReturnParameter: {
        FormalParameterID: 'Result',
        DataType: 'String',
        Value: ''
      }
    }
    const res = DpMsgXml.objectToXML(application, 'Application')
    DpMsgClientSrv.Send(enumExApp, 'ID_PDM_APP_OPENFILE', res, 3)
  }

  /**
   * 结构
   */
  public static async loadPart(enumExApp: EnumExApp, fileFulPath: string) {
    const application: Application = {
      IntefaceName: 'ID_PDM_APP_LOADPART',
      InterfaceFunName: '',
      ActualParameters: {
        ActualParameter: [
          {
            FormalParameterID: 'cDocList',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: `<file name = "${fileFulPath}"></file>`
          },
          {
            FormalParameterID: 'ErrInfo',
            IOType: 'Out',
            DataType: 'String',
            IsArray: 'False',
            Value: ''
          }
        ]
      },
      ReturnParameter: {
        FormalParameterID: 'Result',
        DataType: 'String',
        Value: ''
      }
    }
    const res = DpMsgXml.objectToXML(application, 'Application')
    DpMsgClientSrv.Send(enumExApp, 'ID_PDM_APP_LOADPART', res, 3)
  }

  /**
   * 关闭文件
   */
  public static async closeFile(enumExApp: EnumExApp, fileFulPath: string) {
    const application: Application = {
      IntefaceName: 'ID_PDM_APP_CLOSEFILEDEL',
      InterfaceFunName: '',
      ActualParameters: {
        ActualParameter: [
          {
            FormalParameterID: 'cDocList',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: fileFulPath
          },
          {
            FormalParameterID: 'ErrInfo',
            IOType: 'Out',
            DataType: 'String',
            IsArray: 'False',
            Value: ''
          }
        ]
      },
      ReturnParameter: {
        FormalParameterID: 'Result',
        DataType: 'String',
        Value: ''
      }
    }
    const res = DpMsgXml.objectToXML(application, 'Application')
    DpMsgClientSrv.Send(enumExApp, 'ID_PDM_APP_CLOSEFILEDEL', res, 2)
  }
}
