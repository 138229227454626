
import { defineView } from '@kmsoft/upf-core'
import { KCustomerNeedsCreateWithFolderSelectorPropOptions, KCustomerNeedsCreateWithFolderSelectorEventEmits } from './interface'
import KCustomerNeedsCreateWithFolderSelectorViewModel from './KCustomerNeedsCreateWithFolderSelectorViewModel'

export default defineView({
  name: 'KCustomerNeedsCustomerNeedsCreate',
  props: KCustomerNeedsCreateWithFolderSelectorPropOptions,
  emits: KCustomerNeedsCreateWithFolderSelectorEventEmits,
  viewModel: KCustomerNeedsCreateWithFolderSelectorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
