
import { defineView } from '@kmsoft/upf-core'
import { KEditFormPropOptions, KEditFormEventEmits } from './interface'
import KEditFormViewModel from './KEditFormViewModel'

export default defineView({
  name: 'KEditForm',
  props: KEditFormPropOptions,
  emits: KEditFormEventEmits,
  viewModel: KEditFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
