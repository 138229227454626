
import { defineView, EnumDataGridRowModelType, KGridPagination } from '@kmsoft/upf-core'
import { KMyProductNeedsPropOptions, KMyProductNeedsEventEmits } from './interface'
import KMyProductNeedsViewModel from './KMyProductNeedsViewModel'
import { clsCodeMap } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KMyProductNeeds',
  props: KMyProductNeedsPropOptions,
  emits: KMyProductNeedsEventEmits,
  viewModel: KMyProductNeedsViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      pagination: { pageIndex: 1, pageSize: 100 } as KGridPagination,
      clsCodeMap
    }
  }
})
