import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dbb9e4b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  style: {"height":"100%"},
  ref: "mainContainer"
}
const _hoisted_2 = { class: "full part-validation-metrics" }
const _hoisted_3 = { style: {"display":"flex","justify-content":"space-between","align-items":"center"} }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { style: {"margin-left":"5px"} }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { style: {"margin-left":"5px"} }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_tool_strip = _resolveComponent("k-object-tool-strip")!
  const _component_k_checkbox = _resolveComponent("k-checkbox")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_data_grid_check_box_column = _resolveComponent("k-data-grid-check-box-column")!
  const _component_k_tag = _resolveComponent("k-tag")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.showToolStrip)
          ? (_openBlock(), _createBlock(_component_k_object_tool_strip, {
              key: 0,
              ref: _ctx.$vm.refMetricsToolStrip,
              items: _ctx.$vm.metricsToolStripItems,
              onItemClicked: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onMetricsToolStripItemClicked(event))
            }, null, 8, ["items"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createVNode(_component_k_checkbox, {
            class: "createNext",
            checked: _ctx.$vm.includeChild,
            "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.includeChild) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode("显示子项")
            ]),
            _: 1
          }, 8, ["checked"])
        ])
      ]),
      _createVNode(_component_k_data_grid, {
        class: "validation-metrics-grid",
        rowKey: "id",
        name: "refValidationMetricsGrid",
        ref: _ctx.$vm.refValidationMetricsGrid,
        pagination: false,
        rowModelType: _ctx.rowModelType,
        loadData: _ctx.$vm.loadMetricsData,
        onRowSelected: _cache[2] || (_cache[2] = (event) => _ctx.$vm.changeMetricsToolItem(event)),
        autosizeColumnsMode: _ctx.autosizeColumnsMode,
        unCheckablePredicate: _ctx.$vm.selectDisabel
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_template_column, {
            id: "number",
            name: "number",
            dataPropertyName: "validationMetrics",
            headerText: "编码",
            align: "center"
          }, {
            default: _withCtx(({ cellValue,row }) => [
              _createElementVNode("a", {
                href: "javascript:void(0)",
                onClick: () => _ctx.$vm.openMetricsTab(row)
              }, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(cellValue.number), 1)
              ], 8, _hoisted_4)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "title",
            name: "title",
            dataPropertyName: "validationMetrics.title",
            headerText: "标题",
            readonly: false,
            align: "center"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "breakdown",
            name: "breakdown",
            dataPropertyName: "breakdown",
            headerText: "所属需求",
            align: "center"
          }, {
            default: _withCtx(({ cellValue}) => [
              cellValue
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(cellValue.title), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_7, "- -"))
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_combo_box_column, {
            id: "type",
            name: "type",
            dataPropertyName: "validationMetrics.type",
            headerText: "指标类型",
            readonly: false,
            options: _ctx.$vm.validationMetricsTypeOptions,
            filterOption: _ctx.$vm.filterOption,
            align: "center",
            width: "120"
          }, null, 8, ["options", "filterOption"]),
          _createVNode(_component_k_data_grid_check_box_column, {
            id: "isGeneral",
            name: "isGeneral",
            dataPropertyName: "validationMetrics.isGeneral",
            headerText: "通用指标",
            align: "center",
            width: "120"
          }),
          _createVNode(_component_k_data_grid_combo_box_column, {
            id: "executor",
            name: "executor",
            dataPropertyName: "validationMetrics.executor",
            headerText: "执行者",
            options: _ctx.$vm.userList,
            filterOption: _ctx.$vm.filterOption,
            align: "center"
          }, null, 8, ["options", "filterOption"]),
          (_ctx.showToolStrip)
            ? (_openBlock(), _createBlock(_component_k_data_grid_template_column, {
                key: 0,
                id: "result",
                name: "result",
                dataPropertyName: "verificationPlanAndMetrics.result",
                headerText: "验证结果",
                readonly: "true",
                align: "center",
                width: "150"
              }, {
                default: _withCtx(({ cellValue }) => [
                  _createVNode(_component_k_tag, {
                    color: _ctx.validationResultColor[cellValue || 'NOT_EXECUTED'],
                    style: {"flex":"1","text-align":"center","width":"56px","max-width":"60px"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.EnumValidationResult[cellValue || 'NOT_EXECUTED']), 1)
                    ]),
                    _: 2
                  }, 1032, ["color"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.showToolStrip)
            ? (_openBlock(), _createBlock(_component_k_data_grid_template_column, {
                key: 1,
                id: "planTitle",
                name: "planTitle",
                dataPropertyName: "plan.title",
                headerText: "关联验证计划",
                readonly: false,
                align: "left"
              }, {
                default: _withCtx(({ cellValue,row }) => [
                  _createElementVNode("a", {
                    href: "javascript:void(0)",
                    onClick: () => _ctx.$vm.openPlanTab(row.plan)
                  }, [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(cellValue), 1)
                  ], 8, _hoisted_8)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "description",
            name: "description",
            dataPropertyName: "validationMetrics.description",
            headerText: "描述",
            readonly: false,
            align: "center"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "creator",
            name: "creator",
            dataPropertyName: "validationMetrics",
            headerText: "指标创建者",
            align: "center"
          }, {
            default: _withCtx(({ cellValue}) => [
              _createTextVNode(_toDisplayString(cellValue.creator?.split(' ')[0]), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "createTime",
            name: "createTime",
            dataPropertyName: "validationMetrics",
            headerText: "指标创建时间",
            align: "center"
          }, {
            default: _withCtx(({ cellValue}) => [
              _createTextVNode(_toDisplayString(cellValue.createTime), 1)
            ]),
            _: 1
          }),
          (_ctx.showToolStrip)
            ? (_openBlock(), _createBlock(_component_k_data_grid_template_column, {
                key: 2,
                id: "action",
                name: "action",
                fixed: "right",
                align: "center",
                headerText: "操作",
                width: "auto"
              }, {
                default: _withCtx(({row}) => [
                  _createElementVNode("a", {
                    class: "row-operation-item",
                    onClick: (e) => _ctx.$vm.openMetricsTab(row)
                  }, "查看", 8, _hoisted_10),
                  _createElementVNode("a", {
                    class: _normalizeClass(!row.verificationPlanAndMetrics && 'no-cilck'),
                    onClick: (e) => _ctx.$vm.editResult(row)
                  }, " 修改验证结果 ", 10, _hoisted_11)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["rowModelType", "loadData", "autosizeColumnsMode", "unCheckablePredicate"])
    ])
  ], 512))
}