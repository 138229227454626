import {
  BaseViewModel,
  EnumDialogResult,
  KDialog,
  KDialogClosingEvent,
  KDataGridViewModel,
  KNotification,
  MemoryCacheFactory,
  SelectOption,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KValidationBoardEmitsType, KValidationBoardPropType } from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode, EnumValidationMetrics, EnumVerification, ObjectClientSrv, UserInfoCache } from '@kmsoft/ebf-common'
import { KEditForm, KEditFormViewModel } from './edit-form'

/** KValidationBoard */
export default class KValidationBoardViewModel extends BaseViewModel<KValidationBoardEmitsType, KValidationBoardPropType> {
  selectProductValue = ref<string>()
  productOption = ref<Array<SelectOption>>([])
  refValidationMetricsGrid = ref<KDataGridViewModel>()
  /** 指标类型候选值 */
  validationMetricsTypeOptions = ref<Array<SelectOption>>([])
  /** 验证类型候选值 */
  verificationPlanTypeOptions = ref<Array<SelectOption>>(EnumVerification._list)
  hasData = ref<boolean>(false)
  filterType = ref<string>('')
  validationResultOptions = ref<any[]>([
    { value: 'NOT_EXECUTED', label: '未执行' },
    { value: 'EXECUTING', label: '执行中' },
    { value: 'BLOCKED', label: '阻塞' },
    { value: 'SUCCESS', label: '成功' },
    { value: 'FAILED', label: '失败' }
  ])
  validationResultTypeTitle = ref<Record<string, any>>({
    text: '',
    left: 'center',
    top: 'center',
    textStyle: {
      fontSize: 28,
      color: '#000'
    },
    subtextStyle: {
      fontSize: 14,
      color: 'gray'
    }
  })
  legend = ref<Record<string, any>>({
    orient: 'vertical',
    right: 'right',
    bottom: 20
  })
  validationResultSeries = ref<Record<string, any>[]>([
    {
      type: 'pie',
      label: {
        position: 'inside',
        formatter: function(e: any) {
          const data = e.data
          if (data.value == 0) {
            return ''
          } else {
            return data.value
          }
        }
      },
      data: [
        { value: 0, name: '未执行', type: 'NOT_EXECUTED', itemStyle: { color: '#3366cc' } },
        { value: 0, name: '执行中', type: 'EXECUTING', itemStyle: { color: '#0099ff' } },
        { value: 0, name: '阻塞', type: 'BLOCKED', itemStyle: { color: '#ffcc00' } },
        { value: 0, name: '成功', type: 'SUCCESS', itemStyle: { color: '#52C41A' } },
        { value: 0, name: '失败', type: 'FAILED', itemStyle: { color: '#FF4C4C' } }
      ]
    }
  ])
  /** 人员列表 */
  userList = ref<Record<string, any>[]>([])
  constructor(options: ViewModelOptions<KValidationBoardPropType>) {
    super(options)
  }

  viewDidMount() {
    this.getUserData()
    this.getProductNeeds()
  }

  async getList() {
    const list = await ObjectClientSrv.getPropertyList('ValidationMetrics', 'Type')
    this.validationMetricsTypeOptions.value = list
  }

  getTypeLabel(value: string) {
    const data = this.validationMetricsTypeOptions.value.find(item => item.value == value)
    return data?.label || '--'
  }

  getUserData() {
    const cacheInstance = MemoryCacheFactory.get<UserInfoCache>(UserInfoCache.cacheKey)
    this.userList.value = cacheInstance.getUserOptionList()
  }

  async getProductNeeds() {
    const result = await Api.post('requirement', 'ProductNeeds', 'listAll', {
      data: []
    })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.productOption.value = result.data.map((item: any) => {
        return {
          label: `${item.title}(${item.number})`,
          value: item.id
        }
      })
    }
  }

  async queryData() {
    if (!this.selectProductValue.value) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择产品需求'
      })
      return
    }
    this.filterType.value = ''
    this.refValidationMetricsGrid.value?.refresh()
  }

  /** 加载指标网格 */
  async loadData() {
    if (!this.selectProductValue.value) {
      return []
    }
    await this.getList()
    const params = {
      data: [this.selectProductValue.value]
    }
    const result = await Api.post('requirement', 'ValidationMetrics', 'listByProductNeedsId', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.setSeriesData(result.data)
      const list = result.data.filter((item: any) => {
        return (
          !this.filterType.value ||
          item.result == this.filterType.value ||
          (!item.result && this.filterType.value == 'NOT_EXECUTED')
        )
      })
      return list
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取验证指标失败',
        details: result.detail
      })
      return []
    }
  }

  setSeriesData(data: any) {
    const result = data
    this.hasData.value = result.length > 0
    this.validationResultSeries.value[0].data.forEach((item: any) => {
      item.value = result.filter((r: any) => r.result == item.type || (item.type == 'NOT_EXECUTED' && !r.result)).length
    })
  }

  filterOption(inputValue: string, option: any): boolean {
    return option.label?.includes(inputValue)
  }

  chartClick(param: any) {
    this.filterType.value = param.data.type
    this.refValidationMetricsGrid.value?.refresh()
  }

  edit(row: any) {
    if (!row.plan) {
      return
    }
    KDialog.show({
      title: '编辑验证结果',
      size: { width: 400, height: 200 },
      props: {
        formData: { id: row.id, result: row.result }
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KEditForm,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const formViewModel = event.viewModel as KEditFormViewModel
        const formData = formViewModel.getValue()
        if (!formData) {
          KNotification.warn({
            message: '系统提示',
            description: '验证结果不能为空'
          })
          event.cancel = true
          return
        }
        const params = {
          data: [[{ id: row.id, result: formData }]]
        }
        const result = await Api.post('requirement', 'ValidationMetrics', 'switchVerificationPlanAndMetricsResult', params)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.filterType.value = ''
          this.refValidationMetricsGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '系统提示',
            content: result.message || '修改失败',
            details: result.detail
          })
          event.cancel = true
        }
      }
    })
  }

  /** 打开指标对象 */
  async openTab(row: any) {
    const param = {
      id: row?.validationMetrics.id,
      modelCode: row!.validationMetrics.rdmExtensionType,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row?.validationMetrics.rdmExtensionType!)
    }
    ObjectClientSrv.openObj(param)
  }

  /** 打开计划对象 */
  async openPlanTab(row: any) {
    const param = {
      id: row?.plan.id,
      modelCode: row!.plan.rdmExtensionType,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row?.plan.rdmExtensionType!)
    }
    ObjectClientSrv.openObj(param)
  }

  /** 打开计划对象 */
  async openRequirementTab(row: any) {
    const param = {
      id: row?.id,
      modelCode: row!.rdmExtensionType,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row?.rdmExtensionType!)
    }
    ObjectClientSrv.openObj(param)
  }
}
