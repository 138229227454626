import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KBasePropertyEditorPropOptions, KBasePropertyEditorEventEmits } from '../../base'

/** 参数 **/
export const KLifecycleStateEditorPropOptions = {
  ...KBasePropertyEditorPropOptions,
  value: VuePropTypes.createType<any>().def(),
  readonly: VuePropTypes.bool().def(false),
  disabled: VuePropTypes.bool().def(false),
  propertyName: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KLifecycleStateEditorPropType = ViewPropsTypeExtract<typeof KLifecycleStateEditorPropOptions>

/** 事件 */
export const KLifecycleStateEditorEventEmits = {
  ...KBasePropertyEditorEventEmits
}

/** 事件类型 **/
export type KLifecycleStateEditorEmitsType = ViewEmitsTypeExtract<typeof KLifecycleStateEditorEventEmits>
