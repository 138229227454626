import {
  BaseViewModel,
  EnumDialogResult,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  SimpleViewModel,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KPromoteActivityAfterGridEmitsType, KPromoteActivityAfterGridPropType } from './interface'
import { ref, watch } from 'vue'
import {
  Api,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  EnumRequestCode,
  KObjectSelectorViewModel,
  ObjectClientSrv,
  EnumWorkingState,
  EnumLifecycleState
} from '@kmsoft/ebf-common'
import lodash from 'lodash'
import {
  KIssueReportRelationPart,
  KIssueReportRelationPartViewModel
} from '../issue-report-affected-views/issue-report-relation-part'
import {
  KIssueReportRelationDoc,
  KIssueReportRelationDocViewModel
} from '../issue-report-affected-views/issue-report-relation-doc'

/** KChangeActivityObjGrid */
export default class KChangeActivityObjGridViewModel extends SimpleViewModel<
  KPromoteActivityAfterGridEmitsType,
  KPromoteActivityAfterGridPropType
> {
  gridData = ref<any[]>([])
  refDataGrid = ref<KDataGridViewModel>()
  constructor(options: ViewModelOptions<KPromoteActivityAfterGridPropType>) {
    super(options)
  }

  viewDidMount() {}

  public setValue(newValue: any, setChanged?: boolean) {
    this.gridData.value = lodash.cloneDeep(newValue)
  }

  // 根据masterId检查是否有重复数据
  checkRepeat(selectData: Record<string, any>[]) {
    let hasRepeat = false
    const selectMasterId = selectData.map(item => {
      if (!hasRepeat) {
        hasRepeat = this.gridData.value.some(data => data.targetMasterId == item.master.id || data?.master?.id == item.master.id)
      }
      return item.master.id
    })
    if (selectMasterId.length != new Set(selectMasterId).size) {
      KNotification.warn({
        message: '系统提示',
        description: '选择数据行中存在重复数据'
      })
      return true
    }
    if (hasRepeat) {
      KNotification.warn({
        message: '系统提示',
        description: '选择数据行中存在已添加的数据'
      })
      return true
    }
    return false
  }

  addGridData(gridData: any[]) {
    const addDataList = this.gridDataFilter(gridData)
    this.gridData.value.push(...addDataList)
    this.refresh()
  }

  gridDataFilter(gridData: any[]) {
    // 去重
    const addDataList =
      gridData.map((item: any) => {
        // 版本
        item.versionInfo = `${item.version}.${item.iteration}`
        // 状态
        item.state = item.lifecycleStateCode ? EnumLifecycleState[item.lifecycleStateCode] : '--'
        // 检入标记
        item.checkInState = item.workingState ? EnumWorkingState[item.workingState as keyof typeof EnumWorkingState] : '--'
        return item
      }) || []

    return addDataList
  }

  /** 移除 **/
  removeData() {
    const selectedRows = this.refDataGrid.value?.getSelectedRows() || []
    if (selectedRows.length > 0) {
      this.gridData.value = this.gridData.value.filter(item => !selectedRows.some(row => row.id === item.id))
    }
  }
  /** 刷新 **/
  refresh() {}
  /** 相关零部件 **/
  async relatedPart() {
    const rows = this.refDataGrid.value?.getSelectedRows() || []
    if (rows && rows.length !== 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    const className = await ObjectClientSrv.getModelGroupByCode(rows[0].rdmExtensionType!)
    if (className !== 'part') {
      KNotification.warn({
        message: '系统提示',
        description: '只能选择零部件行'
      })
      return
    }

    KDialog.show({
      title: '相关零部件',
      size: { width: 1200, height: 800 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KIssueReportRelationPart,
      props: {
        objParam: {
          id: rows[0].id,
          rdmExtensionType: rows[0].rdmExtensionType,
          modelCode: 'ChangeIssue',
          modelGroup: 'change'
        }
      },
      extraButtons: ['添加'],
      onClosing: async (event: KDialogClosingEvent) => {
        const selectorViewModel = event.viewModel as KIssueReportRelationPartViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        // 根据viewModel获取到当前选中的数据
        const selectedRows = selectorViewModel.getSelectedRows()

        const filterReleasedRows = this.filterReleasedData(selectedRows, true)
        if (filterReleasedRows.length == 0) {
          return
        }

        this.addGridData(filterReleasedRows)

        //刷新重新渲染
        this.refresh()

        if (event.dialogResult === '添加') {
          event.cancel = true
        }
      }
    })
  }
  /** 相关文档 **/
  relatedDocument() {
    const rows = this.refDataGrid.value?.getSelectedRows() || []
    if (rows && rows.length !== 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    KDialog.show({
      title: '相关文档',
      size: { width: 1000, height: 600 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KIssueReportRelationDoc,
      props: {
        objParam: {
          id: rows[0].id,
          rdmExtensionType: rows[0].rdmExtensionType,
          modelCode: 'ChangeIssue',
          modelGroup: 'change'
        }
      },
      onClosing: async (event: KDialogClosingEvent) => {
        const selectorViewModel = event.viewModel as KIssueReportRelationDocViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        // 根据viewModel获取到当前选中的数据
        const selectedRows = selectorViewModel.getSelectedRows()

        const filterReleasedRows = this.filterReleasedData(selectedRows, true)
        if (filterReleasedRows.length == 0) {
          return
        }

        this.addGridData(filterReleasedRows)

        //刷新重新渲染
        this.refresh()
      }
    })
  }

  isModified(): boolean {
    // 编辑时默认认为修改
    return false
  }

  getModifiedValue() {
    return this.getValue()
  }

  public getValue() {
    return this.gridData.value || []
  }

  filterReleasedData(selectedRows: any, showWarn: boolean) {
    const filterReleasedRows: Array<Record<string, any>> = []
    if (selectedRows != null && selectedRows.length > 0) {
      selectedRows.forEach((item: any) => {
        if (item.lifecycleStateCode == 'Released') {
          filterReleasedRows.push(item)
        }
      })
    }

    if (filterReleasedRows.length == 0 && showWarn) {
      KNotification.warn({
        message: '系统提示',
        description: '选择数据行中没有发布数据'
      })
      return []
    }
    return filterReleasedRows
  }
  /**
   * 打开新标签页展示对象
   */
  async openObjTab(row: any) {
    const param = {
      id: row?.targetId || row.id,
      modelCode: row!.targetExtensionType || row.rdmExtensionType,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.targetExtensionType || row.rdmExtensionType)
    }
    ObjectClientSrv.openObj(param)
  }

  getView(row: any) {
    if (row.partView?.description) {
      return '(' + row.partView?.description + ')'
    }
    return ''
  }
}
