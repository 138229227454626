import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5455b7bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "full requirement-obj-manage" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { style: {"margin-left":"5px"} }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_tool_strip = _resolveComponent("k-object-tool-strip")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_lifecycle_state_tag = _resolveComponent("k-lifecycle-state-tag")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_object_tool_strip, {
      ref: "refToolStripItem",
      items: _ctx.$vm.requirementChangeRequestToolStripItems,
      onItemClicked: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onRequirementChangeRequestToolStripItemClicked(event))
    }, null, 8, ["items"]),
    _createVNode(_component_k_data_grid, {
      class: "requirement-change-request-grid",
      rowKey: "id",
      name: "refContainLinkDataGrid",
      ref: _ctx.$vm.refChangeRequestDataGrid,
      pagination: false,
      rowModelType: _ctx.rowModelType,
      loadData: _ctx.$vm.loadRequestChageData,
      autosizeColumnsMode: _ctx.autosizeColumnsMode,
      onRowSelected: _ctx.$vm.onRowSelected
    }, {
      columns: _withCtx(() => [
        _createVNode(_component_k_data_grid_template_column, {
          id: "rdmExtensionType",
          name: "rdmExtensionType",
          dataPropertyName: "rdmExtensionType",
          headerText: "实体类型",
          align: "left"
        }, {
          default: _withCtx(({ cellValue}) => [
            _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_k_data_grid_template_column, {
          id: "number",
          name: "number",
          dataPropertyName: "number",
          headerText: "编码",
          align: "left"
        }, {
          default: _withCtx(({ cellValue,row }) => [
            _createElementVNode("a", {
              href: "javascript:void(0)",
              onClick: () => _ctx.$vm.openRequestChange(row)
            }, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(cellValue), 1)
            ], 8, _hoisted_2)
          ]),
          _: 1
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          id: "title",
          name: "title",
          dataPropertyName: "title",
          headerText: "标题",
          readonly: false,
          align: "left"
        }),
        _createVNode(_component_k_data_grid_template_column, {
          id: "lifecycleState",
          name: "lifecycleState",
          dataPropertyName: "lifecycleState",
          headerText: "生命周期状态",
          width: "120",
          readonly: "true",
          align: "center"
        }, {
          default: _withCtx(({ row }) => [
            _createVNode(_component_k_lifecycle_state_tag, { row: row }, null, 8, ["row"])
          ]),
          _: 1
        }),
        _createVNode(_component_k_data_grid_template_column, {
          id: "needDate",
          name: "needDate",
          dataPropertyName: "needDate",
          headerText: "需要日期",
          align: "left"
        }, {
          default: _withCtx(({ cellValue}) => [
            cellValue
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(cellValue.split(' ')[0]), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_5, " - - "))
          ]),
          _: 1
        }),
        _createVNode(_component_k_data_grid_template_column, {
          headerText: "负责人",
          id: "head",
          name: "head",
          dataPropertyName: "head",
          options: _ctx.$vm.userList,
          align: "left"
        }, {
          default: _withCtx(({ cellValue }) => [
            cellValue
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$vm.getUserName(cellValue)), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_7, "--"))
          ]),
          _: 1
        }, 8, ["options"]),
        _createVNode(_component_k_data_grid_template_column, {
          id: "createTime",
          name: "createTime",
          dataPropertyName: "createTime",
          headerText: "创建时间",
          align: "left"
        }, {
          default: _withCtx(({ cellValue}) => [
            cellValue
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(cellValue), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_9, " - - "))
          ]),
          _: 1
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          id: "creator",
          name: "creator",
          dataPropertyName: "creator",
          headerText: "创建人",
          align: "left"
        }),
        _createVNode(_component_k_data_grid_template_column, {
          id: "action",
          name: "action",
          fixed: "right",
          align: "left",
          headerText: "操作",
          width: "auto"
        }, {
          default: _withCtx(({row}) => [
            _createElementVNode("a", {
              class: "row-operation-item",
              onClick: (e) => _ctx.$vm.openRequestChange(row)
            }, "查看", 8, _hoisted_10)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["rowModelType", "loadData", "autosizeColumnsMode", "onRowSelected"])
  ]))
}