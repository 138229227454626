import { BaseViewModel, KSelectViewModel, ViewModelOptions, KInputViewModel } from '@kmsoft/upf-core'
import { KLifecycleStateEditorEmitsType, KLifecycleStateEditorPropType } from './interface'
import { ref, watch } from 'vue'
import { EnumLifecycleState } from '../../../../index'
import { ObjectClientSrv } from '../../../../client-srv/object/ObjectClientSrv'
import { EnumEditorEnvironment, KBasePropertyEditorViewModel } from '../../base'

/** KLifecycleStateEditor */
export default class KLifecycleStateEditorViewModel extends KBasePropertyEditorViewModel<
  KLifecycleStateEditorEmitsType,
  KLifecycleStateEditorPropType
> {
  refSelect = ref<KSelectViewModel>()
  stateValue = ref<string>()
  originValue = ref<string>()
  stateReadonly = ref<boolean>(false)
  hasValue = ref<boolean>(true)
  constructor(options: ViewModelOptions<KLifecycleStateEditorPropType>) {
    super(options)
    watch(
      () => options.props.value,
      () => {
        this.stateValue.value = options.props.value
      },
      {
        deep: true,
        immediate: true
      }
    )
    watch(
      () => options.props.readonly,
      newValue => {
        this.stateReadonly.value = newValue
      },
      {
        deep: true,
        immediate: true
      }
    )
  }

  viewDidMount() {}
  getModifiedValue() {
    return this.stateValue.value
  }
  getValue() {
    return this.stateValue.value
  }
  setValue(value: string, setChange?: boolean) {
    this.stateValue.value = value
    if (setChange) {
      this.originValue.value = value
    }
  }
  isModified() {
    return this.originValue.value !== this.stateValue.value
  }
  public displayValue() {
    const api = this.props.api
    const data = api?.getValue()
    let displayValue = '--'
    let lifecycleState = null
    let lifecycleStateCode = null
    const propertyName = this.props.propertyName
    if (data && Object.keys(data).length) {
      const properties = propertyName?.split('.')
      let lifecycleData = null
      if (properties && properties!.length > 1) {
        lifecycleData = properties.slice(0, -1).reduce((acc, key) => acc?.[key], data)
        lifecycleState = lifecycleData.lifecycleState
        lifecycleStateCode = lifecycleData.lifecycleStateCode
      } else {
        lifecycleState = data.lifecycleState
        lifecycleStateCode = data.lifecycleStateCode
      }

      if (lifecycleState) {
        this.setValue(lifecycleState.descriptionEn)
        displayValue = lifecycleState.name
      } else {
        const options = EnumLifecycleState._list
        const value = lifecycleStateCode
        displayValue = options.find((item: { value: any }) => item.value === value)?.label
      }
    } else {
      return
    }
    this.hasValue.value = displayValue != '--'
    return displayValue
  }
  lifecycleStateTags() {
    const api = this.props.api
    const data = api.getValue()
    let lifecycleState = null
    let lifecycleStateCode = null
    const propertyName = this.props.propertyName
    if (data && Object.keys(data).length) {
      const properties = propertyName?.split('.')
      let lifecycleData = null
      if (properties && properties!.length > 1) {
        lifecycleData = properties.slice(0, -1).reduce((acc, key) => acc?.[key], data)
        lifecycleState = lifecycleData.lifecycleState
        lifecycleStateCode = lifecycleData.lifecycleStateCode
      } else {
        lifecycleState = data.lifecycleState
        lifecycleStateCode = data.lifecycleStateCode
      }
      if (lifecycleState) {
        const phase = ObjectClientSrv.getLifeCycleStatePhase(lifecycleState.internalName)
        return phase
      } else {
        return lifecycleStateCode
      }
    }
    return ''
  }
}
