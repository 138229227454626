import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  KSchema,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KDataElementTree, KDesignerControl, KBizContext } from '../types'
import { DEFAULT_BIZ_CONTEXT } from '../config'

/** 事件 **/
export const KLayoutDesignerEventEmits = {
  ...BaseViewEventEmits
}

/** 参数 **/
export const KLayoutDesignerPropOptions = {
  ...BaseViewPropOptions,
  /** 标题 */
  title: VuePropTypes.string().def(''),
  /** 布局方案 */
  schema: VuePropTypes.createType<KSchema>().def({} as KSchema),
  /** 控件集合 */
  designerMetaControl: VuePropTypes.createType<Array<KDesignerControl>>().def([] as Array<KDesignerControl>),
  /** 业务上下文*/
  bizContext: VuePropTypes.func<() => KBizContext>().def(() => DEFAULT_BIZ_CONTEXT),
  /** 数据元素 */
  dataSource: VuePropTypes.createType<KDataElementTree>().def(),
  /** 是否显示基础组件 */
  showBaseMetaControl: VuePropTypes.bool().def(false),
  /**所属类型*/
  belongClsCode: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KLayoutDesignerPropType = ViewPropsTypeExtract<typeof KLayoutDesignerPropOptions>
/** 事件协议 */
export type KLayoutDesignerEventEmitsTypes = ViewEmitsTypeExtract<typeof KLayoutDesignerEventEmits>
