import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KObjectClassTreePropOptions } from '../object-class-tree'
import lodash from 'lodash'
import { QueryCondition } from '../../client-srv'

/** 参数 **/
export const KObjectWorkspacePropOptions = {
  ...KObjectClassTreePropOptions,
  /** 默认对象编码 */
  modelCode: VuePropTypes.string().def(''),
  /** 启用`包含子类`勾选框 */
  includeChildrenCheck: VuePropTypes.bool().def(false),
  /** 搜索状态, 处于搜索状态将禁用底部按钮 */
  isSearching: VuePropTypes.bool().def(false),
  /** 是否显示搜索 */
  showSearch: VuePropTypes.bool().def(false),
  /** 是否显示取消 */
  showCancel: VuePropTypes.bool().def(false),
  /** 设置要显示的对象id集合,包含子节点 */
  showObjClsCodes: VuePropTypes.array<string>().def([]),
  /** 是否启用网格多选 */
  isMultipleSelection: VuePropTypes.bool().def(true),
  /** 要查询的状态 */
  showLifecycleState: VuePropTypes.createType<Record<string, string[]>>().def(),
  /**文档类型 */
  documentType: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KObjectWorkspacePropType = ViewPropsTypeExtract<typeof KObjectWorkspacePropOptions>

/** 事件 */
export const KObjectWorkspaceEventEmits = {
  ...BaseViewEventEmits,
  /** 对象类Id改变事件 */
  'update:modelCode': (value: string) => true,
  /** 此方法会在点击查询后被调用 */
  search: (condition: QueryCondition): boolean => true,
  /** 点击取消按钮 */
  cancel: () => true
}

/** 事件类型 **/
export type KObjectWorkspaceEmitsType = ViewEmitsTypeExtract<typeof KObjectWorkspaceEventEmits>
