import { connect, withInstall } from '@kmsoft/upf-core'
import KAllCustomerNeedsView from './KAllCustomerNeeds.vue'
import KAllCustomerNeedsViewModel from './KAllCustomerNeedsViewModel'

const KAllCustomerNeeds = connect(KAllCustomerNeedsView, KAllCustomerNeedsViewModel)

export default withInstall(KAllCustomerNeeds)
export { KAllCustomerNeeds, KAllCustomerNeedsView, KAllCustomerNeedsViewModel }

// 模板生成文件
// export * from './{{folderName}}'
