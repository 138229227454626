/* eslint-disable no-async-promise-executor */
import {
  Api,
  ClipBoard,
  CommonClientSrv,
  EnumClipBoarbType,
  EnumClipBoardDataType,
  EnumRequestCode,
  FolderTypeEnum,
  KFolderSelector,
  KFolderSelectorViewModel,
  ObjBusinessParam,
  ObjectClientSrv
} from '@kmsoft/ebf-common'
import {
  EnumDialogResult,
  IKTreeNode,
  KDataGridRowData,
  KDialog,
  KDialogClosingEvent,
  KIcon,
  KModal,
  KNotification,
  VNodeProps
} from '@kmsoft/upf-core'
import { createVNode, resolveComponent } from 'vue'
import { ChangeManageClientSrv } from '../../../../ebf-change-manage/src/client-srv'
import { DocClientSrv } from '../../../../ebf-doc/src/client-srv'
import { WorkflowClientSrv } from '../../../../ebf-workflow/src'
import { KPartStructureManage, KPartStructureManagePropType } from '../../controls'
import { KPartMaterialBomForm, KPartMaterialBomFormViewModel } from '../../controls/part-material-bom-form'
import { KPartMaterialForm, KPartMaterialFormViewModel } from '../../controls/part-material-form'
import { PartStructureTreeNode } from '../../controls/part-structure-tree/nodes'
import KPartUsingStatistics from '../../controls/part-using-statistics'
import { KPartReviseForm, KPartReviseFormViewModel } from '../../pages/part-manage/part-revise-form'
import { KPartSaveAsForm, KPartSaveAsFormViewModel } from '../../pages/part-manage/part-saveas-form'
import { EnumPartNodeChildExpandMode, EnumPartViewModeConstDef, PartStructureNodeParam } from './types'
import {
  DocEchoGridDataInner,
  EnumOperateType,
  EnumOperateOptions
} from '../../../../ebf-doc/src/controls/doc-echo-list-panel/interface'
import { KDocEchoListPanel, KDocEchoListPanelViewModel } from '../../../../ebf-doc/src/controls/doc-echo-list-panel'

export class PartClientSrv {
  /**
   * 根据查询规则匹配展开方式
   * @param type 查询规则
   * @returns 展开方式
   */
  static getPartNodeChildExpandModeByRuleType(type: string) {
    switch (type) {
      case 'LATEST':
        return EnumPartNodeChildExpandMode.LatestVersion
      default:
        break
    }
  }
  /**
   * 获取展开模式
   * @param viewName
   * @param isBaseLine
   * @returns
   */
  static getPartNodeChildExpandMode(viewName?: string, isBaseLine: boolean = false): EnumPartNodeChildExpandMode {
    if (isBaseLine) {
      return EnumPartNodeChildExpandMode.LatestVersion
    }

    switch (viewName) {
      case EnumPartViewModeConstDef.StructTabView:
        return EnumPartNodeChildExpandMode.Default
      case EnumPartViewModeConstDef.LatestView:
        return EnumPartNodeChildExpandMode.LatestVersion
      case EnumPartViewModeConstDef.LatestPublishedView:
        return EnumPartNodeChildExpandMode.LatestPublishVersion
      case EnumPartViewModeConstDef.Baseline:
        return EnumPartNodeChildExpandMode.Baseline
      case EnumPartViewModeConstDef.OtherBaseline:
        return EnumPartNodeChildExpandMode.OtherBaseline
      default:
        return EnumPartNodeChildExpandMode.OtherRules
    }
  }

  static getPartViewModeViewName(mode: EnumPartNodeChildExpandMode) {
    switch (mode) {
      case EnumPartNodeChildExpandMode.Default:
        return EnumPartViewModeConstDef.StructTabView
      case EnumPartNodeChildExpandMode.LatestVersion:
        return EnumPartViewModeConstDef.LatestView
      case EnumPartNodeChildExpandMode.LatestPublishVersion:
        return EnumPartViewModeConstDef.LatestPublishedView
      default:
        return EnumPartViewModeConstDef.StructTabView
    }
  }

  //#region 复制相关
  /**
   * 父节点是否相同
   * @param nodes
   * @returns
   */
  static isSameParent(nodes: Array<PartStructureTreeNode>): (string | undefined)[] {
    const parentIds = nodes.map(m => {
      return m.parentPartObjParam.id
    })
    return Array.from(new Set(parentIds))
  }

  /**
   * 是否可复制
   * @param nodes
   * @returns
   */
  static isCanCopy(nodes: Array<PartStructureTreeNode>): boolean {
    let flg = true
    for (let index = 0; index < nodes.length; index++) {
      if (!this.__isCanCopy(nodes[index])) {
        KDialog.error({ title: '提示', content: nodes[index].name + '节点不能复制' })
        flg = false
        break
      }
    }
    return flg
  }

  /**
   * 判断节点是否可复制
   * @param node
   * @returns
   */
  static __isCanCopy(node: PartStructureTreeNode) {
    if (!(node instanceof PartStructureTreeNode)) {
      return false
    }

    /** root为根节点 */
    const isRootNode: boolean = node.id == 'root' ? true : false

    return !node.isSwap && !isRootNode
  }

  /**
   * 是否可粘贴
   * @param nodes
   * @returns
   */
  static isCanCut(nodes: Array<PartStructureTreeNode>): boolean {
    let flg = true
    for (let index = 0; index < nodes.length; index++) {
      if (!this.__isCanCut(nodes[index])) {
        KDialog.error({ title: '提示', content: nodes[index].name + '节点不能剪切' })
        flg = false
        break
      }
    }
    return flg
  }

  /**
   * 是否可粘贴
   * @param node
   * @returns
   */
  static __isCanCut(node: PartStructureTreeNode) {
    if (!(node instanceof PartStructureTreeNode)) {
      return false
    }
    /** root为根节点 */
    const isRootNode: boolean = node.id == 'root' ? true : false
    return !node.isSwap && !isRootNode && (node.currentExtendMode == 0 || node.currentExtendMode == 3)
  }

  /**
   * 节点是否可粘贴
   */
  static isCanPaste(node: PartStructureTreeNode) {
    if (!(node instanceof PartStructureTreeNode)) {
      return false
    }
    return !node.isSwap && (node.currentExtendMode == 0 || node.currentExtendMode == 3)
  }
  //#endregion

  /**
   * 根据零部件结构树获取零部件对象
   * @param nodes
   * @returns
   */
  static getPartObjBusinessParam(nodes: Array<PartStructureTreeNode>): Array<PartStructureNodeParam> {
    const objParamReqModels = nodes.map(m => {
      return {
        objParam: m.partObjParam,
        structureId: m.id,
        nodeText: m.name,
        parentObjParam: m.parentPartObjParam,
        nodeKey: m.key,
        node: m
      }
    })
    return objParamReqModels
  }

  /**
   * 复制弹出消息
   * @param nodes
   * @returns
   */
  static clipBoardTips(nodes: Array<PartStructureTreeNode>, type: EnumClipBoarbType = EnumClipBoarbType.Copy) {
    let megInfo = ''

    nodes.forEach(element => {
      megInfo += '[' + element.name + ']'
    })

    const msg = type == EnumClipBoarbType.Copy ? '已复制' : '已剪切'

    KNotification.success({
      title: msg,
      content: msg + nodes.length + '条 --' + megInfo
    })
  }

  static async openPartStructManage(objParam: ObjBusinessParam) {
    // 如果参数不正确
    if (!objParam || !objParam.id || !objParam.modelCode) {
      return
    }

    /** 标签页标识 */
    const tabKey = `${objParam.id}#${objParam.modelCode}`

    /** 标题 */
    const title = `对象：${objParam.modelCode}`
    /** 面板参数 */
    const panelProps: VNodeProps<KPartStructureManagePropType> = { objParam: objParam }

    // 打开页面
    CommonClientSrv.openPage(title, () => resolveComponent('KPartStructureManage'), panelProps, tabKey)
  }

  /** 粘贴替换件 */
  static async pasteSwap(structureId: string) {
    if (!structureId) {
      KDialog.warning({ title: '提示', content: '请选择零部件' })
      return false
    }
    const flg = ClipBoard.contains(EnumClipBoardDataType.CommonDataObj)
    if (!flg) {
      KDialog.info({ title: '提示', content: '无可粘贴数据' })
      return
    }
    const data = ClipBoard.getData<Array<ObjBusinessParam>>(EnumClipBoardDataType.CommonDataObj)
    const dataObject = data?.dataObject!
    // const result = await PartStructureClientSrv.addChildSwapBatch(structureId, dataObject)
    // if (result.success) {
    //   KDialog.success({ title: '提示', content: '粘贴成功' })
    // } else {
    //   KDialog.warning({ title: '提示', content: result.errMsg! })
    // }
    // return result.success
  }

  /**
   * 通过查询添加零件
   * @param args 添加零部件参数
   * @param onSuccess 添加成功后回调
   */
  // static async addPartByQuery(args: AddPartParams, onSuccess?: EmptyAction): Promise<void> {
  //   const objectClassTreeProps: KObjectClassManageProps = {
  //     objClassTypes: [metaObjClsType.PART],
  //     defaultSelectClsId: args.partObjClsId
  //   }
  //   const isMultipleSelection = args.environment != EnumQueryObjEnvironment.ChangeChildPart
  //   const eventArgs: PartAddQueryEvent = {
  //     ...args,
  //     objectClassTreeProps
  //   }

  //   await EventFunctionManager.triggerEvent(EnumEventFunctionName.CPartAddByQueryBefore, eventArgs)

  //   if (eventArgs.cancel === true) {
  //     return
  //   }

  //   let objParam = {
  //     objClsID: args.partObjClsId!,
  //     objID: args.partId!
  //   }

  //   let title = ''
  //   if (args.environment == EnumQueryObjEnvironment.InsertChildPart) {
  //     title = '添加子零部件'
  //   } else if (args.environment == EnumQueryObjEnvironment.InsertPChildPart) {
  //     title = '添加兄零部件'
  //   } else if (args.environment == EnumQueryObjEnvironment.InsertYoungBrotherPart) {
  //     title = '添加弟零部件'
  //   } else if (args.environment == EnumQueryObjEnvironment.ChangeChildPart) {
  //     title = '更换零部件'
  //   } else if (args.environment == EnumQueryObjEnvironment.InsertChildSwap) {
  //     title = '添加替换件'
  //   } else {
  //     title = '查找零部件'
  //   }

  //   const objSelectIterator = await ObjectClientSrv.selectObjByQuery({
  //     supportedQueryTypes: EnumQueryType.ObjClsManagement | EnumQueryType.AdvancedSearch,
  //     objectClassManageProps: { ...objectClassTreeProps },
  //     objectSearchProps: { ...objectClassTreeProps },
  //     // recentOpenObjectGridProps: {
  //     //   objClsTypes: objectClassTreeProps.objClassTypes,
  //     //   showObjClsIds: objectClassTreeProps.showObjClsIds
  //     // },
  //     isMultipleSelection,
  //     allowNull: false,
  //     showApply: true,
  //     title: title
  //   })

  //   for await (const iteratorItem of objSelectIterator) {
  //     const selectedPartObjParms = iteratorItem.dialogValues
  //     const eventArg: PartAddSaveEvent = {
  //       ...args,
  //       newObjParams: selectedPartObjParms,
  //       cancel: false
  //     }

  //     await EventFunctionManager.triggerEvent(EnumEventFunctionName.CPartAddSaveBefore, eventArg)

  //     if (eventArg.cancel === true) {
  //       if (iteratorItem.isApply) continue
  //       else break
  //     }

  //     //#region 非替换零部件操作（添加子、兄、弟），需要过滤掉不合法的零部件参数
  //     const validPartObjParms = new Array<ObjBusinessParam>()

  //     if (
  //       args.environment == EnumQueryObjEnvironment.ChangeChildPart ||
  //       args.environment == EnumQueryObjEnvironment.InsertChildSwap
  //     ) {
  //       const selectedPartObjParm = selectedPartObjParms[0]
  //       //替换子零部件-查找
  //       if (objParam.objID == selectedPartObjParm.objID) {
  //         await commonClientSrv.alertAsync('更换零部件时选择了自己。请选择其他对象。')
  //         continue
  //       } else {
  //         validPartObjParms.push(selectedPartObjParm)
  //       }
  //     } else {
  //       for await (const selectedPartObjParm of selectedPartObjParms) {
  //         //添加子、兄、弟零部件-查找
  //         const tempResp = (await partStructureSrv.existPartByParentPartId({
  //           parentPartObjId: args.addNodePosition == EnumAddNodePosition.Sub ? args.partId : args.partParentId,
  //           partObjClsId: selectedPartObjParm.objClsID,
  //           partObjId: selectedPartObjParm.objID
  //         }))!

  //         if (!tempResp.existedChildPartId) {
  //           validPartObjParms.push(selectedPartObjParm)
  //         } else if (tempResp.existedChildPartId == selectedPartObjParm.objID) {
  //           const isOk = await commonClientSrv.confirmAsync(tempResp.message!)
  //           if (isOk) validPartObjParms.push(selectedPartObjParm)
  //         } else {
  //           await commonClientSrv.alertAsync(tempResp.message!)
  //         }
  //       }
  //     }
  //     //#endregion

  //     //如果没有合法的零部件参数，则忽略
  //     if (validPartObjParms.length == 0) {
  //       if (iteratorItem.isApply) continue
  //       else break
  //     }

  //     const saveResult = await PartStructureClientSrv.addPart({
  //       currentPartParam: {
  //         objClsID: args.partObjClsId,
  //         objID: args.partId
  //       },
  //       currStructureId: args.partStructureId,
  //       parentPartParam: {
  //         objClsID: args.partParentObjClsId,
  //         objID: args.partParentId
  //       },
  //       environment: args.environment,
  //       addNodePosition: args.addNodePosition,
  //       partObjParams: validPartObjParms as any
  //     })

  //     if (saveResult.success) {
  //       if (args.environment == EnumQueryObjEnvironment.ChangeChildPart) {
  //         objParam = selectedPartObjParms[0]
  //       }
  //       onSuccess?.()
  //       if (!iteratorItem.isApply) {
  //         break
  //       }
  //     } else {
  //       KDialog.info({
  //         content: saveResult.errMsg
  //       })
  //     }
  //   }
  // }

  /**
   * 移除替换件
   * @param structId
   * @param callBack
   */
  // static async removeChildSwap(structId: string) {
  //   const confirm = await commonClientSrv.confirmAsync('确定要移除选中的替换件吗？', async () => {
  //     //TODO call commonApi
  //     const result = await PartStructureClientSrv.removeChildSwap({ objcetId: structId })
  //     if (!result.success) {
  //       KDialog.warning({ title: '提示', content: result.errMsg! })
  //       return false
  //     }
  //     return true
  //   })
  //   return confirm
  // }

  /**
   * 检入零部件
   *
   * @returns 检入后的新对象
   */
  static async checkInPart(partId: string) {
    return new Promise((resolve, reject) => {
      KDialog.confirm({
        title: '确认检入吗？',
        onOk: async () => {
          // 获取对象id
          const result = await Api.post('part', 'Part', 'checkin', { data: [partId] })
          if (result.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '检入成功'
            })

            // 检入后的新对象
            const data = result.data
            resolve(data)
          } else {
            KNotification.error({
              title: '操作失败',
              content: result.message || '检入失败',
              details: result.detail
            })
            reject(void 0)
          }
        }
      })
    })
  }
  /**
   * 检出零部件
   *
   * @returns 检出后的副本对象
   */
  static async checkoutPart(partId: string) {
    return new Promise((resolve, reject) => {
      KDialog.confirm({
        title: '确认检出吗？',
        onOk: async () => {
          // 获取对象id
          const result = await Api.post('part', 'Part', 'checkout', { data: [partId] })
          if (result.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '检出成功'
            })

            // 检出后的副本对象
            const data = result.data
            resolve(data)
          } else {
            KNotification.error({
              title: '操作失败',
              content: result.message || '检出失败',
              details: result.detail
            })
            reject(void 0)
          }
        }
      })
    })
  }
  /**
   * 撤销检出零部件
   *
   * @returns 检出前的对象
   */
  static async undoCheckoutPart(partId: string) {
    return new Promise((resolve, reject) => {
      KDialog.confirm({
        title: '确认撤销检出吗？',
        onOk: async () => {
          // 获取对象id
          const result = await Api.post('part', 'Part', 'undoCheckout', { data: [partId] })
          if (result.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '撤销检出成功'
            })

            // 检出前的对象
            const data = result.data
            resolve(data)
          } else {
            KNotification.error({
              title: '操作失败',
              content: result.message || '撤销检出失败',
              details: result.detail
            })
            reject(void 0)
          }
        }
      })
    })
  }

  /**
   * 批量转通用件
   */
  static async batchConvertUniversalparts(parts: Array<KDataGridRowData>) {
    const objList = parts!.map(row => {
      return {
        oldFolderedLinkId: row.id,
        oldFolderId: row.targetFolderId,
        objId: row.targetBranchId,
        className: row.targetClass,
        branchId: row.targetBranchId
      }
    })

    KDialog.show({
      title: '转通用件',
      size: { width: 500, height: 200 },
      props: {
        showWorkspaceType: [FolderTypeEnum.COMMON_PARTS]
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KFolderSelector,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KFolderSelectorViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const folder = formViewModel.getValue() as any
        if (folder && folder.id === '') {
          KNotification.warn({
            message: '系统提示',
            description: '请选择文件夹'
          })
          event.cancel = true
          return
        }
        const result = await Api.post('folder', 'Folder', 'copyFolderObjLink', { data: [folder.id, objList] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '添加成功'
          })
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '添加失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 使用统计
   */
  static async usingStatisticsManage(partId: string) {
    // 获取对象
    const partData = await this.getPart(partId)
    const key = new Date().getTime().toString()

    CommonClientSrv.openPage(
      `零部件(${partData.partView?.description}):` + partData.name + ': 使用统计',
      KPartUsingStatistics,
      { objParam: { modelCode: partData.rdmExtensionType, modelGroup: 'part', id: partData.id } },
      partData.id + key
    )
  }

  /**
   * 发布零部件
   */
  static async releasePart(parts: Array<KDataGridRowData>) {
    return new Promise(async (resolve, reject) => {
      const partIds = parts!.map(row => row.targetBranchId)
      const param = parts.map(part => {
        return {
          objId: part.targetBranchId,
          objClsCode: part.targetExtensionType,
          name: part.name,
          number: part.number
        }
      })
      WorkflowClientSrv.checkWorkflowAndChangeManaged(param).then(res => {
        if (res) {
          const version = parts[0]?.version
          const lifecycleStateCode = parts[0]?.lifecycleStateCode
          if (version == 'A' && lifecycleStateCode == 'InWork') {
            // 如果是A版本并且生命周期状态是工作中，说明未取码
            KDialog.confirm({
              title: '该零件尚未取码，请确认是否仍要发布？',
              onOk: async () => {
                const result = await Api.post('part', 'Part', 'batchReleaseByBranch', { data: [partIds] })
                if (result && result.code == EnumRequestCode.SUCCESS) {
                  KNotification.success({
                    title: '系统提示',
                    content: '发布成功'
                  })
                  resolve(result)
                } else {
                  KNotification.error({
                    title: '操作失败',
                    content: result.message || '发布失败',
                    details: result.detail
                  })
                  reject(void 0)
                }
              }
            })
          } else {
            KDialog.confirm({
              title: '确认发布吗？发布后不可恢复!',
              onOk: async () => {
                const result = await Api.post('part', 'Part', 'batchReleaseByBranch', { data: [partIds] })
                if (result && result.code == EnumRequestCode.SUCCESS) {
                  KNotification.success({
                    title: '系统提示',
                    content: '发布成功'
                  })
                  resolve(result)
                } else {
                  KNotification.error({
                    title: '操作失败',
                    content: result.message || '发布失败',
                    details: result.detail
                  })
                  reject(void 0)
                }
              }
            })
          }
        } else {
          reject(void 0)
        }
      })
    })
  }

  /**
   * 修订零部件
   * @param partId 零部件id
   * @returns
   */
  static async revisePart(partId: string, folderId: string): Promise<any> {
    const showDialog = KDialog.info({
      title: '提示',
      content: '正在获取数据，请稍后...',
      showOk: false
    })
    const result = (await Api.post('part', 'Part', 'previewRevise', { data: [partId] })) as any

    if (!result || result.code != EnumRequestCode.SUCCESS || !result.data) {
      showDialog.destroy()
      KNotification.warn({
        message: '系统提示',
        description: result.message || '获取零部件错误'
      })
      return undefined
    }
    // 获取路径
    const folder = {
      id: folderId
    } as any
    const folderPathResult = await Api.post('folder', 'Folder', 'getFolderPath', { data: [folder.id] })
    if (folderPathResult && folderPathResult.code == EnumRequestCode.SUCCESS) {
      folder.fullPath = folderPathResult.data
    }
    const data = result.data.data
    const docEchoList = data.map((item: any, index: number) => {
      return {
        ...item,
        index: index + 1,
        folder: folder,
        operate: 'Revise'
      } as DocEchoGridDataInner
    })
    showDialog.destroy()
    return new Promise((resolve, reject) => {
      KDialog.show({
        title: '修订',
        size: { width: 700, height: 600 },
        props: {
          dataSource: docEchoList,
          operateType: EnumOperateType.Revise,
          operateOptions: EnumOperateOptions.REVISE
        },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KDocEchoListPanel,
        onClosing: async (event: KDialogClosingEvent) => {
          const docEchoListPanelViewModel = event.viewModel as KDocEchoListPanelViewModel
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
          if (!docEchoListPanelViewModel.validate()) {
            KNotification.warn({
              message: '系统提示',
              description: '请补全数据'
            })
            event.cancel = true
            return
          }
          const operateList = docEchoListPanelViewModel.getOperateData() as DocEchoGridDataInner
          if (operateList.length == 0) {
            KNotification.warn({
              message: '系统提示',
              description: '请至少选择一条数据'
            })
            event.cancel = true
            return
          }
          const result = await Api.post('part', 'Part', 'reviseAndUpdate', {
            data: [{ data: operateList }]
          })
          if (result && result.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '修订成功'
            })
            // 后台成功之后，重置当前面板对象的ID参数
            resolve(result)
          } else {
            KNotification.error({
              title: '操作失败',
              content: result.message || '修订失败',
              details: result.detail
            })
            event.cancel = true
            reject(0)
          }
        }
      })
    })
    /* const part = result.data
    return new Promise((resolve, reject) => {
      KDialog.show({
        title: '修订零部件',
        size: { width: 800, height: 600 },
        props: {
          loadData: () => {
            return {
              ...part,
              masterId: part.master.id,
              number: part.master.number,
              lifecycleStateCode: 'InWork',
              startTime: ''
            }
          }
        },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KPartReviseForm,
        onClosing: async (event: KDialogClosingEvent) => {
          const formViewModel = event.viewModel as KPartReviseFormViewModel
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
          const validateResult = await formViewModel.validate()
          if (!validateResult) {
            event.cancel = true
            reject(void 0)
          }
          let formData = formViewModel.getModifiedValue() as any
          formData = {
            ...formData,
            id: partId,
            masterId: part.master.id,
            lifecycleStateCode: 'InWork'
          }
          const result = await Api.post('part', 'Part', 'reviseAndUpdate', { data: [formData] })
          if (result && result.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '修订成功'
            })
            resolve(result)
          } else {
            KNotification.error({
              title: '操作失败',
              content: result.message || '修订失败',
              details: result.detail
            })
            event.cancel = true
            reject(void 0)
          }
        }
      })
    }) */
  }

  /**
   * 另存零部件
   */
  static async saveAsPart(partId: string, folderId: string): Promise<any> {
    const showDialog = KDialog.info({
      title: '提示',
      content: '正在获取数据，请稍后...',
      showOk: false
    })
    // 获取另存预览
    const result = (await Api.post('part', 'Part', 'previewSaveAs', { data: [partId] })) as any
    if (!result || result.code != EnumRequestCode.SUCCESS || !result.data) {
      showDialog.destroy()
      KNotification.warn({
        message: '系统提示',
        description: result.message || '获取零部件错误'
      })
      return undefined
    }
    // 获取路径
    const folder = {
      id: folderId
    } as any
    const folderPathResult = await Api.post('folder', 'Folder', 'getFolderPath', { data: [folder.id] })
    if (folderPathResult && folderPathResult.code == EnumRequestCode.SUCCESS) {
      folder.fullPath = folderPathResult.data
    }
    const data = result.data.data
    const docEchoList = data.map((item: any, index: number) => {
      return {
        ...item,
        index: index + 1,
        folder: folder,
        operate: 'Saveas'
      }
    })
    showDialog.destroy()
    return new Promise((resolve, reject) => {
      KDialog.show({
        title: '另存',
        size: { width: 900, height: 600 },
        props: {
          dataSource: docEchoList,
          operateType: EnumOperateType.SaveAs,
          operateOptions: EnumOperateOptions.SAVEAS
        },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KDocEchoListPanel,
        onClosing: async (event: KDialogClosingEvent) => {
          const docEchoListPanelViewModel = event.viewModel as KDocEchoListPanelViewModel
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
          if (!docEchoListPanelViewModel.validate()) {
            KNotification.warn({
              message: '系统提示',
              description: '请补全数据'
            })
            event.cancel = true
            return
          }
          const operateList = docEchoListPanelViewModel.getOperateData() as DocEchoGridDataInner
          if (operateList.length == 0) {
            KNotification.warn({
              message: '系统提示',
              description: '请至少选择一条数据'
            })
            event.cancel = true
            return
          }
          const result = await Api.post('part', 'Part', 'saveAs', { data: [{ data: operateList }] })
          if (result && result.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '另存成功'
            })
            resolve(result)
          } else {
            KNotification.error({
              title: '操作失败',
              content: result.message || '另存失败',
              details: result.detail
            })
            event.cancel = true
            reject(void 0)
          }
        }
      })
    })
  }
  /**
   * 获取另存零部件
   * @param id 零部件id
   * @returns 另存零部件信息
   */
  static async getSaveAsPart(id: string) {
    const result = (await Api.post('part', 'Part', 'get', { data: [id] })) as any
    if (!(result && result.code == EnumRequestCode.SUCCESS)) {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取零部件详情失败',
        details: result.detail
      })
      return
    }
    return result.data as any
  }

  /**
   * 批量删除分支
   * @param partIds
   */
  static async batchDeleteBranch(partIds: Array<any>) {
    return new Promise((resolve, reject) => {
      KModal.delete({
        title: '删除提示',
        icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
        content: '您正在进行删除操作，请确认是否删除',
        onOk: async () => {
          const param = {
            data: [partIds]
          }
          const result = await Api.post('part', 'Part', 'batchDeleteBranch', param)
          if (result && result.code == EnumRequestCode.SUCCESS) {
            KNotification.success('对象移除成功')
            resolve(result.data)
          } else {
            KNotification.error({
              title: '对象移除失败',
              content: result.message!
            })
            reject(void 0)
          }
        }
      })
    })
  }

  /**
   * 结构管理
   */
  static async structManage(partId: string) {
    // 获取对象
    const partData = (await this.getPart(partId)) as any
    const key = new Date().getTime().toString()
    const title = '结构管理:' + ObjectClientSrv.getObjBusinessDesc(partData)
    return new Promise((resolve, reject) => {
      CommonClientSrv.openPage(
        title,
        KPartStructureManage,
        {
          objParam: { modelCode: partData.rdmExtensionType, modelGroup: 'part', id: partData.id, folderId: partData.folder.id },
          partViewName: partData.partView.name,
          env: 'structure'
        },
        partData.id + key
      )
      resolve(void 0)
    })
  }

  /**
   * 物料属性更新
   */
  static async batchUpdateMateriaProp(checkedNodes: Array<Record<string, any>>) {
    return new Promise((resolve, reject) => {
      if (!checkedNodes || checkedNodes.length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '未选择数据'
        })
        reject()
      }
      //更新
      KDialog.show({
        title: '批量更新物料属性',
        size: { width: 480, height: 250 },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KPartMaterialForm,
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
            return
          }

          const formViewModel = event.viewModel as KPartMaterialFormViewModel
          await formViewModel.batchUpdateMateriaProp(checkedNodes)
          resolve(true)
        }
      })
    })
  }

  /**
   * 物料属性更新
   */
  static async batchUpdateBom(selectedNode: ObjBusinessParam) {
    return new Promise((resolve, reject) => {
      KDialog.show({
        title: 'Bom属性批量维护',
        size: { width: 1250, height: 800 },
        resizable: false,
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KPartMaterialBomForm,
        props: {
          selectedNode: selectedNode
        },
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
            return
          }

          const formViewModel = event.viewModel as KPartMaterialBomFormViewModel
          const result = (await formViewModel.batchUpdateBom()) as any
          if (result) {
            if (result.code == EnumRequestCode.SUCCESS) {
              resolve(result)
              KNotification.success('更新成功！')
            } else {
              KNotification.error({
                title: '更新失败',
                content: result.message,
                details: result.detail
              })
              event.cancel = true
              formViewModel.beginEdit()
            }
          }
        }
      })
    })
  }

  /**
   * 获取零部件
   */
  static async getPart(id: string) {
    const result = (await Api.post('part', 'Part', 'get', { data: [id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取零部件失败',
        details: result.detail
      })
      return
    }
  }

  /**
   * 批量查询零部件对象信息
   * @param parts 勾选零部件数据行
   * @returns 零部件对象清单
   */
  static batchGetParts(parts: Array<KDataGridRowData>) {
    const partIds = parts.map(part => {
      return part.targetId
    })
    return Api.post('part', 'Part', 'batchGet', { data: [partIds] })
  }

  /**
   * 创建测试问题报告
   * @param parts 文件夹关联对象清单
   * @param folder 文件夹树节点对象
   * @returns 零部件对象清单
   */
  static async createChangeIssue(parts: Array<KDataGridRowData>, container: any, folder?: IKTreeNode) {
    if (parts.length === 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请勾选至少一条数据'
      })
      return
    }
    const result = await PartClientSrv.batchGetParts(parts)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      ChangeManageClientSrv.createChangeIssue(result.data, container, folder?.id)
    } else {
      KNotification.error({
        title: '系统提示',
        content: '获取零部件信息失败'
      })
    }
  }

  /**
   * 创建变更请求
   * @param parts 文件夹关联对象清单
   * @param folder 文件夹树节点对象
   * @returns 零部件对象清单
   */
  static async createChangeRequest(parts: Array<KDataGridRowData>, container: any, folder?: IKTreeNode) {
    if (parts.length === 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请勾选至少一条数据'
      })
      return
    }
    const result = await PartClientSrv.batchGetParts(parts)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      ChangeManageClientSrv.createChangeRequest(result.data, container, folder?.id)
    } else {
      KNotification.error({
        title: '系统提示',
        content: '获取零部件信息失败'
      })
    }
  }

  /**
   * 浏览
   * @param partId 零部件id
   */
  static async doBrowse(partId: string) {
    const result = await Api.post('part', 'Part', 'getDescribeDocumentByPart', {
      data: [{ clazz: 'Part', id: partId }]
    })

    if (!(result && result.code == EnumRequestCode.SUCCESS)) {
      KNotification.error({
        title: '操作失败',
        content: result.message,
        details: result.detail
      })
    }
    if (result.data) {
      DocClientSrv.browseDoc({
        id: result.data.id,
        modelCode: result.data.rdmExtensionType
      })
    } else {
      KDialog.warning('未找到描述文档')
    }
  }

  /**
   * 获取最新版本
   * @param branchIds 分支对象id
   * @returns 零部件最新版本
   */
  static async getLatestByBranchIds(branchIds: string[]) {
    const result = await Api.post('part', 'Part', 'listLatestByBranch', {
      data: [branchIds]
    })

    if (result && result.code == EnumRequestCode.SUCCESS) {
      if (result.data && result.data.length > 0) {
        return result.data
      }
    }
  }
}
