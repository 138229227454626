import { Api, EnumRequestCode, FolderTypeEnum, ObjectClientSrv, ObjectToolStripItem, ToolStripHelper } from '@kmsoft/ebf-common'
import { BaseViewModel, KIcon, KModal, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { IFolder } from '../../../pages/folder-manage/folder-tree/interface'
import { createVNode, ref } from 'vue'
import {
  ElectronicComponentsPartWorkspace,
  KElectronicComponentsWorkspaceEmitsType,
  KElectronicComponentsWorkspacePropType
} from './interface'
import { KFolderManageViewModel } from '../../../pages/folder-manage'
import _ from 'lodash'

/** KElectronicComponentsWorkspace */
export default class KElectronicComponentsWorkspaceViewModel extends BaseViewModel<
  KElectronicComponentsWorkspaceEmitsType,
  KElectronicComponentsWorkspacePropType
> {
  refCommonFolderManage = ref<KFolderManageViewModel>()

  /** 工具栏数据 */
  toolStripItems = ref<Array<ObjectToolStripItem>>(_.cloneDeep(ToolStripHelper.getFolderGeneralObjectOperationToolStripItems()))

  constructor(options: ViewModelOptions<KElectronicComponentsWorkspacePropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 加载树节点
   */
  async loadTreeData() {
    const param = {
      data: [FolderTypeEnum.ELECTRONIC_COMPONENTS]
    }
    const res = await Api.post('folder', 'Folder', 'getFolderByType', param)
    if (res && res.code == EnumRequestCode.SUCCESS) {
      const node = {
        id: res.data?.id,
        name: res.data?.name,
        leafFlag: res.data.leafFlag,
        nameEn: res.data.nameEn,
        type: res.data.type
      } as IFolder
      return [new ElectronicComponentsPartWorkspace(node)]
    } else {
      KNotification.error({
        title: '获取工作区数据异常',
        content: res.message!
      })
      return Promise.resolve([])
    }
  }
  refresh() {
    this.refCommonFolderManage.value?.refresh()
  }

  /**
   * 操作栏事件
   * @param params
   */
  async onOperationClick(params: any) {
    if (!params) {
      return
    }
    const row = params.params.row
    if (params.key == 'edit') {
      const param = {
        id: row?.targetId,
        modelCode: row!.targetExtensionType,
        modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.targetExtensionType)
      }
      ObjectClientSrv.openObj(param)
    } else if (params.key == 'delete') {
      this.batchRemoveObjLink([params.params.row])
    }
  }

  /**
   * 移除对象链接
   */
  batchRemoveObjLink(rows: any) {
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一个对象'
      })
      return
    }
    const deleteRows = rows.map((row: any) => {
      return {
        folderedLinkId: row.id,
        folderId: row.targetFolderId,
        objId: row.targetId,
        objNumber: row.number,
        className: row.targetClass,
        branchId: row.targetBranchId
      }
    })
    const param = {
      data: [this.refCommonFolderManage.value?.getSelectNode()?.id, [...deleteRows]]
    }
    KModal.delete({
      title: '移除提示',
      icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
      content: '您正在进行移除操作，请确认是否移除',
      onOk: async () => {
        const result = await Api.post('folder', 'Folder', 'deleteFolderObjLink', param)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success('对象关系移除成功')
          // 移除选中的行
          this.refCommonFolderManage.value?.refresh()
        } else {
          KNotification.error({
            title: '对象关系移除失败',
            content: result.message!
          })
        }
      }
    })
  }
}
