
import { defineView, EnumDataGridRowModelType, KDataGridRowSelectedEvent, KGridPagination } from '@kmsoft/upf-core'
import { KMySubscribedRequirementPropOptions, KMySubscribedRequirementEventEmits } from './interface'
import KMySubscribedRequirementViewModel from './KMySubscribedRequirementViewModel'
import { clsCodeMap, EnumLifecycleState, EnumLifecycleStateColor } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KMySubscribedRequirement',
  props: KMySubscribedRequirementPropOptions,
  emits: KMySubscribedRequirementEventEmits,
  viewModel: KMySubscribedRequirementViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      pagination: { pageIndex: 1, pageSize: 100 } as KGridPagination,
      clsCodeMap
    }
  }
})
