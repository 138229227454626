import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

export interface IAssociateClient {
  clientIP: string
  clientName: string
}

/** 参数 **/
export const KAssociateClientFormPropOptions = {
  ...BaseViewPropOptions,
  associateClient: VuePropTypes.createType<IAssociateClient>().def()
}

/** 参数类型 **/
export type KAssociateClientFormPropType = ViewPropsTypeExtract<typeof KAssociateClientFormPropOptions>

/** 事件 */
export const KAssociateClientFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KAssociateClientFormEmitsType = ViewEmitsTypeExtract<typeof KAssociateClientFormEventEmits>
