import { connect, withInstall } from '@kmsoft/upf-core'
import KProductNeedsCreateWithFolderSelectorView from './KProductNeedsCreateWithFolderSelector.vue'
import KProductNeedsCreateWithFolderSelectorViewModel from './KProductNeedsCreateWithFolderSelectorViewModel'

const KProductNeedsCreateWithFolderSelector = connect(
  KProductNeedsCreateWithFolderSelectorView,
  KProductNeedsCreateWithFolderSelectorViewModel
)

export default withInstall(KProductNeedsCreateWithFolderSelector)
export {
  KProductNeedsCreateWithFolderSelector,
  KProductNeedsCreateWithFolderSelectorView,
  KProductNeedsCreateWithFolderSelectorViewModel
}

// 模板生成文件
// export * from './{{folderName}}'
