import { enumFactory } from '../model'

export const EnumWorkingState = enumFactory({
  INWORK: '工作中',
  CHECKED_IN: '已检入',
  CHECKED_OUT: '已检出'
})

export const EnumActivityType = enumFactory({
  COMPONENT_CHANGE: '零部件变更',
  DESIGN_CHANGE: '设计变更',
  DOCUMENTATION_CORRECTION: '资料修正',
  OTHER: '其他'
})

export const EnumDocumentType = enumFactory({
  UG_SOLIDEDGE: 'UG数模',
  Solidworks: 'Solidworks数模',
  Autocad2d: 'AutoCAD图纸',
  Excel: 'Excel文件',
  Word: 'Word文件',
  Cadence: 'Cadence原理图',
  CadencePcb: 'CadencePCB图',
  Gerber: 'Gerber文件',
  OdbPlus: 'ODB++文件',
  Coordinate: '坐标文件',
  NetList: '网表文件',
  SymbolLib: '符号库文件',
  WrapperLib: '封装库文件',
  Solidworks2d: '工程图文件',
  Other: '其他类型'
})
// export const EnumLifecycleState = enumFactory({
//   Start: '开始',
//   End: '结束',
//   Released: '已发布',
//   UnderReview: '审核中',
//   InWork: '工作中',
//   Terminated: '评审终止',
//   Openned: '已创建',
//   Processed: '已处理',
//   Processing: '处理中',
//   Accepted: '已采纳',
//   Evaluated: '评估中',
//   Recognized: '已识别',
//   Validating: '验证中',
//   Confirmed: '已确认',
//   Confirming: '确认中',
//   Raised: '已提出',
//   Closed: '已关闭',
//   Obsoleted: '已废弃'
// })
export const EnumLifecycleState = enumFactory({
  Start: '开始',
  End: '结束',
  Released: '发布',
  UnderReview: '审核中',
  InWork: '正在工作',
  Terminated: '评审终止',
  Openned: '已创建',
  Processed: '已处理',
  Processing: '处理中',
  Accepted: '已采纳',
  Evaluated: '评估中',
  Recognized: '已识别',
  Validating: '验证中',
  Confirmed: '已确认',
  Confirming: '确认中',
  Raised: '已提出',
  Closed: '已关闭',
  Obsoleted: '已废弃',
  Realizing: '实现中',
  Yiquma: '已取码'
})
export const EnumLifecycleStateColor = enumFactory({
  Start: '#28a745',
  End: '#6c757d',
  Released: '#007bff',
  UnderReview: '#fd7e14',
  InWork: '#52c41a',
  Terminated: '#dc3545',
  Openned: '#4abddb',
  Processed: '#218838',
  Processing: '#ffc107',
  Accepted: '#6f42c1',
  Evaluated: '#d63384',
  Recognized: '#20c997',
  Validating: '#ffc107',
  Confirmed: '#0056b3',
  Confirming: '#007bff',
  Raised: '#e83e8c',
  Closed: '#343a40',
  Obsoleted: '#495057',
  Yiquma: '#17a2b8'
})
export const EnumComplexity = enumFactory({
  Low: '低复杂度',
  Medium: '中等复杂度',
  High: '高复杂度'
})

export const EnumChangeType = enumFactory({
  DOCUMENTATION_CORRECTION: '资料修正',
  DESIGN_CHANGE: '设计变更',
  COMPONENT_CHANGE: '零部件变更',
  OTHER: '其它'
})

export const EnumPriority = enumFactory({
  LOW: '低',
  MEDIUM: '中',
  HIGH: '高',
  URGENT: '紧急',
  UrgentAndImportant: '紧急且重要',
  ImportantButNotUrgent: '重要但不紧急',
  UrgentButNotImportant: '紧急但不重要',
  NeitherUrgentNorImportant: '不紧急也不重要'
})

export const EnumLifecycleStateSimple = enumFactory({
  Start: '开始',
  End: '结束',
  Released: '发布',
  UnderReview: '审核中',
  InWork: '正在工作',
  Terminated: '评审终止',
  Openned: '已创建',
  Processed: '已处理',
  Processing: '处理中',
  Accepted: '已采纳',
  Evaluated: '评估中',
  Recognized: '已识别',
  Validating: '验证中',
  Confirmed: '已确认',
  Confirming: '确认中',
  Raised: '已提出',
  Closed: '已关闭',
  Obsoleted: '已废弃',
  Realizing: '实现中',
  Yiquma: '已取码'
})
export const EnumPhaseState = enumFactory({
  Design: '设计',
  Prototype: '原理样机',
  Engineering: '工程样机',
  Manufacturing: '量产',
  Obsolete: '废弃'
})

export const EnumHandingMethod = enumFactory({
  1: '报废',
  2: '继续使用'
})

export const EnumValidationMetrics = enumFactory({
  Reliability: '可靠性',
  Accuracy: '准确性',
  RequirementSatisfaction: '需求满足度',
  Consistency: '一致性',
  Traceability: '可追溯性',
  Performance: '性能',
  Stability: '稳定性',
  Usability: '可用性'
})

export const EnumVerification = enumFactory({
  RequirementReview: '需求评审',
  SimulationAndEmulation: '模拟和仿真',
  FeasibilityAnalysis: '可行性分析',
  CompletenessCheck: '完整性检查',
  ConsistencyCheck: '一致性检查'
})

export const EnumRequirementType = enumFactory({
  STRUCTURAL_DESIGN: '结构设计',
  ELECTRONIC_DESIGN: '电子设计',
  SOFTWARE_DESIGN: '软件设计'
})

export const EnumTaskPlanType = enumFactory({
  NotStarted: '未开始',
  InProgress: '进行中',
  Completed: '已完成',
  Paused: '暂停',
  Terminated: '终止'
})

export const EnumProductNeedsLifecycleState = enumFactory({
  Released: '发布',
  UnderReview: '审核中',
  InWork: '正在工作',
  Realizing: '实现中',
  Closed: '已关闭'
})
export const EnumSource = enumFactory({
  Make: '自制',
  Buy: '采购',
  Singlesource: '购买-单一供应源'
})

export const validationResultColor = enumFactory({
  NOT_EXECUTED: '#3366cc',
  EXECUTING: '#0099ff',
  BLOCKED: '#ffcc00',
  SUCCESS: '#52C41A',
  FAILED: '#FF4C4C'
})
export const EnumValidationResult = enumFactory({
  NOT_EXECUTED: '未执行',
  EXECUTING: '执行中',
  BLOCKED: '阻塞',
  SUCCESS: '成功',
  FAILED: '失败'
})
