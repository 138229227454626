import { BaseViewModel, KDataGridViewModel, KDialog, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KFlowHistorySigningEmitsType, KFlowHistorySigningPropType } from './interface'
import { WorkflowClientSrv } from '../../client-srv/workflow-client-srv/WorkflowClientSrv'
import { ref } from 'vue'
import { processCellCallback } from '@kmsoft/ebf-common'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

/** KFlowHistorySigning */
export default class KFlowHistorySigningViewModel extends BaseViewModel<
  KFlowHistorySigningEmitsType,
  KFlowHistorySigningPropType
> {
  refDataGrid = ref<KDataGridViewModel>()

  constructor(options: ViewModelOptions<KFlowHistorySigningPropType>) {
    super(options)
  }

  viewDidMount() {}

  refresh(noClear?: boolean) {
    if (!noClear) {
      this.refDataGrid.value?.clearSelectedRows()
    }
    this.refDataGrid.value?.refresh()
  }

  rowChanged({ row }: { row: any }) {
    this.emit('rowChanged', row)
  }

  openProcessDetail(row: any) {
    return WorkflowClientSrv.openProcessDetail(row)
  }

  exportSelectAsExcel() {
    const data = this.refDataGrid.value?.getSelectedRows()
    if (data && data.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择要导出的数据'
      })
      return
    }
    const params = {
      onlySelected: true,
      columnKeys: this.refDataGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id),
      processCellCallback: (params: any) => {
        const self = this
        return processCellCallback(params, self.refDataGrid.value)
      }
    }
    this.refDataGrid.value?.exportDataAsExcel(params)
  }
  exportAsExcel() {
    const params = {
      columnKeys: this.refDataGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id),
      processCellCallback: (params: any) => {
        const self = this
        return processCellCallback(params, self.refDataGrid.value)
      }
    }
    this.refDataGrid.value?.exportDataAsExcel(params)
  }

  async exportPdf(row: any) {
    const url = WorkflowClientSrv.openProcessDetail(row)
    // const origin = 'http://localhost:40005/#/requirementBoard'
    const dialog = KDialog.info({ content: '正在转pdf文件，请稍后...', title: '提示', showOk: false })
    try {
      // 创建一个 iframe 元素
      const iframe = document.createElement('iframe')
      iframe.style.position = 'absolute'
      iframe.style.top = '0'
      iframe.style.zIndex = '-1'
      iframe.style.width = '100%'
      iframe.style.height = '100%'
      document.body.appendChild(iframe)

      // 设置 iframe 的 src 为目标页面
      iframe.src = url

      // 等待 iframe 加载完成
      iframe.onload = async () => {
        // // 添加样式到 iframe 的 body
        // const body = iframe.contentDocument?.body
        // if (body) {
        //   body.style.transform = 'scale(0.9)'
        //   body.style.transformOrigin = '0 0'
        // }
        // 获取并修改 <link> 标签的 href 值
        const linkTags = iframe.contentDocument?.head.getElementsByTagName('link')
        if (linkTags && linkTags.length > 0) {
          for (const link of linkTags) {
            // 修改 href 值
            if (link.href.includes('.css')) {
              link.href = this.convertTestToTest1(url, link.href)
            }
          }
        }

        // 等待一段时间以确保内容完全加载
        await new Promise(resolve => setTimeout(resolve, 5000))

        // 使用 html2canvas 捕获 iframe 内容
        const canvas = await html2canvas(iframe.contentWindow!.document.body, { useCORS: true })
        const imgData = canvas.toDataURL('image/png')

        // 创建 PDF 文档
        const pdf = new jsPDF({ orientation: 'landscape' })
        pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight())

        // 下载 PDF
        pdf.save(`${this.props.objParam?.objName}_${row.processName}.pdf`)
        dialog.destroy()
        // 移除 iframe
        document.body.removeChild(iframe)
      }
    } catch (error) {
      KNotification.warn({
        message: '系统提示',
        description: '导出失败'
      })
      dialog.destroy()
    }
  }

  convertTestToTest1(originUrl: string, cssUrl: string): string {
    // 提取 URL 中的路径部分
    const url = new URL(originUrl)
    const pathSegments = url.pathname.split('/')
    pathSegments.pop()
    const cssPath = cssUrl.split('/')

    // 获取文件名并构建新的 URL
    const fileName = cssPath.pop() // 获取最后一个部分，即文件名
    const newUrl = `${url.origin}${pathSegments.join('/')}/assets/${fileName}`
    return newUrl
  }
}
