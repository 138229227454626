import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KCreateFormEmitsType, KCreateFormPropType } from './interface'
import { ref } from 'vue'
import { Api, KObjectPropertyPanelViewModel } from '../../../../../ebf-common/src'
import { KCommonFolderManageViewModel } from '../../../../../ebf-folder/src/pages/common-folder-manage'

/** KCreateForm */
export default class KCreateFormViewModel extends BaseViewModel<KCreateFormEmitsType, KCreateFormPropType> {
  /** 创建面板 */
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  /** 默认加载面板值 */
  formData = ref<any>()

  constructor(options: ViewModelOptions<KCreateFormPropType>) {
    super(options)
    this.formData.value = {
      ...options.props.formValue,
      type: 'Reliability',
      isGeneral: this.props.isGeneral,
      executor: ''
    }
  }

  viewDidMount() {}

  loaded() {}

  /**
   * 校验
   */
  validate() {
    return this.refObjectCreatePanel.value?.validateFormValue()
  }

  /**
   * 创建通用指标
   */
  save() {
    const formValue = this.refObjectCreatePanel.value?.getValue()
    formValue!.isGeneral = !!formValue!.isGeneral
    let metricsCreateObject
    if (formValue && formValue?.folder?.id) {
      metricsCreateObject = {
        ...formValue,
        ...{ folder: { id: formValue.folder.id, clazz: 'Folder' } }
      } as any
    } else {
      metricsCreateObject = {
        ...formValue,
        ...{ folder: { id: this.props.folderId, clazz: 'Folder' } }
      } as any
    }

    if (metricsCreateObject.secondary) {
      metricsCreateObject.secondary = metricsCreateObject.secondary.map((element: string) => {
        return { id: element }
      })
    }
    return Api.post('requirement', 'ValidationMetrics', 'create', { data: [metricsCreateObject] })
  }

  /**
   * 创建验证指标
   */
  saveMetrics(requirement: any) {
    const metricsCreateObject = {
      ...this.refObjectCreatePanel.value?.getValue(),
      requirement: requirement
    } as any
    metricsCreateObject!.isGeneral = !!metricsCreateObject!.isGeneral
    if (metricsCreateObject.secondary) {
      metricsCreateObject.secondary = metricsCreateObject.secondary.map((element: string) => {
        return { id: element }
      })
    }
    return Api.post('requirement', 'ValidationMetrics', 'createValidationMetrics', { data: [metricsCreateObject] })
  }
}
