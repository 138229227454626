import { connect, withInstall } from '@kmsoft/upf-core'
import KBaselineFormView from './KBaselineForm.vue'
import KBaselineFormViewModel from './KBaselineFormViewModel'

const KBaselineForm = connect(KBaselineFormView, KBaselineFormViewModel)

export default withInstall(KBaselineForm)
export { KBaselineForm, KBaselineFormView, KBaselineFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'
