import {
  BaseViewModel,
  EnumDialogResult,
  IQuery,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KIcon,
  KModal,
  KNotification,
  request,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KAssociateClientGridEmitsType, KAssociateClientGridPropType } from './interface'
import { Api, EnumRequestCode, SelectItem } from '../../../../client-srv'
import { createVNode, ref } from 'vue'
import { KAssociateClientForm, KAssociateClientFormViewModel } from '../associate-client-form/index'

/** KAssociateClientGrid */
export default class KAssociateClientGridViewModel extends BaseViewModel<
  KAssociateClientGridEmitsType,
  KAssociateClientGridPropType
> {
  refGrid = ref<KDataGridViewModel>()

  constructor(options: ViewModelOptions<KAssociateClientGridPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 刷新网格数据
   */
  refresh() {
    this.refGrid.value?.refresh()
  }
  search() {
    this.refresh()
  }

  async loadData(qry: IQuery) {
    const param = {
      data: [
        {
          currentPage: qry.page?.pageIndex,
          pageSize: 50
        }
      ]
    }

    const result = await Api.post('sys', 'UserClientService', 'query', param)
    // const result = await request.post('/wyzApi/request/systest/UserClientService/query', param, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     token: '568039397618880512/test1 3c03e719256a427eb9277b64fc83fb40/1111'
    //   }
    // })

    if (result && result.code == EnumRequestCode.SUCCESS && result.data.dataList) {
      return {
        rows: result.data.dataList,
        total: result.data.pager.recordCount
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  create() {
    KDialog.show({
      title: '创建关联',
      size: { width: 480, height: 260 },
      content: KAssociateClientForm,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
          return
        }
        /** 当前组件 */
        const formViewModel = event.viewModel as KAssociateClientFormViewModel

        /** 获取表单值 */
        const formValue = formViewModel.getFormValue()
        const param = {
          data: [
            {
              ...formValue
            }
          ]
        }

        const result = await Api.post('sys', 'UserClientService', 'insert', param)
        // const result = await request.post('/wyzApi/request/systest/UserClientService/insert', param, {
        //   headers: {
        //     'Content-Type': 'application/json',
        //     token: '568039397618880512/test1 3c03e719256a427eb9277b64fc83fb40/1111'
        //   }
        // })

        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refGrid.value?.refresh()
        } else {
          KNotification.warn({
            message: '创建关联异常',
            description: result.message
          })
          event.cancel = true
        }
      }
    })
  }

  update(row: any) {
    KDialog.show({
      title: '编辑关联',
      size: { width: 480, height: 360 },
      props: {
        associateClient: {
          clientIP: row.clientIP,
          clientName: row.clientName
        }
      },
      content: KAssociateClientForm,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
          return
        }
        /** 当前组件 */
        const formViewModel = event.viewModel as KAssociateClientFormViewModel

        /** 获取表单值 */
        const formValue = formViewModel.getFormValue() as { clientName?: string }
        const param = {
          data: [
            {
              id: row.id,
              clientName: formValue?.clientName
            }
          ]
        }

        const result = await Api.post('sys', 'UserClientService', 'update', param)
        // const result = await request.post('/wyzApi/request/systest/UserClientService/update', param, {
        //   headers: {
        //     'Content-Type': 'application/json',
        //     token: '568039397618880512/test1 3c03e719256a427eb9277b64fc83fb40/1111'
        //   }
        // })

        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refGrid.value?.refresh()
        } else {
          KNotification.warn({
            message: '修改关联异常',
            description: result.message
          })
          event.cancel = true
        }
      }
    })
  }

  delete(id: string) {
    KModal.delete({
      title: '关联删除提示',
      icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
      content: '您正在进行删除操作，请确认是否删除',
      onOk: async () => {
        const param = {
          data: [id]
        }

        const result = await Api.post('sys', 'UserClientService', 'delete', param)
        // const result = await request.post('/wyzApi/request/systest/UserClientService/delete', param, {
        //   headers: {
        //     'Content-Type': 'application/json',
        //     token: '568039397618880512/test1 3c03e719256a427eb9277b64fc83fb40/1111'
        //   }
        // })

        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refGrid.value?.refresh()
        } else {
          KNotification.warn({
            message: '修改关联异常',
            description: result.message
          })
        }
      }
    })
  }
}
