import { connect, withInstall } from '@kmsoft/upf-core'
import KMyRequirementView from './KMyRequirement.vue'
import KMyRequirementViewModel from './KMyRequirementViewModel'

const KMyRequirement = connect(KMyRequirementView, KMyRequirementViewModel)

export default withInstall(KMyRequirement)
export { KMyRequirement, KMyRequirementView, KMyRequirementViewModel }

// 模板生成文件
// export * from './{{folderName}}'
