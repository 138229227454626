import { BaseViewModel, KFormViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { IAssociateClient, KAssociateClientFormEmitsType, KAssociateClientFormPropType } from './interface'
import { ref } from 'vue'

/** KAssociateClientForm */
export default class KAssociateClientFormViewModel extends BaseViewModel<
  KAssociateClientFormEmitsType,
  KAssociateClientFormPropType
> {
  /** 基本信息表单 */
  refBasePropForm = ref<KFormViewModel<IAssociateClient>>()

  /** 基本信息 */
  formData = ref<IAssociateClient>({
    clientIP: '',
    clientName: ''
  })

  /** 校验规则 */
  rules = {
    clientIP: [{ required: true, message: '客户端IP不能为空', trigger: 'blur' }],
    clientName: [{ required: true, message: '客户端名称不能为空', trigger: 'blur' }]
  }

  disabledClientIP = ref(false)
  constructor(options: ViewModelOptions<KAssociateClientFormPropType>) {
    super(options)

    if (this.props.associateClient) {
      this.formData.value = this.props.associateClient
      this.disabledClientIP.value = true
    } else {
      this.disabledClientIP.value = false
    }
  }

  viewDidMount() {}

  /**
   * 获取表单值
   * @returns
   */
  getFormValue() {
    const value = this.refBasePropForm.value?.formState

    /** 参数 */
    const param = {
      ...value
    }
    return param
  }
}
