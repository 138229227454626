import { BaseViewModel, IKTreeNode, KDataGridViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KCompareResultEmitsType, KCompareResultPropType } from './interface'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { ref } from 'vue'

/** KCompareResult */
export default class KCompareResultViewModel extends BaseViewModel<KCompareResultEmitsType, KCompareResultPropType> {
  refResultGrid = ref<KDataGridViewModel>()
  leftTreeNode = ref<IKTreeNode[]>([])
  rightTreeNode = ref<IKTreeNode[]>([])
  showCompareTree = ref<boolean>(false)
  showTreeNode = ref<boolean>(false)
  compareResult = ref<any>([])
  leftTitle = ref<string>('')
  rightTitle = ref<string>('')
  constructor(options: ViewModelOptions<KCompareResultPropType>) {
    super(options)
  }

  viewDidMount() {
    this.compareBaseline()
  }

  async compareBaseline() {
    this.showCompareTree.value = true
    this.showTreeNode.value = false
    const selectRows = this.props.selectRows
    this.leftTitle.value = selectRows![0].baselineName
    this.rightTitle.value = selectRows![1].baselineName
    const params = {
      data: [
        [
          {
            needsView: {
              id: selectRows![0].needsView.id,
              rdmExtensionType: selectRows![0].needsView.rdmExtensionType
            },
            baselineName: selectRows![0].baselineName,
            createTime: selectRows![0].createTime,
            baselineType: selectRows![0].baselineType
          },
          {
            needsView: {
              id: selectRows![1].needsView.id,
              rdmExtensionType: selectRows![1].needsView.rdmExtensionType
            },
            baselineName: selectRows![1].baselineName,
            createTime: selectRows![1].createTime,
            baselineType: selectRows![1].baselineType
          }
        ]
      ]
    }
    const result = (await Api.post('requirement', 'Requirement', 'baselineCompare', params)) as any
    this.showTreeNode.value = true
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.compareResult.value = result.data.differenceList.map((item: any, index: number) => {
        return {
          id: index,
          ...item
        }
      })
      const leftTreeRoot = result.data.treeDataList[0].filter((item: any) => !item.parentId)
      if (leftTreeRoot) {
        this.leftTreeNode.value = this.buildTree(result.data.treeDataList[0], leftTreeRoot.id)
        console.log(this.leftTreeNode.value)
      }
      const rightTreeRoot = result.data.treeDataList[1].filter((item: any) => !item.parentId)
      if (rightTreeRoot) {
        this.rightTreeNode.value = this.buildTree(result.data.treeDataList[1], rightTreeRoot.id)
      }
    }
  }

  /**
   * 构建树
   */
  buildTree(array: Array<IKTreeNode>, parentId: string): Array<IKTreeNode> {
    const result = [] as Array<IKTreeNode>
    array.forEach(item => {
      if (item.parentId == parentId) {
        item.children = this.buildTree(array, item.id)
        result.push(item)
      }
    })
    return result
  }

  refresh() {
    this.refResultGrid.value?.refresh()
  }

  exportAsExcel() {
    const params = {
      columnKeys: this.refResultGrid.value?.getColumnDefs().map((x: any) => x.id)
    }
    this.refResultGrid.value?.exportDataAsExcel(params)
  }
}
