
import {
  EnumDataGridRowModelType,
  defineView,
  KDataGridRowSelectedEvent,
  EnumDataGridAutoSizeColumnsMode
} from '@kmsoft/upf-core'
import { KValidationPlanTabPropOptions, KValidationPlanTabEventEmits } from './interface'
import KValidationPlanTabViewModel from './KValidationPlanTabViewModel'
import { clsCodeMap, EnumValidationMetrics, EnumVerification } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KValidationPlanTab',
  props: KValidationPlanTabPropOptions,
  emits: KValidationPlanTabEventEmits,
  viewModel: KValidationPlanTabViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      clsCodeMap,
      EnumValidationMetrics,
      EnumVerification,
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      autosizeColumnsMode: EnumDataGridAutoSizeColumnsMode.FILL
    }
  }
})
