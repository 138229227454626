import { BaseViewModel, KSelectViewModel, SelectOption, ViewModelOptions, MemoryCacheFactory } from '@kmsoft/upf-core'
import { EnumOptionsType, KSelectWrapperEmitsType, KSelectWrapperPropType } from './interface'
import { EnumListCache } from '../../../../client-srv'
import { ref, watch } from 'vue'

/** KSelectWrapper */
export default class KSelectWrapperViewModel extends BaseViewModel<KSelectWrapperEmitsType, KSelectWrapperPropType> {
  private apiKey = 'server.baseApiUrl'
  refSelect = ref<KSelectViewModel>()
  options = ref<Array<SelectOption>>([])
  stateValue = ref<any>()
  originValue = ref<any>()
  disabled = ref<boolean | undefined>(this.props.disabled)
  stateReadonly = ref<boolean>()
  constructor(options: ViewModelOptions<KSelectWrapperPropType>) {
    super(options)
    this.initData()
    watch(
      () => options.props.optionType,
      () => {
        // this.initData()
      },
      {
        immediate: true
      }
    )
    watch(
      () => options.props.readonly,
      newVal => {
        this.stateReadonly.value = newVal
        this.refSelect.value?.setReadonly(newVal!)
      },
      {
        immediate: true
      }
    )
    watch(
      () => options.props.value,
      () => {
        this.originValue.value = options.props.value
        this.stateValue.value = options.props.value
      },
      {
        deep: true,
        immediate: true
      }
    )
    watch(
      () => options.props.optionsDisable,
      () => {
        this.initData()
      },
      {
        deep: true,
        immediate: true
      }
    )
  }

  viewDidMount() {}

  async initData() {
    const optionType = this.props.optionType
    switch (optionType) {
      case EnumOptionsType.OPTIONS:
        this.options.value = this.props.options
          .filter(item => !this.props.optionsDisable.includes(item.value))
          .map((item: { label: any; value: any }) => {
            if (['true', 'false'].includes(item.value)) {
              return {
                ...item,
                value: item.value == 'true'
              }
            } else {
              return item
            }
          })
        break
      case EnumOptionsType.Enum: {
        // eslint-disable-next-line no-case-declarations
        const refEnumCode = this.props.refEnumCode!
        // eslint-disable-next-line no-case-declarations
        // TODO 生命周期查询枚举
        const options = this.getEnumData(refEnumCode)
        this.options.value = (options || []) as Array<SelectOption>
        break
      }
      case EnumOptionsType.DATA_SOURCE_LINK:
        console.info('数据源,待完善')
        break
      default:
        break
    }
  }

  getEnumData(code: string) {
    const cacheInstance = MemoryCacheFactory.get<EnumListCache>(EnumListCache.cacheKey)
    const enumList = cacheInstance.getEnumByCode(code)
    return enumList?.map((x: { displayValue: any; actualValue: any }) => {
      return {
        label: x.displayValue,
        value: x.actualValue
      }
    })
  }

  setDisabled(value: boolean) {
    this.disabled.value = value
  }

  getValue() {
    return this.stateValue.value
  }
  setValue(value: any, setChanged?: boolean) {
    this.stateValue.value = value
    if (setChanged) {
      this.originValue.value = value
    }
    this.refSelect.value?.setValue(value, setChanged)
  }

  /**
   * 过滤下拉列表数据
   * @param list 过滤的值
   * @param type true为将list中的值过滤出来，false为排除list中的值
   */
  setFilterOption(list: any[], type: boolean = true) {
    this.options.value = this.props.options.filter(item => list.includes(item.value) == type)
  }

  getModifiedValue() {
    return this.stateValue.value
  }
  isModified() {
    return this.stateValue.value != this.originValue.value
  }
  displayValue() {
    if (this.options.value.length > 0) {
      return (
        this.options.value.find((item: { value: any }) => {
          let newVal = item.value
          if (['true', 'false'].includes(item.value)) {
            newVal = Boolean(newVal)
          }

          if (newVal == this.stateValue.value) {
            return item
          }
        })?.label || ''
      )
    } else {
      return ''
    }
  }
  onChange(value: any) {
    this.emit('change', value)
  }
  onSelect(value: any) {
    this.emit('select', value)
  }
  filterOption(inputValue: string, option: any) {
    return (
      option.label
        ?.toString()
        ?.toLowerCase()
        ?.indexOf(inputValue.toLowerCase()) >= 0 ||
      option.value
        ?.toString()
        ?.toLowerCase()
        ?.indexOf(inputValue.toLowerCase()) >= 0
    )
  }
}
