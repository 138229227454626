import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_lifecycle_state_tag = _resolveComponent("k-lifecycle-state-tag")!
  const _component_k_data_grid_check_box_column = _resolveComponent("k-data-grid-check-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    header: _withCtx(() => [
      _createVNode(_component_k_tool_strip, { name: "objClsToolStrip" })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        ref: "refDataGrid",
        rowKey: "linkId",
        autoIndex: true,
        isMultipleSelection: false,
        isLazyLoadTreeData: false,
        treeData: true,
        loadData: _ctx.$vm.loadData,
        rowModelType: 'serverSide',
        onRowDoubleClick: _ctx.$vm.onDoubleClick
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "master.number",
            name: "master.number",
            dataPropertyName: "master.number",
            headerText: "编码",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "name",
            name: "name",
            dataPropertyName: "name",
            headerText: "名称",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "className",
            name: "className",
            dataPropertyName: "className",
            headerText: "实体类型",
            align: "center"
          }, {
            default: _withCtx(({ cellValue}) => [
              _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "version",
            name: "version",
            dataPropertyName: "version",
            headerText: "版本",
            align: "center"
          }, {
            default: _withCtx(({ row}) => [
              _createTextVNode(_toDisplayString(row.version + '.' + row.iteration), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "lifecycleState",
            name: "lifecycleState",
            dataPropertyName: "lifecycleState",
            headerText: "状态",
            width: "120",
            align: "center"
          }, {
            default: _withCtx(({ row }) => [
              _createVNode(_component_k_lifecycle_state_tag, { row: row }, null, 8, ["row"])
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_check_box_column, {
            id: "workingCopy",
            name: "workingCopy",
            dataPropertyName: "workingCopy",
            headerText: "检出标记",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "createTime",
            name: "createTime",
            dataPropertyName: "createTime",
            headerText: "创建时间",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "creator",
            name: "creator",
            dataPropertyName: "creator",
            headerText: "创建者",
            align: "center"
          }, {
            default: _withCtx(({ cellValue}) => [
              _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "description",
            name: "description",
            dataPropertyName: "description",
            headerText: "描述",
            align: "center"
          })
        ]),
        _: 1
      }, 8, ["loadData", "onRowDoubleClick"])
    ]),
    _: 1
  }))
}