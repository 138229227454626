import { BaseViewModel, DateTime, KFormViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KBaselineFormEmitsType, KBaselineFormPropType } from './interface'
import { ref } from 'vue'
import { IKFolderFormDef } from '../../../../../ebf-folder/src/pages/folder-manage/folder-form/interface'

/** KBaselineForm */
export default class KBaselineFormViewModel extends BaseViewModel<KBaselineFormEmitsType, KBaselineFormPropType> {
  refBaselineForm = ref<KFormViewModel>()
  //表单绑定数据
  formData = ref({
    baselineName: ''
  })
  constructor(options: ViewModelOptions<KBaselineFormPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * getNormalData
   */
  public async getFormData() {
    try {
      await this.refBaselineForm.value?.validate()
      return this.formData.value
    } catch (error) {
      return false
    }
  }
}
