import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  KTreeNode
} from '@kmsoft/upf-core'

/** 参数 **/
export const KRequirementChangeAfterPropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<any>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KRequirementChangeAfterPropType = ViewPropsTypeExtract<typeof KRequirementChangeAfterPropOptions>

/** 事件 */
export const KRequirementChangeAfterEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KRequirementChangeAfterEmitsType = ViewEmitsTypeExtract<typeof KRequirementChangeAfterEventEmits>

export class NeedsTreeNode extends KTreeNode {
  /**
   * 图标
   */
  iconType: string
  /**
   * 父id
   */
  parentId: string
  /**
   * 对象类
   * @param
   */
  className: string

  constructor(data: NodeData) {
    super()
    this.id = data.id
    this.name = data.name
    this.leaf = !data.hasChildren
    this.iconType = 'folder'
    this.parentId = data.parentId
    this.className = data.className
  }
  async populateChildren(populateType: number): Promise<Array<KTreeNode>> {
    const newChild = [] as Array<KTreeNode>
    if (this.leaf) {
      return newChild
    }
    const param = {
      data: [this.id]
    }
    let service = this.className
    if (this.parentId == '-1') {
      service = this.className + 'Section'
    }
    const result = await Api.post('requirement', service, 'listChildNode', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      for (const item of result.data!) {
        const childNode = new NeedsTreeNode(item as any)
        childNode.className = item.type
        newChild.push(childNode)
      }
    }
    return newChild
  }
}

export interface NodeData {
  /**
   * 目录Id
   */
  id: string
  /**
   * 父目录Id
   */
  parentId: string
  /**
   * 目录名称
   */
  name: string
  /**
   * 编码
   */
  nameEn: string
  /**
   * 类型
   */
  type: string
  /**
   * 根节点类型
   */
  rootNodeType?: string
  /**
   * 描述
   */
  description: string

  /**
   * 创建时间
   */
  createTime: string

  /**
   * 创建人id
   */
  creator: string

  /**
   * 创建人名称
   */
  creatorName: string
  /**
   * 是否叶子节点
   */
  hasChildren: boolean
  /**
   * 图标
   */
  iconType?: string
  /**
   * 对象类
   */
  className: string
}
