import { BaseViewModel, KContainer, KControl, KNotification, KSchema, ViewModelOptions, AppContext } from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import { EnumLayoutSchemaType, LayoutClientSrv, MetaClass, ObjBusinessBase } from '../../client-srv'
import { KObjectPropertyEditPanelEmitsType, KObjectPropertyEditPanelPropType } from './interface'
import { EnumToolStripItemKeys } from '../index'
import { KObjectPropertyEditPanelBaseViewModel } from './controls'
import lodash, { isArray } from 'lodash'

/** 对象编辑面板 */
export default class KObjectPropertyEditPanelViewModel extends BaseViewModel<
  KObjectPropertyEditPanelEmitsType,
  KObjectPropertyEditPanelPropType
> {
  /** 属性面板引用 */
  refObjectPropertyEditPanelBase = ref<KObjectPropertyEditPanelBaseViewModel>()
  /** 对象类定义 */
  metaClass: MetaClass
  /** 是否正在加载 */
  isLoading = ref<boolean>(true)
  /** 布局 */
  schema = ref<KSchema>()
  /** 原始布局 */
  orgSchema: KSchema

  constructor(options: ViewModelOptions<KObjectPropertyEditPanelPropType>) {
    super(options)

    watch(
      () => this.props.objParam,
      (newValue, oldValue) => {
        // 如果对象类不变，则不重新拉取对象类信息
        if (newValue && oldValue) {
          // if (newValue?.modelCode == oldValue?.modelCode) {
          if (newValue.id !== oldValue.id) {
            // this.isLoading.value = true
            this.cancelEdit()
            this.refresh().then(() => {
              // this.isLoading.value = false
            })
          }
          return
          // }
        }
        this.init()
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {
    //控制按钮状态
    // this.initKiaguidLimit()
  }

  /**
   * 初始化
   * @description 初始化
   */
  init() {
    this.isLoading.value = true
    this.metaClass = {} as any
    this.isLoading.value = false
  }

  initKiaguidLimit() {
    const hasKiaguid = AppContext.current.getEnvironment().getProperty<string>('app.dataPermissionControl')
    if (hasKiaguid != 'true') return
    const kiaguid = this.props.objParam.kiaguid

    if (!kiaguid?.includes('MODIFY')) {
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, true)
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT, true)
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT, true)
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN, true)
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_MOVE, true)
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CUT, true)
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_PASTE, true)
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT_PART, true)
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT_PART, true)
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN_PART, true)
    }
    if (!kiaguid?.includes('CREATE')) {
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS, true)
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(
        EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_PART_VIEW_VERSION,
        true
      )
    }
    if (!kiaguid?.includes('REVISE')) {
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_REVISE, true)
    }
    if (!kiaguid?.includes('DOWNLOAD')) {
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD_DOC, true)
    }
    if (!kiaguid?.includes('DELETE')) {
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_OPTION, true)
    }
    if (!kiaguid?.includes('MANAGE')) {
      this.refObjectPropertyEditPanelBase.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE, true)
    }
  }

  /**
   * 获取面板名称
   * @returns
   */
  getPanelName() {
    // switch (this.props.objParam.group) {
    //   case EnumClassTemplate.DOC:
    //     return 'KDocObjectPropertyEditPanel'
    //   default:
    return 'KObjectPropertyEditPanelBase'
    // }
  }

  /**
   * 刷新
   */
  async refresh() {
    return this.refObjectPropertyEditPanelBase.value?.refresh()
  }

  getValue() {
    return this.refObjectPropertyEditPanelBase.value?.getValue()
  }

  getObjParam() {
    return this.props.objParam
  }

  getModifiedValue() {
    return this.refObjectPropertyEditPanelBase.value?.getModifiedValue()
  }
  setReadOnly(value: boolean) {
    this.refObjectPropertyEditPanelBase.value?.setReadOnly(value)
  }
  setItemVisible(id: string, isVisible: boolean) {
    this.refObjectPropertyEditPanelBase.value?.setItemVisible(id, isVisible)
  }
  setItemDisabled(id: string, isVisible: boolean) {
    // const limit: any = {
    //   MODIFY: [
    //     EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY,
    //     EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT,
    //     EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT,
    //     EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN,
    //     EnumToolStripItemKeys.TOOL_STRIP_ITEM_MOVE,
    //     EnumToolStripItemKeys.TOOL_STRIP_ITEM_CUT,
    //     EnumToolStripItemKeys.TOOL_STRIP_ITEM_PASTE,
    //     EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT_PART,
    //     EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT_PART,
    //     EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN_PART
    //   ],
    //   CREATE: [EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS, EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_PART_VIEW_VERSION],
    //   REVISE: [EnumToolStripItemKeys.TOOL_STRIP_ITEM_REVISE],
    //   DOWNLOAD: [EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD_DOC],
    //   DELETE: [EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_OPTION]
    // }
    // let hasLimit = false
    // const hasKiaguid = AppContext.current.getEnvironment().getProperty<string>('app.dataPermissionControl')
    // if (hasKiaguid == 'true') {
    //   for (const key in limit) {
    //     if (limit[key].includes(id) && !isVisible) {
    //       hasLimit = !this.props.objParam.kiaguid?.includes(key)
    //     }
    //   }
    // }
    // if (hasLimit) return
    this.refObjectPropertyEditPanelBase.value?.setItemDisabled(id, isVisible)
  }

  openCategoryAttributes(id: string | null) {
    return this.refObjectPropertyEditPanelBase.value?.openCategoryAttributes(id)
  }

  validate() {
    if (!this.refObjectPropertyEditPanelBase.value) {
      return false
    }
    /** 修改值 */
    const value = this.getValue() as ObjBusinessBase
    /** 修改的值 */
    const modifiedValue = this.getModifiedValue()
    /** 不符合要求的字段 */
    const nonComplianceProperties: Array<string> = []

    //#region 属性校验
    const schame = this.refObjectPropertyEditPanelBase.value?.getSchame()

    //1.递归获取属性校验
    for (const key of Object.keys(value)) {
      const property = (this.getProperty(schame!, key) as unknown) as KControl
      console.log(key, property)

      if (property && (property.nullable || (property.nullable as any) == 1)) {
        continue
      }

      if (property && property.field! in value) {
        const field = property.field!
        //1.若存在值为对象的情况,则校验id
        //2.校验数组
        //3.校验字符串
        const type = typeof value[field] == 'object'
        const isExtend = field.includes('extendedAttributes')
        if (isExtend) {
          value.extAttrs.forEach((item: any) => {
            if (item.name === field.split('#')[1] && !item.value) {
              nonComplianceProperties.push(property.title)
            }
          })
        } else if (type && value[field] && 'id' in value[field] && value[field]['id'] === '') {
          nonComplianceProperties.push(property.title)
        } else if (isArray(value[field]) && value[field] && value[field].length == 0) {
          nonComplianceProperties.push(property.title)
        } else if (value[field] === '' || value[field] == undefined) {
          nonComplianceProperties.push(property.title)
        }
      }
    }

    // 如果有不符合要求的字段
    if (nonComplianceProperties.length > 0) {
      KNotification.warning({
        title: '属性空',
        content: `以下属性不能为空:\r\n${nonComplianceProperties.join(',')}`
      })
      return false
    }
    return true
  }

  /**获取schema 中的属性信息 */
  private getProperty(schema: KSchema | KContainer, key: string): KContainer | KControl | undefined {
    const newSchema = isArray(schema) ? schema : [schema]
    for (const control of newSchema) {
      if (((control as unknown) as KControl).field === key) {
        return (control as unknown) as KControl
      }
      if (control.controls && control.controls.length > 0) {
        const result = this.getProperty((control.controls as unknown) as KContainer, key)
        if (result) {
          return result
        }
      }
    }
    return undefined
  }

  /** 初始化布局 */
  private async initSchame() {
    // /** 布局 */
    // const scheme = await this.getSchemaById(this.props.objParam.modelCode)
    // if (Object.keys(scheme).length > 0) {
    //   // 原始布局
    //   this.orgSchema = lodash.cloneDeep(scheme)
    //   // // 处理 schema
    //   // processSchema(scheme.controls, {
    //   //   // api: this.getEditorApi,
    //   //   environment: EnumEditorEnvironment.PROPERTY_PANEL
    //   // })
    //   this.schema.value = scheme
    // } else {
    //   this.schema.value = undefined
    // }
  }

  // /**
  //  * 通过 对象类Code 获取布局定义
  //  * @param modelCode
  //  * @returns
  //  */
  // private async getSchemaById(modelCode: string): Promise<KSchema> {
  //   if (this.props.objParam.modelGroup) {
  //     return await LayoutClientSrv.getSchemeById(this.props.objParam.modelGroup, modelCode, EnumLayoutSchemaType.FormEdit)
  //   }
  //   return {} as KSchema
  // }

  setEditItemValue(value: boolean) {
    this.refObjectPropertyEditPanelBase.value?.setEditItemValue(value)
  }

  /** 取消编辑 */
  cancelEdit() {
    this.refObjectPropertyEditPanelBase.value?.cancelEditFun()
  }
  getContainer() {
    return this.refObjectPropertyEditPanelBase.value?.getContainer()
  }
}
