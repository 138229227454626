import { BaseViewModel, ViewModelOptions, KDialog, request, KNotification } from '@kmsoft/upf-core'
import { ConfigClientSrv, EnumRequestCode, Api } from '../../client-srv'
import { KObjectStructFileUploadEmitsType, KObjectStructFileUploadPropType } from './interface'
import { ref } from 'vue'

/** KObjectStructFileUpload */
export default class KObjectStructFileUploadViewModel extends BaseViewModel<
  KObjectStructFileUploadEmitsType,
  KObjectStructFileUploadPropType
> {
  fileId = ref<string>()
  uploadSign = ref<boolean>(false)
  fileType = ref<string>()
  constructor(options: ViewModelOptions<KObjectStructFileUploadPropType>) {
    super(options)
  }

  viewDidMount() {}

  downloadTemplate(type: string) {
    const url = `${ConfigClientSrv.getFileApiBaseUrl}/importModule/download?type=${type}`
    // a标签下载
    const link = document.createElement('a')
    link.href = url
    link.download = `导入模板.docx`
    link.click()
  }

  async uploadFile() {
    try {
      // 选择文件
      const file = await this.selectFile('.docx,.xlsx')
      if (!file.name.toLowerCase().endsWith('.docx') && !file.name.toLowerCase().endsWith('.xlsx')) {
        KDialog.warning({ title: '提示', content: '请选择 .docx,.xlsx 文件' })
        return
      }
      if (file.size <= 0) {
        KNotification.warn({
          message: '系统提示',
          description: '上传文件为空文件'
        })
        return
      }
      this.uploadSign.value = false
      this.fileId.value = ''

      // 显示导入提示对话框
      const dialog = KDialog.info({ content: '正在上传文档数据，请稍后...', title: '提示', showOk: false })

      try {
        // 普通上传文件
        const fileId = await this.uploadPrimaryFile(file)
        if (!fileId) {
          throw new Error('上传文件失败')
        }
        this.fileType.value = file.name?.substring(file.name?.lastIndexOf('.') + 1)
        this.uploadSign.value = true
        this.fileId.value = fileId
      } finally {
        dialog.destroy() // 销毁对话框
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : '导入失败'
      KNotification.error({ title: '提示', content: errorMessage })
    }
  }

  private selectFile(accept: string): Promise<File> {
    return new Promise((resolve, reject) => {
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.accept = accept

      fileInput.onchange = event => {
        const files = (event.target as HTMLInputElement).files
        if (files && files.length > 0) {
          resolve(files[0])
        } else {
          reject(new Error('未选择文件'))
        }
      }
      fileInput.click()
    })
  }

  async uploadPrimaryFile(file: File) {
    const req = new FormData()
    req.append('modelName', this.props.modelName)
    req.append('attributeName', this.props.attributeName)
    req.append('files', file)
    const result = (await request.post('/kmsaasFileApi/upload', req, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })) as any
    if (result && result.result == 'SUCCESS') {
      const data = result.data
      return data[0]
    }
  }

  getFileId() {
    return { fileId: this.fileId.value, type: this.fileType.value }
  }
}
