import { connect, withInstall } from '@kmsoft/upf-core'
import KCustomerNeedsCreateWithFolderSelectorView from './KCustomerNeedsCreateWithFolderSelector.vue'
import KCustomerNeedsCreateWithFolderSelectorViewModel from './KCustomerNeedsCreateWithFolderSelectorViewModel'

const KCustomerNeedsCreateWithFolderSelector = connect(
  KCustomerNeedsCreateWithFolderSelectorView,
  KCustomerNeedsCreateWithFolderSelectorViewModel
)

export default withInstall(KCustomerNeedsCreateWithFolderSelector)
export {
  KCustomerNeedsCreateWithFolderSelector,
  KCustomerNeedsCreateWithFolderSelectorView,
  KCustomerNeedsCreateWithFolderSelectorViewModel
}

// 模板生成文件
// export * from './{{folderName}}'
