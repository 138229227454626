import { connect, withInstall } from '@kmsoft/upf-core'
import KEditFormView from './KEditForm.vue'
import KEditFormViewModel from './KEditFormViewModel'

const KEditForm = connect(KEditFormView, KEditFormViewModel)

export default withInstall(KEditForm)
export { KEditForm, KEditFormView, KEditFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'
