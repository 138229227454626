import { ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KValidationMetricsManagePropOptions = {
  ...BaseViewPropOptions,
  showToolStrip: VuePropTypes.bool().def(true),
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  onDataUpdated: VuePropTypes.func().def(),
  container: VuePropTypes.createType<HTMLElement>().def(),
  oldRefMetricsParams: VuePropTypes.createType<Record<string, any>[]>().def()
}

/** 参数类型 **/
export type KValidationMetricsManagePropType = ViewPropsTypeExtract<typeof KValidationMetricsManagePropOptions>

/** 事件 */
export const KValidationMetricsManageEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KValidationMetricsManageEmitsType = ViewEmitsTypeExtract<typeof KValidationMetricsManageEventEmits>
