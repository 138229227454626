import { App, Plugin } from 'vue'
import './style'

import KBizScriptEditor from './biz-script-editor'
export * from './biz-script-editor'
export * from './class-property-selector'
export * from './directory-tree'
export * from './file-uploader'
export * from './object-class-grid'
export * from './object-class-grid-filter'
export * from './object-class-grid-find'
export * from './object-class-manage'
export * from './object-class-tree'
export * from './object-dynamic-create-panel'
export * from './object-create-panel'
export * from './object-panel'
export * from './object-property-edit-panel'
export * from './object-property-editor'
export * from './object-property-panel'
export * from './object-relation'
export * from './object-relation-grid'
export * from './object-relations'
export * from './object-search'
export * from './object-searcher'
export * from './object-selector'
export * from './object-tool-strip'
export * from './object-version-grid'
export * from './quick-search-bar'
export * from './rule-definer'
export * from './folder-selector'
export * from './object-workspace'
export * from './object-file-upload'
export * from './code-generator'
export * from './object-struct-file-upload'
export * from './office-preview'
export * from './lifecycle-state-tag'
export * from './log-manage'

import KClassPropertySelector from './class-property-selector'

import KDirectoryTree from './directory-tree'

import KFileUploader from './file-uploader'

import KObjectClassGrid from './object-class-grid'

import KObjectClassGridFind from './object-class-grid-find'

import KObjectClassGridFilter from './object-class-grid-filter'

import KObjectClassManage from './object-class-manage'

import KObjectCreatePanel from './object-create-panel'
import KObjectCreate from './object-dynamic-create-panel'

import KObjectPanel from './object-panel'

import KObjectPropertyEditPanel from './object-property-edit-panel'

import KObjectPropertyPanel from './object-property-panel'

import KObjectClassTree from './object-class-tree'

import KRuleDefiner from './rule-definer'

import KObjectSearch from './object-search'

import KObjectSearcher from './object-searcher'

import KObjectSelector from './object-selector'

import KObjectToolStrip from './object-tool-strip'

import KObjectVersionGrid from './object-version-grid'

import KQuickSearchBar from './quick-search-bar'

import KObjectPropertyEditor from './object-property-editor'

import KObjectRelationGrid from './object-relation-grid'

import KObjectRelations from './object-relations'
import KObjectViewSelector from './object-view-selector'

import Editors from './editors'
import KObjectRelation from './object-relation'
import KObjectFileUpload from './object-file-upload'
import KStructrureGraph from './structrure-graph'

export * from './class-property-selector/property-grid'
export * from './editors'
export * from './temp-user-selector'
export * from './office-document-editor'

import KPropertyGrid from './class-property-selector/property-grid'

import KFolderSelector from './folder-selector'

export * from './toolstrip'

import KConfigForm from './config-form'
import KObjectWorkspace from './object-workspace'
import KCodeGenerator from './code-generator'
import KBottomBar from './object-class-grid/bottom-bar'
import KOfficeDocumentEditor from './office-document-editor'
import KObjectStructFileUpload from './object-struct-file-upload'
import KOfficePreview from './office-preview'
import KLifecycleStateTag from './lifecycle-state-tag'

const components: Record<string, Plugin> = {
  KBizScriptEditor,
  KClassPropertySelector,
  KDirectoryTree,
  KObjectCreate,
  KObjectCreatePanel,
  KObjectPanel,
  KObjectPropertyEditPanel,
  KObjectPropertyPanel,
  KObjectClassTree,
  KRuleDefiner,
  KObjectPropertyEditor,
  KObjectClassGrid,
  KObjectSearch,
  KObjectSearcher,
  KObjectSelector,
  KObjectToolStrip,
  KObjectVersionGrid,
  KQuickSearchBar,
  KObjectClassGridFilter,
  KObjectClassGridFind,
  KObjectClassManage,
  KObjectRelationGrid,
  KObjectRelation,
  Editors,
  KFolderSelector,
  KCodeGenerator,
  KFileUploader,
  KPropertyGrid,
  KObjectRelations,
  KConfigForm,
  KObjectWorkspace,
  KObjectFileUpload,
  KBottomBar,
  KStructrureGraph,
  KObjectViewSelector,
  KOfficeDocumentEditor,
  KObjectStructFileUpload,
  KOfficePreview,
  KLifecycleStateTag
}

const install = function(app: App) {
  Object.keys(components).forEach(key => {
    app.use(components[key])
  })
  console.log('app', app)
}

export default {
  install: install
}
