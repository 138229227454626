/**
 * 产品工具条按钮Id枚举
 */
export enum EnumToolStripItemKeys {
  //#region 通用菜单按钮
  /** 刷新 */
  TOOL_STRIP_ITEM_REFRESH = 'toolStripItemRefresh',
  /** 重置 */
  TOOL_STRIP_ITEM_RESET = 'TOOL_STRIP_ITEM_RESET',
  /** 查找 */
  TOOL_STRIP_ITEM_FIND = 'toolStripItemFind',
  /** 新建 */
  TOOL_STRIP_ITEM_NEW = 'toolStripItemNew',
  /** 添加 */
  TOOL_STRIP_ITEM_ADD = 'toolStripItemAdd',
  /** 插入 */
  TOOL_STRIP_ITEM_INSERT = 'toolStripItemInsert',
  /** 删除 */
  TOOL_STRIP_ITEM_DELETE = 'toolStripItemDelete',
  /** 删除分支 */
  TOOL_STRIP_ITEM_DELETE_BRANCH = 'toolStripItemDeleteBranch',
  /** 删除选项 */
  TOOL_STRIP_ITEM_DELETE_OPTION = 'toolStripItemDeleteOption',
  /** 移除 */
  TOOL_STRIP_ITEM_REMOVE = 'toolStripItemRemove',
  /** 编辑 */
  TOOL_STRIP_ITEM_EDIT = 'toolStripItemEdit',
  /** 导出Excel */
  TOOL_STRIP_ITEM_EXPORT_EXCEL = 'ExportExcel',
  /** 查看报表 */
  TOOL_STRIP_ITEM_REPORT = 'Report',
  /** 引入 */
  TOOL_STRIP_IMPORT = 'ToolStripImport',
  /** 显示复选框 */
  TOOL_STRIP_ITEM_SHOW_CHECKBOX = 'ShowCheckbox',
  /** 隐藏复选框 */
  TOOL_STRIP_ITEM_HIDE_CHECKBOX = 'HideCheckbox',
  //#endregion
  //#region 调整顺序
  /** 上移 */
  TOOL_STRIP_ITEM_MOVE_UP = 'MoveUp',
  /** 下移 */
  TOOL_STRIP_ITEM_MOVE_DOWN = 'MoveDown',
  /** 移到最顶 */
  TOOL_STRIP_ITEM_MOVE_TO_TOP = 'MoveToTop',
  /** 移到最底 */
  TOOL_STRIP_ITEM_MOVE_TO_BOTTOM = 'MoveToBottom',
  /** 重新生成序号 */
  TOOL_STRIP_ITEM_REINDEX = 'Reindex',
  /** 根据序号排序 */
  TOOL_STRIP_ITEM_SORT_BY_INDEX = 'SortByIndex',
  /** 根据序号排序 */
  TOOL_STRIP_ITEM_ROW_SORT = 'RowSort',
  //#endregion
  //#region 对象类网格
  /** 对象分组 */
  TOOL_STRIP_GROUP_OBJ = 'toolStripGroupObj',
  /** 创建 */
  TOOL_STRIP_ITEM_NEWOBJ = 'toolStripItemNewObj',
  /** 创建大版本*/
  TOOL_STRIP_ITEM_NEWVERISON = 'toolStripItemNewVerison',
  /** 创建小版本 */
  TOOL_STRIP_ITEM_NEWVERISON_OF_MINOR = 'toolStripItemNewVerisonOfMinor',
  /** 另存*/
  TOOL_STRIP_ITEM_SAVEAS = 'toolStripItemSaveAs',
  /** 使用统计 */
  TOOL_STRIP_ITEM_USING_STATISTICS = 'toolStripItemUsingStatistics',
  /** 批量转通用件 */
  TOOL_STRIP_ITEM_CONVERT = 'toolStripItemConvert',
  /** 发布*/
  TOOL_STRIP_ITEM_PUBLISH = 'toolStripItemPublish',
  /**废弃 */
  TOOL_STRIP_ITEM_OBSOLETE = 'toolStripeItemObsolete',
  /** 冻结 */
  TOOL_STRIP_ITEM_FROZEN = 'toolStripItemFrozen',
  /** 修改所有者 */
  TOOL_STRIP_ITEM_EDIT_OWNER = 'toolStripItemEditOwner',

  /** 操作分组 */
  TOOL_STRIP_GROUP_OPERATION = 'toolStripItemOperation',
  /** 操作分组 */
  TOOL_STRIP_OBJ_GROUP_OPERATION = 'toolStripItemObjOperation',
  /** 打开 */
  TOOL_STRIP_ITEM_OPEN_OBJ = 'toolStripItemOpenObj',
  /** 打开主描述对象 */
  TOOL_STRIP_ITEM_OPEN_MAIN_DESC_OBJ = 'toolStripItemOpenMainDescObj',
  /** 解冻 */
  TOOL_STRIP_ITEM_UN_FREEZE = 'toolStripItemUnFreeze',
  // /** 查看流程 */
  TOOL_STRIP_ITEM_VIEW_FLOW_OBJ = 'toolStripItemViewFlowObj',
  // /** 加入到现有流程 */
  // TOOL_STRIP_ITEM_ADD_TO_EXIST_FLOW = 'toolStripItemAddToExistFlow',
  // /** 按批次加入流程 */
  // TOOL_STRIP_ITEM_BATCH_ADD_TO_FLOW = 'toolStripItemBatchAddToFlow',
  // /** 加入到现有批次任务 */
  // TOOL_STRIP_ITEM_ADD_TO_BATCH_TASK = 'toolStripItemAddToBatchTask',
  // /** 终止流程 */
  // TOOL_STRIP_STOP = 'ToolStripStop',
  // /** 重新启动流程 */
  // TOOL_STRIP_RESTART = 'ToolStripRestart',
  // /** 流程选项 */
  // TOOL_STRIP_FLOW_OPTION = 'ToolStripFlowOption',
  /** 查看主对象 */
  TOOL_STRIP_ITEM_VIEW_MAIN_OBJ = 'toolStripItemViewMainObj',
  /** 主描述关系 */
  TOOL_STRIP_ITEM_MAIN_DESC_REL = 'toolStripItemMainDescRel',
  /** 参考 */
  TOOL_STRIP_ITEM_CONSULT = 'toolStripItemConsult',
  /** 文件替换 */
  TOOL_STRIP_ITEM_FILE_REPLACE = 'toolStripItemFileReplace',
  /** 文件添加 */
  TOOL_STRIP_ITEM_FILE_ADD = 'toolStripItemFileAdd',
  /** 文件替换-从本地添加 */
  TOOL_STRIP_ITEM_FILE_REPLACE_FROM_LOCAL_FILE = 'toolStripItemFileReplaceFromLocalFile',
  /** 替换-从模板添加 */
  TOOL_STRIP_FILE_REPLACE_NEW = 'toolStripFileReplace_New',
  /** 文件替换-查找 */
  TOOL_STRIP_ITEM_FILE_REPLACE_QUERY = 'toolStripItemFileReplaceFromQuery',
  /** 浏览 */
  TOOL_STRIP_ITEM_BROWSE_DOC = 'toolStripItemBrowseDoc',
  /** 浏览主描述文档 */
  TOOL_STRIP_ITEM_BROWSE_MAIN_DESC_DOC = 'toolStripItemBrowseMainDescDoc',
  /** 浏览签字文件 */
  TOOL_STRIP_ITEM_BROWSE_ASSIGN_DOC = 'toolStripItemBrowseAssignDoc',
  /** 编辑 */
  TOOL_STRIP_ITEM_EDIT_DOC = 'toolStripItemEditDoc',
  /** 编辑工程图 */
  TOOL_STRIP_ITEM_EDIT_PROJECT_DOC = 'toolStripItemEditProjectDoc',
  /** 取消检出 */
  TOOL_STRIP_ITEM_UN_CHECK_OUT = 'toolStripItemUnCheckOutDoc',
  /** 检出 */
  TOOL_STRIP_ITEM_CHECK_OUT = 'toolStripItemCheckOutDoc',
  /** 检入 */
  TOOL_STRIP_ITEM_CHECK_IN = 'toolStripItemCheckInDoc',
  /** 修订 */
  TOOL_STRIP_ITEM_REVISE = 'toolStripItemRevise',
  /** 下载 */
  TOOL_STRIP_ITEM_DOWNLOAD_DOC = 'toolStripItemDownloadDoc',
  /** 历史版本记录 */
  TOOL_STRIP_ITEM_HISTORY_VERSION = 'toolStripItemHistoryVersion',
  /** 打印 */
  TOOL_STRIP_ITEM_PRINT = 'toolStripItemPrint',
  /** 程序打印 */
  TOOL_STRIP_APP_PRINT = 'toolStripAppPrint',
  /** 原文件打印 */
  TOOL_STRIP_SOURCE_APP_PRINT = 'toolStripSourceAppPrint',
  /**拼图打印 */
  TOOL_STRIP_MENU_ITEM_PINTU_PRINT = 'tsMenuItemPintuPrint',
  /**批注 */
  TOOL_STRIP_REMARK = 'toolStripRemark',
  /**查看批注 */
  TOOL_STRIP_VIEW_REMARK = 'toolStripViewRemark',
  /**查看批注历史 */
  TOOL_STRIP_VIEW_REMARK_HISTORY = 'toolStripViewRemarkHistory',
  /**批注 */
  TOOL_STRIP_REMARK_OPTION = 'toolStripRemarkOption',
  /** 结构管理 */
  TOOL_STRIP_ITEM_STRUC_MANAGE = 'toolStripItemStrucManage',
  /** 开始 */
  TOOL_STRIP_ITEM_BEGIN = 'toolStripItemBegin',
  /** 结束 */
  TOOL_STRIP_ITEM_END = 'toolStripItemEnd',
  /** 创建需求变更请求 */
  TOOL_STRIP_ITEM_CREATE_REQUIREMENT_CHANGE = 'toolStripItemCreateRequirementChange',
  /**需求订阅 */
  TOOL_STRIP_ITEM_SUBSCRIBE_REQUIREMENT = 'toolStripItemSubscribeRequirement',
  /**移除订阅 */
  TOOL_STRIP_ITEM_UNSUBSCRIBE_REQUIREMENT = 'toolStripItemUnsubscribeRequirement',
  /** 结构比较 */
  TOOL_STRIP_ITEM_STRUC_COMPARE = 'toolStripItemStrucCompare',
  /** 关联比较 */
  TOOL_STRIP_ITEM_RELATION_COMPARE = 'toolStripItemRelationCompare',
  /** 批量编辑属性 */
  TOOL_STRIP_ITEM_BATCH_EDIT = 'toolStripItemBatchEdit',
  /** 批量复制 */
  TOOL_STRIP_ITEM_BATCH_COPY = 'toolStripItemBatchCopy',
  /** 收集文档 */
  TOOL_STRIP_ITEM_COLLECT_RELATED_DOC = 'TOOL_STRIP_ITEM_COLLECT_RELATED_DOC',

  /** 创建bom视图 */
  TOOL_STRIP_ITEM_CREATE_BOM_VIEW = 'toolStripItemCreateBomView',

  /** 发送到 */
  TOOL_STRIP_ITEM_SEND_TO = 'toolStripItemSendTo',
  /** 发生到我的目录 */
  TOOL_STRIP_ITEM_SEND_TO_DIRECTORY = 'toolStripItemSendToMyDirectory',
  /** 复制 */
  TOOL_STRIP_ITEM_COPY = 'toolStripItemCopy',
  /** 粘贴 */
  TOOL_STRIP_ITEM_PASTE = 'toolStripItemPaste',
  /** 剪切 */
  TOOL_STRIP_ITEM_CUT = 'toolStripItemCut',
  /** 移除对象链接 */
  TOOL_STRIP_ITEM_REMOVE_OBJ_LINK = 'toolStripItemRemoveObjLink',
  /** 移动 */
  TOOL_STRIP_ITEM_MOVE = 'toolStripItemMove',
  /** 共享 */
  TOOL_STRIP_ITEM_SHARE = 'toolStripItemShare',

  /** 何处使用*/
  TOOL_STRIP_ITEM_WHERE_USE = 'toolStripItemWhereUse',
  /** 单层 */
  TOOL_STRIP_ITEM_WHERE_USE_SINGLE_LEVEL = 'toolStripItemWhereUseSingleLevel',
  /** 多层*/
  TOOL_STRIP_ITEM_WHERE_USE_MULTI_LEVEL = 'toolStripItemWhereUseMultiLevel',
  /** 环检测 */
  TOOL_STRIP_ITEM_WHERE_USE_CHECK_CIRCLE = 'toolStripItemWhereUseCheckCircle',
  /** MABOM*/
  TOOL_STRIP_ITEM_WHERE_USE_MABOM = 'toolStripItemWhereUseMABOM',

  /** 更多 */
  TOOL_STRIP_GROUP_MORE = 'toolStripItemGroupMore',

  /** 列表分组 */
  TOOL_STRIP_GROUP_GRID_OPERATION = 'toolStripGroupGridOperation',
  /** 排序*/
  TOOL_STRIP_ITEM_SORT = 'toolStripItemSort',
  /** 过滤 */
  TOOL_STRIP_ITEM_FILTER = 'toolStripItemFilter',
  /** 取消过滤 */
  TOOL_STRIP_ITEM_NO_FILTER = 'toolStripItemNoFilter',
  /** 修改对象类 */
  TOOL_STRIP_ITEM_CHANGE_OBJCLS = 'toolStripItemChangeObjCls',
  /** 刷新选中行*/
  TOOL_STRIP_ITEM_REFRESH_SELECT_ROW = 'toolStripItemRefreshSelectRow',

  /** 列表视图 */
  TOOL_STRIP_ITEM_ALL_VERSIONS = 'toolStripItemAllVersions',
  /** 显示子类*/
  TOOL_STRIP_ITEM_SHOW_SUBCLASS = 'toolStripItemShowSubclass',
  //#endregion
  //#region 属性面板
  /** 编辑属性 */
  TOOL_STRIP_ITEM_EDIT_PROPERTY = 'toolStripItemEditProperty',
  // /** 完成编辑 */
  // TOOL_STRIP_ITEM_EDIT_PROPERTY_FINISH = 'toolStripItemEditPropertyFinish',
  /** 保存 */
  TOOL_STRIP_ITEM_SAVE = 'toolStripItemSave',
  /** 还原 */
  TOOL_STRIP_ITEM_RESTORE = 'toolStripItemRestore',
  /** 全局查询 */
  TOOL_STRIP_ITME_GLOBAL_SEARCH = 'toolStripItemGlobalSearch',
  //#endregion
  //#region 布局设计
  /** 布局分组 */
  TOOL_STRIP_GROUP_LAYOUT = 'toolStripItemGroupLayout',
  /** 设计布局 */
  TOOL_STRIP_ITEM_DESIGN_LAYOUT = 'toolStripItemDesignLayout',
  /** 保存全局布局 */
  TOOL_STRIP_ITEM_SAVE_GLOBAL_LAYOUT = 'toolStripItemSaveGlobalLayout',
  /** 清理全局布局 */
  TOOL_STRIP_ITEM_CLEAR_GLOBAL_LAYOUT = 'toolStripItemClearGlobalLayout',
  /** 保存本地布局 */
  TOOL_STRIP_ITEM_SAVE_LOCAL_LAYOUT = 'toolStripItemSaveLocalLayout',
  /** 清理本地布局 */
  TOOL_STRIP_ITEM_CLEAR_LOCAL_LAYOUT = 'toolStripItemClearLocalLayout',
  //#endregion
  //#region 选择用户
  /** 用户模式 */
  TOOL_STRIP_ITEM_USER_SELECT_LIST = 'toolStripItemUserSelectList',
  /** 用户模式 */
  TOOL_STRIP_ITEM_USER_SELECT_MODE = 'toolStripItemUserSelectMode',
  /** 用户模式 */
  TOOL_STRIP_ITEM_USER_MODE = 'toolStripItemUserMode',
  /** 组织模式 */
  TOOL_STRIP_ITEM_ORG_MODE = 'toolStripItemOrgMode',
  /** 角色模式 */
  TOOL_STRIP_ITEM_ROLE_MODE = 'toolStripItemRoleMode',
  /** 项目组织模式 */
  TOOL_STRIP_ITEM_PRJ_ORG_MODE = 'toolStripItemPrjOrgMode',

  // 用户过滤
  /** 用户过滤*/
  TOOL_STRIP_ITEM_USER_FILTER = 'toolStripItemUserFilter',

  /** 过滤列表 */
  TOOL_STRIP_ITEM_ORG_FILTER_LIST = 'toolStripItemOrgFilterList',
  /** 组织过滤 */
  TOOL_STRIP_ITEM_ORG_FILTER = 'toolStripItemOrgFilter',

  /** 角色过滤 */
  TOOL_STRIP_ITEM_ROLE_FILTER_LIST = 'toolStripItemRoleFilterList',
  TOOL_STRIP_ITEM_ROLE_FILTER = 'toolStripItemRoleFilter',

  /** 过滤列表 */
  TOOL_STRIP_ITEM_USER_FILTER_LIST = 'toolStripItemUserFiterList',
  /** 全部 */
  TOOL_STRIP_ITEM_FILTER_ALL = 'toolStripItemFiterAll',
  /** 当前组织 */
  TOOL_STRIP_ITEM_FILTER_CURRENT_ORG = 'toolStripItemCurrentOrg',
  /** 当前项目 */
  TOOL_STRIP_ITEM_FILTER_CURRENT_PROJECT = 'toolStripItemCurrentProject',
  /** 指定角色 */
  TOOL_STRIP_ITEM_FILTER_SPECIFY_ROLE = 'toolStripItemSpecifyRole',
  /** 常用人员 */
  TOOL_STRIP_ITEM_FILTER_FREQUENTLY_USED_USER = 'toolStripItemFrequentlyUsedUser',
  /** 当前角色 */
  TOOL_STRIP_ITEM_FILTER_CURRENT_ROLE = 'toolStripItemCurrentRole',

  /** 常用人员管理 */
  TOOL_STRIP_ITEM_FILTER_FREQUENTLY_USED_USER_MANAGE = 'toolStripItemFrequentlyUsedUserManage',
  /** 过滤模式 */
  TOOL_STRIP_ITEM_FILTER_MODE = 'toolStripItemUserListFilterMode',

  /** 用户列表 */
  TOOL_STRIP_ITEM_USER_LIST_TYPE = 'toolStripItemUserListType',
  /** 用户列表 */
  TOOL_STRIP_ITEM_USER_LIST = 'toolStripItemUserList',
  /** 组织结构+用户列表 */
  TOOL_STRIP_ITEM_ORG_USER_LIST = 'toolStripItemOrgUserList',
  //#endregion
  //#region 关联
  /** 添加关联对象 */
  TOOL_STRIP_ADD_RELATION_OBJ = 'toolStripAddRelationObj',
  /** 移除关联对象 */
  TOOL_STRIP_REMOVE_RELATION_OBJ = 'toolStripRemoveRelationObj',
  /** 删除关联对象 */
  TOOL_STRIP_DELETE_RELATION_OBJ = 'toolStripDeleteRelationObj',
  /** 更换... */
  TOOL_STRIPC_CHANGE_RELATION_OBJ = 'toolStripChangeRelationObj',
  /** 转换为指定关联 */
  TOOL_STRIP_CHANGE_RELATION_OBJ_TO_APPOINT = 'toolStripChangeRelationObjToAppoint',
  //#endregion
  /**
   * 新建...
   */
  TOOL_STRIP_DROP_DOWN_CREATE_DESC = 'toolStripDropDownCreateDesc',
  /**
   * 添加...
   */
  TOOL_STRIP_DROP_DOWN_ADD_DESC = 'toolStripDropDownAddDesc',
  /**
   * 创建其他
   */
  TOOL_STRIP_BUTTON_CREATE_OTHER_DESC = 'toolStripButton_CreateOtherDesc',
  /**
   * 添加其他
   */
  TOOL_STRIP_ADD_OTHER_DESC = 'toolStripAddOtherDesc',
  /**
   * 设为主描述关系
   */
  TOOL_STRIP_SET_MAIN_DESC_OBJ = 'ToolStripSetMainDescObj',
  //#endregion
  /**
   * 展示版本类型
   */
  TOOL_STRIP_VERSION_SHOW_TYPE = 'ToolStripVersionShowType',

  //#region  =目录================
  /** 新窗口中打开 */
  TOOL_NEW_FORM_OPEN = 'ToolNewFormOpen',
  /** 新建 */
  TOOL_CREATE = 'ToolCreate',
  /** 新建自定义目录 */
  TOOL_CREATE_CUSTOM_DIR = 'ToolCreateCustomDir',
  /** 属性 */
  TOOL_PROPERTY = 'ToolProperty',
  /** 查询 */
  TOOL_SEARCH = 'ToolSearch',
  /**设置查询字段 */
  TOOL_STRIP_BTN_SET_QUERY_FLDS = 'tsBtnSetQueryFlds',
  /** 查询—目录 */
  TOOL_OBJ_DIR_SEARCH = 'ToolObjDirSearch',
  /** 查询—对象 */
  TOOL_OBJ_SEARCH = 'ToolObjSearch',
  /** 重命名 */
  TOOL_RENAME = 'ToolRename',
  /** 目录对象操作 */
  TOOL_STRIP_DIR_OBJ_OPERATION = 'ToolStripDirObjOperation',
  /** 显示子目录*/
  TOOL_STRIP_ITEM_SHOW_SUB_DIR = 'toolStripItemShowSubDir',
  /** 复制 */
  TOOL_STRIP_ITEM_COPY_DIR = 'toolStripItemCopyDir',
  /** 粘贴 */
  TOOL_STRIP_ITEM_PASTE_DIR = 'toolStripItemPasteDir',
  /** 剪切 */
  TOOL_STRIP_ITEM_CUT_DIR = 'toolStripItemCutDir',
  /** 添加用户或角色 */
  TOOL_STRIP_ITEM_ADD_USER_OR_ROLE = 'toolStripItemAddUserOrRole',
  /** 添加全部用户 */
  TOOL_STRIP_ITEM_ADD_ALL_USER = 'toolStripItemAddAllUser',
  /** 添加用户 */
  TOOL_STRIP_ITEM_ADD_USER = 'toolStripItemAddUser',
  /** 添加角色 */
  TOOL_STRIP_ITEM_ADD_ROLE = 'toolStripItemAddRole',
  /** 移除用户或角色 */
  TOOL_STRIP_ITEM_REMOVE_USER_OR_ROLE = 'toolStripItemRemoveUserOrRole',
  //#endregion
  //#region AddFlow画图工具相关
  /** AddFlow操作类型 */
  TOOL_STRIP_ITEM_ADD_FLOW_OPR_TYPE = 'toolStripItemAddFlowOprType',
  /** 撤销 */
  TOOL_STRIP_ITEM_UNDO = 'toolStripItemUnDo',
  /** 重做*/
  TOOL_STRIP_ITEM_REDO = 'toolStripItemReDo',
  /** 重做*/
  TOOL_STRIP_ITEM_ZOOM_ORIGIN = 'toolStripItemZoomOrigin',
  /** 缩小 */
  TOOL_STRIP_ITEM_ZOOM_IN = 'toolStripItemZoomIn',
  /** 放大 */
  TOOL_STRIP_ITEM_ZOOM_OUT = 'toolStripItemZoomOut',

  TOOL_STRIP_ITEM_ALIGN_LEFT = 'toolStripItemAlignLeft',
  TOOL_STRIP_ITEM_ALIGN_CENTER = 'toolStripItemAlignCenter',
  TOOL_STRIP_ITEM_ALIGN_RIGHT = 'toolStripItemAlignRight',
  TOOL_STRIP_ITEM_SAME_WIDTH = 'toolStripItemSameWidth',
  /** 顶部对齐 */
  TOOL_STRIP_ITEM_ALIGN_TOP = 'toolStripItemAlignTop',
  /** 中间对齐 */
  TOOL_STRIP_ITEM_ALIGN_MIDDLE = 'toolStripItemAlignMiddle',
  /** 底部对齐 */
  TOOL_STRIP_ITEM_ALIGN_BOTTOM = 'toolStripItemAlignBottom',
  /** 相同高度 */
  TOOL_STRIP_ITEM_SAME_HEIGHT = 'toolStripItemSameHeight',
  /** 垂直等间距 */
  TOOL_STRIP_ITEM_VERTICAL_UNIFORMPISTON = 'toolStripItemVerticalUniformpiston',
  /** 水平等间距 */
  TOOL_STRIP_ITEM_HORIZONAL_UNIFORMPISTON = 'toolStripItemHorizonalUniformpiston',
  /** 统一最小 */
  TOOL_STRIP_ITEM_SAME_WITH_MIN = 'toolStripItemSameWithMin',
  /** 统一最大 */
  TOOL_STRIP_ITEM_SAME_WITH_MAX = 'toolStripItemSameWithMax',

  /** 步骤执行人 */
  TOOL_STRIP_ITEM_STEP_EXCUTOR = 'toolStripItemStepExcutor',
  /** 执行人规则 */
  TOOL_STRIP_ITEM_EXCUTOR_RULE = 'toolStripItemExcutorRule',
  /** 步骤显示顺序 */
  TOOL_STRIP_ITEM_STEP_ORDER = 'toolStripItemStepOrder',
  //#endregion
  //#region 文件历史浏览
  TOOL_STRIP_ITEM_ROLLBACK_HISTORY_VERSION = 'toolStripItemRollbackHistoryVersion',
  //#endregion
  //#region 脚本编辑器
  /** 添加 */
  TOOL_STRIP_ITEM_ADD_SCRIPT = 'toolStripItemAddScript',
  /**
   * 验证
   */
  TOOL_STRIP_ITEM_VERIFY_SCRIPT = 'toolStripItemVerifyScript',
  /**
   * 保存
   */
  TOOL_STRIP_ITEM_SAVE_SCRIPT = 'toolStripItemSaveScript',
  /**
   * 预执行
   */
  TOOL_STRIP_ITEM_EXEC_SCRIPT = 'toolStripItemExecScript',
  /**
   * 样例脚本
   */
  TOOL_STRIP_ITEM_SCRIPT_INFO = 'toolStripItemScriptInfo',
  //#endregion
  //#region 任务操作
  TOOL_STRIP_ITEM_TASK_OPERATION = 'toolStripItemSubmitTaskOperation',
  TOOL_STRIP_ITEM_DEFINE_EXECUTOR = 'toolStripItemDefineExecutor',
  TOOL_STRIP_ITEM_DELEGATE = 'toolStripItemDelegate',
  TOOL_STRIP_ITEM_DELEGATE_MANAGE = 'toolStripItemDelegateManage',
  TOOL_STRIP_ITEM_DELETE_DELEGATE = 'toolStripItemDeleteDelegate',
  TOOL_STRIP_ITEM_TASK_INFO = 'toolStripItemTaskInfo',
  TOOL_STRIP_ITEM_SUBMIT_TASK = 'toolStripItemSubmitTask',
  TOOL_STRIP_ITEM_RECALL = 'toolStripItemRecall',
  //#endregion
  /** 创建者 */
  TOOL_STRIP_ITEM_ADD_CREATER = 'toolStripItemAddCreater',

  /** 导入 */
  TOOL_STRIP_ITEM_IMPORT = 'ToolStripItemImport',
  /** 下载 */
  TOOL_STRIP_ITEM_DOWNLOAD = 'ToolStripItemDownload',
  /** 确认入库 */
  TOOL_STRIP_COMMIT_IMPORT = 'ToolStripItemCommitImport',
  /** 入库并发布 */
  TOOL_STRIP_IMPORT_AND_PUBLISH = 'ToolStripItemImportAndPublish',
  /** 入库并加入流程 */
  TOOL_STRIP_IMPORT_AND_ADD_WF = 'ToolStripItemImportAndAddWf',
  /** 游离文档处理 */
  TOOL_STRIP_IMPORT_DOC_MANA = 'ToolStripItemDocMana',

  /** 登录用户管理 */
  /** 移除登录用户 */
  TOOL_STRIP_ITEM_LOGIN_USER_MANAGE_REMOVE = 'ToolStripItemLoginUserManageRemove',
  /** 移除登录模块 */
  TOOL_STRIP_ITEM_GROUP_LOGIN_USER_MANAGE_REMOVE_MODULE = 'ToolStripItemGroupLoginUserManageRemoveModule',
  /** 解锁 */
  TOOL_STRIP_ITEM_LOGIN_USER_MANAGE_UNLOCK = 'ToolStripItemLoginUserManageRemoveUnlock',

  /** 日志审计 */
  /** 审计 */
  TOOL_STRIP_ITEM_LOG_LIST_AUDIT = 'ToolStripItemLogListAudit',
  /** 导出 */
  TOOL_STRIP_ITEM_LOG_LIST_EXPORT = 'ToolStripItemLogListQueryExport',
  /** 检索设置 */
  TOOL_STRIP_ITEM_LOG_LIST_QUERY_SETTING = 'ToolStripItemLogListQuerySetting',
  /** 收藏 */
  TOOL_STRIP_ITEM_COLLECTION = 'ToolStripItemCollection',
  /** 取消收藏 */
  TOOL_STRIP_ITEM_CANCEL_COLLECTION = 'ToolStripItemCancelCollection',
  /** 移除共享 */
  TOOL_STRIP_ITEM_REMOVE_SHARE = 'ToolStripItemRemoveShare',
  /** 移除收到的共享 */
  TOOL_STRIP_ITEM_REMOVE_RECEIVED_SHARE = 'ToolStripItemRemoveReceivedShare',

  /** 零部件编辑 */
  TOOL_STRIP_ITEM_EDIT_PART = 'TOOL_STRIP_ITEM_EDIT_PART',
  /** 零部件检出 */
  TOOL_STRIP_ITEM_CHECK_OUT_PART = 'TOOL_STRIP_ITEM_CHECK_OUT_PART',
  /** 零部件取消检出 */
  TOOL_STRIP_ITEM_UN_CHECK_OUT_PART = 'TOOL_STRIP_ITEM_UN_CHECK_OUT_PART',
  /** 零部件检入 */
  TOOL_STRIP_ITEM_CHECK_IN_PART = 'TOOL_STRIP_ITEM_CHECK_IN_PART',
  /** 收集相关零部件 */
  TOOL_STRIP_ITEM_COLLECT_RELATED_PART = 'TOOL_STRIP_ITEM_COLLECT_RELATED_PART',
  /** 创建变更请求变更 **/
  TOOL_STRIP_ITEM_CREATE_REQUEST_CHANGE = 'TOOL_STRIP_ITEM_CREATE_REQUEST_CHANGE',
  /** 变更活动变更差异 **/
  TOOL_STRIP_ITEM_CHANGE_ACTIVITY_UPDATE_DIFF = 'TOOL_STRIP_ITEM_CHANGE_ACTIVITY_UPDATE_DIFF',
  /** 加入流程 **/
  TOOL_STRIP_ITEM_ADD_WORK_FLOW = 'TOOL_STRIP_ITEM_ADD_WORK_FLOW',
  /** 查看流程 **/
  TOOL_STRIP_ITEM_VIEW_WORK_FLOW = 'TOOL_STRIP_ITEM_VIEW_WORK_FLOW',
  /** 创建视图版本 */
  TOOL_STRIP_ITEM_CREATE_PART_VIEW_VERSION = 'TOOL_STRIP_ITEM_CREATE_PART_VIEW_VERSION',
  /** 创建仿真 */
  TOOL_STRIP_ITEM_CREATE_PART_EMULATION = 'TOOL_STRIP_ITEM_CREATE_PART_EMULATION',
  /** 查询仿真报告 */
  TOOL_STRIP_ITEM_SEARCH_EMULATION_REPORT = 'TOOL_STRIP_ITEM_SEARCH_EMULATION_REPORT',
  /** 库存查询 */
  TOOL_STRIP_ITEM_PART_MATERIAL_QUERY = 'TOOL_STRIP_ITEM_PART_MATERIAL_QUERY',
  /** 修改状态 **/
  TOOL_STRIP_ITEM_UPDATE_STATE = 'TOOL_STRIP_ITEM_UPDATE_STATE',
  /** 废弃 **/
  TOOL_STRIP_ITEM_DISCARD = 'TOOL_STRIP_ITEM_DISCARD',
  /** 基线对比 **/
  TOOL_STRIP_ITEM_BASELINE_COMPARE = 'TOOL_STRIP_ITEM_BASELINE_COMPARE',
  /** 基线对比 **/
  TOOL_STRIP_ITEM_REQUIREMENT_BASELINE_COMPARE = 'TOOL_STRIP_ITEM_REQUIREMENT_BASELINE_COMPARE',
  /** 删除基线 **/
  TOOL_STRIP_ITEM_REQUIREMENT_DELETE_BASELINE = 'TOOL_STRIP_ITEM_REQUIREMENT_DELETE_BASELINE',
  /** 创建基线 **/
  TOOL_STRIP_ITEM_REQUIREMENT_CREATE_BASELINE = 'TOOL_STRIP_ITEM_REQUIREMENT_CREATE_BASELINE',
  /**编辑基线 */
  TOOL_STRIP_ITEM_REQUIREMENT_EDIT_BASELINE = 'TOOL_STRIP_ITEM_REQUIREMENT_EDIT_BASELINE',

  /** 插入视图 */
  TOOL_STRIP_ITEM_INSERT_PART_VIEW = 'TOOL_STRIP_ITEM_INSERT_PART_VIEW',
  /** 删除视图 */
  TOOL_STRIP_ITEM_DELETE_PART_VIEW = 'TOOL_STRIP_ITEM_DELETE_PART_VIEW',

  /**签字 */
  TOOL_STRIP_ITEM_SIGN = 'TOOL_STRIP_ITEM_SIGN',
  /**编辑文件 */
  TOOL_STRIP_ITEM_EDIT_FILE = 'TOOL_STRIP_ITEM_EDIT_FILE',
  // TOOL_STRIP_ITEM_EDIT_FILE = 'TOOL_STRIP_ITEM_EDIT_FILE'

  /** 设为主要对象 **/
  TOOL_STRIP_ITEM_SET_PRIMARY_OBJ = 'TOOL_STRIP_ITEM_SET_PRIMARY_OBJ',
  /** 替换成员 **/
  TOOL_STRIP_ITEM_REPLACE_MEMBER = 'TOOL_STRIP_ITEM_REPLACE_MEMBER',

  /** 工作流提交 **/
  TOOL_STRIP_ITEM_WORKFLOW_SUBMIT = 'TOOL_STRIP_ITEM_WORKFLOW_SUBMIT',
  /** 工作流启动 **/
  TOOL_STRIP_ITEM_WORKFLOW_START = 'TOOL_STRIP_ITEM_WORKFLOW_START',
  /** 工作流委托 **/
  TOOL_STRIP_ITEM_WORKFLOW_DELEGATE = 'TOOL_STRIP_ITEM_WORKFLOW_DELEGATE',
  /** 工作流暂停 **/
  TOOL_STRIP_ITEM_WORKFLOW_PAUSE = 'TOOL_STRIP_ITEM_WORKFLOW_PAUSE',
  /** 工作流重启 **/
  TOOL_STRIP_ITEM_WORKFLOW_RESTART = 'TOOL_STRIP_ITEM_WORKFLOW_RESTART',
  /** 工作流终止 **/
  TOOL_STRIP_ITEM_WORKFLOW_STOP = 'TOOL_STRIP_ITEM_WORKFLOW_STOP',
  /** 工作流修改执行人 **/
  TOOL_STRIP_ITEM_WORKFLOW_CHANGE_EXECUTOR = 'TOOL_STRIP_ITEM_WORKFLOW_CHANGE_EXECUTOR',
  /**文档连接到dp */
  TOOL_STRIP_ITEM_DOC_CONNECT_TO_DP = 'TOOL_STRIP_ITEM_DOC_CONNECT_TO_DP',
  /** 添加至CAD工作区 */
  TOOL_STRIP_ITEM_ADD_TO_CAD = 'TOOL_STRIP_ITEM_ADD_TO_CAD',

  /** 基于改后创建变更活动 **/
  TOOL_STRIP_ITEM_AFTER_UPDATE_CREATE = 'TOOL_STRIP_ITEM_AFTER_UPDATE_CREATE',
  /** 基于改后添加至变更活动 **/
  TOOL_STRIP_ITEM_AFTER_UPDATE_ADD_ACTIVITY = 'TOOL_STRIP_ITEM_AFTER_UPDATE_ADD_ACTIVITY',
  /** 基于改前创建变更活动 **/
  TOOL_STRIP_ITEM_BEFORE_UPDATE_CREATE = 'TOOL_STRIP_ITEM_BEFORE_UPDATE_CREATE',
  /** 基于改前添加至变更活动 **/
  TOOL_STRIP_ITEM_BEFORE_UPDATE_ADD_ACTIVITY = 'TOOL_STRIP_ITEM_BEFORE_UPDATE_ADD_ACTIVITY',
  /** 变更管理 */
  TOOL_STRIP_ITEM_CHANGE_MANAGED = 'TOOL_STRIP_ITEM_CHANGE_MANAGED',
  /**创建变更问题报告 */
  TOOL_STRIP_ITEM_CREATE_CHANGE_ISSUE = 'TOOL_STRIP_ITEM_CREATE_CHANGE_ISSUE',
  /**创建变更申请 */
  TOOL_STRIP_ITEM_CREATE_CHANGE_REQUEST = 'TOOL_STRIP_ITEM_CREATE_CHANGE_REQUEST',
  /**创建变更单 */
  TOOL_STRIP_ITEM_CREATE_CHANGE_ORDER = 'TOOL_STRIP_ITEM_CREATE_CHANGE_ORDER',
  /**创建变更活动 */
  TOOL_STRIP_ITEM_CREATE_CHANGE_ACTIVITY = 'TOOL_STRIP_ITEM_CREATE_CHANGE_ACTIVITY',

  /** 创建产品需求 **/
  TOOL_STRIP_ITEM_CREATE_PRODUCT_NEEDS = 'TOOL_STRIP_ITEM_CREATE_PRODUCT_NEEDS',

  /**CAD文档管理 */
  TOOL_STRIP_ITEM_CAD_DOCUMENT_MANAGED = 'TOOL_STRIP_ITEM_CAD_DOCUMENT_MANAGED',
  /** CAD文件替换-从本地添加 */
  TOOL_STRIP_ITEM_CAD_FILE_REPLACE_FROM_LOCAL_FILE = 'TOOL_STRIP_ITEM_CAD_FILE_REPLACE_FROM_LOCAL_FILE',
  /** CAD文件浏览 */
  TOOL_STRIP_ITEM_CAD_BROWSE_DOC = 'TOOL_STRIP_ITEM_CAD_BROWSE_DOC',
  /** CAD文件编辑 */
  TOOL_STRIP_ITEM_CAD_EDIT = 'TOOL_STRIP_ITEM_CAD_EDIT',
  /** CAD文件检出 */
  TOOL_STRIP_ITEM_CAD_CHECK_OUT = 'TOOL_STRIP_ITEM_CAD_CHECK_OUT',
  /** CAD文件下载 */
  TOOL_STRIP_ITEM_CAD_DOWNLOAD_DOC = 'TOOL_STRIP_ITEM_CAD_DOWNLOAD_DOC',
  /**CAD历史版本管理 */
  TOOL_STRIP_ITEM_CAD_HISTORY_VERSION = 'TOOL_STRIP_ITEM_CAD_HISTORY_VERSION',
  /** CAD查看结构 */
  TOOL_STRIP_ITEM_CAD_STRUC_MANAGE = 'TOOL_STRIP_ITEM_CAD_STRUC_MANAGE',

  /** 文件分组操作 */
  TOOL_STRIP_GROUP_FILE_OPERATION = 'toolStripGroupFileOperation',
  /** 结构列表 */
  TOOL_STRIP_ITEM_STRUC_LIST = 'toolStripItemStrucList',
  /** 流程操作分组 **/
  TOOL_STRIP_ITEM_GROUP_WORK_FLOW = 'TOOL_STRIP_ITEM_GROUP_WORK_FLOW',
  /** 正式变更 */
  TOOL_STRIP_ITEM_FORMAL_CHANGE = 'TOOL_STRIP_ITEM_FORMAL_CHANGE',
  /** 快速变更 */
  TOOL_STRIP_ITEM_QUICK_CHANGE = 'TOOL_STRIP_ITEM_QUICK_CHANGE',
  /** 查看子项 */
  TOOL_STIP_ITEM_INCLUDE_CHILD = 'TOOL_STIP_ITEM_INCLUDE_CHILD',
  /** 创建验证计划 */
  TOOL_STRIP_ITEM_CREATE_VERIFICATION_PLAN = 'TOOL_STRIP_ITEM_CREATE_VERIFICATION_PLAN',
  /** 添加到验证计划 */
  TOOL_STRIP_ITEM_ADD_TO_VERIFICATION_PLAN = 'TOOL_STRIP_ITEM_ADD_TO_VERIFICATION_PLAN',
  /** 从计划移除指标 */
  TOOL_STRIP_ITEM_REMOVE_METRICS_FROM_PLAN = 'TOOL_STRIP_ITEM_REMOVE_METRICS_FROM_PLAN',
  /** 查看计划指标 */
  TOOL_STRIP_ITEM_SEARCH_METRICS = 'TOOL_STRIP_ITEM_SEARCH_METRICS',

  /** 批量新建文档 */
  TOOL_STRIP_ITEM_BATCH_CREATE_DOCUMENTS = 'TOOL_STRIP_ITEM_BATCH_CREATE_DOCUMENTS',
  /** 相关零部件 */
  TOOL_STRIP_ITEM_RELATEDPART = 'TOOL_STRIP_ITEM_RELATEDPART',
  /** 相关文档 */
  TOOL_STRIP_ITEM_RELATEDDOC = 'TOOL_STRIP_ITEM_RELATEDDOC',
  /** 同步 */
  TOOL_STRIP_ITEM_SYNCHRONOUS = 'TOOL_STRIP_ITEM_SYNCHRONOUS',
  /** 同步对象类 */
  TOOL_STRIP_ITEM_SYNCHRONOUS_OBJ = 'TOOL_STRIP_ITEM_SYNCHRONOUS_OBJ',
  // TOOL_STRIP_ITEM_VIEW_FLOW_OBJ = 'toolStripItemViewFlowObj',
  // /** 加入到现有流程 */
  // TOOL_STRIP_ITEM_ADD_TO_EXIST_FLOW = 'toolStripItemAddToExistFlow',
  // /** 按批次加入流程 */
  // TOOL_STRIP_ITEM_BATCH_ADD_TO_FLOW = 'toolStripItemBatchAddToFlow',
  // /** 加入到现有批次任务 */
  // TOOL_STRIP_ITEM_ADD_TO_BATCH_TASK = 'toolStripItemAddToBatchTask',
  // /** 终止流程 */
  // TOOL_STRIP_STOP = 'ToolStripStop',
  // /** 重新启动流程 */
  // TOOL_STRIP_RESTART = 'ToolStripRestart',
  // /** 流程选项 */
  // TOOL_STRIP_FLOW_OPTION = 'ToolStripFlowOption',
  /** 查看主对象 */
  NEW = 'NEW',
  EDIT = 'EDIT',
  REFRESH = 'REFRESH'
}

/** 工具条使用环境 */
export enum EnumToolStripUseEnv {
  /** 对象网格 */
  ObjGrid,
  /** 对象面板 */
  ObjPanel
}
