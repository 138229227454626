import {
  BaseViewModel,
  EnumDialogResult,
  IQuery,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  request,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KLogManageEmitsType, KLogManagePropType } from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode, SelectItem } from '../../client-srv'
import { uniqBy } from 'lodash'
import moment from 'moment/moment'

import { KAssociateClientGrid } from './associateClient/associate-client-grid'

/** KLogManage */
export default class KLogManageViewModel extends BaseViewModel<KLogManageEmitsType, KLogManagePropType> {
  refGrid = ref<KDataGridViewModel>()

  modelList = ref<any[]>([])
  models = ref<string[]>()
  featureList = ref<SelectItem[]>([])
  features = ref<string[]>()
  clientIpList = ref<SelectItem[]>([])
  clientIPs = ref<string[]>([])
  userList = ref<SelectItem[]>([])
  userIds = ref<string[]>([])
  operationUri = ref<string>()

  // moment(new Date())
  //   .subtract(6, 'days')
  //   .format('YYYY-MM-DD'),
  // moment().format('YYYY-MM-DD')
  dateTime = ref<any[]>([])
  constructor(options: ViewModelOptions<KLogManagePropType>) {
    super(options)
  }

  viewDidMount() {
    this.getModelList()
    this.getClientUserList()
    this.getUserList()
  }

  /**
   * 刷新网格数据
   */
  refresh() {
    this.refGrid.value?.refresh()
  }
  search() {
    this.refresh()
  }

  async loadData(qry: IQuery) {
    const beginTime = this.dateTime.value?.length > 0 ? this.dateTime.value[0] + ' 00:00:00' : undefined
    const endTime = this.dateTime.value?.length > 0 ? this.dateTime.value[1] + ' 23:59:59' : undefined
    const param = {
      data: [
        {
          currentPage: qry.page?.pageIndex,
          pageSize: 50,
          clientIPs: this.clientIPs.value,
          features: this.features.value,
          models: this.models.value,
          userIds: this.userIds.value,
          operationUri: this.operationUri.value,
          beginTime,
          endTime
        }
      ]
    }

    const result = await Api.post('sys', 'OperationRecordService', 'query', param)
    // const result = await request.post('/wyzApi/request/systest/OperationRecordService/query', param, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     token: '568039397618880512/test1 3c03e719256a427eb9277b64fc83fb40/1111'
    //   }
    // })

    if (result && result.code == EnumRequestCode.SUCCESS && result.data.dataList) {
      return {
        rows: result.data.dataList,
        total: result.data.pager.recordCount
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  async getModelList() {
    const param = {
      data: []
    }
    const result = await Api.post('sys', 'OperationResourceService', 'list', param)
    // const result = await request.post('/wyzApi/request/systest/OperationResourceService/list', param, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     token: '568039397618880512/test1 3c03e719256a427eb9277b64fc83fb40/1111'
    //   }
    // })

    if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
      this.modelList.value = uniqBy(
        (result.data as Array<{ model?: string; feature?: string }>).map(item => new SelectItem(item?.model, item?.model)),
        'value'
      )
      this.featureList.value = uniqBy(
        (result.data as Array<{ model?: string; feature?: string }>).map(item => new SelectItem(item.feature, item.feature)),
        'value'
      )
    } else {
      this.modelList.value = []
      this.featureList.value = []
    }
  }

  async getClientUserList() {
    const param = {
      data: []
    }

    const result = await Api.post('sys', 'UserClientService', 'list', param)
    // const result = await request.post('/wyzApi/request/systest/UserClientService/list', param, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     token: '568039397618880512/test1 3c03e719256a427eb9277b64fc83fb40/1111'
    //   }
    // })

    if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
      this.clientIpList.value = (result.data as Array<{ clientIP?: string; clientName?: string }>).map(
        item => new SelectItem(item.clientIP, item.clientName)
      )

      // 如果更新后，选中的ip在列表在clientIpList中不存在，则清空
      const allClientIpIps = this.clientIpList.value.map(v => v.value)
      this.clientIPs.value = this.clientIPs.value.filter(ip => allClientIpIps.includes(ip))
    } else {
      this.clientIpList.value = []
      this.clientIPs.value = []
    }
  }

  async getUserList() {
    const param = {
      data: []
    }

    const result = await Api.post('sys', 'OperationRecordService', 'userList', param)
    // const result = await request.post('/wyzApi/request/systest/OperationRecordService/userList', param, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     token: '568039397618880512/test1 3c03e719256a427eb9277b64fc83fb40/1111'
    //   }
    // })

    if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
      this.userList.value = (result.data as Array<{ userId?: string; userName?: string }>).map(
        item => new SelectItem(item.userId, item.userName)
      )
    } else {
      this.userList.value = []
    }
  }

  associateClient() {
    KDialog.show({
      title: '客户端关联',
      size: { width: 1200, height: 700 },
      content: KAssociateClientGrid,
      showConfirm: false,
      onClosing: async (event: KDialogClosingEvent) => {
        // 关闭窗口后刷新客户端列表
        this.getClientUserList()

        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
          return
        }
      }
    })
  }
}
