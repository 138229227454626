import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KDocOptionalPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KDocOptionalPropType = ViewPropsTypeExtract<typeof KDocOptionalPropOptions>

/** 事件 */
export const KDocOptionalEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KDocOptionalEmitsType = ViewEmitsTypeExtract<typeof KDocOptionalEventEmits>
