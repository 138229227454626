
import { defineView } from '@kmsoft/upf-core'
import { KAssociateClientFormPropOptions, KAssociateClientFormEventEmits } from './interface'
import KAssociateClientFormViewModel from './KAssociateClientFormViewModel'

export default defineView({
  name: 'KAssociateClientForm',
  props: KAssociateClientFormPropOptions,
  emits: KAssociateClientFormEventEmits,
  viewModel: KAssociateClientFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})
