import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KValidationBoardPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KValidationBoardPropType = ViewPropsTypeExtract<typeof KValidationBoardPropOptions>

/** 事件 */
export const KValidationBoardEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KValidationBoardEmitsType = ViewEmitsTypeExtract<typeof KValidationBoardEventEmits>
