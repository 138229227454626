import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KAllRequirementPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KAllRequirementPropType = ViewPropsTypeExtract<typeof KAllRequirementPropOptions>

/** 事件 */
export const KAllRequirementEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KAllRequirementEmitsType = ViewEmitsTypeExtract<typeof KAllRequirementEventEmits>
