import { connect, withInstall } from '@kmsoft/upf-core'
import KRequirementChangeTabView from './KRequirementChangeTab.vue'
import KRequirementChangeTabViewModel from './KRequirementChangeTabViewModel'

const KRequirementChangeTab = connect(KRequirementChangeTabView, KRequirementChangeTabViewModel)

export default withInstall(KRequirementChangeTab)
export { KRequirementChangeTab, KRequirementChangeTabView, KRequirementChangeTabViewModel }

// 模板生成文件
// export * from './{{folderName}}'
