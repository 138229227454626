import { BaseViewModel, KDataGridRowDoubleClickEvent, ViewModelOptions } from '@kmsoft/upf-core'
import { KObjectClassManageEmitsType, KObjectClassManagePropType } from './interface'
import { IObjectSelectorComponent } from '../object-selector'
import { ObjBusinessParam, ObjBusinessBase, ObjectClientSrv, ClassMetaClientSrv } from '../../client-srv'
import { ref, watch } from 'vue'
import { KObjectClassGridViewModel } from '../object-class-grid'
import { KObjectClassTreeViewModel } from '../object-class-tree'

/** KObjectClassManage */
export default class KObjectClassManageViewModel extends BaseViewModel<KObjectClassManageEmitsType, KObjectClassManagePropType>
  implements IObjectSelectorComponent {
  /** 树引用 */
  refObjectClassTree = ref<KObjectClassTreeViewModel>()
  /** 网格引用 */
  refObjectClassGrid = ref<KObjectClassGridViewModel>()
  /** 对象类Code */
  modelCode = ref('')
  /** 对象类Code */
  modelGroup = ref<string | undefined>()
  filter = ref<Record<string, any>>({
    conditions: [],
    joiner: 'and'
  })

  constructor(options: ViewModelOptions<KObjectClassManagePropType>) {
    super(options)
    /** 对象选择子类型时增加过滤子类型条件 */
    watch(
      () => this.modelCode.value,
      newValue => {
        if (this.props.filter) {
          this.filter.value = this.props.filter
        } else {
          this.filter.value = {
            conditions: [],
            joiner: 'and'
          }
        }

        const value: Record<string, any> = {
          conditionName: 'rdmExtensionType',
          conditionValues: [newValue],
          operator: '='
        }
        const index = this.filter.value?.conditions?.findIndex((item: any) => item.conditionName == 'rdmExtensionType')
        if (index != undefined && index > -1 && this.filter.value?.conditions) {
          this.filter.value!.conditions[index] = value
        } else {
          this.filter.value?.conditions?.push(value)
        }
        if (
          this.props.showLifecycleState &&
          this.props.showLifecycleState[newValue] &&
          this.props.showLifecycleState[newValue].length > 0
        ) {
          const lifecycleState: Record<string, any> = {
            conditionName: 'lifecycleState.nameEn',
            conditionValues: this.props.showLifecycleState[newValue],
            operator: 'in'
          }
          const stateIndex = this.filter.value?.conditions?.findIndex(
            (item: any) => item.conditionName == 'lifecycleState.nameEn'
          )
          if (stateIndex != undefined && stateIndex > -1 && this.filter.value?.conditions) {
            this.filter.value!.conditions[stateIndex] = lifecycleState
          } else {
            this.filter.value?.conditions?.push(lifecycleState)
          }
        }
      }
    )
  }

  viewDidMount() {}

  /**
   * 获取选中的业务对象参数
   * @returns
   */
  getSelectedObjParams(): ObjBusinessParam[] {
    if (!this.refObjectClassGrid.value) {
      return []
    }
    return this.refObjectClassGrid.value.getSelectedObjParams()
  }

  /**
   * 获取选中的业务对象
   * @returns
   */
  getSelectedObjBussiness(): ObjBusinessBase[] {
    if (!this.refObjectClassGrid.value) {
      return []
    }
    return this.refObjectClassGrid.value.getSelectedObjBussiness()
  }

  /**
   * 获取选中行
   * @returns
   */
  getSelectedRows(): Record<string, any>[] {
    if (!this.refObjectClassGrid.value) {
      return []
    }

    /** 获取选中行 */
    const rows = this.refObjectClassGrid.value.getSelectedRows()

    // 如果没选中行
    if (!rows) {
      return []
    }

    return rows
  }

  /**
   * 双击打开编辑面板
   * @param event 行双击事件
   */
  async onRowDoubleClick(event: KDataGridRowDoubleClickEvent) {
    const param = {
      id: event.row!.id,
      modelCode: event.row!.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(event.row!.className!)
    }
    ObjectClientSrv.openObj(param)
  }
}
