import { BaseViewModel, KNotification, KTreeViewViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KRequirementChangeAfterEmitsType, KRequirementChangeAfterPropType, NodeData, NeedsTreeNode } from './interface'
import { ref, watch } from 'vue'
import { Api, EnumRequestCode, ObjBusinessParam, KObjectPanelViewModel } from '@kmsoft/ebf-common'

/** KRequirementChangeAfter */
export default class KRequirementChangeAfterViewModel extends BaseViewModel<
  KRequirementChangeAfterEmitsType,
  KRequirementChangeAfterPropType
> {
  /**树组件 */
  refTree = ref<KTreeViewViewModel>()

  /** 对象面板 */
  refObjectPanel = ref<KObjectPanelViewModel>()

  objParam = ref<any>()

  rootId = ref<string>()

  TreeNodeParam = ref<any>()

  constructor(options: ViewModelOptions<KRequirementChangeAfterPropType>) {
    super(options)
    watch(
      () => this.props.objParam,
      () => {
        this.objParam.value = this.props.objParam
      },
      {
        immediate: true,
        deep: true
      }
    )
  }

  viewDidMount() {}

  async loadTreeData() {
    const result = (await Api.post('requirement', 'RequirementChangeRequest', 'get', { data: [this.objParam.value?.id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const node = {
        id: result.data?.changeAfter.needsView.id,
        name: result.data?.changeAfter.needsView.name,
        hasChildren: result.data.changeAfter.hasChildren,
        parentId: '-1',
        className: result.data?.changeAfter.needsView.className
      } as NodeData
      this.rootId.value = result.data?.changeAfter.needsView.id
      return [new NeedsTreeNode(node)]
    } else {
      KNotification.error({
        title: '查询需求变更详情异常',
        content: result.message || '查询需求变更详情异常',
        details: result.detail
      })
      return []
    }
  }

  onAfterSelect(param: any) {
    this.TreeNodeParam.value = {
      id: param.node.id,
      modelCode: param.node.className,
      modelGroup: 'requirement'
    }
  }

  onReady() {
    this.refTree.value?.setSelectedNode(this.rootId.value!)
  }

  /** 加载数据 */
  async loadData() {
    const result = (await Api.post('requirement', this.TreeNodeParam.value.modelCode, 'get', {
      data: [this.TreeNodeParam.value.id]
    })) as any

    if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取产品需求失败',
        details: result.detail
      })
      return
    }
  }
}
