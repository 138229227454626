import {
  BaseViewModel,
  KNotification,
  ViewModelOptions,
  IQuery,
  KDialog,
  KModal,
  KIcon,
  KDialogClosingEvent,
  EnumDialogResult,
  PageManager,
  EnumDialogState,
  KDataGridViewModel,
  KDataGridRowSelectedEvent
} from '@kmsoft/upf-core'
import { IWorkspace, KCADWorkspaceEmitsType, KCADWorkspacePropType } from './interface'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { KCreateCadWorkspace, KCreateCadWorkspaceViewModel } from './create-cad-workspace'
import { createVNode, ref } from 'vue'
import KCadWorkspaceDetail from './cad-workspace-detail'

/** KCADWorkspace */
export default class KCADWorkspaceViewModel extends BaseViewModel<KCADWorkspaceEmitsType, KCADWorkspacePropType> {
  /**工作区列表网格 */
  refWorkspaceGrid = ref<KDataGridViewModel>()
  deleteDisabled = ref<boolean>(true)
  editDisabled = ref<boolean>(true)
  activatedDisabled = ref<boolean>(true)
  constructor(options: ViewModelOptions<KCADWorkspacePropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 初始化工作区网格
   */
  async loadData(qry: IQuery) {
    const param = {
      data: [
        { filter: {}, isNeedTotal: true, sort: 'DESC', orderBy: 'createTime' },
        { maxPageSize: 1000, curPage: 1, pageSize: 50, totalRows: 0, totalPages: 0, startIndex: 0, endIndex: 0 }
      ]
    }

    const result = await Api.post('folder', 'Folder', 'listMyCADFolder', param)
    if (result && result.code == EnumRequestCode.SUCCESS && result.data.data) {
      return {
        rows: result.data.data,
        total: result.data.page.totalRows
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  selectRows(event: KDataGridRowSelectedEvent<any>) {
    const selectRows = this.refWorkspaceGrid.value?.getSelectedRows()
    if (selectRows && selectRows.length > 0) {
      this.editDisabled.value = selectRows.length == 1 ? false : true
      this.activatedDisabled.value = selectRows.length == 1 ? false : true
      this.deleteDisabled.value = false
      selectRows.forEach(item => {
        if (item.activated == 1) {
          this.deleteDisabled.value = true
          this.activatedDisabled.value = true
        }
      })
    } else {
      this.editDisabled.value = true
      this.deleteDisabled.value = true
      this.activatedDisabled.value = true
    }
  }

  getSelectRows() {
    return this.refWorkspaceGrid.value?.getSelectedRows()
  }

  create() {
    KDialog.show({
      title: '创建CAD工作区',
      size: { width: 560, height: 550 },
      content: KCreateCadWorkspace,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
          return
        }
        /** 当前组件 */
        const formViewModel = event.viewModel as KCreateCadWorkspaceViewModel

        /** 获取表单值 */
        const formValue = await formViewModel.getFormValue()
        if (!formValue) {
          event.cancel = true
          return
        }
        const param = {
          data: [
            {
              ...formValue
            }
          ]
        }
        const result = await Api.post('folder', 'Folder', 'createCADWorkspace', param)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refWorkspaceGrid.value?.refresh()
        } else {
          KNotification.warn({
            message: '创建CAD工作区异常',
            description: result.message
          })
          event.cancel = true
        }
      }
    })
  }

  delete() {
    const selectData = this.refWorkspaceGrid.value?.getSelectedRows()
    const ids = selectData?.map(item => item.id)
    KModal.delete({
      title: 'CAD工作区删除提示',
      icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
      content: '您正在进行删除操作，请确认是否删除',
      onOk: () => {
        const param = {
          data: [ids]
        }
        Api.post('folder', 'Folder', 'batchDeleteCADWorkspace', param).then(res => {
          if (res && res.code == EnumRequestCode.SUCCESS) {
            this.refWorkspaceGrid.value?.clearSelectedRows()
            this.refWorkspaceGrid.value?.refresh()
            KNotification.success({
              title: '系统提示',
              content: 'CAD工作区删除成功'
            })
          } else {
            KNotification.warn({
              message: '删除失败',
              description: res.message
            })
            return
          }
        })
      }
    })
  }

  edit() {
    const selectRows = this.refWorkspaceGrid.value?.getSelectedRows()
    const bomLibraryPositionName = selectRows![0].bomLibraryPositionName.split('/').filter((item: string) => item)
    const docLibraryPositionName = selectRows![0].docLibraryPositionName.split('/').filter((item: string) => item)
    const params = {
      name: selectRows![0].name,
      libraryFolderId: selectRows![0].libraryFolderId,
      libraryFolderName: selectRows![0].libraryFolderName,
      bomLibraryPosition: selectRows![0].bomLibraryPosition,
      docLibraryPosition: selectRows![0].docLibraryPosition,
      bomLibraryPositionName: bomLibraryPositionName[bomLibraryPositionName.length - 1],
      docLibraryPositionName: docLibraryPositionName[docLibraryPositionName.length - 1],
      localCachePosition: selectRows![0].localCachePosition,
      description: selectRows![0].description
    }
    KDialog.show({
      title: '编辑CAD工作区',
      size: { width: 560, height: 500 },
      content: KCreateCadWorkspace,
      props: { formData: params, libraryType: selectRows![0].libraryType, handleType: 'edit' },
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
          return
        }
        /** 当前组件 */
        const formViewModel = event.viewModel as KCreateCadWorkspaceViewModel

        /** 获取表单值 */
        const formValue: any = await formViewModel.getFormValue()
        if (!formValue) {
          event.cancel = true
          return
        }
        const param = {
          data: [
            {
              id: selectRows![0].id,
              name: formValue.name,
              libraryType: formValue.libraryType,
              libraryFolderId: formValue.libraryFolderId,
              bomLibraryPosition: formValue.bomLibraryPosition,
              docLibraryPosition: formValue.docLibraryPosition,
              localCachePosition: formValue.localCachePosition,
              description: formValue.description
            }
          ]
        }
        const result = await Api.post('folder', 'Folder', 'updateCADWorkspace', param)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refWorkspaceGrid.value?.refresh()
        } else {
          KNotification.warn({
            message: '编辑CAD工作区异常',
            description: result.message
          })
          event.cancel = true
        }
      }
    })
  }

  activated() {
    const selectRows = this.refWorkspaceGrid.value?.getSelectedRows()
    KModal.delete({
      title: 'CAD工作区激活提示',
      content: '您正在进行激活操作，请确认是否激活',
      onOk: () => {
        const param = {
          data: [{ id: selectRows![0].id }]
        }
        Api.post('folder', 'Folder', 'activateCADWorkspace', param).then(res => {
          if (res && res.code == EnumRequestCode.SUCCESS) {
            this.refWorkspaceGrid.value?.clearSelectedRows()
            this.refWorkspaceGrid.value?.refresh()
            KNotification.success({
              title: '系统提示',
              content: 'CAD工作区激活成功'
            })
          } else {
            KNotification.warn({
              message: '激活失败',
              description: res.message
            })
            return
          }
        })
      }
    })
  }

  refresh() {
    this.refWorkspaceGrid.value?.refresh()
  }

  openProductWorkspace(row: IWorkspace) {
    const partFolder = row.bomLibraryPosition?.split('/').filter(item => item)
    const docFolder = row.docLibraryPosition?.split('/').filter(item => item)
    if (this.props.agent) {
      KDialog.show({
        title: `CAD工作区（` + row.name + `)`,
        state: EnumDialogState.Maximum,
        closable: true,
        maximizeBox: false,
        minimizeBox: false,
        props: {
          workspaceId: row.id,
          workspaceName: row.name,
          folderId: {
            part: partFolder![partFolder!.length! - 1],
            doc: docFolder![docFolder!.length! - 1]
          }
        },
        showFooter: false,
        showApply: false,
        content: KCadWorkspaceDetail,
        onClosing: async (event: KDialogClosingEvent) => {
          const formViewModel = event.viewModel as any
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
        }
      })
    } else {
      PageManager.openPage(row.id, `CAD工作区（` + row.name + `)`, KCadWorkspaceDetail, {
        workspaceId: row.id,
        workspaceName: row.name,
        folderId: {
          part: partFolder![partFolder!.length! - 1],
          doc: docFolder![docFolder!.length! - 1]
        }
      })
    }
  }
}
