import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a9c17df"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "full baseline-compare" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_tool_strip = _resolveComponent("k-object-tool-strip")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_object_tool_strip, {
      ref: "refToolStrip",
      items: _ctx.$vm.toolStripItems,
      onItemClicked: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onToolStripItemClicked(event))
    }, null, 8, ["items"]),
    _createVNode(_component_k_data_grid, {
      class: "validation-metrics-grid",
      rowKey: "id",
      name: "refBaselineGrid",
      ref: "refBaselineGrid",
      pagination: false,
      rowModelType: _ctx.EnumDataGridRowModelType.SERVER_SIDE,
      loadData: _ctx.$vm.loadData,
      autoSizeColumnsMode: _ctx.autoSizeColumnsMode,
      onRowSelected: _ctx.$vm.rowSelected
    }, {
      columns: _withCtx(() => [
        _createVNode(_component_k_data_grid_text_box_column, {
          id: "baselineName",
          name: "baselineName",
          dataPropertyName: "baselineName",
          headerText: "名称",
          align: "left"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          id: "baselineType",
          name: "baselineType",
          dataPropertyName: "baselineType",
          headerText: "类型",
          readonly: false,
          align: "left"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          id: "createTime",
          name: "createTime",
          dataPropertyName: "createTime",
          headerText: "创建时间",
          readonly: false,
          align: "left"
        }),
        _createVNode(_component_k_data_grid_template_column, {
          id: "action",
          name: "action",
          fixed: "right",
          align: "center",
          headerText: "操作",
          width: "130"
        }, {
          default: _withCtx(({row}) => [
            _createElementVNode("a", {
              class: "row-operation-item",
              onClick: (e) => _ctx.$vm.openObj(row)
            }, "查看基线内容", 8, _hoisted_2)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["rowModelType", "loadData", "autoSizeColumnsMode", "onRowSelected"])
  ]))
}