import { KSpin } from '@kmsoft/upf-core'
import { createVNode, render } from 'vue'
class LoadingHelper {
  loadingDom = document.createElement('div')

  show(dom?: HTMLElement) {
    const parentNode = dom ? dom : document.body
    this.loadingDom.style.position = 'absolute'
    this.loadingDom.style.width = '100%'
    this.loadingDom.style.height = '100%'
    this.loadingDom.style.zIndex = '10000'
    this.loadingDom.style.background = 'rgba(0, 0, 0, 0.2)'
    this.loadingDom.style.top = '0'
    const spin = createVNode(KSpin, { size: 'large', style: { display: 'flex' } })
    render(spin, this.loadingDom)
    parentNode.appendChild(this.loadingDom)
  }

  remove(dom?: HTMLElement) {
    const parentNode = dom ? dom : document.body
    if (parentNode.contains(this.loadingDom)) {
      parentNode.removeChild(this.loadingDom)
    }
  }
}

const loadingHandle = new LoadingHelper()

export { loadingHandle }
