import { connect, withInstall } from '@kmsoft/upf-core'
import KAssociateClientFormView from './KAssociateClientForm.vue'
import KAssociateClientFormViewModel from './KAssociateClientFormViewModel'

const KAssociateClientForm = connect(KAssociateClientFormView, KAssociateClientFormViewModel)

export default withInstall(KAssociateClientForm)
export { KAssociateClientForm, KAssociateClientFormView, KAssociateClientFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'
