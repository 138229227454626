import { ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KRequirementChangeTabPropOptions = {
  ...BaseViewPropOptions,
  showToolStrip: VuePropTypes.bool().def(true),
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  refreshTip: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KRequirementChangeTabPropType = ViewPropsTypeExtract<typeof KRequirementChangeTabPropOptions>

/** 事件 */
export const KRequirementChangeTabEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KRequirementChangeTabEmitsType = ViewEmitsTypeExtract<typeof KRequirementChangeTabEventEmits>
