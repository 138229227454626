
import { defineView } from '@kmsoft/upf-core'
import { KProductNeedsCreateWithFolderSelectorPropOptions, KProductNeedsCreateWithFolderSelectorEventEmits } from './interface'
import KProductNeedsCreateWithFolderSelectorViewModel from './KProductNeedsCreateWithFolderSelectorViewModel'

export default defineView({
  name: 'KProductNeedsCreateWithFolderSelector',
  props: KProductNeedsCreateWithFolderSelectorPropOptions,
  emits: KProductNeedsCreateWithFolderSelectorEventEmits,
  viewModel: KProductNeedsCreateWithFolderSelectorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
