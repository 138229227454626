import { connect, withInstall } from '@kmsoft/upf-core'
import KLifecycleStateTagView from './KLifecycleStateTag.vue'
import KLifecycleStateTagViewModel from './KLifecycleStateTagViewModel'

const KLifecycleStateTag = connect(KLifecycleStateTagView, KLifecycleStateTagViewModel)

export default withInstall(KLifecycleStateTag)
export { KLifecycleStateTag, KLifecycleStateTagView, KLifecycleStateTagViewModel }

// 模板生成文件
// export * from './{{folderName}}'
