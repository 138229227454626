import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_common_folder_manage = _resolveComponent("k-common-folder-manage")!

  return (_openBlock(), _createBlock(_component_k_common_folder_manage, {
    ref: "refCommonFolderManage",
    folderType: _ctx.folderType,
    modelCode: "ValidationMetrics",
    modelGroup: "requirement",
    schemaType: "ValidationMetrics_folder",
    toolStripItemClicked: _ctx.$vm.onToolStripItemClicked,
    operatorColumn: _ctx.operatorColumn,
    operationClick: _ctx.$vm.onOperationClick
  }, null, 8, ["folderType", "toolStripItemClicked", "operatorColumn", "operationClick"]))
}