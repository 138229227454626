
import { EnumDataGridAutoSizeColumnsMode, EnumDataGridRowModelType, defineView } from '@kmsoft/upf-core'
import { KRequirementObjManagePropOptions, KRequirementObjManageEventEmits, IReqDataItemDefinition } from './interface'
import KRequirementObjManageViewModel from './KRequirementObjManageViewModel'
import { EnumLifecycleStateColor, EnumLifecycleStateSimple, clsCodeMap, EnumLifecycleState } from '../../../../ebf-common/src'

const data: IReqDataItemDefinition[] = [
  {
    id: '#jhrw1',
    title: '项目任务',
    key: '1'
  },
  {
    id: '#ywdx2',
    title: '业务对象',
    key: '2'
  },
  {
    id: '#xqxgx3',
    title: '产品需求',
    key: '3'
  },
  {
    id: '#xqxgx4',
    title: '原始需求',
    key: '6'
  },
  {
    id: '#jggx3',
    title: '子需求',
    key: '5'
  }
]

export default defineView({
  name: 'KRequirementObjManage',
  props: KRequirementObjManagePropOptions,
  emits: KRequirementObjManageEventEmits,
  viewModel: KRequirementObjManageViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      data,
      clsCodeMap,
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      autosizeColumnsMode: EnumDataGridAutoSizeColumnsMode.FILL,
      EnumLifecycleStateSimple,
      EnumLifecycleStateColor,
      EnumLifecycleState
    }
  }
})
