import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_lifecycle_state_tag = _resolveComponent("k-lifecycle-state-tag")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_data_grid, {
      ref: "refDataGrid",
      rowModelType: "serverSide",
      loadData: _ctx.$vm.loadData,
      pagination: { pageIndex: 1, pageSize: 50 },
      onPaginationChanged: _ctx.$vm.paginationChanged,
      isMultipleSelection: false
    }, {
      columns: _withCtx(() => [
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "ID",
          dataPropertyName: "id",
          align: "center",
          width: "150"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "编码",
          dataPropertyName: "number",
          align: "center",
          width: "150"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "名称",
          dataPropertyName: "name",
          align: "center",
          width: "150"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "版本",
          dataPropertyName: "versionInfo",
          align: "center",
          width: "150"
        }),
        _createVNode(_component_k_data_grid_template_column, {
          id: "lifecycleState",
          name: "lifecycleState",
          dataPropertyName: "lifecycleState",
          headerText: "状态",
          width: "120",
          align: "center"
        }, {
          default: _withCtx(({ row }) => [
            _createVNode(_component_k_lifecycle_state_tag, { row: row }, null, 8, ["row"])
          ]),
          _: 1
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "检入标记",
          dataPropertyName: "checkInState",
          align: "center",
          width: "150"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "创建时间",
          dataPropertyName: "createTime",
          align: "center",
          width: "150"
        }),
        _createVNode(_component_k_data_grid_template_column, {
          headerText: "创建者",
          dataPropertyName: "creator",
          align: "center",
          width: "300"
        }, {
          default: _withCtx(({ cellValue}) => [
            _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["loadData", "onPaginationChanged"])
  ]))
}