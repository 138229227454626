
import { defineView, EnumDataGridRowModelType, KGridPagination } from '@kmsoft/upf-core'
import { clsCodeMap } from '@kmsoft/ebf-common'
import { KAllProductNeedsPropOptions, KAllProductNeedsEventEmits } from './interface'
import KAllProductNeedsViewModel from './KAllProductNeedsViewModel'

export default defineView({
  name: 'KAllProductNeeds',
  props: KAllProductNeedsPropOptions,
  emits: KAllProductNeedsEventEmits,
  viewModel: KAllProductNeedsViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      pagination: { pageIndex: 1, pageSize: 100 } as KGridPagination,
      clsCodeMap
    }
  }
})
