import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KWorkflowSigningHistoryEmitsType, KWorkflowSigningHistoryPropType } from './interface'
import { ref } from 'vue'
import { Api, ClassMetaClientSrv, EnumRequestCode, ObjectClientSrv } from '@kmsoft/ebf-common'
import { watch } from 'vue'

/** KWorkflowSigningHistory */
export default class KWorkflowSigningHistoryViewModel extends BaseViewModel<
  KWorkflowSigningHistoryEmitsType,
  KWorkflowSigningHistoryPropType
> {
  signingHistoryList = ref<any>([])
  flowDetailData = ref<any>({})
  loadingData = ref<boolean>(false)
  showExport = ref<boolean>(false)
  constructor(options: ViewModelOptions<KWorkflowSigningHistoryPropType>) {
    super(options)
    watch(
      () => this.props.objParam,
      newVal => {
        if (newVal.modelCode == 'RequirementChangeRequest') {
          this.showExport.value = true
        }
        this.getSigningHistoryData()
      },
      {
        immediate: true
      }
    )
    watch(
      () => this.props.refreshTip,
      () => {
        this.getSigningHistoryData()
      },
      {
        deep: true
      }
    )
  }

  viewDidMount() {
    // this.getSigningHistoryData()
  }

  async getSigningHistoryData() {
    // 临时处理 如果传入的是Document或者Part，并且this.props.objParam?.branchId为空，则调用接口获取数据后再查询
    const group = await ObjectClientSrv.getModelGroupByCode(this.props.objParam.modelCode)
    //const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(this.props.objParam.modelCode)
    let designerCode =
      this.props.objParam.modelCode == 'PromoteActivity'
        ? this.props.objParam.modelCode
        : await ClassMetaClientSrv.getDesignerEntityCode(this.props.objParam.modelCode)

    if ((designerCode == 'Document' || designerCode == 'Part') && !this.props.objParam?.branchId) {
      const result = await Api.post(group!, designerCode, 'get', { data: [this.props.objParam.id] })
      if (result && result.code == EnumRequestCode.SUCCESS) {
        this.props.objParam.branchId = result.data.branch.id
      } else {
        KNotification.error({
          title: '获取失败',
          content: result.message,
          details: result.detail
        })
        return
      }
    }
    const workObjId =
      designerCode == 'Document' || designerCode == 'Part' ? this.props.objParam?.branchId : this.props.objParam?.id

    designerCode =
      designerCode == 'Part' || designerCode == 'PromoteActivity'
        ? this.props.objParam.modelCode
        : await ClassMetaClientSrv.getDesignerEntityCode(this.props.objParam.modelCode)
    const signingHistoryPara = {
      workObjId: workObjId,
      workObjClsCode: designerCode
    }
    Api.post('official', 'ProcessContentService', 'listHistoryProcess', { data: [signingHistoryPara] }).then(result => {
      if (result && result.code == EnumRequestCode.SUCCESS) {
        const data =
          (result.data || []).map((item: any) => {
            item.id = item.processInstanceId
            return item
          }) || []

        this.signingHistoryList.value = data
      } else {
        KNotification.error({
          title: '获取失败',
          content: result.message,
          details: result.detail
        })
      }
    })
  }
}
