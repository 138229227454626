import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6746966e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"100%","height":"100%"} }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_loading = _resolveComponent("k-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$vm.loading)
      ? (_openBlock(), _createBlock(_component_k_loading, {
          key: 0,
          desc: _ctx.$vm.loadingText
        }, null, 8, ["desc"]))
      : (_openBlock(), _createElementBlock("iframe", {
          key: 1,
          src: _ctx.$vm.url,
          class: "iframe-style"
        }, null, 8, _hoisted_2))
  ]))
}