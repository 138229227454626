import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","height":"100%","display":"flex","justify-content":"center","align-items":"center","gap":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_checkbox = _resolveComponent("k-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_checkbox, {
      checked: _ctx.$vm.model.relatedPart,
      "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.model.relatedPart) = $event))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 关联零部件 ")
      ]),
      _: 1
    }, 8, ["checked"])
  ]))
}