import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KConfigFormEmitsType, KConfigFormPropType, formFields, groupList } from './interface'
import { ref, watch, computed } from 'vue'
import { get, cloneDeep } from 'lodash'

/** KConfigForm */
export default class KConfigFormViewModel extends BaseViewModel<KConfigFormEmitsType, KConfigFormPropType> {
  // 表单绑定modelvalue值
  formValue = ref<Record<string, any>>({})
  // 表单列配置
  formList = ref<Array<formFields>>([])
  // 是否为查看态
  isView = ref<Boolean>(true)
  // 表单ref
  refConfigForm = ref<any>(null)
  // 表单label宽度
  labelCol = ref<Number>(4)
  // 表单多少列排列
  colSpan = ref<Number>(1)

  // 带下的配置
  groupList = ref<Array<groupList>>([])
  activeKey = ref<Array<string>>([])
  isCol = ref<Boolean>(false)

  constructor(options: ViewModelOptions<KConfigFormPropType>) {
    super(options)
    this.labelCol.value = options.props.labelCol
    this.isView.value = options.props.isView
    this.colSpan.value = options.props.colSpan

    // 监听值
    watch(
      () => this.props.formValue,
      newVal => {
        this.formValue.value = newVal
      },
      {
        immediate: true
      }
    )

    // 监听列配置
    watch(
      () => this.props.formList,
      (newVal, oldVal) => {
        if (newVal && newVal !== oldVal) {
          this.formList.value = newVal.map((arr: any) => {
            return this.refreshSelect(arr)
          })
        }
      },
      {
        immediate: true
      }
    )

    // 监听折叠
    watch(
      () => this.props?.groupList,
      (newVal, oldVal) => {
        if (newVal && newVal !== oldVal) {
          this.groupList.value = newVal.map((arr: any) => {
            if (arr?.children && arr?.children?.length) {
              arr.children.map((arrMap: any) => {
                return this.refreshSelect(arrMap)
              })
            }
            return arr
          })
          if (this.props?.isExpand) {
            this.activeKey.value = (newVal || []).map((arr: any) => {
              return arr?.props
            })
          }
        }
      },
      { immediate: true }
    )

    this.isCol = computed(() => {
      return !!this.groupList.value?.length
    })
  }

  viewDidMount() {
    this.refresh()
  }

  // 表单预留刷新方法
  refresh() {
    console.log(this.refConfigForm.value, '666')
  }

  // 公共获取值
  getValue() {
    const result = {} as any
    if (this.isCol) {
      const allList = this.mergeChildren(cloneDeep(this.groupList.value))
      ;(allList || []).forEach((arrEach: any) => {
        const fieldsDom = this.getByRecursion(arrEach?.name, this) as any
        const getValue = fieldsDom?.getValue() || get(this.formValue.value, `${arrEach?.props}`, '')
        result[arrEach?.props] = getValue
      })
    } else {
      ;(this.formList.value || []).forEach((arrEach: any) => {
        const fieldsDom = this.getByRecursion(arrEach?.name, this) as any
        const getValue = fieldsDom?.getValue()
        result[arrEach?.props] = getValue
      })
    }
    return this.props.getValue(result)
  }

  mergeChildren(arr: any) {
    let result = [] as any
    arr.forEach((item: any) => {
      if (item.children) {
        result = result.concat(this.mergeChildren(item.children))
      }
      delete item.children
      result.push(item)
    })
    return result
  }

  // 公共设置
  setValue() {
    ;(this.formList.value || []).forEach((arrEach: any) => {
      const fieldsDom = this.getByRecursion(arrEach?.name) as any
      fieldsDom?.setValue(get(this.formValue.value, `${arrEach?.props}`, ''))
    })
  }

  // 刷新下拉options
  refreshSelect(arr: any) {
    if (arr?.code) {
      arr.attrs = {
        ...arr.attrs,
        options: [{ label: 'aa', value: 'aa' }]
      }
    }
    return arr
  }
}
