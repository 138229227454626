import { connect, withInstall } from '@kmsoft/upf-core'
import KValidationBoardView from './KValidationBoard.vue'
import KValidationBoardViewModel from './KValidationBoardViewModel'

const KValidationBoard = connect(KValidationBoardView, KValidationBoardViewModel)

export default withInstall(KValidationBoard)
export { KValidationBoard, KValidationBoardView, KValidationBoardViewModel }

// 模板生成文件
// export * from './{{folderName}}'
