import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KMyRequirementPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KMyRequirementPropType = ViewPropsTypeExtract<typeof KMyRequirementPropOptions>

/** 事件 */
export const KMyRequirementEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KMyRequirementEmitsType = ViewEmitsTypeExtract<typeof KMyRequirementEventEmits>
