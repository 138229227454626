import { ObjBusinessParam, ObjectPanelTabRetrieveEvent } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KProductNeedsSectionEditPanelPropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KProductNeedsSectionEditPanelPropType = ViewPropsTypeExtract<typeof KProductNeedsSectionEditPanelPropOptions>

/** 事件 */
export const KProductNeedsSectionEditPanelEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 自定义事件
   * @param event
   * @returns
   */
  retrieve: (event: ObjectPanelTabRetrieveEvent<any, any>) => true,
  clickEdit: (event: boolean) => true
}

/** 事件类型 **/
export type KProductNeedsSectionEditPanelEmitsType = ViewEmitsTypeExtract<typeof KProductNeedsSectionEditPanelEventEmits>
