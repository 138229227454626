import {
  Api,
  EnumObjTabPageId,
  EnumRequestCode,
  KObjectPanelViewModel,
  ObjBusinessParam,
  ObjectClientSrv,
  ObjectPanelDataUpdatedEvent,
  ObjectPanelRetrieveEvent,
  loadingHandle,
  TREE_VIEW_ROOT_NODE_KEY
} from '@kmsoft/ebf-common'
import {
  BaseViewModel,
  EnumTreeViewNodeRefreshType,
  KNotification,
  TreeViewCheckedEventArgs,
  TreeViewInsertEventArgs,
  TreeViewSelectEventArgs,
  ViewModelOptions,
  KCascaderViewModel
} from '@kmsoft/upf-core'
import { computed, ref, watch } from 'vue'
import {
  EnumFilterType,
  EnumPartNodeChildExpandMode,
  PartClientSrv,
  PartFilterResult,
  PartView,
  StructureView
} from '../../client-srv'
import { KPartStructureTreeViewModel, PartStructureTreeNode } from '../part-structure-tree'
import { EnumPartToolStripItemKeys } from '../toolstrip'
import { KPartStructureManageEmitsType, KPartStructureManagePropType } from './interface'

/** 零部件结构关联 */
export default class KPartStructureManageViewModel extends BaseViewModel<
  KPartStructureManageEmitsType,
  KPartStructureManagePropType
> {
  /** 零部件结构树引用 */
  refPartStructureTree = ref<KPartStructureTreeViewModel>()
  /** 对象面板 */
  refObjectPanel = ref<KObjectPanelViewModel>()
  refCascader = ref<KCascaderViewModel>()

  //#region 界面控制
  /** 是否显示替换件网格 */
  isShowReplaceGrid = ref<boolean>(true)
  /** 是否初始化完成 */
  isInitialized = ref<boolean>(false)
  //#endregion

  //#region 对象参数
  /** 结构树根对象参数 */
  rootObjParam = ref<ObjBusinessParam>()
  /** 当前选择的对象 */
  selectObjParam = ref<ObjBusinessParam>()
  /** 选中的树节点 */
  selectedNode = ref<PartStructureTreeNode>()

  /**bomLinkId */
  bomLinkParam = ref<ObjBusinessParam>()
  //#endregion

  //#region 视图
  /** 视图列表 */
  structureViews = ref<Array<StructureView>>([])
  /** 视图下拉列表 */
  structureViewOptions = ref<any[]>([])
  /** 视图名称 */
  structureViewName = ref<string>('')
  /** 基线Id */
  baselineId = ref<string>('')
  /** 时间 */
  datedValue = ref<string>()
  /** 视图模式 */
  partStructureViewMode = computed(() => {
    return this.structureViews.value.find(a => a.viewName == this.structureViewName.value)
  })
  //#endregion

  /** 视图列表 */
  partViews = ref<Array<PartView>>([])
  /** 视图下拉列表 */
  partViewOptions = ref<any[]>([])
  /** 视图名称 */
  partViewName = ref<string>('Design')

  //#region 过滤
  /** 过滤条件 */
  // structureFilterOptions = ref<Array<StructureFilterOption>>([])
  /** 过滤名称 */
  structureFilterName = ref<string>()
  /** 零部件过滤规则 */
  filterResult: PartFilterResult = {
    searchCondition: '',
    filterType: EnumFilterType.None,
    results: []
  }
  //#endregion

  /** 节点展开模式 */
  nodeChildExpandMode = ref<EnumPartNodeChildExpandMode>()

  //#region 控制属性
  /** 是否正在变更选择项 */
  private selectionChanging = false
  /** 是否允许选择 */
  private allowSelectionChange = false
  /** 查询条件 */
  queryCriteria = ref<string>('')
  /** 查询索引 */
  queryIndex = ref<number>(0)
  /** 查询结果 */
  queryData = ref<Array<any>>([])
  /** 查询节点 */
  queryNode = ref<PartStructureTreeNode>()
  /** 查询选中节点 */
  queryNodeData = ref<Array<any>>([])
  //#endregion
  /** 展开方式 */
  currentExtendMode?: EnumPartNodeChildExpandMode

  //#region 对外组件API
  /** 零部件结构树 */
  get partStructureTree() {
    return this.refPartStructureTree.value
  }

  /** 对象面板 */
  get objectPanel() {
    return this.refObjectPanel.value
  }
  //#endregion

  constructor(options: ViewModelOptions<KPartStructureManagePropType>) {
    super(options)
    this.rootObjParam.value = options.props.objParam
    this.selectObjParam.value = options.props.objParam

    // 监听对象参数
    watch(
      () => options.props.objParam,
      async (newValue, oldValue) => {
        this.rootObjParam.value = newValue
        if (oldValue) {
          await this.refresh()
        } else {
          await this.init()
        }
      },
      {
        immediate: true
      }
    )

    // 节点展开模式
    watch(this.nodeChildExpandMode, newValue => {
      this.emit('update:nodeChildExpandMode', newValue!)
    })

    // // 监听版本规则选择
    // watch(
    //   this.structureViewName,
    //   async (newVal,oldVal) => {
    //     this.onStructureViewChange(newVal!)
    //     // this.queryData.value = []
    //   },
    //   {
    //     immediate: true
    //   }
    // )

    // 监听过滤条件
    // watch(this.structureFilterName, async newValue => {
    //   // 获取过滤结果
    //   await this.getFilterResult()

    //   // 刷新树
    //   await this.refPartStructureTree.value?.treeView?.refresh()

    //   // 选中默认节点
    //   this.selectRootNode()
    // })
  }

  viewDidMount() {}

  /**
   * 初始化
   */
  async init() {
    if (!this.props.objParam) {
      return
    }

    // 加载完成
    this.isInitialized.value = false

    // 加载过滤器
    await this.initFilter()

    // 加载完成
    this.isInitialized.value = true
  }

  /**
   * 重载
   */
  async refresh() {
    await this.init()
  }

  /**
   * 初始化过滤器
   */
  async initFilter(partId?: string) {
    // API
    this.structureViews.value = []
    this.structureViews.value.push({
      viewName: '最新版本',
      expandMode: EnumPartNodeChildExpandMode.LatestVersion
    })
    this.structureViews.value.push({
      viewName: '最新发布版本',
      expandMode: EnumPartNodeChildExpandMode.LatestPublishVersion
    })
    this.structureViews.value.push({
      viewName: '基线',
      expandMode: EnumPartNodeChildExpandMode.OtherBaseline
    })
    this.structureViews.value.push({
      viewName: '时间有效性',
      expandMode: EnumPartNodeChildExpandMode.DatedEffectivity,
      datedValue: this.datedValue.value
    })

    const options = this.structureViews.value.map(structureView => {
      return {
        label: structureView.viewName,
        value: structureView.viewName,
        children: [] as any[]
      }
    })

    const param = {
      data: [partId || this.rootObjParam.value?.id]
    }
    const result = await Api.post('baseline', 'ManagedBaseline', 'listPartBaseline', param)
    if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
      result.data.forEach((data: any) => {
        const res = {
          viewName: data.number + '【' + data.name + '】',
          expandMode: EnumPartNodeChildExpandMode.Baseline,
          baselineObj: {
            modelCode: 'ManagedBaseline',
            modelGroup: 'baseline',
            id: data.id
          }
        }
        options.forEach(option => {
          if (option.label == '基线') {
            option.children.push({
              label: res.viewName,
              value: res.viewName,
              children: []
            })
          }
        })
        this.structureViews.value.push(res)
      })
      this.structureViewOptions.value = options
    }
    if (partId) return
    /** 默认视图 */
    let defaultViewName = ''
    if (this.props.viewName) {
      defaultViewName = this.props.viewName
    } else {
      const expandMode = EnumPartNodeChildExpandMode.LatestVersion
      defaultViewName = PartClientSrv.getPartViewModeViewName(expandMode)
    }

    // API
    // this.structureFilterOptions.value = [{ label: '??', value: '' }]

    this.structureViewName.value = defaultViewName

    this.partViews.value = []
    const resultPartView = await Api.post('part', 'PartView', 'listAll', { data: [] })
    if (resultPartView && resultPartView.code == EnumRequestCode.SUCCESS && resultPartView.data) {
      resultPartView.data.forEach((data: any) => {
        this.partViews.value.push({ name: data.name, description: data.description })
      })
    }

    //this.partViews.value = []
    //this.partViews.value.push({ name: 'Design', description: '设计' })
    //this.partViews.value.push({ name: 'Manufacture', description: '制造' })
    this.partViewOptions.value = this.partViews.value.map(value => {
      return {
        label: value.description,
        value: value.name,
        children: [] as any[]
      }
    })

    // 默认设计视图
    if (this.props.partViewName) {
      this.partViewName.value = this.props.partViewName!
    }
  }

  /**
   * 获取过滤结果
   * @returns
   */
  async getFilterResult() {
    // 如果没有选中过滤条件
    if (!this.structureFilterName.value) {
      this.filterResult.filterType = EnumFilterType.None
      return
    }

    // 获取根节点
    const rootNode = this.refPartStructureTree.value?.treeView?.getRootNodes() as Array<PartStructureTreeNode>

    // const parentId = rootNode[0].partId
    // const parentClsId = rootNode[0].partObjClsId

    // filterResult = await DataSrv.partStructureSrv.getFilterResult({
    //   objClsID: parentClsId,
    //   objID: parentId,
    //   filterName: structureFilterName.value,
    //   viewName: structureViewName.value
    // })
  }

  /** 选中根节点 */
  selectRootNode() {
    this.refPartStructureTree.value?.treeView?.setSelectedNode(TREE_VIEW_ROOT_NODE_KEY)
  }

  /**显示/隐藏替换件网格 */
  setReplaceGridCollapse(collapse: boolean) {
    this.isShowReplaceGrid.value = collapse
  }

  //#region 事件
  /**
   * 节点选择前事件
   * @param event
   */
  async onBeforeNodeSelect(event: TreeViewSelectEventArgs) {
    if (!event.node) {
      return
    }

    // 取消选择
    event.cancel = !this.allowSelectionChange

    // 如果正在选择
    if (this.selectionChanging) {
      return
    }

    this.selectionChanging = true

    try {
      /** 保存更改 */
      const saveResult = await this.refObjectPanel.value?.saveChanges()

      // 如果取消
      if (saveResult && saveResult.cancel) {
        return
      }

      this.allowSelectionChange = true

      try {
        this.refPartStructureTree.value?.treeView?.setSelectedNode(event.node.key!)
      } finally {
        this.allowSelectionChange = false
      }
    } finally {
      this.selectionChanging = false
    }
  }

  /** 节点选择事件 */
  async onAfterSelect(event: TreeViewSelectEventArgs<PartStructureTreeNode>) {
    const node = event.node
    // 保存选中的树节点
    this.selectedNode.value = node
    //获取设计态编码
    node.parentPartObjParam.modelGroup = await ObjectClientSrv.getModelGroupByCode(node.parentPartObjParam.modelCode)
    const group = await ObjectClientSrv.getModelGroupByCode(node.partObjParam.modelCode!)
    const param = { ...node.partObjParam, modelGroup: group }
    this.selectObjParam.value = param

    //若存在BomLink参数则取BomLink参数
    if (node.bomLinkParam) {
      this.bomLinkParam.value = node.bomLinkParam
    } else {
      this.bomLinkParam.value = undefined
    }

    this.emit('afterTreeSelect', event)
  }

  /** 节点插入前 */
  onBeforeNodeInsert(event: TreeViewInsertEventArgs<PartStructureTreeNode>) {
    // 防止报错
    const node = event.node
    if (node == null) {
      return
    }

    //结构ID不存在时，不生成节点
    if (this.filterResult.filterType == EnumFilterType.FilterByChildCondition) {
      //获取节点对应结果

      const index = this.filterResult.results.find(a => a.structID == node.id)
      //如果没有过滤结果，就阻止添加
      if (!index) {
        event.cancel = true
      } else if (index.isFlag) {
        //C# 代码
        //打标的更换图标，原图标后加-mark
        // node.ImageKey = Path.Combine('RES', 'MainProgram', 'PartManage', 'PART1-mark.png')
        // node.SelectedImageKey = Path.Combine('RES', 'MainProgram', 'PartManage', 'PART1-mark.png')
        // node.Expand() //打标节点自动展开
      } else if (index.isChildFlag) {
        //C# 代码
        //熊奎 2016-7-18 添加了一个标记，标志子节点满足打标，自身不满足打标的情况 bug 8418
        // node.ImageKey = Path.Combine('RES', 'MainProgram', 'PartManage', 'PART2-mark.png')
        // node.SelectedImageKey = Path.Combine('RES', 'MainProgram', 'PartManage', 'PART2-mark.png')
        // node.Expand() //打标节点自动展开
      }
    }
  }

  async onPartViewChange(arg: any) {
    this.partViewName.value = arg.newValue
    let defaultViewName = ''
    if (this.props.viewName) {
      defaultViewName = this.props.viewName
    } else {
      const expandMode = EnumPartNodeChildExpandMode.LatestVersion
      defaultViewName = PartClientSrv.getPartViewModeViewName(expandMode)
    }
    this.structureViewName.value = defaultViewName
    this.refCascader.value?.setValue(['最新版本'])
    await this.refPartStructureTree.value?.treeView?.refresh()
    this.selectRootNode()
    this.refreshQueryCriteria()
    // 视图改变后重新加载基线
    const rootNode = this.refPartStructureTree.value?.treeView?.getRootNodes()[0] as PartStructureTreeNode
    this.initFilter(rootNode?.partObjParam?.id)
  }

  /**
   * 版本规则改变
   * @param newValue
   * @returns
   */
  async onStructureViewChange(value: Array<string | number>, selectedOptions: Record<string, any>) {
    const result = selectedOptions.pop()
    if (result.value == '基线' && result.children.length == 0) {
      KNotification.warning({
        title: '系统提示',
        content: '当前结构树没有基线'
      })
      return
    }
    this.structureViewName.value = result.value
    if (!this.partStructureViewMode.value) {
      return
    }

    // 获取展开模式
    this.nodeChildExpandMode.value = this.partStructureViewMode.value.expandMode

    if (this.partStructureViewMode.value.expandMode == EnumPartNodeChildExpandMode.OtherBaseline) {
      KNotification.warning({
        title: '系统提示',
        content: '当前结构树没有基线'
      })
      return
    }

    // 如果是Bom视图
    if (this.partStructureViewMode.value.baselineContents) {
      if (this.partStructureViewMode.value.baselineContents!.length <= 0) {
        return
      }
      this.rootObjParam.value = this.partStructureViewMode.value.baselineContents.find(
        _ => _.masterId == this.props.objParam.masterId
      )!
      this.selectObjParam.value = {
        modelCode: this.selectObjParam.value!.modelCode,
        id: this.partStructureViewMode.value.baselineContents[0].id,
        modelGroup: this.selectObjParam.value?.modelGroup!
      }
    } else {
      this.rootObjParam.value = this.props.objParam
      // this.selectObjParam.value = this.props.objParam
    }
    if (result.value == '时间有效性') {
      const structureView = this.structureViews.value.find(e => e.viewName == '时间有效性')
      if (!structureView?.datedValue) return
    }
    await this.refPartStructureTree.value?.treeView?.refresh()
    this.selectRootNode()
    this.refreshQueryCriteria()
    this.emit('update:viewName', result.value)
  }

  /** 树加载完成以后 */
  async onTreeReady() {
    // 选择根节点
    this.selectRootNode()

    // 使用灵活队列的方式显示数据
    setTimeout(() => {
      // 不使用树默认展开功能，频繁加载大量数据会导致控件卡顿
      const rootNodes = this.refPartStructureTree.value?.treeView?.getRootNodes()
      if (rootNodes) {
        this.refPartStructureTree.value?.treeView?.expandLevel(rootNodes, 0)
      }
    }, 0)

    // 抛出树就绪事件
    this.emit('treeReady', this.refPartStructureTree.value!)
  }

  /** 节点选中后事件 */
  onTreeAfterCheck(event: TreeViewCheckedEventArgs) {
    this.emit('afterTreeCheck', event)
  }

  /** 节点刷新 */
  onNodeRefresh() {
    this.refObjectPanel.value?.refresh()
  }

  /** 替换网格替换零部件 */
  async onSwapChangePart() {
    // 替换件网格刷新后刷新节点
    await this.refPartStructureTree.value?.refreshNode(this.selectedNode.value!, EnumTreeViewNodeRefreshType.NODE_PARENT)
    // 手动设置选中节点，刷新节点不会触发节点切换
    this.selectedNode.value && this.refPartStructureTree.value?.treeView?.setSelectedNode(this.selectedNode.value.key)
  }

  /** 替换件网格更新后刷新树 */
  async onSwapRefresh() {
    // 替换件网格刷新后刷新节点
    await this.refPartStructureTree.value?.refreshNode(this.selectedNode.value!, EnumTreeViewNodeRefreshType.NODE_CHILD_AND_SELF)
    // 手动设置选中节点，刷新节点不会触发节点切换
    this.selectedNode.value && this.refPartStructureTree.value?.treeView?.setSelectedNode(this.selectedNode.value.key)
  }

  /**
   * 对象面板更新事件
   * @param event
   */
  async onObjectPanelDataUpdated(event: ObjectPanelDataUpdatedEvent) {
    if (event.tabId == EnumObjTabPageId.StructureList || event.tabId == EnumObjTabPageId.Property) {
      /** 获取选中的节点 */
      const selectedNode = this.refPartStructureTree.value?.treeView?.getSelectedNode() as PartStructureTreeNode
      /** 刷新选择的节点 */
      if (selectedNode) {
        await this.refPartStructureTree.value?.refreshNode(
          selectedNode,
          event.tabId == EnumObjTabPageId.StructureList
            ? EnumTreeViewNodeRefreshType.NODE_CHILD
            : EnumTreeViewNodeRefreshType.SELF
        )
      }
    }
  }
  //#endregion

  /**
   * 清空查询数据
   */
  refreshQueryCriteria() {
    this.queryIndex.value = 0
    this.queryData.value = []
    this.queryNodeData.value = []
  }

  /**
   * 定位搜索
   */
  async locateSearch() {
    const qry = this.queryCriteria.value
    const index = this.queryIndex.value
    const queryData = this.queryData.value
    const rootNode = this.refPartStructureTree.value?.treeView?.getRootNodes()[0] as PartStructureTreeNode

    this.currentExtendMode = rootNode?.currentExtendMode
    let queryRule = { type: 'LATEST_WITH_WORKING_COPY' } as any
    switch (this.currentExtendMode) {
      case 0:
        queryRule = { type: 'LATEST_WITH_WORKING_COPY' }
        break
      case 1:
        break
      case 2:
        queryRule = { type: 'LATEST_RELEASED' }
        break
      case 3:
        queryRule = { type: 'LATEST_WITH_WORKING_COPY' }
        break
      case 5:
      case 6:
        queryRule = {
          type: 'BASELINE',
          baseline: {
            id: rootNode.baselineId,
            clazz: 'ManagedBaseline'
          }
        }
        break
      case 7:
        queryRule = {
          type: 'DATED_EFFECTIVITY',
          datedValue: this.datedValue.value
        }
        break
    }
    if (qry == '') {
      // 参数为空不做查询
      this.refreshQueryCriteria()
      return
    }

    // 判断queryData是否为空
    if (queryData.length == 0) {
      const param = {
        key: qry,
        root: {
          id: rootNode?.partObjParam?.id,
          clazz: rootNode?.partObjParam?.modelCode
        },
        queryRule: queryRule,
        view: rootNode.view
      }
      const result = await Api.post('part', 'Part', 'findPartPath', { data: [param] })
      if (result && result.code == EnumRequestCode.SUCCESS) {
        // 接口成功
        // 判断是否查询到属性
        if (result.data.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '查询结果不存在'
          })
          this.queryIndex.value = 0
          return
        }
        if (result.data.length > 0) {
          const data = result.data
          this.queryData.value = data
          // 左侧树勾选当前选中的节点，会触发右侧面板更新
          this.refPartStructureTree.value?.treeView?.expandByPath(rootNode!, rootNode?.id + ',' + data[0], true)
          this.queryIndex.value = 1
          return
        }
      } else {
        KNotification.error({
          title: '搜索失败',
          content: result.message || '搜索失败',
          details: result.detail
        })
        return
      }
    } else {
      this.refPartStructureTree.value?.treeView?.expandByPath(rootNode!, rootNode?.id + ',' + queryData[index], true)
      this.queryIndex.value = queryData[index + 1] == undefined ? 0 : index + 1
    }
  }

  async onDatedValueChange(arg: any) {
    const structureView = this.structureViews.value.find(e => e.viewName == '时间有效性')
    structureView!.datedValue = arg.newValue

    await this.refPartStructureTree.value?.treeView?.refresh()
    this.selectRootNode()
    this.refreshQueryCriteria()
  }

  /**工具栏点击事件 */
  async onToolStripClicked(event: EnumPartToolStripItemKeys) {
    if (event === EnumPartToolStripItemKeys.TOOL_STRIP_CREATE_BASELINE) {
      await this.initFilter()
    }
  }
  onRetrieve(event: ObjectPanelRetrieveEvent<any>) {
    switch (event.command) {
      case 'partNodeObjectUpdate':
        const selectNode = this.refPartStructureTree.value?.treeView?.getSelectedNode() as PartStructureTreeNode
        if (selectNode.id == 'root') {
          this.refPartStructureTree.value!.rootNodeId = event.data.id
        }
        this.refPartStructureTree.value?.refreshNode(selectNode!, EnumTreeViewNodeRefreshType.NODE_CHILD_AND_SELF)
        break
      default:
        break
    }
  }

  // 找上一处
  async findPrevious(params: any, reFind: boolean) {
    this.find(params, reFind, 1)
  }

  // 最上
  async findTop(params: any, reFind: boolean) {
    this.find(params, reFind, 3)
  }

  // 找下一处
  async findNext(params: any, reFind: boolean) {
    this.find(params, reFind, 2)
  }

  // 最下
  async findBottom(params: any, reFind: boolean) {
    this.find(params, reFind, 4)
  }

  // 选中全部
  async selectAll(params: any, reFind: boolean, onSuccess?: () => void) {
    const name = params?.name
    const number = params?.number
    const maxLevel = params?.maxLevel
    const isFindAll = params?.isFindAll
    const queryNodeData = this.queryNodeData.value as any
    let rootNode = this.refPartStructureTree.value?.treeView?.getRootNodes()[0] as PartStructureTreeNode

    if (name == '' && number == '') {
      // 参数为空不做查询
      this.refreshQueryCriteria()
      return
    }
    // 判断是否需要查询

    if (reFind || queryNodeData.length == 0) {
      if (!isFindAll) {
        this.queryNode.value = this.queryNode.value
          ? this.queryNode.value
          : ((this.refPartStructureTree.value?.operateNode as unknown) as PartStructureTreeNode)
        rootNode = this.queryNode.value as PartStructureTreeNode
      }
      const root = rootNode.partObjParam as any
      const currentExtendMode = rootNode?.currentExtendMode
      let queryRule = { type: 'LATEST' } as any
      switch (currentExtendMode) {
        case 0:
          queryRule = { type: 'LATEST' }
          break
        case 1:
          break
        case 2:
          queryRule = { type: 'LATEST_RELEASED' }
          break
        case 3:
          queryRule = { type: 'LATEST' }
          break
        case 5:
        case 6:
          queryRule = {
            type: 'BASELINE',
            baseline: {
              id: rootNode.baselineId,
              clazz: 'ManagedBaseline'
            }
          }
          break
        case 7:
          queryRule = {
            type: 'DATED_EFFECTIVITY',
            datedValue: this.datedValue.value
          }
          break
      }
      const param = {
        name: name,
        number: number,
        maxLevel: maxLevel == 0 ? 99 : maxLevel,
        root: {
          id: root?.id,
          clazz: root?.modelCode
        },
        rootBomLinkId: rootNode.key,
        queryRule: queryRule,
        view: rootNode.view
      }
      loadingHandle.show()
      const result = await Api.post('part', 'Part', 'findPartPath', { data: [param] })
      loadingHandle.remove()
      if (result && result.code == EnumRequestCode.SUCCESS) {
        // 接口成功
        // 判断是否查询到属性
        if (result.data.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '查询结果不存在'
          })
          this.queryIndex.value = -1
          this.queryNodeData.value = []
          return
        }
        if (result.data.length > 0) {
          this.queryNodeData.value = result.data
          this.queryIndex.value = -1
          onSuccess?.()
          this.refPartStructureTree.value?.treeView?.setCheckedKeys(result.data)
          return
        }
      } else {
        KNotification.error({
          title: '查找失败',
          content: result.message || '查找失败',
          details: result.detail
        })
        return
      }
    } else {
      onSuccess?.()
      // 选中所有节点
      this.refPartStructureTree.value?.treeView?.setCheckedKeys(queryNodeData)
    }
  }

  // 取消选中全部
  async cancelSelectAll() {
    this.refPartStructureTree.value?.treeView?.clearChecked()
  }

  // 清洗查询数据
  async refershSearchData() {
    this.queryIndex.value = 0
    this.queryData.value = []
    this.queryNodeData.value = []
  }

  // 清除查询节点
  async clearQueryNode() {
    this.queryNode.value = undefined
  }

  // 查找上(下)一处(place: 1(上) 2 (下) 3(最上) 4(最下))
  async find(params: any, reFind: boolean, place: number) {
    const name = params?.name
    const number = params?.number
    const maxLevel = params?.maxLevel
    const isFindAll = params?.isFindAll
    const index = this.queryIndex.value
    const queryData = this.queryData.value
    const originRootNode = this.refPartStructureTree.value?.treeView?.getRootNodes()[0] as PartStructureTreeNode
    let rootNode = originRootNode
    if (name == '' && number == '') {
      // 参数为空不做查询
      this.refreshQueryCriteria()
      return
    }

    // 判断是否需要查询
    if (reFind || queryData.length == 0) {
      if (!isFindAll) {
        this.queryNode.value = this.queryNode.value
          ? this.queryNode.value
          : ((this.refPartStructureTree.value?.operateNode as unknown) as PartStructureTreeNode)
        rootNode = this.queryNode.value as PartStructureTreeNode
      }
      const root = rootNode.partObjParam as any
      const currentExtendMode = rootNode?.currentExtendMode
      let queryRule = { type: 'LATEST' } as any
      switch (currentExtendMode) {
        case 0:
          queryRule = { type: 'LATEST' }
          break
        case 1:
          break
        case 2:
          queryRule = { type: 'LATEST_RELEASED' }
          break
        case 3:
          queryRule = { type: 'LATEST' }
          break
        case 5:
        case 6:
          queryRule = {
            type: 'BASELINE',
            baseline: {
              id: rootNode.baselineId,
              clazz: 'ManagedBaseline'
            }
          }
          break
        case 7:
          queryRule = {
            type: 'DATED_EFFECTIVITY',
            datedValue: this.datedValue.value
          }
          break
      }
      const param = {
        name: name,
        number: number,
        maxLevel: maxLevel == 0 ? 99 : maxLevel,
        root: {
          id: root?.id,
          clazz: root?.modelCode
        },
        rootBomLinkId: rootNode.key,
        queryRule: queryRule,
        view: rootNode.view
      }
      loadingHandle.show()
      const result = await Api.post('part', 'Part', 'findPartPath', { data: [param] })
      loadingHandle.remove()
      if (result && result.code == EnumRequestCode.SUCCESS) {
        // 接口成功
        // 判断是否查询到属性
        if (result.data.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '查询结果不存在'
          })
          this.queryIndex.value = 0
          this.queryData.value = []
          return
        }
        if (result.data.length > 0) {
          const data = result.data
          this.queryData.value = data
          // 左侧树勾选当前选中的节点，会触发右侧面板更新
          this.queryIndex.value = place == 1 || place == 4 ? data.length - 1 : 0
          this.refPartStructureTree.value?.treeView?.expandByPath(originRootNode!, data[this.queryIndex.value], true)
          return
        }
      } else {
        KNotification.error({
          title: '查找失败',
          content: result.message || '查找失败',
          details: result.detail
        })
        return
      }
    } else {
      if (place == 1) {
        this.queryIndex.value = queryData[index - 1] == undefined ? queryData.length - 1 : index - 1
      } else if (place == 2) {
        this.queryIndex.value = queryData[index + 1] == undefined ? 0 : index + 1
      } else if (place == 3) {
        this.queryIndex.value = 0
      } else {
        this.queryIndex.value = queryData.length - 1
      }
      if (!isFindAll) {
        this.refPartStructureTree.value?.treeView?.expandByPath(originRootNode!, queryData[this.queryIndex.value], true)
      } else {
        this.refPartStructureTree.value?.treeView?.expandByPath(originRootNode!, queryData[this.queryIndex.value], true)
      }
    }
  }
}
