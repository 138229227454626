import { KNotification, MemoryCacheBase } from '@kmsoft/upf-core'
import lodash from 'lodash'
import { Api, EnumRequestCode } from '../../../..'
/** 对象类缓存 */
export class MetricsTypeCache extends MemoryCacheBase<Array<Record<string, any>>> {
  public static cacheKey = 'MetricsTypeCache'
  constructor() {
    super()
    this.cacheKey = MetricsTypeCache.cacheKey
  }

  public async reload() {
    const res = await Api.post('official', 'ClsLayoutPropService', 'listPropByClsCode', { data: [['ValidationMetrics']] })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      const reslut = res.data.ValidationMetrics.find((item: any) => item.propCode == 'Type')
      this.cacheData = JSON.parse(reslut.propertyArgs).options
    } else {
      KNotification.error({
        title: '获取失败',
        content: res.message,
        details: res.detail
      })
      this.cacheData = []
    }
  }

  /** 获取所有数据 */
  public geMetricsTypeList() {
    return this.cacheData
  }
}
