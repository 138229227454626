
import { defineView, EnumDataGridRowModelType, KGridPagination } from '@kmsoft/upf-core'
import { KMyCustomerNeedsPropOptions, KMyCustomerNeedsEventEmits } from './interface'
import KMyCustomerNeedsViewModel from './KMyCustomerNeedsViewModel'
import { clsCodeMap } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KMyCustomerNeeds',
  props: KMyCustomerNeedsPropOptions,
  emits: KMyCustomerNeedsEventEmits,
  viewModel: KMyCustomerNeedsViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      pagination: { pageIndex: 1, pageSize: 100 } as KGridPagination,
      clsCodeMap
    }
  }
})
