import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { EnumLayoutSchemaType, ObjBusinessBase, ObjBusinessParam, ObjBusinessResult } from '../../../../client-srv'
import { ObjectToolStripItem } from '../../../object-tool-strip'
import { ObjectLoadDataProvider } from '../../../../controls'

export type editPanelLoadData = (params: ObjBusinessParam) => Promise<ObjBusinessBase | undefined>

/** 参数 **/
export const KObjectPropertyEditPanelBasePropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  /** 在后面插入工具栏 */
  toolStripItems: VuePropTypes.createType<ObjectToolStripItem[]>().def([]),
  /** 在最前端插入工具栏 */
  toolStripItemsInsert: VuePropTypes.createType<ObjectToolStripItem[]>().def([]),
  /** 面板值加载 */
  loadData: VuePropTypes.func<ObjectLoadDataProvider>()
    .setRequired()
    .def(() => Promise.resolve({} as ObjBusinessResult)),
  /** 是否添加扩展属性 **/
  extendedAttributes: VuePropTypes.bool().def(false),
  /** 扩展属性添加的位置 */
  extendedPosition: VuePropTypes.number().def(),
  /**布局方案 */
  schemaType: VuePropTypes.createType<EnumLayoutSchemaType | string>().def(EnumLayoutSchemaType.FormEdit),
  /** 是否显示工具栏 **/
  showToolStripItems: VuePropTypes.bool().def(true)
}

/** 参数类型 **/
export type KObjectPropertyEditPanelBasePropType = ViewPropsTypeExtract<typeof KObjectPropertyEditPanelBasePropOptions>

/** 事件 */
export const KObjectPropertyEditPanelBaseEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 工具栏点击事件
   * @param event
   * @returns
   */
  toolStripItemClicked: (event: ToolStripItemClickedEventArgs) => true,
  /**
   * 工具条下拉控件选择项改变事件
   * @param event
   * @returns
   */
  toolStripItemChange: (event: ToolStripItemChangeEventArgs) => true,
  /**加载完成事件 */
  loaded: () => true,
  /**取消事件 */
  toolStripItemCancel: () => true
}

/** 事件类型 **/
export type KObjectPropertyEditPanelBaseEmitsType = ViewEmitsTypeExtract<typeof KObjectPropertyEditPanelBaseEventEmits>
