
import {
  EnumAttributeType,
  EnumLabelPosition,
  EnumSizeType,
  EnumType,
  IKTreeNode,
  KBaseElement,
  KContainer,
  KControl,
  KLayoutConfig,
  KRootLayout,
  defineView
} from '@kmsoft/upf-core'
import _ from 'lodash'
import LayoutDesignerViewModel from './KLayoutDesignerViewModel'
import { KLayoutDesignerPropOptions } from './interface'
import { KViewConfig, KPreview, KDesignerToolbox } from './controls'
import { KDesignerControl, PageDesignerDataSourceProperty } from '../types'
import { generateControlName, BASE_CONTROL_ATTRIBUTE } from '../config'

export default defineView({
  name: 'KLayoutDesigner',
  props: KLayoutDesignerPropOptions,
  viewModel: LayoutDesignerViewModel,
  setup(props, { attrs, emit, expose, slots, vm }) {
    /**
     * 将元素转为视图区所需格式
     * 工具箱元素被拖出时触发
     * @param dragElement 工具箱中拖出的元素
     */
    const __convertElement2Preview = (dragElement: KDesignerControl | PageDesignerDataSourceProperty) => {
      if (dragElement) {
        return __convert2ElementFormat(dragElement)
      }

      return {
        control: (dragElement as KDesignerControl).control,
        title: (dragElement as KDesignerControl).title,
        name: `input${_.uniqueId()}`,
        styles: {},
        props: {},
        layout: {}
      }
    }

    /**
     * 将工具箱元素转为视图区所需的元素
     * @param dragElement 拖动的工具箱元素
     */
    const __convert2ElementFormat = (
      dragElement: KDesignerControl | PageDesignerDataSourceProperty
    ): KBaseElement | undefined => {
      const props: Record<string, any> = {}
      const layout: Record<string, any> = {}
      const base: Record<string, any> = {}

      // 1.获取控件信息
      const originDataSource = dragElement.attrs.find(y => y.name === 'dataSource')?.defaultValue
      const originFiled = dragElement.attrs.find(y => y.name === 'field')?.defaultValue

      // 2.获取控件的组件名称
      const controlName = getControlName(dragElement)
      const elementName = __getControlKey(dragElement, 'name')
      const title = __getControlKey(dragElement, 'title')

      if (!controlName) {
        return
      }

      const controlItem = vm.getLocalControlInfo(originDataSource, originFiled, controlName)

      if (!controlItem) {
        return
      }

      const controlAttrs = _.cloneDeep(controlItem)
      const baseConfig = controlAttrs.attrs.filter(x => x.attributeType === EnumAttributeType.BASE)
      const layoutConfig = controlAttrs.attrs.filter(x => x.attributeType === EnumAttributeType.LAYOUT)
      const propsConfig = controlAttrs.attrs.filter(x => x.attributeType === EnumAttributeType.PROPS)

      /** 处理基础属性 */
      for (const iterator of BASE_CONTROL_ATTRIBUTE) {
        base[iterator.name] = iterator.defaultValue
      }

      baseConfig.map((item: any) => {
        base[item.name] = item.defaultValue
      })

      layoutConfig.map((item: any) => {
        layout[item.name] = item.defaultValue
      })

      propsConfig.map((item: any) => {
        props[item.name] = item.defaultValue
      })

      // 如果是容器
      if (dragElement.type === EnumType.CONTAINER) {
        const result: KContainer = {
          ...base,
          title: title,
          name: generateControlName(elementName, true),
          control: dragElement.control!,
          type: EnumType.CONTAINER,
          props: props,
          controls: [],
          layout: layout as KLayoutConfig,
          events: []
        }
        return _.cloneDeep(result)
      } else {
        const result: KControl = {
          ...base,
          title: title,
          name: generateControlName(elementName),
          control: controlName,
          type: EnumType.CONTROL,
          events: [],
          props: props,
          layout: layout as KLayoutConfig,
          dataType: dragElement.dataType
        }
        return (_.cloneDeep(result) as unknown) as KControl
      }
    }

    /**获取控件的组件名称
     * @param  element 控件
     */
    const getControlName = (element: KDesignerControl | PageDesignerDataSourceProperty) => {
      const controlAttrs = element.attrs.find(x => x.name === 'control')

      if (controlAttrs && controlAttrs.defaultValue !== '') {
        return controlAttrs.defaultValue
      }

      return element.control
    }

    /**获取控件的标识
     * @param  element 控件
     */
    const __getControlKey = (element: KDesignerControl | PageDesignerDataSourceProperty, key: string) => {
      const controlAttrs = element.attrs.find(x => x.name === key)
      if (controlAttrs && controlAttrs.defaultValue !== '') {
        return controlAttrs.defaultValue
      }
      return element[key as keyof KDesignerControl]
    }

    return () => {
      if (!vm.localSchema.value) {
        return <k-loading desc="正在加载，请耐心等待..." />
      }

      /** 根布局 */
      const rootSchema: KRootLayout = {
        name: 'ROOT',
        title: '根节点',
        type: EnumType.ROOT,
        disabled: false,
        visible: true,
        readonly: false,
        nullable: true,
        props: {
          /** 最小列宽度 */
          minColWidth: 260,
          /** 行间距 */
          rowGap: 0,
          /** 列间距 */
          colGap: 0,
          /** 列数 */
          colCount: 3,
          labelWidth: 50,
          labelPosition: EnumLabelPosition.LEFT
        },
        events: [],
        /**控件 */
        control: 'KRootLayout',
        controls: [vm.localSchema.value]
      }

      const renderConfig = () => {
        if (!vm.selectedElement.value) {
          return
        }

        if (!vm.selectedControl.value) {
          return
        }

        return (
          <KViewConfig
            v-if={vm.selectedElement.value}
            ref={vm.refDesignerConfig}
            element={vm.selectedElement.value}
            control={vm.selectedControl.value}
            designerMetaControl={vm.designerMetaControl.value}
            dataSource={props.dataSource}
            bizContext={props.bizContext}
            belongClsCode={props.belongClsCode}
            onElementChange={vm.elementPropertyChange}
          />
        )
      }

      return (
        <k-split-container class="k-layout-designer default-theme" sizeUnit={EnumSizeType.Pixel}>
          <k-split-pane size={250}>
            <KDesignerToolbox
              convertElement={__convertElement2Preview}
              schema={vm.localSchema.value}
              selectElementCode={vm.selectedElement.value?.name}
              designerMetaControl={vm.designerMetaControl.value}
              onHierarchyTreeNodeSelected={(node: IKTreeNode) => vm.hierarchyTreeNodeSelected(node)}
              dataSource={props.dataSource}
            />
          </k-split-pane>
          <k-split-pane class="k-layout-designer-page-container">
            <div ref={vm.refPreviewContainer} class="k-layout-designer-page-view">
              <div class="k-layout-designer-page">
                <KPreview
                  schema={rootSchema}
                  rootSchema={rootSchema}
                  selectedElement={vm.selectedElement.value}
                  designerMetaControl={vm.designerMetaControl.value}
                  onElementSelected={vm.previewElementSelected}
                />
              </div>
            </div>
          </k-split-pane>
          <k-split-pane class="propsConfig" size={300}>
            {renderConfig()}
          </k-split-pane>
        </k-split-container>
      )
    }
  }
})
