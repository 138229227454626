import { Api, EnumRequestCode, IClsOption, KObjectDynamicCreatePanel, ObjBusinessParam } from '@kmsoft/ebf-common'
import { EnumDialogResult, KDataGridRowData, KDialog, KDialogClosingEvent, KNotification } from '@kmsoft/upf-core'
import { DocEchoGridDataInner } from '../../../ebf-doc/src/controls/doc-echo-list-panel/interface'
import { KDocEchoListPanelViewModel } from '../../../ebf-doc/src/controls/doc-echo-list-panel'
import { KDocEchoListPanel } from '../../../ebf-doc/src/controls/doc-echo-list-panel'
import { EnumOperateType } from '../../../ebf-doc/src/controls/doc-echo-list-panel/interface'
import { EnumOperateOptions } from '../../../ebf-doc/src/controls/doc-echo-list-panel/interface'

export class RequirementChangeClientSrv {
  public static async create(row: KDataGridRowData, folderId: string) {
    return new Promise((resolve, reject) => {
      //校验当前数据是否是最新发布的async
      Api.post('requirement', 'Requirement', 'checkCanChange', { data: [row.targetId, row.targetExtensionType] }).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          if (!res.data.operatable) {
            KNotification.warn({
              message: '系统提示',
              description: '当前需求不可变更'
            })
            return
          }
        } else {
          KNotification.error({
            title: '校验异常',
            content: res.message || '',
            details: res.detail
          })
          return
        }

        const clsOptions = [] as Array<IClsOption>
        KDialog.show({
          title: '创建对象',
          content: KObjectDynamicCreatePanel,
          props: {
            clsOptions: clsOptions,
            folderId: folderId,
            showObjClsCodes: ['RequirementChangeRequest'],
            defaultSelectClsCode: 'RequirementChangeRequest',
            showObjClsSelector: false
          },
          size: { width: 900, height: document.documentElement.clientHeight - 300 },
          onClosing: async (event: KDialogClosingEvent) => {
            const formViewModel = event.viewInstance as any
            if (event.dialogResult == EnumDialogResult.Cancel) return
            if (event.dialogResult == EnumDialogResult.Close) return
            const validateResult = await formViewModel.validate()
            if (!validateResult) {
              event.cancel = true
              return
            }
            const result = await formViewModel.save(row, folderId)
            if (result && result.code == EnumRequestCode.SUCCESS) {
              KNotification.success('对象创建成功')
              resolve(result)
            } else {
              if (result) {
                KNotification.error({
                  title: '操作失败',
                  content: result.message || '创建对象失败',
                  details: result.detail
                })
              }
              event.cancel = true
              reject(void 0)
            }
          }
        })
      })
    })
  }

  public static async subscrebeRequirement(originalNumber: any, rdmExtensionType: any) {
    return new Promise((resolve, reject) => {
      const param = {
        objId: originalNumber,
        objClass: rdmExtensionType
      }
      Api.post('notify', 'DmeMessageSubscribeService', 'subscribe', { data: [[param]] }).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          KNotification.success('需求订阅成功')
          resolve(res)
        } else {
          KNotification.error({
            title: '需求订阅异常',
            content: res.message || '',
            details: res.detail
          })
          resolve(res)
        }
      })
    })
  }

  public static async unsubscrebeRequirement(originalNumber: any, rdmExtensionType: any) {
    return new Promise((resolve, reject) => {
      const param = {
        objId: originalNumber,
        objClass: rdmExtensionType
      }
      Api.post('notify', 'DmeMessageSubscribeService', 'unsubscribe', { data: [[param]] }).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '取消订阅成功'
          })
          resolve(res)
        } else {
          KNotification.error({
            title: '系统提示',
            content: res.message!
          })
          resolve(res)
        }
      })
    })
  }

  /**
   * 另存零部件
   */
  public static async saveAsPart(row: any): Promise<any> {
    const showDialog = KDialog.info({
      title: '提示',
      content: '正在获取数据，请稍后...',
      showOk: false
    })
    const result = (await Api.post('requirement', row.targetExtensionType, 'get', { data: [row.targetId] })) as any
    if (!result || result.code != EnumRequestCode.SUCCESS || !result.data) {
      showDialog.destroy()
      KNotification.warn({
        message: '系统提示',
        description: result.message || '获取需求数据错误'
      })
      return undefined
    }
    // 获取路径
    const folder = {
      id: row.targetFolderId
    } as any
    const folderPathResult = await Api.post('folder', 'Folder', 'getFolderPath', { data: [folder.id] })
    if (folderPathResult && folderPathResult.code == EnumRequestCode.SUCCESS) {
      folder.fullPath = folderPathResult.data
    }
    const data = [result.data]
    const docEchoList = data.map((item: any, index: number) => {
      return {
        ...item,
        index: index + 1,
        type: item.rdmExtensionType,
        folder: folder,
        operate: 'Saveas'
      }
    })
    showDialog.destroy()
    return new Promise((resolve, reject) => {
      KDialog.show({
        title: '另存',
        size: { width: 900, height: 600 },
        props: {
          dataSource: docEchoList,
          operateType: EnumOperateType.SaveAs,
          operateOptions: EnumOperateOptions.SAVEAS
        },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KDocEchoListPanel,
        onClosing: async (event: KDialogClosingEvent) => {
          const docEchoListPanelViewModel = event.viewModel as KDocEchoListPanelViewModel
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
          if (!docEchoListPanelViewModel.validate()) {
            KNotification.warn({
              message: '系统提示',
              description: '请补全数据'
            })
            event.cancel = true
            return
          }
          const operateList = docEchoListPanelViewModel.getOperateData() as DocEchoGridDataInner
          if (operateList.length == 0) {
            KNotification.warn({
              message: '系统提示',
              description: '请至少选择一条数据'
            })
            event.cancel = true
            return
          }
          const list = operateList.map((item: any) => {
            return {
              id: item.id,
              rdmExtensionType: item.rdmExtensionType,
              number: item.number,
              name: item.name,
              folder: item.folder
            }
          })
          const result = await Api.post('requirement', operateList[0].rdmExtensionType, 'saveAs', {
            data: list
          })
          if (result && result.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '另存成功'
            })
            resolve(result)
          } else {
            KNotification.error({
              title: '操作失败',
              content: result.message || '另存失败',
              details: result.detail
            })
            event.cancel = true
            reject(void 0)
          }
        }
      })
    })
  }
}
