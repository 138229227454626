import {
  BaseViewModel,
  ViewModelOptions,
  EnumToolStripCompType,
  KDataGridViewModel,
  IKTreeNode,
  EnumDialogResult,
  KNotification,
  KDialog,
  KDialogClosingEvent
} from '@kmsoft/upf-core'
import { Api, EnumToolStripItemKeys, KObjectToolStripViewModel, EnumRequestCode, ObjectClientSrv } from '@kmsoft/ebf-common'
import { KBaselineCompareEmitsType, KBaselineComparePropType } from './interface'
import { KAddPlanDialog } from '../../controls'
import { ref } from 'vue'
import KBaselineForm, { KBaselineFormViewModel } from './baseline-form'
import { KBaselineContent } from './baseline-content'
import { KCompareResult } from './compare-result'

/** KBaselineCompare */
export default class KBaselineCompareViewModel extends BaseViewModel<KBaselineCompareEmitsType, KBaselineComparePropType> {
  refToolStrip = ref<KObjectToolStripViewModel>()
  refBaselineGrid = ref<KDataGridViewModel>()
  constructor(options: ViewModelOptions<KBaselineComparePropType>) {
    super(options)
  }

  /** 工具栏按钮 */
  toolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REQUIREMENT_CREATE_BASELINE,
      title: '创建基线',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REQUIREMENT_EDIT_BASELINE,
      title: '编辑',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REQUIREMENT_DELETE_BASELINE,
      title: '删除基线',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REQUIREMENT_BASELINE_COMPARE,
      title: '基线对比',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    }
  ]

  viewDidMount() {
    this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_REQUIREMENT_BASELINE_COMPARE, true)
    this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_REQUIREMENT_EDIT_BASELINE, true)
  }

  onToolStripItemClicked(event: any) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REQUIREMENT_CREATE_BASELINE:
        this.createBaseline()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REQUIREMENT_EDIT_BASELINE:
        this.updateBaseline()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REQUIREMENT_BASELINE_COMPARE:
        this.compareBaseline()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REQUIREMENT_DELETE_BASELINE:
        this.deleteBaseline()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refBaselineGrid.value?.refresh()
        break
      default:
        break
    }
  }

  rowSelected() {
    const data = this.refBaselineGrid.value?.getSelectedRows()
    if (data?.length == 2) {
      this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_REQUIREMENT_BASELINE_COMPARE, false)
      this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_REQUIREMENT_EDIT_BASELINE, true)
    } else if (data?.length == 1) {
      this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_REQUIREMENT_EDIT_BASELINE, false)
    } else {
      this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_REQUIREMENT_BASELINE_COMPARE, true)
      this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_REQUIREMENT_EDIT_BASELINE, true)
    }
  }

  async loadData() {
    const result = (await Api.post('requirement', 'Requirement', 'listBaseline', {
      data: [
        {
          id: this.props.objParam.id,
          clazz: this.props.objParam.modelCode
        }
      ]
    })) as any

    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    }
  }

  async compareBaseline() {
    const selectRows = this.refBaselineGrid.value?.getSelectedRows()
    KDialog.show({
      title: '基线对比结果',
      size: { width: 1100, height: 860 },
      props: {
        selectRows: selectRows
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      showCancel: false,
      content: KCompareResult,
      onClosing: async (event: KDialogClosingEvent) => {
        return
      }
    })
  }

  async createBaseline() {
    KDialog.show({
      title: '创建基线',
      size: { width: 450, height: 180 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KBaselineForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KBaselineFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
        //获取表单的数据
        const formData = await formViewModel.getFormData()
        if (!formData) {
          event.cancel = true
          return
        }
        if ([';', '-'].includes(formData.baselineName)) {
          KNotification.warn({
            message: '系统提示',
            description: '不合法的基线名称'
          })
        }
        const result = (await Api.post('requirement', 'Requirement', 'createBaseline', {
          data: [
            {
              needsView: {
                id: this.props.objParam.id,
                rdmExtensionType: this.props.objParam.modelCode
              },
              newBaselineName: formData.baselineName
            }
          ]
        })) as any

        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '基线创建成功'
          })
          this.refBaselineGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '系统提示',
            content: result.message!
          })
        }
      }
    })
  }

  async updateBaseline() {
    const rows = this.refBaselineGrid.value?.getSelectedRows()
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    KDialog.show({
      title: '编辑基线',
      size: { width: 450, height: 180 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KBaselineForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KBaselineFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
        //获取表单的数据
        const formData = await formViewModel.getFormData()
        if (!formData) {
          event.cancel = true
          return
        }
        if ([';', '@'].includes(formData.baselineName)) {
          KNotification.warn({
            message: '系统提示',
            description: '不合法的基线名称'
          })
        }

        const result = (await Api.post('requirement', 'Requirement', 'updateBaseline', {
          data: [
            {
              needsView: {
                id: rows[0].needsView.id,
                rdmExtensionType: rows[0].needsView.rdmExtensionType
              },
              baselineName: rows[0].baselineName,
              createTime: rows[0].createTime,
              baselineType: rows[0].baselineType,
              newBaselineName: formData.baselineName
            }
          ]
        })) as any

        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '基线更新成功'
          })
          this.refBaselineGrid.value?.clearSelectedRows()
          this.refBaselineGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '系统提示',
            content: result.message!
          })
        }
      }
    })
  }

  async deleteBaseline() {
    const rows = this.refBaselineGrid.value?.getSelectedRows()
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }
    const param = rows.map(row => {
      return {
        needsView: {
          id: row.needsView.id,
          rdmExtensionType: row.needsView.rdmExtensionType
        },
        baselineName: row.baselineName,
        createTime: row.createTime,
        baselineType: row.baselineType
      }
    })
    const result = (await Api.post('requirement', 'Requirement', 'deleteBaseline', {
      data: [param]
    })) as any

    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success({
        title: '系统提示',
        content: '基线删除成功'
      })
      this.refBaselineGrid.value?.clearSelectedRows()
      this.refBaselineGrid.value?.refresh()
    } else {
      KNotification.error({
        title: '系统提示',
        content: result.message!
      })
    }
  }

  /**
   * 构建树
   */
  buildTree(array: Array<IKTreeNode>, parentId: string): Array<IKTreeNode> {
    const result = [] as Array<IKTreeNode>
    array.forEach(item => {
      if (item.parentId == parentId) {
        item.children = this.buildTree(array, item.id)
        result.push(item)
      }
    })
    return result
  }

  /**
   * 打开新标签页展示对象
   */
  async openObj(row: any) {
    const objParam = {
      needsView: {
        id: row.needsView.id,
        rdmExtensionType: row.needsView.rdmExtensionType
      },
      baselineName: row.baselineName,
      createTime: row.createTime,
      baselineType: row.baselineType
    }
    KDialog.show({
      title: '基线内容',
      size: { width: 850, height: 650 },
      props: {
        objParam
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      showCancel: false,
      content: KBaselineContent,
      onClosing: async (event: KDialogClosingEvent) => {
        return
      }
    })
  }
}
