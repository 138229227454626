
import { defineView, EnumDataGridAutoSizeColumnsMode, EnumDataGridRowModelType } from '@kmsoft/upf-core'
import { KLogManagePropOptions, KLogManageEventEmits } from './interface'
import KLogManageViewModel from './KLogManageViewModel'

export default defineView({
  name: 'KLogManage',
  props: KLogManagePropOptions,
  emits: KLogManageEventEmits,
  viewModel: KLogManageViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      autoSizeColumnsMode: EnumDataGridAutoSizeColumnsMode.FILL
    }
  }
})
