import { KContainer, KControl, KNotification, KSchema, SimpleViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KDynamicViewViewModel } from '@kmsoft/upf-view-engine'
import lodash, { isArray, isFunction } from 'lodash'
import { nextTick, ref, watch } from 'vue'
import {
  ClassMetaClientSrv,
  EnumLayoutSchemaType,
  LayoutClientSrv,
  MetaProperty,
  ObjBusinessBase,
  ObjBusinessResult
} from '../../client-srv'
import { EditorModifiedValue, EditorSetPropertyValueParams, EnumEditorEnvironment, IKEditorAPI, processSchema } from '../editors'
import { categoryAttributesSchema } from './categoryAttributesSchema'
import { extendedAttributesSchema, PropertyType } from './extendedAttributesSchema'
import { KObjectPropertyPanelEventEmitsType, KObjectPropertyPanelPropType, ObjectLoadDataProviderParams } from './interface'

/** 属性面板 */
export default class KObjectPropertyPanelViewModel<
  E extends KObjectPropertyPanelEventEmitsType = KObjectPropertyPanelEventEmitsType,
  P extends KObjectPropertyPanelPropType = KObjectPropertyPanelPropType
> extends SimpleViewModel<E, P> {
  /** 渲染器 */
  refDynamicView = ref<KDynamicViewViewModel>()
  /** 是否在加载 */
  isloading = ref<boolean>(true)
  /** 原始值 */
  orgFormValue: ObjBusinessResult | undefined = undefined
  /** 原始布局 */
  orgSchema: KSchema
  /** 布局 */
  schema = ref<KSchema>()
  /** 扩展属性类型映射 **/
  extendedAttributesTypeMapping = ref<Record<string, string>>()
  /**
   * 对象类定义
   * @description 目前只考虑单个对象类
   */
  metaProperty: Array<MetaProperty>
  /**
   * 总更改的值
   * @description 不在面板中渲染的值，修改记录会存在此处
   */
  changedValue: Record<string, any> = {}
  /** 记录未设置成功的属性 */
  unSetProperty = ref<Record<string, any>>({})

  /**分类属性字段 */
  categoryAttrField: string

  modelCode = ref<string>(this.props.modelCode)

  constructor(options: ViewModelOptions<P>) {
    super(options)
    // 监听对象类改变事件

    watch(
      () => options.props.modelCode,
      newValue => {
        if (newValue) {
          this.modelCode.value = newValue
          this.categoryAttrField = 'Classification'
          this.init()
        }
      },
      {
        immediate: true
      }
    )
  }

  //#region 初始化
  /** 初始化 */
  private async init() {
    // 标记为加载
    this.isloading.value = true
    // 初始化布局
    await this.initSchame()
    // //添加扩展属性
    // await this.addExtendedAttributes()
    // 初始化插件
    this.initPlugin()
    // 初始化表单数据
    await this.initFormData()
    // 标记为加载完成
    this.isloading.value = false
    // 加载成功
    nextTick(() => this.emit('loaded'))
  }

  /** 初始化布局 */
  private async initSchame() {
    /** 布局 */
    const scheme = await this.getSchemaById(this.props.modelCode)

    if (Object.keys(scheme).length > 0) {
      // 原始布局
      this.orgSchema = lodash.cloneDeep(scheme)

      // 处理 schema
      processSchema(scheme.controls, {
        api: this.getEditorApi,
        environment: EnumEditorEnvironment.PROPERTY_PANEL
      })
      this.schema.value = scheme
    } else {
      this.schema.value = undefined
    }
  }

  public getSchame() {
    return this.schema.value
  }

  /**
   * 初始化插件
   */
  private initPlugin() {
    // 自动计算
  }

  /**
   * 初始化表单数据
   */
  private async initFormData() {
    // 设置表单值为空
    this.orgFormValue = undefined
    // 清空修改记录
    this.changedValue = {}
    /** 获取表单数据 */
    const formData = await this.getObjectBusiness()

    this.orgFormValue = lodash.cloneDeep(formData)
    this.setValue(formData)
  }
  //#endregion

  /**
   * 获取列定义
   * @param name 属性
   * @returns 属性定义
   */
  public getMetaProperty(propId: string): MetaProperty | undefined {
    return this.metaProperty.find(a => a.id == propId)
  }

  /**
   * 获取表单属性
   * @param propId 属性
   * @returns 属性定义
   */
  public getTableProperty(propId: string) {
    // return this.metaClass.attachedTables.find(a => a.propId == propId)
  }

  /** 重置表单 */
  public restore() {
    if (!this.orgFormValue) {
      return
    }
    this.refDynamicView.value?.setValue(this.orgFormValue)
  }

  /**
   * 设置属性禁用
   * @param name 属性名称
   * @param disabled 是否禁用
   */
  public setPropertyDisabled(name: string, disabled: boolean) {
    throw new Error('')
  }

  /** 验证表单 */
  public validateFormValue() {
    if (!this.refDynamicView.value) {
      return false
    }
    /** 修改值 */
    const value = this.getValue() as ObjBusinessBase
    /** 修改的值 */
    const modifiedValue = this.getModifiedValue()
    /** 不符合要求的字段 */
    const nonComplianceProperties: Array<string> = []
    value.extAttrs?.forEach((item: any) => {
      value[`extendedAttributes#${item.name}`] = item.value || ''
    })
    //#region 属性校验

    //1.递归获取属性校验
    for (const key of Object.keys(value)) {
      const property = (this.getProperty(this.schema.value!, key) as unknown) as KControl

      if (property && (property.nullable || (property.nullable as any) == 1)) {
        continue
      }

      if (property && property.field! in value) {
        const field = property.field!
        //1.若存在值为对象的情况,则校验id
        //2.校验数组
        //3.校验字符串
        const type = typeof value[field] == 'object'
        if (type && 'id' in value[field] && value[field]['id'] === '') {
          nonComplianceProperties.push(property.title)
        } else if (isArray(value[field]) && value[field].length == 0) {
          nonComplianceProperties.push(property.title)
        } else if (value[field] === '' || value[field] == undefined) {
          nonComplianceProperties.push(property.title)
        }
      }
    }

    // 如果有不符合要求的字段
    if (nonComplianceProperties.length > 0) {
      KNotification.warning({
        title: '属性空',
        content: `以下属性不能为空:\r\n${nonComplianceProperties.join(',')}`
      })
      return false
    }
    return true
  }

  /**
   * 获取修改的值
   * @returns
   */
  public getModifiedValue() {
    if (!this.refDynamicView.value) {
      return {}
    }
    /** 获取修改记录 */
    const modifyValue = this.refDynamicView.value?.getModifiedValue()

    const result = this.convertToObject({ ...this.changedValue, ...modifyValue })
    //扩展属性获取值转换
    this.extendedAttributesGetConvert(result)
    //分类属性获取值转换
    this.categoryAttributesGetConvert(result)
    // 合并修改记录
    return result
  }

  /**
   * 扩展属性获取值转换
   * @param data
   */
  public extendedAttributesGetConvert(data: Record<string, any>) {
    if (Reflect.has(data, 'extendedAttributes')) {
      // 如果面板有分类组件（KCategorySelector）,那么data.extAttrs一定为数组，扩展属性需要加在extAttrs上
      if (Reflect.has(data, 'extAttrs') && Array.isArray(data.extAttrs)) {
        const extAttrs = Object.keys(data.extendedAttributes).map(key => {
          return {
            name: key,
            type: this.extendedAttributesTypeMapping.value?.[key] || key,
            value: data.extendedAttributes[key] == undefined ? null : String(data.extendedAttributes[key])
          }
        })

        data.extAttrs.push(...extAttrs)
      } else {
        //如果没有分类组件（KCategorySelector），只有扩展属性,那么直接添加,有extAttrs属性，且不为数组，暂不考虑
        const extAttrs = Object.keys(data.extendedAttributes).map(key => {
          return {
            name: key,
            type: this.extendedAttributesTypeMapping.value?.[key] || key,
            value: data.extendedAttributes[key] == undefined ? null : String(data.extendedAttributes[key])
          }
        })
        data.extAttrs = extAttrs
      }
    }

    Reflect.deleteProperty(data, 'extendedAttributes')
  }

  /**
   * 分类属性获取值转换
   * @param data
   */
  public categoryAttributesGetConvert(data: Record<string, any>) {
    if (Reflect.has(data, 'categoryAttributes')) {
      // 分类属性要拿到所有的值，即使只改了一个属性，也要拿到面板中所有的属性值
      const allData = this.refDynamicView.value?.getValue() as ObjBusinessResult
      const categoryAttributes = allData.categoryAttributes
      // const value = {} as Record<string, any>
      // Object.keys(categoryAttributes).forEach(item => {
      //   value[item] = categoryAttributes[item] || ''
      // })
      for (const key in categoryAttributes) {
        categoryAttributes[key] = categoryAttributes[key] || null
      }
      // 如果data中本身有clsAttrs值，会被分类属性中的值覆盖掉
      // 零部件的分类属性字段特殊处理

      data.clsAttrs = [{ [this.categoryAttrField]: { ...categoryAttributes } }]
      Reflect.deleteProperty(data, 'categoryAttributes')
    }
  }

  /** 刷新 */
  public async refresh() {
    // 标记为加载
    // this.isloading.value = true
    // 初始化表单数据
    await this.initFormData()
    // 标记为加载完成
    // this.isloading.value = false
  }

  /**
   * 获取属性值
   * @param name
   * @returns
   */
  public getPropertyValue(name: string): any | undefined {
    // 优先从修改值取
    if (this.changedValue[name]) {
      return this.changedValue[name]
    }

    /** 从控件中取 */
    const controlValue = this.refDynamicView.value?.getControlValue(name)
    if (controlValue) {
      return controlValue
    }

    if (this.stateValue.value) {
      return this.getValueByPath(this.stateValue.value, name)
    }
  }

  /**
   * 设置组件值
   * @param name 字段名称
   * @param value 值
   * @param setChanged 标记为修改的
   * @returns
   */
  public setPropertyValue(name: string, value: any, setChanged?: boolean) {
    // 如果标记为修改
    if (setChanged) {
      // 增加修改记录
      this.changedValue[name] = value
    }
    /** 避免已修改的面板值被旧值覆盖*/
    const values = this.refDynamicView.value?.getValue() as ObjBusinessResult
    this.stateValue.value = { ...this.stateValue.value, ...values }
    /** 是否修改成功 */
    const changedSuccess = this.refDynamicView.value?.setControlValue(name, value)

    /** 若未修改成功则直接更新Form值 */
    this.stateValue.value[name] = value
  }

  /**
   * 设置值
   * @param value 业务对象数据
   * @param setChanged 设置是否更改
   */
  public setValue(value: ObjBusinessResult | undefined) {
    // 扩展属性赋值转换
    this.extendAttributesSetConvert(value)
    // 分类属性赋值转换
    this.categoryAttributesSetConvert(value)

    super.setValue(value, true)
  }

  /**
   * 获取值
   * @returns
   */
  public getValue(): ObjBusinessResult {
    // 如果还未初始化完成
    if (!this.refDynamicView.value) {
      return this.stateValue.value
    }

    /** 面板值 */
    const value = this.refDynamicView.value?.getValue() as ObjBusinessResult
    //扩展属性获取值转换
    this.extendedAttributesGetConvert(value)
    //分类属性获取值转换
    this.categoryAttributesGetConvert(value)
    // 将修改值和面板值拼接面板值
    return { ...this.stateValue.value, ...this.changedValue, ...value }
  }

  /**
   * 获取编辑器 API
   * @param control 组件配置
   * @returns
   */
  public getEditorApi = (control: KControl): IKEditorAPI => {
    /**
     * 获取值
     * @param key 字段名称
     * @returns
     */
    const getPropertyValue = (key: string) => {
      /** 新key */
      const newKey = getControlKey(control, key)

      return this.getPropertyValue(newKey)
    }

    /**
     * 设置值
     * @param key 字段名称
     * @param value 值
     * @param params 参数
     * @returns
     */
    const setPropertyValue = (key: string, value: string, params?: EditorSetPropertyValueParams) => {
      /** 新key */
      const newKey = getControlKey(control, key)

      return this.setPropertyValue(newKey, value, params?.setChanged)
    }

    /**
     * 获取数据
     * @returns
     */
    const getValue = () => {
      return this.getValue()
    }

    /** 获取编辑记录 */
    const getModifiedValue = async (): Promise<EditorModifiedValue> => {
      // const value = await this.getModifiedValue()
      // return {
      //   properties: value.properties,
      //   tableProperties: value.tableProperties,
      //   valueChanged: value.valueChanged
      // }
      return { properties: {}, tableProperties: [], valueChanged: false }
    }

    /**
     * 获取组件key
     * @private
     * @param control
     * @param key
     * @returns
     */
    const getControlKey = (control: KControl, key: string) => {
      /** 属性 Key */
      const keyArray: Array<string> = []

      // 如果有数据源 如 properties
      if (control.dataSource) {
        keyArray.push(control.dataSource)
      }

      // 添加 key名称 如 name
      keyArray.push(key)

      /** 组装key properties#name */
      const newKey = keyArray.join('#')

      return newKey
    }

    const getCurrentPanelViewModel = () => {
      return this
    }
    const getCurrentPanelProps = () => {
      return this.props
    }

    return { getPropertyValue, setPropertyValue, getValue, getCurrentPanelViewModel, getCurrentPanelProps }
  }

  /** 获取是否在编辑 */
  public getIsLoading() {
    return this.isloading.value
  }

  /**
   * 通过 对象类Code 获取布局定义
   * @param modelCode
   * @returns
   */
  private async getSchemaById(modelCode: string): Promise<KSchema> {
    //1.优先获取自身布局、若不存在则获取当前对象设计态的布局
    const isSchemaType = [EnumLayoutSchemaType.FormNew, EnumLayoutSchemaType.FormEdit].includes(this.props.schemaType)
    //文档中子节点属于其他系统，暂时给子节点统一使用文档布局
    // const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(modelCode)
    // modelCode = designerCode == 'Document' ? designerCode : modelCode
    if (isSchemaType) {
      const schema = await LayoutClientSrv.getSchemaByType(modelCode, this.props.schemaType)
      // if (Object.keys(schema).length == 0) {
      //   // const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(modelCode)
      //   return await LayoutClientSrv.getSchemaByType(modelCode!, this.props.schemaType)
      // }
      return schema
    } else {
      const schema = await LayoutClientSrv.getSchemaByCode(modelCode, this.props.schemaType)
      // if (Object.keys(schema).length == 0) {
      //   // const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(modelCode)
      //   return await LayoutClientSrv.getSchemaByCode(modelCode!, this.props.schemaType)
      // }
      return schema
    }
  }

  /**
   * 获取业务对象
   * @param objParam 对象参数
   * @returns
   */
  public async getObjectBusiness(): Promise<ObjBusinessResult | undefined> {
    /** 对象面加载参数 */
    const params: ObjectLoadDataProviderParams = {}
    /** 加载结果 */
    let result = {} as ObjBusinessResult
    if (isFunction(this.props.loadData)) {
      result = (await this.props.loadData(params)) as ObjBusinessResult
    }

    /**保存业务对象值 */
    this.stateValue.value = result

    return result
  }

  /**
   * 扩展属性赋值转换
   * @param data
   */
  extendAttributesSetConvert(data: ObjBusinessResult | undefined) {
    if (data != null && Reflect.has(data, 'extAttrs') && Array.isArray(data.extAttrs)) {
      // 先过滤掉分类属性，其它都为扩展属性的值
      data.extAttrs.forEach(item => {
        if (item.type == 'CATEGORY') {
          data[`extendedAttributes#${item.name}`] = data.extAttrs
        } else {
          data[`extendedAttributes#${item.name}`] = item.value
        }
      }, {})
    } else {
      //后台返回的extAttrs一定是个数组，其它类型暂不处理
    }
  }

  /**
   * 分类属性赋值转换
   * @param data
   */
  categoryAttributesSetConvert(data: ObjBusinessResult | undefined) {
    if (data != null && Reflect.has(data, 'clsAttrs') && Array.isArray(data.clsAttrs) && data.clsAttrs.length > 0) {
      const classification = data.clsAttrs[0][this.categoryAttrField] || {}
      Object.keys(classification).forEach((key: string) => {
        data[`categoryAttributes#${key}`] = classification[key]
      })
    } else {
      //后台返回的clsAttrs分类属性一定是个数组，其它类型暂不处理
    }
  }

  //#endregion

  //#region 私有

  /**
   * 通过路径获取对象值
   * @param obj
   * @param path
   * @returns
   */
  private getValueByPath(obj: Object, path: string) {
    /** 拆分路径 */
    const keys = path.split('#')

    let value = obj

    // 通过路径循环
    for (const key of keys) {
      if (Reflect.has(value, key)) {
        value = Reflect.get(value, key)
      } else {
        return undefined // 如果路径不存在，返回 undefined
      }
    }

    return value
  }

  /**
   * 将拉平的对象转换为对象
   * @param inputObj
   * @returns
   */
  private convertToObject(inputObj: Record<string, any>): Record<string, any> {
    const result: Record<string, any> = {}

    for (const key in inputObj) {
      const keys: string[] = key.split('#')
      let currentLevel: Record<string, any> = result

      keys.forEach((nestedKey, index) => {
        if (index === keys.length - 1) {
          currentLevel[nestedKey] = inputObj[key]
        } else {
          if (!currentLevel[nestedKey]) {
            currentLevel[nestedKey] = {}
          }
          currentLevel = currentLevel[nestedKey]
        }
      })
    }

    return result
  }
  /**获取schema 中的属性信息 */
  private getProperty(schema: KSchema | KContainer, key: string): KContainer | KControl | undefined {
    const newSchema = isArray(schema) ? schema : [schema]
    for (const control of newSchema) {
      if (((control as unknown) as KControl).field === key) {
        return (control as unknown) as KControl
      }
      if (control.controls && control.controls.length > 0) {
        const result = this.getProperty((control.controls as unknown) as KContainer, key)
        if (result) {
          return result
        }
      }
    }
    return undefined
  }
  //#endregion

  //#region 设计器
  /** 打开设计面板 */
  openDesignLayout() {}

  /** 保存设计 */
  saveLocalLayout() {}

  /** 保存全局布局 */
  saveOverallLayout() {}

  /** 清除本地布局 */
  cleanLocalLayout() {}

  /** 清除全局布局 */
  cleanOverallLayout() {}

  /** 清除布局 */
  clearLayout() {}
  //#endregion

  /** 添加扩展属性 **/
  addExtendedAttributes() {
    if (this.props.extendedAttributes && this.props.modelCode) {
      return extendedAttributesSchema(this.props.modelCode, this.props).then(schema => {
        if (schema) {
          //扩展属性类型映射
          this.extendedAttributesTypeMapping.value = schema.controls.reduce((obj, item) => {
            obj[item.name] = PropertyType[item.props.extendType as keyof typeof PropertyType]
            return obj
          }, {} as { [key: string]: string })
          if (this.props.extendedPosition) {
            this.schema.value!.controls.splice(this.props.extendedPosition - 1, 0, schema)
          } else {
            this.schema.value!.controls.push(schema)
          }
        }
      })
    }

    return Promise.resolve()
  }

  //分类属性id缓存
  cacheCategoryAttributeId: string | null = null

  /** 打开分类属性 **/
  openCategoryAttributes(id: string | null) {
    if (this.cacheCategoryAttributeId === id) {
      return Promise.resolve()
    } else {
      this.cacheCategoryAttributeId = id
    }

    if (id == null || id == '') {
      // 去掉分类属性 categoryAttributes
      this.deleteCategoryProperties()
      return Promise.resolve()
    } else {
      return categoryAttributesSchema(id, this.props).then(schema => {
        if (schema) {
          this.deleteCategoryProperties()

          const originValue = this.getValue()
          nextTick(() => {
            this.schema.value!.controls.push(schema)
            nextTick(() => {
              if (originValue != null && Reflect.has(originValue, 'clsAttrs') && Array.isArray(originValue.clsAttrs)) {
                const classification = originValue.clsAttrs[0][this.categoryAttrField] || {}
                Object.keys(classification).forEach((key: string) => {
                  const vm = this.getByRecursion(`categoryAttributes#${key}`, this) as SimpleViewModel
                  vm && vm.setValue(classification[key])
                })
              }
            })
          })
        } else {
          this.deleteCategoryProperties()
        }
      })
    }
  }

  public setItemDisabled(itemList: string[]): void {
    itemList.forEach(item => {
      const viewMode = this.getByRecursion(item, this) as SimpleViewModel
      viewMode.setDisabled(true)
    })
  }

  deleteCategoryProperties() {
    this.schema.value!.controls = this.schema.value!.controls.filter(item => item.name !== 'categoryAttributes')
  }
  getContainer() {
    return this.refDynamicView.value
  }
}
