import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40dca2fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chart-header" }
const _hoisted_2 = {
  ref: "refChart",
  style: {"width":"100%","height":"300px"}
}
const _hoisted_3 = {
  key: 0,
  style: {"height":"300px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_empty = _resolveComponent("k-empty")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, _toDisplayString(_ctx.targetTitle), 1),
      (_ctx.isSelect)
        ? (_openBlock(), _createBlock(_component_k_select, {
            key: 0,
            value: _ctx.$vm.selectValue,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.selectValue) = $event)),
            style: {"width":"120px"},
            options: _ctx.selectOptions,
            onChange: _ctx.selectChange
          }, null, 8, ["value", "options", "onChange"]))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, null, 512), [
      [_vShow, _ctx.hasData]
    ]),
    (!_ctx.hasData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_k_empty, { description: "暂无数据" })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}