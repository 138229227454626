
import { defineView } from '@kmsoft/upf-core'
import { KBaselineContentPropOptions, KBaselineContentEventEmits } from './interface'
import KBaselineContentViewModel from './KBaselineContentViewModel'

export default defineView({
  name: 'KBaselineContent',
  props: KBaselineContentPropOptions,
  emits: KBaselineContentEventEmits,
  viewModel: KBaselineContentViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
