import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KEditFormPropOptions = {
  ...BaseViewPropOptions,
  formData: VuePropTypes.createType<IFormProp>().def()
}

/** 参数类型 **/
export type KEditFormPropType = ViewPropsTypeExtract<typeof KEditFormPropOptions>

/** 事件 */
export const KEditFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KEditFormEmitsType = ViewEmitsTypeExtract<typeof KEditFormEventEmits>

export interface IFormProp {
  /**工作区id */
  id: string
  /**工作区名称 */
  result: string
}
