import { BaseViewModel, KNotification, KTreeViewViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KRequirementChangeBeforeEmitsType, KRequirementChangeBeforePropType } from './interface'
import { ref, watch } from 'vue'
import { Api, EnumRequestCode, KObjectPanelViewModel } from '@kmsoft/ebf-common'
import { NodeData, NeedsTreeNode } from '../requirement-change-after/interface'

/** KRequirementChangeBefore */
export default class KRequirementChangeBeforeViewModel extends BaseViewModel<
  KRequirementChangeBeforeEmitsType,
  KRequirementChangeBeforePropType
> {
  /**树组件 */
  refTree = ref<KTreeViewViewModel>()

  /** 对象面板 */
  refObjectPanel = ref<KObjectPanelViewModel>()

  objParam = ref<any>()

  rootId = ref<string>()

  TreeNodeParam = ref<any>()

  constructor(options: ViewModelOptions<KRequirementChangeBeforePropType>) {
    super(options)
    watch(
      () => this.props.objParam.id,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          this.objParam.value = this.props.objParam
        }
      },
      {
        immediate: true,
        deep: true
      }
    )
  }

  viewDidMount() {}

  async loadTreeData() {
    const result = (await Api.post('requirement', 'RequirementChangeRequest', 'get', { data: [this.objParam.value?.id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const node = {
        id: result.data?.changeBefore.needsView.id,
        name: result.data?.changeBefore.needsView.name,
        hasChildren: result.data.changeBefore.hasChildren,
        parentId: '-1',
        className: result.data?.changeBefore.needsView.className
      } as NodeData
      this.rootId.value = result.data?.changeBefore.needsView.id
      return [new NeedsTreeNode(node)]
    } else {
      KNotification.error({
        title: '查询需求变更详情异常',
        content: result.message || '查询需求变更详情异常',
        details: result.detail
      })
      return
    }
  }

  onAfterSelect(param: any) {
    this.TreeNodeParam.value = {
      id: param.node.id,
      modelCode: param.node.className,
      modelGroup: 'requirement'
    }
  }

  onReady() {
    this.refTree.value?.setSelectedNode(this.rootId.value!)
  }

  /** 加载数据 */
  async loadData() {
    const result = (await Api.post('requirement', this.TreeNodeParam.value.modelCode, 'get', {
      data: [this.TreeNodeParam.value.id]
    })) as any

    if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取产品需求失败',
        details: result.detail
      })
      return
    }
  }
}
