import { connect, withInstall } from '@kmsoft/upf-core'
import KInfoMessageView from './KInfoMessage.vue'
import KInfoMessageViewModel from './KInfoMessageViewModel'

const KInfoMessage = connect(KInfoMessageView, KInfoMessageViewModel)

export default withInstall(KInfoMessage)
export { KInfoMessage, KInfoMessageView, KInfoMessageViewModel }

// 模板生成文件
// export * from './{{folderName}}'
