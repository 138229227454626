import { Api, ConfigClientSrv, EnumClassTemplate, EnumRequestCode } from '@kmsoft/ebf-common'
import { EnumDocDependencyType } from '@kmsoft/ebf-common/src/types/enums'
import { AppContext, BaseViewModel, KNotification, request, ViewModelOptions } from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import { CommentItem, EnumKmVueFileType, IK3DVueBrowser, SaveCommentsParams, StructData } from '../vue-browser'
import * as Utils from '../vue-browser/utils'
import { DocClientSrv } from './../../../../ebf-doc/src/client-srv/DocClientSrv'
import { KModelBrowserEmitsType, KModelBrowserPropType } from './interface'

/** KModelBrowser */
export default class KModelBrowserViewModel extends BaseViewModel<KModelBrowserEmitsType, KModelBrowserPropType> {
  refBrowser = ref<IK3DVueBrowser>()
  apiUrl = `todo`
  fileUrl = ref<string>('')
  structData = ref<Array<StructData>>()
  fileType = ref<string>('')
  /** 错误消息 */
  errorMsg = ref<string>('')
  /** 是否在加载 */
  isLoading = ref(true)
  /** 是否显示批注 */
  remarkPurview = ref<boolean>(false)
  /** 查看批注权限 */
  viewRemarkPurview = ref<boolean>(false)
  /** 批注显示类型 */
  remarkType = ref<number>(0)
  /**加载遮罩 */
  loading = ref<boolean>(false)
  /**loading文字*/
  loadingText = ref<string>('')
  /** 进度条百分比 */
  rate = ref<any>(0)
  /** 定时器 */
  timer = ref<any>()
  constructor(options: ViewModelOptions<KModelBrowserPropType>) {
    super(options)
    watch(
      () => this.rate.value,
      newValue => {
        if (newValue === '100') {
          setTimeout(() => {
            this.loading.value = false
            clearInterval(this.timer.value)
          }, 1000)
        }
      }
    )
  }

  async viewDidMount() {
    this.loading.value = this.props.loading
    const task = await DocClientSrv.getTaskInfo(this.props.objParam.id, '')
    if (task && task.status == 2) {
      this.loadingText.value = '正在转换中，请等待'
      this.loading.value = true
    }
    await this.buildFileUrl()
  }

  loaded() {
    this.loadComments()
    this.setNodesColor()
  }

  setNodesColor = async () => {
    if (!this.props.nodesColorParam) {
      return
    }
    const nodesColorParam = this.props.nodesColorParam
    const paths = nodesColorParam.paths
    const color = nodesColorParam.color
    const allPathArray = [] as string[]
    paths.forEach(path => {
      const ids = path.split(',')
      ids[0] = 'root'
      const pathArray = Utils.getPathByCidPath(this.structData.value!, ids)
      if (pathArray && pathArray.length > 0) {
        allPathArray.push(...pathArray)
      }
    })
    if (allPathArray.length > 0) {
      this.refBrowser.value?.setNodesColor(allPathArray, color)
    }
  }

  /** 加载批注 */
  loadComments = async () => {
    if (!this.props.annotationFileIds || this.props.annotationFileIds.length == 0) {
      return
    }
    await this.refBrowser.value?.clearComments()
    const fileIds = this.props.annotationFileIds
    if (!fileIds || fileIds.length == 0) return
    let conmmentItems = [] as Array<CommentItem>
    for (const fileId of fileIds) {
      //下载文件
      const fileResult = (await request.post(
        '/kmsaasFileApi/download',
        {
          modelName: 'DocumentMarkRecord',
          fileIds: fileId,
          attributeName: 'MarkFile'
        },
        { responseType: 'blob' }
      )) as any
      const jsonBlob: Blob = fileResult.data

      // 将 Blob 转换为 JSON 字符串
      const jsonString = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = error => reject(error)
        reader.readAsText(jsonBlob)
      })
      conmmentItems = conmmentItems.concat(JSON.parse(jsonString) as Array<CommentItem>)
    }
    this.refBrowser.value?.addComments(conmmentItems)
    // const result = await Api.post('doc', 'Document', 'listAnnotations', { data: [this.props.objParam.id] })
    // if (result && result.code == EnumRequestCode.SUCCESS) {
    //   if (result.data && result.data.markFile) {
    //     //下载文件
    //     const fileResult = (await request.post(
    //       '/kmsaasFileApi/download',
    //       {
    //         modelName: 'DocumentMarkRecord',
    //         fileIds: result.data.markFile[0]?.id,
    //         attributeName: 'MarkFile'
    //       },
    //       { responseType: 'blob' }
    //     )) as any
    //     const jsonBlob: Blob = fileResult.data

    //     // 将 Blob 转换为 JSON 字符串
    //     const jsonString = await new Promise<string>((resolve, reject) => {
    //       const reader = new FileReader()
    //       reader.onload = () => resolve(reader.result as string)
    //       reader.onerror = error => reject(error)
    //       reader.readAsText(jsonBlob)
    //     })
    //     this.refBrowser.value?.addComments(JSON.parse(jsonString) as Array<CommentItem>)
    //   }
    // }
  }

  /**
   * 保存批注事件
   * @param params
   */
  onSaveComment = async (params: SaveCommentsParams) => {
    // 获取流程
    const doc = await DocClientSrv.getDoc(this.props.objParam.id)
    const result = await Api.post('official', 'ProcessContentService', 'getProcessByWorkObj', {
      data: [{ workObjId: doc.branch.id, workObjClsCode: EnumClassTemplate.DOC }]
    })
    if (!result || !result.data || result.data.length == 0 || result.data.tasks.length == 0) {
      KNotification.warn({ message: '系统提示', description: '未找到流程' })
      return
    }
    const taskData = result.data.tasks[0]
    const workflowId = taskData.businessId
    const taskId = taskData.taskId
    //上传批注文件
    const fileId = await this.uploadJsonAsBinary(params.datas)
    // 保存批注记录
    const saveAnnotationRes = await Api.post('doc', 'Document', 'createAnnotation', {
      data: [
        {
          documentId: this.props.objParam.id,
          rdmExtensionType: 'Document',
          fileId: fileId,
          taskId: taskId,
          workflowId: workflowId
        }
      ]
    })
    // const fileId = await this.uploadJsonAsBinary(params.datas)
    // const req = { data: [{ documentId: this.props.objParam.id, fileId: fileId }] }
    // const result = await Api.post('doc', 'Document', 'createOrUpdateAnnotation', req)
    if (saveAnnotationRes && saveAnnotationRes.code == EnumRequestCode.SUCCESS) {
      KNotification.success({
        title: '系统提示',
        content: '保存成功'
      })
    } else {
      KNotification.error({
        title: '保存失败',
        content: saveAnnotationRes.message!
      })
      return
    }
  }

  /**
   * 上传批注数据作为json到文件服务
   * @param jsonData 批注json数据
   * @returns 文件id
   */
  async uploadJsonAsBinary(jsonData: any) {
    const jsonString = JSON.stringify(jsonData)
    const binaryData = new Blob([jsonString], { type: 'application/json' })
    const req = new FormData()
    req.append('modelName', 'DocumentMarkRecord')
    req.append('attributeName', 'MarkFile')
    req.append('files', new File([jsonString], 'annotation.json', { type: 'application/json' }))
    const result = (await request.post('/kmsaasFileApi/upload', req, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })) as any
    if (result && result.result == 'SUCCESS') {
      const data = result.data
      return data[0]
    }
  }

  async buildFileUrl() {
    if (this.props.url && this.props.fileType) {
      this.fileType.value = this.props.fileType
      this.fileUrl.value = this.props.url
      this.getDownFileRate()
      return
    }

    //有传入 直接用传入
    if (this.props.fileId && this.props.fileType) {
      this.fileType.value = this.props.fileType
      this.fileUrl.value = ConfigClientSrv.getFileApiBaseUrl + '/ilePreview/Document/' + this.props.fileId
      return
    }
    const reqParam = { data: [this.props.objParam.id] }
    const result = await Api.post('doc', 'Document', 'get', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const doc = result.data
      if (!doc.primary) {
        KNotification.info({
          message: '没有主文件'
        })
        return
      }
      const primaryFilename = doc?.primary[0]?.name as string
      const ext = primaryFilename
        .split('.')
        .pop()
        ?.toLowerCase()
      switch (ext) {
        case 'sldasm':
          this.fileUrl.value = await this.handleSldasm(doc)
          break
        case 'sldprt':
          this.fileUrl.value = await this.handleSldprt(doc)
          break
        case 'slddrw':
          this.fileUrl.value = await this.handleSlddrw(doc)
          break
        case 'dwg':
        case 'brd':
        case 'dsn':
          this.fileUrl.value = await this.handlePdf(doc)
          break
        case 'pdf':
          this.fileType.value = EnumKmVueFileType.PDF + ''
          this.fileUrl.value = ConfigClientSrv.getFileApiBaseUrl + '/ilePreview/Document/' + doc.primary[0].id
          break
        case 'xlsx':
          this.fileType.value = EnumKmVueFileType.Office + ''
          this.fileUrl.value = ConfigClientSrv.getFileApiBaseUrl + '/ilePreview/Document/' + doc.primary[0].id + '.xlsx'
          break
        case 'docx':
          this.fileType.value = EnumKmVueFileType.Office + ''
          this.fileUrl.value = ConfigClientSrv.getFileApiBaseUrl + '/ilePreview/Document/' + doc.primary[0].id + '.docx'
          break
        default:
          break
      }
      return
    } else {
      KNotification.error({
        title: '系统错误',
        content: result.message
      })
    }
    return
  }

  /**
   * 访问结构xml数据
   * @param doc 文档对象
   * @returns xml访问地址
   */
  async handleSldasm(doc: any) {
    this.fileType.value = '4'
    const identity = AppContext.current.getIdentity()
    const base64Token = this.stringToBase64(identity!.token).replaceAll('=', '')
    const fileUrl = ConfigClientSrv.getFileApiBaseUrl + '/doc/getDocXml/' + base64Token + '/' + doc.id + '/product_shattered.xml'
    const result = await Api.post('doc', 'Document', 'findDocXml', { data: [doc.id] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const strunctData = this.transformToStructData(result.data)
      this.structData.value = strunctData
    }
    return fileUrl
  }

  /**
   * 字符串转base64
   * @param input  编码的字符串
   * @returns  encode后的字符串
   */
  stringToBase64(input: string): string {
    const base64 = btoa(input)
    return base64
  }

  /**
   *  访问prt结构数据
   * @param doc 主文档对象
   * @returns
   */
  async handleSldprt(doc: any) {
    this.fileType.value = '5'
    //查询关联的浏览文档
    const reqParam = { data: [this.props.objParam.id, EnumDocDependencyType.BROWSE] }
    const result = await Api.post('doc', 'Document', 'getAttachmentDocumentByType', reqParam)
    if (result && result.data) {
      return ConfigClientSrv.getFileApiBaseUrl + '/ilePreview/Document/' + result.data.primary[0]?.id
    }
    return ''
  }

  /**
   *  访问prt结构数据
   * @param doc 主文档对象
   * @returns
   */
  async handleSlddrw(doc: any) {
    this.fileType.value = '2'
    //查询关联的浏览文档
    const reqParam = { data: [this.props.objParam.id, EnumDocDependencyType.BROWSE] }
    const result = await Api.post('doc', 'Document', 'getAttachmentDocumentByType', reqParam)
    if (result && result.data) {
      return ConfigClientSrv.getFileApiBaseUrl + '/ilePreview/Document/' + result.data.primary[0]?.id
    }
    return ''
  }

  // handleDwg(doc: any): string {
  //   return this.handlePdf()
  // }

  async handlePdf(doc: any): Promise<string> {
    this.fileType.value = '2'
    //查询关联的浏览文档
    const reqParam = { data: [this.props.objParam.id, EnumDocDependencyType.BROWSE] }
    const result = await Api.post('doc', 'Document', 'getAttachmentDocumentByType', reqParam)
    if (result && result.data) {
      return ConfigClientSrv.getFileApiBaseUrl + '/ilePreview/Document/' + result.data.primary[0]?.id
    }
    return ''
  }

  transformToStructData(xmlString: string): StructData[] {
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(xmlString, 'application/xml')
    const productOccurrences = xmlDoc.getElementsByTagName('ProductOccurence')
    const idMap: { [key: number]: StructData } = {}

    for (const occurrence of Array.from(productOccurrences)) {
      const id = parseInt(occurrence.getAttribute('Id')!, 10)
      const docId = occurrence.getAttribute('docId')!
      const index = occurrence.getAttribute('index')!
      const node: StructData = {
        xmlNodeId: id,
        cid: docId,
        index: index,
        children: []
      }

      idMap[id] = node
    }

    for (const occurrence of Array.from(productOccurrences)) {
      const id = parseInt(occurrence.getAttribute('Id')!, 10)
      const childrenStr = occurrence.getAttribute('Children')

      if (childrenStr) {
        const childrenIds = childrenStr.split(' ').map(childId => parseInt(childId, 10))
        const parentNode = idMap[id]
        if (parentNode) {
          parentNode.children = childrenIds.map(childId => idMap[childId]).filter(child => child)
        }
      }
    }
    idMap[0].cid = 'root'

    return [idMap[0]]
  }

  async setNodesHighlight(path: string) {
    const ids = path.split(',')
    ids[0] = 'root'
    const pathArray = Utils.getPathByCidPath(this.structData.value!, ids)
    if (pathArray && pathArray.length > 0) {
      this.refBrowser.value?.setNodesHighlight(pathArray, true)
      return
    } else {
      console.log('找不到文档坐标')
    }
    // if (Utils.getNodeIdByPath(this.structData.value!, ids)) {
    //   this.refBrowser.value?.setNodesHighlight(ids.join(','), true)
    // } else {
    //   console.log('找不到文档坐标')
    // }
  }
  /**获取下载进度 */
  getDownFileRate() {
    if (this.props.guid) {
      try {
        this.timer.value = setInterval(async () => {
          const result = await Api.post(
            this.props.type ? 'requirement' : 'doc',
            this.props.type ? 'Requirement' : 'Document',
            this.props.type ? 'getExportProcess' : 'getDocExportProcess',
            {
              data: [this.props.guid]
            }
          )
          if (result && result.code == EnumRequestCode.SUCCESS && result.data && result.data != '-1') {
            if (this.rate.value < Number(result.data)) {
              this.rate.value = Number(result.data).toFixed(0) as any
            }
          } else {
            clearInterval(this.timer.value)
            this.loading.value = false
          }
        }, 800)
      } catch (error) {
        clearInterval(this.timer.value)
        this.loading.value = false
        KNotification.error({
          title: '系统错误',
          content: '文件下载错误'
        })
      }
    }
  }
}
