
import { defineView } from '@kmsoft/upf-core'
import { KLifecycleStateTagPropOptions, KLifecycleStateTagEventEmits } from './interface'
import KLifecycleStateTagViewModel from './KLifecycleStateTagViewModel'
import { EnumLifecycleState, EnumLifecycleStateColor } from '../../client-srv'

export default defineView({
  name: 'KLifecycleStateTag',
  props: KLifecycleStateTagPropOptions,
  emits: KLifecycleStateTagEventEmits,
  viewModel: KLifecycleStateTagViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      EnumLifecycleStateColor
    }
  }
})
