import {
  BaseViewModel,
  EnumDialogResult,
  EnumItemClickedCancelType,
  EnumToolStripCompType,
  KDataGridViewModel,
  KDialogClosingEvent,
  KDialog,
  KNotification,
  MemoryCacheFactory,
  SelectOption,
  ToolStripItemClickedEventArgs,
  ViewModelOptions,
  KDataGridRowData
} from '@kmsoft/upf-core'
import { KRequirementChangeTabEmitsType, KRequirementChangeTabPropType } from './interface'
import {
  Api,
  EnumPurviewCode,
  EnumRequestCode,
  EnumSupportObjectNumMode,
  EnumToolStripItemKeys,
  EnumVerification,
  KObjectClassGridViewModel,
  KObjectToolStripViewModel,
  ObjBusinessParam,
  ObjectClientSrv,
  UserInfoCache
} from '@kmsoft/ebf-common'
import { ref, watch } from 'vue'
import { WorkflowClientSrv } from '../../../../ebf-workflow/src'

/** KRequirementChangeTab */
export default class KRequirementChangeTabViewModel extends BaseViewModel<
  KRequirementChangeTabEmitsType,
  KRequirementChangeTabPropType
> {
  /** 对象 */
  objParam = ref<ObjBusinessParam>()
  refToolStripItem = ref<KObjectToolStripViewModel>()
  /** 变更请求网格 */
  refChangeRequestDataGrid = ref<KObjectClassGridViewModel>()
  userList = ref<Record<string, any>[]>([])

  constructor(options: ViewModelOptions<KRequirementChangeTabPropType>) {
    super(options)
    this.objParam.value = options.props.objParam
    watch(
      () => this.props.objParam,
      newValue => {
        if (newValue && newValue.id) {
          this.objParam.value = newValue
          this.refChangeRequestDataGrid.value?.refresh()
        }
      }
    )
    watch(
      () => this.props.refreshTip,
      () => {
        this.refChangeRequestDataGrid.value?.refresh()
      },
      {
        deep: true
      }
    )
  }

  viewDidMount() {
    this.getUserData()
  }

  getUserData() {
    const cacheInstance = MemoryCacheFactory.get<UserInfoCache>(UserInfoCache.cacheKey)
    this.userList.value = cacheInstance.getUserOptionList()
  }

  getUserName(value: string) {
    const data = this.userList.value.find(item => item.value == value)
    return data?.label
  }

  /** 变更请求工具栏点击事件 */
  async onRequirementChangeRequestToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    const rows = this.refChangeRequestDataGrid.value!.getSelectedRows()
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refChangeRequestDataGrid.value?.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_BRANCH:
        this.batchDelete(rows!)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW:
        this.addToWorkflow(rows!).then(res => {
          this.refChangeRequestDataGrid.value?.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW:
        this.viewWorkFlow(rows!)
        break
      default:
        break
    }
  }

  /**
   * 删除发放单
   */
  batchDelete(rows: Array<KDataGridRowData>) {
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认删除吗？删除后不可恢复!',
      onOk: () => {
        // 获取主对象id集合
        const rowIds = rows!.map(row => row.id)
        Api.post('requirement', 'RequirementChangeRequest', 'batchDelete', { data: [rowIds] }).then(res => {
          if (res.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '删除成功'
            })
            // 刷新网格
            this.refChangeRequestDataGrid.value?.refresh()
          } else {
            KNotification.error({
              title: '操作失败',
              content: res.message || '删除失败',
              details: res.detail
            })
            return
          }
        })
      }
    })
  }

  /**
   * 加入流程
   */
  async addToWorkflow(rows: Array<KDataGridRowData>): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!rows || rows.length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '请至少选择一个对象'
        })
        return
      }

      const partParams = rows.map(row => {
        return {
          objID: row.id,
          objClsCode: row.rdmExtensionType,
          name: row.title,
          number: row.number,
          status: row.lifecycleStateCode
        }
      })

      WorkflowClientSrv.addObjectsToWorkflow(partParams).then(result => {
        resolve()
      })
    })
  }

  /**
   * 查看流程
   */
  async viewWorkFlow(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    const objClsCode = rows[0].rdmExtensionType
    WorkflowClientSrv.viewWorkflow(rows[0].id, objClsCode)
  }

  /** 加载变更请求 */
  async loadRequestChageData() {
    const obj = this.objParam.value
    const params = {
      data: [obj?.id]
    }
    const result = await Api.post('requirement', 'RequirementChangeRequest', 'listByRequirement', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取结构关系失败',
        details: result.detail
      })
      return []
    }
  }

  /** 结构关系工具栏 */
  requirementChangeRequestToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_BRANCH,
      title: '删除',
      icon: 'DeleteObj',
      visible: true,
      kiaguidMenu: 'DELETE',
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
      shortcutKey: 'alt+shift+r'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW,
      title: '加入流程',
      icon: 'AddToFlow',
      visible: true,
      purviewId: EnumPurviewCode.AddWorkflow,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW,
      title: '查看流程',
      icon: 'ViewFlowObj',
      visible: true,
      purviewId: EnumPurviewCode.ViewWorkflow,
      compType: EnumToolStripCompType.BUTTON
    }
  ]

  onRowSelected() {
    const selectRows = this.refChangeRequestDataGrid.value?.getSelectedRows()
    const noInWork = selectRows?.some(
      item => item.lifecycleState?.internalName != 'InWork' || item.lifecycleStateCode != 'InWork'
    )
    if (noInWork) {
      this.refToolStripItem.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW, true)
      this.refToolStripItem.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_BRANCH, true)
    } else {
      this.refToolStripItem.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW, false)
      this.refToolStripItem.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_BRANCH, false)
    }
  }

  /** 打开结构关系对象 */
  async openRequestChange(row: any) {
    const param = {
      id: row?.id,
      modelCode: row?.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row?.className!)
    }
    ObjectClientSrv.openObj(param)
  }
}
