import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KInfoMessageEmitsType, KInfoMessagePropType } from './interface'

/** KInfoMessage */
export default class KInfoMessageViewModel extends BaseViewModel<KInfoMessageEmitsType, KInfoMessagePropType> {
  constructor(options: ViewModelOptions<KInfoMessagePropType>) {
    super(options)
  }

  viewDidMount() {}
}
