import {
  Agent,
  Api,
  CommonClientSrv,
  EnumLifecycleState,
  EnumObjClassManageToolStripOptions,
  EnumRequestCode,
  EnumToolStripItemKeys,
  EnumWorkingState,
  KObjectPropertyEditPanelViewModel,
  KOfficeDocumentEditor,
  ObjBusinessBase,
  ObjBusinessParam,
  ObjectToolStripItem,
  ToolStripHelper
} from '@kmsoft/ebf-common'
import { EnumDocDependencyType, EnumDocType } from '@kmsoft/ebf-common/src/types/enums'
import {
  BaseViewModel,
  EnumItemChangeCancelType,
  EnumItemClickedCancelType,
  KDialog,
  KNotification,
  PageManager,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { default as _, default as lodash } from 'lodash'
import { ref, watch } from 'vue'
import { ChangeManageClientSrv } from '../../../../ebf-change-manage/src/client-srv'
import { WorkflowClientSrv } from '../../../../ebf-workflow'
import { DpMsgHandler } from '../../client-agent'
import { DocClientSrv } from '../../client-srv'
import { KDocRelationObj } from '../doc-relation-obj'
import { EnumWorkState, KDocEditPanelEmitsType, KDocEditPanelPropType } from './interface'

/** KDocEditPanel */
export default class KDocEditPanelViewModel extends BaseViewModel<KDocEditPanelEmitsType, KDocEditPanelPropType> {
  /** 属性 */
  refObjectProperty = ref<KObjectPropertyEditPanelViewModel>()
  /** 文档对象参数 */
  docParam = ref<ObjBusinessParam>()
  /** 属性面板工具栏 */
  toolStripOptions = ref<EnumObjClassManageToolStripOptions>(EnumObjClassManageToolStripOptions.ShowEdit)
  toolStripItems = ref<Array<ObjectToolStripItem>>(ToolStripHelper.getDocGeneralObjectOperationToolStripItems())
  originFormData = ref<any>()

  constructor(options: ViewModelOptions<KDocEditPanelPropType>) {
    super(options)
    watch(
      () => this.originFormData.value,
      newValue => {
        if (newValue) {
          const lifecycleStateCode = newValue['lifecycleStateCode']
          const workState = newValue['workingState']
          const latest = newValue['latest']
          this.updateToolStrip(workState, latest, lifecycleStateCode)
        }
      },
      {
        immediate: true,
        deep: true
      }
    )

    watch(
      () => this.props.objParam,
      () => {
        //不加这一行切换会失败
        this.docParam.value = _.cloneDeep(options.props.objParam)
        // this.refObjectProperty.value?.refresh()
      },
      {
        immediate: true,
        deep: true
      }
    )
    watch(
      () => this.docParam.value,
      (newVal, oldVal) => {
        if (newVal!.id != options.props.objParam.id) {
          //不加这一行切换会失败
          this.refObjectProperty.value?.refresh()
        }
      },
      {
        deep: true
      }
    )
  }

  viewDidMount() {
    //控制按钮状态
    this.handleButtonUsable()
  }

  /**
   * 处理按钮状态
   */

  handleButtonUsable() {
    // this.
  }

  async onLoadData() {
    const reqParam = { data: [this.docParam.value!.id] }
    const result = await Api.post('doc', 'Document', 'get', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.originFormData.value = result.data
      this.docParam.value!.kiaguid = result.data.kiaguid
      return result.data
    }
    KNotification.error({
      title: '系统错误',
      content: result.message
    })
    return
  }

  /**
   * 按钮点击事件处理
   * @param event 按钮事件
   */
  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE:
        this.doSave()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT:
        this.doCheckout()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN:
        this.doCheckin()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT:
        this.doUnCheckout()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS:
        this.doSaveAs()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REVISE:
        this.doRevise()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC:
        this.doBrowse()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_ASSIGN_DOC:
        this.doBrowseAssign()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD_DOC:
        this.doDownload()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refObjectProperty.value?.refresh()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_FILE:
        this.doEdit()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE:
        this.doStructureManage()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW:
        this.addWorkflow()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW:
        this.viewWorkflow()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_UPDATE_STATE:
        this.doUpdateState()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DISCARD:
        this.doDisuse()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_PRINT:
        this.doPrint()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_SOURCE_APP_PRINT:
        this.doSourceAppPrint()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE:
        this.doReplaceFromLocalFile()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE:
        this.doDelete()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_BRANCH:
        this.doDeleteBranch()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOC_CONNECT_TO_DP:
        this.connectToDp()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_REMARK:
        this.doRemark()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK:
        this.doViewRemark()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK_HISTORY:
        this.doViewRemarkHistory()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_HISTORY_VERSION:
        this.doViewHistoryVersion()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_AFTER_UPDATE_CREATE:
        this.openCreateChangeActivityPanel(false)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEFORE_UPDATE_CREATE:
        this.openCreateChangeActivityPanel(true)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_AFTER_UPDATE_ADD_ACTIVITY:
        this.addToChangeActivity(false)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEFORE_UPDATE_ADD_ACTIVITY:
        this.addToChangeActivity(true)
        break
      default:
        break
    }
  }

  /**
   * 添加变更活动
   */
  addToChangeActivity(isUpdateBefore: boolean) {
    const partData = lodash.cloneDeep(this.refObjectProperty.value?.getValue()) as any
    ChangeManageClientSrv.addToChangeActivity(isUpdateBefore, [partData], this.refObjectProperty.value?.getContainer())
  }

  /**
   * 创建变更活动
   */
  openCreateChangeActivityPanel(isUpdateBefore: boolean) {
    const data = lodash.cloneDeep(this.refObjectProperty.value?.getValue()) as ObjBusinessBase
    if (!data.className) {
      data.className = data?.targetClass
    }
    data.state = data.lifecycleStateCode ? EnumLifecycleState[data.lifecycleStateCode] : '--'
    data.checkInState = data.workingState ? EnumWorkingState[data.workingState as keyof typeof EnumWorkingState] : '--'
    if (isUpdateBefore) {
      ChangeManageClientSrv.createChangeActivity(
        '基于修改前创建变更活动',
        [data],
        null,
        this.refObjectProperty.value?.getContainer(),
        data.folder.id
      )
    } else {
      ChangeManageClientSrv.createChangeActivity(
        '基于修改后创建变更活动',
        null,
        [data],
        this.refObjectProperty.value?.getContainer(),
        data.folder.id
      )
    }
  }

  /**
   * 浏览文件历史版本
   */
  doViewHistoryVersion() {
    CommonClientSrv.openPage(
      '文档历史版本',
      KDocRelationObj,
      { objParam: this.docParam.value, activeKey: ['4'] },
      '文档历史版本' + this.docParam.value?.id
    )
  }

  /**
   * 执行删除
   */
  doDelete() {
    const _this = this
    KDialog.confirm({
      title: '删除当前对象',
      //icon: createVNode(ExclamationCircleOutlined),
      content: '你将删除该对象的所有版本数据，请确认是否删除？',
      async onOk() {
        const result = await Api.post('doc', 'Document', 'batchDelete', { data: [[_this.docParam?.value?.id]] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '删除成功'
          })
          // 删除数据后关闭当前页面
          const tabKey = `${_this.docParam?.value?.id}#Document`
          PageManager.closePage(tabKey)
        } else {
          KNotification.error({
            title: '删除失败',
            content: result.message!
          })
          return
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCancel() {}
    })
  }

  /**
   * 执行删除
   */
  doDeleteBranch() {
    const _this = this
    KDialog.confirm({
      title: '删除当前版本数据',
      //icon: createVNode(ExclamationCircleOutlined),
      content: '你将删除该对象的当前版本数据，请确认是否删除？',
      async onOk() {
        const result = await Api.post('doc', 'Document', 'batchDeleteBranch', { data: [[_this.docParam?.value?.id]] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '删除成功'
          })
          // 删除数据后关闭当前页面
          const tabKey = `${_this.docParam?.value?.id}#Document`
          PageManager.closePage(tabKey)
        } else {
          KNotification.error({
            title: '删除失败',
            content: result.message!
          })
          return
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCancel() {}
    })
  }

  async connectToDp() {
    if (await Agent.AgentManager.initialize()) {
      await DpMsgHandler.register()
    }
  }

  /**
   * 从本地文件替换
   */
  async doReplaceFromLocalFile() {
    DocClientSrv.doReplaceFromLocalFile(this.docParam.value!.id).then((result: any) => {
      if (result && result.code == EnumRequestCode.SUCCESS) {
        // 后台取消成功之后，重置当前面板对象的ID参数
        this.docParam.value!.id = result.data.id
      }
    })
  }

  /**
   * 打印
   */
  async doPrint() {
    // 获取签字文件
    DocClientSrv.print(this.docParam.value!.id)
  }

  /**
   * 原程序打印
   */
  doSourceAppPrint() {
    KNotification.info({
      message: '功能开发中'
    })
  }

  /**
   *
   * 查看流程
   */
  async viewWorkflow() {
    const currentDoc = await DocClientSrv.getDoc(this.docParam.value!.id)
    WorkflowClientSrv.viewWorkflow(currentDoc?.branch.id, currentDoc.rdmExtensionType)
  }

  /**
   * 弃用
   */
  doDisuse() {
    KNotification.info({
      message: '功能开发中'
    })
  }

  /**
   * 执行变更生命周期的操作
   */
  doUpdateState() {
    KNotification.info({
      message: '功能开发中'
    })
  }

  /**
   * 结构管理
   */
  async doStructureManage() {
    DocClientSrv.structManage(this.docParam.value!.id)
    // CommonClientSrv.openPage(
    //   '结构管理',
    //   KDocStructureManage,
    //   { objParam: { modelCode: 'Document', modelGroup: 'doc', id: this.docParam.value!.id } },
    //   this.docParam.value!.id + '结构管理'
    // )
  }

  /**
   * 加入流程
   */
  async addWorkflow() {
    const currentDoc = await DocClientSrv.getDoc(this.docParam.value!.id)
    WorkflowClientSrv.addWorkflow(
      currentDoc?.branch.id,
      currentDoc.rdmExtensionType,
      currentDoc.lifecycleStateCode,
      currentDoc.name,
      currentDoc.number,
      currentDoc.version
    )
  }

  /**
   * 下载文件
   */
  doDownload() {
    DocClientSrv.docDownload(this.docParam.value!.id)
  }

  /**
   * 浏览
   */
  async doBrowse() {
    DocClientSrv.browseDoc(this.docParam.value!)
  }

  async doBrowseAssign() {
    const result = await Api.post('doc', 'Document', 'getAttachmentDocumentByType', {
      data: [this.docParam.value?.id, EnumDocDependencyType.SIGNATURE]
    })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      if (result.data && result.data?.primary) {
        const ext = result.data.primary[0].name
          .split('.')
          .pop()
          ?.toLowerCase()
        CommonClientSrv.openPage(
          `签字文件预览：${result.data.number}`,
          KOfficeDocumentEditor,
          {
            modelName: 'Document',
            title: result.data.primary[0].name,
            id: result.data.primary[0].id,
            fileType: ext,
            mode: 'view'
          },
          result.data.id
        )
      } else {
        KNotification.info({
          message: '未找到签字文件'
        })
      }
    } else {
      KNotification.error({
        title: '系统错误',
        content: result.message
      })
    }
  }

  /**
   * 执行修订操作
   */
  doRevise() {
    // DocClientSrv.docRevise(this.docParam.value!.id).then((result: any) => {
    //   if (result && result.code == EnumRequestCode.SUCCESS) {
    //     // 后台成功之后，重置当前面板对象的ID参数
    //     this.docParam.value!.id = result.data.id
    //   }
    // })
  }

  /**
   * 执行另存
   */
  doSaveAs() {
    // DocClientSrv.docSaveAs(this.docParam.value!.id).then((result: any) => {
    //   if (result && result.code == EnumRequestCode.SUCCESS) {
    //     // 后台取消成功之后，重置当前面板对象的ID参数
    //     this.docParam.value!.id = result.data.id
    //   }
    // })
  }

  /**
   * 执行撤销检出
   */
  async doUnCheckout() {
    const docParam = this.docParam.value!
    KDialog.confirm({
      title: '确认撤销检出吗？',
      onOk: async () => {
        // 获取对象id
        DocClientSrv.docUnCheckOut(this.docParam.value!.id).then((result: any) => {
          if (result && result.code == EnumRequestCode.SUCCESS) {
            // 后台取消成功之后，重置当前面板对象的ID参数
            const rootDoc = result.data.find((item: any) => item.branch.id == docParam.branchId)
            this.docParam.value!.id = rootDoc.id
            this.refObjectProperty.value?.cancelEdit()
            this.refObjectProperty.value?.refresh()
            this.props.onDataUpdated!({ id: rootDoc.id })
          }
        })
      }
    })
  }

  /**
   * 执行检入
   */
  async doCheckin() {
    const docParam = this.docParam.value!
    KDialog.confirm({
      title: '确认检入吗？',
      onOk: async () => {
        // 获取对象id
        DocClientSrv.docCheckIn(this.docParam.value!.id).then((result: any) => {
          // 后台检入成功之后，重置当前面板对象的ID参数
          if (result && result.code == EnumRequestCode.SUCCESS) {
            const rootDoc = result.data.find((item: any) => item.branch.id == docParam.branchId)
            this.docParam.value!.id = rootDoc.id
            this.refObjectProperty.value?.cancelEdit()
            //this.docParam.value!.id = result.data[0].id
            this.refObjectProperty.value?.refresh()
            this.props.onDataUpdated!({ id: rootDoc.id })
          }
        })
      }
    })
  }

  /**
   * 执行按钮切换事件
   * @param event 按钮切换事件
   */
  async onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    console.log('toolStripItemChange', event)
    const formData = this.originFormData.value
    if (formData.lifecycleStateCode != 'InWork') {
      KNotification.error({ title: '系统错误', content: '正在工作的数据才能编辑' })
      return
    }

    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY:
        if (event.value) {
          this.doEditProp()
          //固定进入后设置保存为Visible=false
          this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
        } else {
          this.doSave(false)
          event.itemCancelType = EnumItemChangeCancelType.CancelAll
          this.refObjectProperty.value?.setReadOnly(true)
          //固定进入后设置保存为Visible=false
          this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
        }
        break
      default:
        break
    }
  }

  /**
   * 执行检出操作
   */
  async doCheckout() {
    const docParam = this.docParam.value!
    KDialog.confirm({
      title: '确认检出吗？',
      onOk: async () => {
        // 获取对象id
        DocClientSrv.docCheckOut(this.docParam.value!.id).then((result: any) => {
          // 后台成功之后，重置当前面板对象的ID参数
          if (result && result.code == EnumRequestCode.SUCCESS) {
            const rootDoc = result.data.find((item: any) => item.branch.id == docParam.branchId)
            this.docParam.value!.id = rootDoc.id
            this.refObjectProperty.value?.refresh()
            this.props.onDataUpdated!({ id: rootDoc.id })
            //this.docParam.value!.id = result.data[0].id
          }
        })
      }
    })
  }

  /**
   *
   * 执行保存操作
   */

  async doSave(isSave: boolean = true) {
    if (isSave) {
      const validate = this.refObjectProperty.value?.validate()
      if (!validate) {
        return
      }
    }
    const value = this.refObjectProperty.value?.getModifiedValue()! as any
    if (Object.keys(value).length == 0) {
      if (isSave)
        KNotification.warn({
          message: '系统提示',
          description: '没有修改数据'
        })
      return
    }
    if (value.secondary) {
      const secondary = value.secondary.map((element: string) => {
        return { id: element }
      })
      value.secondary = secondary
    }
    const reqParam = { ...value, id: this.docParam.value?.id }
    console.log('doSave', reqParam)
    const result = await Api.post('doc', 'Document', 'update', { data: [reqParam] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success('操作成功！')
      return
    }
    KNotification.error({
      title: '系统错误',
      content: result.message
    })
    return
  }

  /**
   * 执行编辑文件操作
   */
  async doEdit() {
    DocClientSrv.editFile(this.docParam.value!.id).then((docId: any) => {
      // 后台成功之后，重置当前面板对象的ID参数
      if (docId) {
        this.docParam.value!.id = docId
        this.refObjectProperty.value?.refresh()
      }
    })
  }

  /**
   * 执行编辑属性操作
   */
  async doEditProp() {
    const property = this.refObjectProperty.value?.getValue()
    //判断状态
    const workingState = property?.workingState
    if (workingState == 'CHECKED_IN') {
      // 检出
      const result = await Api.post('doc', 'Document', 'batchCheckout', {
        data: [[this.docParam.value!.id], { checkoutPath: '' }]
      })
      if (result && result.code == EnumRequestCode.SUCCESS) {
        this.docParam.value!.id = result.data[0].id
        this.refObjectProperty.value?.setReadOnly(false)
      } else {
        KNotification.error({
          title: '检出失败',
          content: result.message
        })
      }
    } else {
      this.refObjectProperty.value?.setReadOnly(false)
    }
  }

  /**
   * 批注
   */
  async doRemark() {
    // 判断文件类型
    const docData = (await DocClientSrv.getDoc(this.docParam.value!.id)) as any
    if (!docData) return
    DocClientSrv.doRemark(this.docParam.value!.id, docData.documentType)
  }

  /**
   * 查看批注
   */
  async doViewRemark() {
    const docId = this.docParam.value!.id
    DocClientSrv.doViewRemark(docId)
  }

  /**
   * 查看批注历史
   */
  async doViewRemarkHistory() {
    const docId = this.docParam.value!.id
    DocClientSrv.doViewRemarkHistory(docId)
  }

  updateToolStrip(value: string, latest: boolean, lifecycleStateCode: string) {
    //固定进入后设置保存为Visible=false
    this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
    const docType = this.originFormData.value?.documentType
    //结构化文档屏蔽按钮
    if (docType != EnumDocType.CAD && docType != EnumDocType.SW && docType != EnumDocType.UG) {
      this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE, false)
    }
    switch (value) {
      case EnumWorkState.CHECKED_IN:
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT, false)
        break
      case EnumWorkState.CHECKED_OUT:
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_FILE, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_REMARK, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE, true)
        break
      case EnumWorkState.INWORK:
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN, false)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT, false)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_BRANCH, true)
        break
      default:
        break
    }
    if (lifecycleStateCode != 'InWork') {
      this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, true)
      this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT, true)
      this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN, true)
      this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT, true)
    }
  }
}
