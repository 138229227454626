import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "full" }
const _hoisted_2 = { style: {"width":"100%","height":"100%"} }
const _hoisted_3 = { class: "grid-name" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { style: {"margin-left":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_lifecycle_state_tag = _resolveComponent("k-lifecycle-state-tag")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_panel, null, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_k_tool_strip_button_item, {
            icon: "sync",
            name: "refersh",
            title: "刷新",
            onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.refersh())
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_k_data_grid, {
          rowModelType: _ctx.rowModelType,
          class: "verification-plan-grid",
          rowKey: "id",
          name: "verificationPlanGrid",
          ref: _ctx.$vm.refDataGrid,
          loadData: _ctx.$vm.loadData,
          autosizeColumnsMode: _ctx.autosizeColumnsMode
        }, {
          columns: _withCtx(() => [
            _createVNode(_component_k_data_grid_template_column, {
              id: "title",
              name: "title",
              dataPropertyName: "title",
              headerText: "标题",
              align: "left"
            }, {
              default: _withCtx(({ row }) => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(row.title), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              id: "number",
              name: "number",
              dataPropertyName: "number",
              headerText: "编码",
              align: "left"
            }, {
              default: _withCtx(({ cellValue,row }) => [
                _createElementVNode("a", {
                  href: "javascript:void(0)",
                  onClick: () => _ctx.$vm.openPlanTab(row)
                }, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(cellValue), 1)
                ], 8, _hoisted_4)
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              id: "lifecycleState",
              name: "lifecycleState",
              dataPropertyName: "lifecycleState",
              headerText: "生命周期状态",
              width: "120",
              readonly: "",
              align: "center"
            }, {
              default: _withCtx(({ row }) => [
                _createVNode(_component_k_lifecycle_state_tag, { row: row }, null, 8, ["row"])
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              id: "description",
              name: "description",
              dataPropertyName: "description",
              headerText: "描述",
              readonly: false,
              align: "left"
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              id: "createTime",
              name: "createTime",
              dataPropertyName: "createTime",
              headerText: "创建时间",
              align: "left"
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              id: "lastUpdateTime",
              name: "lastUpdateTime",
              dataPropertyName: "lastUpdateTime",
              headerText: "更新时间",
              align: "left"
            })
          ]),
          _: 1
        }, 8, ["rowModelType", "loadData", "autosizeColumnsMode"])
      ]),
      _: 1
    })
  ]))
}