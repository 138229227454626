import {
  Api,
  CommonClientSrv,
  EnumObjectPanelTabParamType,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectClassGridViewModel,
  KObjectCreatePanelViewModel,
  ObjBusinessParam,
  ObjectClientSrv
} from '@kmsoft/ebf-common'
import { KModelBrowserViewModel } from '@kmsoft/km-vue/src/components'
import {
  BaseViewModel,
  EnumDialogResult,
  EnumItemClickedCancelType,
  EnumToolStripCompType,
  KDialog,
  KNotification,
  ToolStripItemClickedEventArgs,
  utils,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import { KPartBomCompare } from '../../pages/part-manage/part-bom-compare'
import { PartStructureTreeNode } from '../part-structure-tree'
import { EnumPartToolStripItemKeys } from '../toolstrip'
import {
  EnumPartSource,
  IObjectReferenceDefinition,
  IPartDefinition,
  IPartQueryRuleType,
  IPartTypeDefinition,
  KPartObjManageEmitsType,
  KPartObjManagePropType
} from './interface'

/** KPartForm */
export default class KPartObjManageViewModel extends BaseViewModel<KPartObjManageEmitsType, KPartObjManagePropType> {
  classCode = ref<string>()

  objParam = ref<ObjBusinessParam>()
  tabId = ref<string>()
  refPartDocDescDataGrid = ref<KObjectClassGridViewModel>()
  refPartDocReferenceDataGrid = ref<KObjectClassGridViewModel>()
  refPartEDADocumentDataGrid = ref<KObjectClassGridViewModel>()
  refHistoryDataGrid = ref<KObjectClassGridViewModel>()
  refChangeLogDataGrid = ref<KObjectClassGridViewModel>()
  refManagedBaselineDataGrid = ref<KObjectClassGridViewModel>()
  refPartAlterLinkDataGrid = ref<KObjectClassGridViewModel>()
  refBomSubstituteLinkDataGrid = ref<KObjectClassGridViewModel>()
  bomSubsituteVisible = ref(false)
  refBrowser = ref<KModelBrowserViewModel>()
  docObjParam = ref<ObjBusinessParam>()
  showBrowser = ref<boolean>()
  descToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD,
      title: '添加',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
      title: '移除',
      icon: 'delete',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'delete'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  referenceToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD,
      title: '添加',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
      title: '移除',
      icon: 'delete',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'delete'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  edaToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD,
      title: '添加',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
      title: '移除',
      icon: 'delete',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'delete'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  historyToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    },
    {
      name: EnumPartToolStripItemKeys.TOOL_STRIP_BOM_COMPARISON,
      title: 'BOM比较',
      icon: 'sort-descending',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    }
  ]

  changeToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  baselineToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  replaceToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD,
      title: '添加',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
      title: '移除',
      icon: 'delete',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'delete'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT,
      title: '编辑',
      icon: 'edit',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE,
      title: '保存',
      icon: 'save',
      visible: false,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  constructor(options: ViewModelOptions<KPartObjManagePropType>) {
    super(options)
    this.objParam.value = options.props.objParam
    this.tabId.value = this.props.tabId
    if (this.tabId.value == 'ksh' || this.tabId.value == 'ptksh') {
      this.initDocObjParam()
    }
    watch(
      () => options.props.objParam,
      () => {
        console.log(options.props.objParam)
        this.objParam.value = options.props.objParam
        this.refPartDocDescDataGrid.value?.refresh()
        this.refPartDocReferenceDataGrid.value?.refresh()
        this.refPartEDADocumentDataGrid.value?.refresh()
        this.refHistoryDataGrid.value?.refresh()
        this.refChangeLogDataGrid.value?.refresh()
        this.refManagedBaselineDataGrid.value?.refresh()
        this.refPartAlterLinkDataGrid.value?.refresh()
        this.initAlterbateToolStripItem()
        this.refBomSubstituteLinkDataGrid.value?.refresh()
        this.initSubstituteToolStripItem()
        this.bomSubsituteVisible.value = false
        const bomLinkParam = this.props.attachParams[EnumObjectPanelTabParamType.BomLinkParam]
        if (bomLinkParam) this.bomSubsituteVisible.value = true
        this.setNodesHighlight()
      },
      {
        immediate: true
      }
    )
    watch(
      () => options.props.objParam.id,
      () => {
        this.refHistoryDataGrid.value?.refresh()
      }
    )
    // watch(
    //   () => this.props.refreshTip,
    //   () => {
    //     this.refreshAll()
    //   },
    //   {
    //     deep: true
    //   }
    // )
  }

  async setNodesHighlight() {
    if (this.docObjParam.value) {
      const selectedNode = this.props.attachParams[EnumObjectPanelTabParamType.SelectedNode]
      if (selectedNode) {
        let rootNode = this.props.attachParams[EnumObjectPanelTabParamType.RootNode] as PartStructureTreeNode
        const path = selectedNode.path
        const nodeIds = path.split(',')
        const partIds = [] as { partId: string; findNumber: string }[]
        for (const nodeId of nodeIds) {
          if (nodeId != 'root') {
            rootNode = rootNode.children?.find(_ => _.id == nodeId) as PartStructureTreeNode
          }
          //TODO 根据id获取树节点数据
          const partId = rootNode.partObjParam.id
          const findNumber = rootNode.findNumber ? rootNode.findNumber : '1'
          partIds.push({ partId: partId, findNumber: findNumber })
        }
        // const partObjParams = partIds.map(id => ({ partId: id }))
        const result = await Api.post('part', 'PartDescribeLink', 'swap2DocPath', { data: [partIds] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          const data = result.data as Record<string, any>[]
          this.refBrowser.value?.setNodesHighlight(data.map(refObj => refObj.docId).join(','))
        } else {
          KNotification.warning({ title: '提示', content: `找不到描述文档` })
          return
        }
      }
    }
  }

  /**
   * 初始化全局替代
   */
  initAlterbateToolStripItem() {
    this.refPartAlterLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
  }

  /**
   * 初始化局部替代
   */
  initSubstituteToolStripItem() {
    this.refBomSubstituteLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
  }

  viewDidMount() {
    // 获取外部传值
    this.formData.value = this.props.formValue as IPartDefinition
    this.initAlterbateToolStripItem()
    this.initSubstituteToolStripItem()
  }

  async initDocObjParam() {
    let partId = this.objParam.value?.id
    if (this.props.attachParams[EnumObjectPanelTabParamType.RootNode]) {
      const rootNode = this.props.attachParams[EnumObjectPanelTabParamType.RootNode] as PartStructureTreeNode
      partId = rootNode.partObjParam.id
    }
    const result = await Api.post('part', 'Part', 'getDescribeDocumentByPart', {
      data: [{ clazz: 'Part', id: partId }]
    })
    //需要判断有主文件
    if (result && result.code == EnumRequestCode.SUCCESS && result.data.primary) {
      this.docObjParam.value = { id: result.data.id, modelCode: 'Document' }
      this.showBrowser.value = true
    }
  }

  // 表单响应式数据
  formData = ref<IPartDefinition>({
    id: '',
    rdmExtensionType: '',
    number: '',
    name: '',
    defaultUnit: '',
    source: EnumPartSource.Make,
    partView: '',
    master: { id: '', clazz: '' } as IObjectReferenceDefinition,
    branch: { id: '', clazz: '' } as IObjectReferenceDefinition,
    folder: { id: '', clazz: '' } as IObjectReferenceDefinition
  })

  // 通用布局
  refObjectCreatePanel = ref<KObjectCreatePanelViewModel>()

  // 零部件扩展类型下拉选择框
  partTypeDefinitionOption = ref<Array<IPartTypeDefinition>>()

  public save(value: IPartDefinition) {
    return this.formData.value
  }

  /**
   * 设置数据源
   * @param value 表单的值
   * @returns
   */
  public setValue(value: IPartDefinition) {
    this.formData.value = value
  }

  /**
   * 获取表单的值
   * @returns 表单的值
   */
  public getValue() {
    const object = this.refObjectCreatePanel.value?.getValue()

    return object
  }

  async onDescToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onDescToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.addPartDocDesc()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.removePartDocDesc()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refreshDescData()
        break
      default:
        break
    }
  }

  async onReferenceToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    const container = this.props.container || this.refPartDocReferenceDataGrid.value?.getContainer()
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.addPartDocReference(container)
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.removePartDocReference()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refreshReferenceData()
        break
      default:
        break
    }
  }

  async onHistoryToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onHistoryToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refHistoryDataGrid.value?.refresh()
        break
      case EnumPartToolStripItemKeys.TOOL_STRIP_BOM_COMPARISON: {
        const rows = this.refHistoryDataGrid.value?.getSelectedRows()
        if (rows && rows.length > 0) {
          if (rows.length == 1) {
            CommonClientSrv.openPage(
              'BOM比较',
              KPartBomCompare,
              {
                leftPart: { ...rows[0], extensionType: rows[0].rdmExtensionType, view: rows[0].partView.name }
              },
              utils.uuid()
            )
            break
          }
          CommonClientSrv.openPage(
            'BOM比较',
            KPartBomCompare,
            {
              leftPart: { ...rows[0], extensionType: rows[0].rdmExtensionType, view: rows[0].partView.name },
              rightPart: { ...rows[1], extensionType: rows[1].rdmExtensionType, view: rows[1].partView.name }
            },
            utils.uuid()
          )
        }
        break
      }
      default:
        break
    }
  }

  async onChangeToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refChangeLogDataGrid.value?.refresh()
        break
      default:
        break
    }
  }

  async onBaselineToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refManagedBaselineDataGrid.value?.refresh()
        break
      default:
        break
    }
  }

  async onAlternateToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onAlternateToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.addPartAlterLink()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.removePartAlterLink()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT:
        this.editPartAlterbateLink()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE:
        this.savePartAlterbateLink()
        this.refPartAlterLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, true)
        this.refPartAlterLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refreshPartAlterLinkData()
        break
      default:
        break
    }
  }

  async onSubstituteToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onSubstituteToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.addBomSubstituteLink()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.removeBomSubstituteLink()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT:
        this.editBomSubstituteLink()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE:
        this.saveBomSubstituteLink()
        this.refBomSubstituteLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, true)
        this.refBomSubstituteLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refreshBomSubstituteLinkData()
        break
      default:
        break
    }
  }

  /**
   * 获取零部件
   */
  async getPart(id: string) {
    const result = (await Api.post('part', 'Part', 'get', { data: [id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取零部件失败',
        details: result.detail
      })
      return
    }
  }

  /**
   * 刷新零部件文档描述关系
   */
  refreshDescData() {
    this.refPartDocDescDataGrid.value?.refresh()
  }

  /**
   * 刷新零部件文档参考关系
   */
  refreshReferenceData() {
    this.refPartDocReferenceDataGrid.value?.refresh()
  }

  /**
   * 刷新零部件全局替换列表
   */
  refreshPartAlterLinkData() {
    this.refPartAlterLinkDataGrid.value?.refresh()
  }

  /**
   * 刷新零部件局部替换列表
   */
  refreshBomSubstituteLinkData() {
    this.refBomSubstituteLinkDataGrid.value?.refresh()
  }

  /**
   * 加载零部件文档描述关系
   * @returns
   */
  loadDescData() {
    return this.listPartDocDesc()
  }

  /**
   * 加载零部件文档参考关系
   * @returns
   */
  loadReferenceData() {
    return this.listPartDocReference()
  }

  /**
   * 加载零部件历史版本
   * @returns
   */
  loadHistoryData() {
    return this.listPartHistory()
  }

  /**
   * 加载零部件变更记录
   * @returns
   */
  loadChangeLogData() {
    return this.listPartChange()
  }

  /**
   * 加载零部件所属基线
   * @returns
   */
  loadManagedBaselineData() {
    return this.listManagedBaseline()
  }

  /**
   * 加载零部件全局替换网格
   * @returns
   */
  loadPartAlterLinkData() {
    return this.listPartAlterLink()
  }

  /**
   * 加载零部件局部替换网格
   * @returns
   */
  loadBomSubstituteLinkData() {
    return this.listBomSubstituteLink()
  }

  /**
   * 局部替换列表
   * @returns
   */
  async listBomSubstituteLink() {
    const id = this.objParam.value?.id as string
    // 获取零部件对象
    const data = await this.getPart(id)
    const partView = data.partView.name
    const bomLinkParam = this.props.attachParams[EnumObjectPanelTabParamType.BomLinkParam]
    const param = {
      data: [
        {
          bomLinkId: bomLinkParam.id,
          partView: partView
        }
      ]
    }

    const result = await Api.post('part', 'BomSubstituteLink', 'list', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return {
        rows: result.data,
        total: result.data.length
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  /**
   * 全局替换列表
   * @returns
   */
  async listPartAlterLink() {
    const id = this.objParam.value?.id as string

    // 获取零部件对象
    const data = await this.getPart(id)
    const masterId = data?.master.id
    const partView = data?.partView.name
    const param = {
      data: [
        {
          sourceId: masterId,
          partView: partView
        }
      ]
    }

    const result = await Api.post('part', 'PartAlterLink', 'list', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return {
        rows: result.data,
        total: result.data.length
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  /**
   * 历史版本
   */
  async listPartHistory() {
    const id = this.objParam.value?.id
    const param = { data: [id] }

    const result = await Api.post('part', 'Part', 'listPartHistory', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return {
        rows: result.data,
        total: result.data.length
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  /**
   * 变更记录
   */
  async listPartChange() {
    const id = this.objParam.value?.id as string
    // 获取零部件对象
    const data = await this.getPart(id)
    const branchId = data?.branch.id
    const param = {
      data: [
        {
          id,
          branchId
        }
      ]
    }

    const result = await Api.post('change', 'ChangeCommon', 'listChangeRecord', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return {
        rows: result.data,
        total: result.data.length
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  /**
   * 所属基线
   */
  async listManagedBaseline() {
    const id = this.objParam.value?.id
    const param = {
      data: [id]
    }

    const result = await Api.post('baseline', 'ManagedBaseline', 'listAssociatedBaseline', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return {
        rows: result.data,
        total: result.data.length
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  /**
   * 查询零部件文档参考列表
   *
   * @returns
   */
  async listPartDocReference() {
    const reqParam = {
      data: [
        { id: this.objParam.value?.id },
        IPartQueryRuleType.LATEST,
        {
          maxPageSize: 1000,
          curPage: 1,
          pageSize: 20,
          totalRows: 0,
          totalPages: 0,
          startIndex: 0,
          endIndex: 0
        }
      ]
    }
    const result = await Api.post('part', 'PartReferenceLink', 'listDocPart', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return Promise.resolve({
        rows: result.data,
        total: result.data.length
      })
    }
    KNotification.error({
      title: '查询失败',
      content: result.message,
      details: result.detail
    })
    return
  }

  /**
   * 查询零部件文档描述列表
   *
   * @returns
   */
  async listPartDocDesc() {
    const reqParam = {
      data: [
        { id: this.objParam.value?.id },
        {
          maxPageSize: 1000,
          curPage: 1,
          pageSize: 20,
          totalRows: 0,
          totalPages: 0,
          startIndex: 0,
          endIndex: 0
        }
      ]
    }
    const result = await Api.post('part', 'PartDescribeLink', 'listDocPart', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return Promise.resolve({
        rows: result.data,
        total: result.data.length
      })
    }
    KNotification.error({
      title: '查询失败',
      content: result.message,
      details: result.detail
    })
    return
  }

  /**
   * 添加零部件文档描述关系
   */
  addPartDocDesc() {
    const objParam = this.props.objParam
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document']
      },
      objectSearchQueryParam: {
        isMultipleSelection: false,
        showObjClsCodes: ['Document', '']
      },
      getContainer: this.props.container || this.refPartDocDescDataGrid.value?.getContainer(),
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]
        const result = await Api.post('part', 'PartDescribeLink', 'create', {
          data: [
            {
              source: {
                id: objParam.id,
                clazz: objParam.modelCode
              },
              target: {
                id: targetParam.id,
                clazz: targetParam.rdmExtensionType
              }
            }
          ]
        })
        if (result && result.success) {
          //刷新网格
          this.refPartDocDescDataGrid.value?.refresh()
          KNotification.success({ title: '系统提示', content: '添加成功' })
        } else {
          KNotification.error({ title: '添加失败', content: result.message, details: result.detail })
          return
        }
      }
    })
  }

  /**
   * 移除零部件文档描述关系
   */
  async removePartDocDesc() {
    const rows = this.refPartDocDescDataGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认删除吗？删除后不可恢复!',
      onOk: async () => {
        // 获取对象id
        const rowIds = rows!.map(row => row.id)
        const reqParam = {
          data: [{ ids: rowIds }]
        }

        Api.post('part', 'PartDescribeLink', 'batchDelete', reqParam).then(result => {
          if (result && result.code == EnumRequestCode.SUCCESS) {
            // 刷新网格
            this.refPartDocDescDataGrid.value?.removeSelectRow()
            //this.refPartDocDescDataGrid.value?.refresh()
            KNotification.success({
              title: '系统提示',
              content: '删除成功'
            })
            return Promise.resolve({
              rows: result.data,
              total: result.data.length
            })
          } else {
            KNotification.error({
              title: '删除失败',
              content: result.message,
              details: result.detail
            })
            return
          }
        })
      }
    })
  }

  /**
   * 添加零部件文档参考关系
   */
  addPartDocReference(container: any) {
    const objParam = this.props.objParam
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document']
      },
      objectSearchQueryParam: {
        isMultipleSelection: false,
        showObjClsCodes: ['Document', '']
      },
      getContainer: container,
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]
        const result = await Api.post('part', 'PartReferenceLink', 'create', {
          data: [
            {
              source: {
                id: objParam.id,
                clazz: objParam.modelCode
              },
              target: {
                id: targetParam.master.id,
                clazz: targetParam.master.rdmExtensionType
              }
            }
          ]
        })
        if (result && result.success) {
          //刷新网格
          this.refPartDocReferenceDataGrid.value?.refresh()
          KNotification.success({ title: '系统提示', content: '添加成功' })
        } else {
          KNotification.error({ title: '添加失败', content: result.message, details: result.detail })
          return
        }
      }
    })
  }

  /**
   * 移除零部件文档参考关系
   */
  async removePartDocReference() {
    const rows = this.refPartDocReferenceDataGrid.value?.getSelectedRows()
    console.log('removePartDocReference', rows)

    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认删除吗？删除后不可恢复!',
      onOk: async () => {
        // 获取对象id
        const rowIds = rows!.map(row => row.id)
        const reqParam = {
          data: [{ ids: rowIds }]
        }

        Api.post('part', 'PartReferenceLink', 'batchDelete', reqParam).then(result => {
          if (result && result.code == EnumRequestCode.SUCCESS) {
            // 刷新网格
            this.refPartDocReferenceDataGrid.value?.removeSelectRow()
            //this.refPartDocReferenceDataGrid.value?.refresh()
            KNotification.success({
              title: '系统提示',
              content: '删除成功'
            })
            return Promise.resolve({
              rows: result.data,
              total: result.data.length
            })
          } else {
            KNotification.error({
              title: '删除失败',
              content: result.message,
              details: result.detail
            })
            return
          }
        })
      }
    })
  }

  async onEDAToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    const container = this.props.container || this.refPartEDADocumentDataGrid.value?.getContainer()
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.addPartEDA(container)
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.removePartEDA()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refreshEDAData()
        break
      default:
        break
    }
  }

  /**
   * 刷新电子元器件文档关系
   */
  refreshEDAData() {
    this.refPartEDADocumentDataGrid.value?.refresh()
  }

  /**
   * 加载电子元器件文档关系
   * @returns
   */
  loadEDADocumentData() {
    return this.listPartEDA()
  }

  /**
   * 查询零部件文档参考列表
   *
   * @returns
   */
  async listPartEDA() {
    const reqParam = {
      data: [{ id: this.objParam.value?.id }]
    }
    const result = await Api.post('part', 'EDADocumentLink', 'listDocPart', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return Promise.resolve({
        rows: result.data,
        total: result.data.length
      })
    }
    KNotification.error({
      title: '查询失败',
      content: result.message,
      details: result.detail
    })
    return
  }

  /**
   * 添加零部件文档参考关系
   */
  addPartEDA(container: any) {
    const objParam = this.props.objParam
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document']
      },
      objectSearchQueryParam: {
        isMultipleSelection: false,
        showObjClsCodes: ['Document', '']
      },
      getContainer: this.props.container || this.refPartEDADocumentDataGrid.value?.getContainer(),
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]
        const result = await Api.post('part', 'EDADocumentLink', 'create', {
          data: [
            {
              source: {
                id: objParam.id,
                clazz: objParam.modelCode
              },
              target: {
                id: targetParam.id,
                clazz: targetParam.rdmExtensionType
              }
            }
          ]
        })
        if (result && result.success) {
          //刷新网格
          this.refPartEDADocumentDataGrid.value?.refresh()
          KNotification.success({ title: '系统提示', content: '添加成功' })
        } else {
          KNotification.error({ title: '添加失败', content: result.message, details: result.detail })
          return
        }
      }
    })
  }

  /**
   * 移除零部件文档参考关系
   */
  async removePartEDA() {
    const rows = this.refPartEDADocumentDataGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认删除吗？删除后不可恢复!',
      onOk: async () => {
        // 获取对象id
        const rowIds = rows!.map(row => row.id)
        const reqParam = {
          data: [{ ids: rowIds }]
        }

        Api.post('part', 'EDADocumentLink', 'batchDelete', reqParam).then(result => {
          if (result && result.code == EnumRequestCode.SUCCESS) {
            // 刷新网格
            this.refPartEDADocumentDataGrid.value?.removeSelectRow()
            KNotification.success({
              title: '系统提示',
              content: '删除成功'
            })
            return Promise.resolve({
              rows: result.data,
              total: result.data.length
            })
          } else {
            KNotification.error({
              title: '删除失败',
              content: result.message,
              details: result.detail
            })
            return
          }
        })
      }
    })
  }

  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onToolStripItemClicked', event.name)
  }

  /**
   * 添加零部件全局替换关系
   */
  addPartAlterLink() {
    const objectClassTreeProps = {
      showObjClsCodes: ['Part', ''],
      defaultSelectClsCode: 'Part'
    }
    const objParam = this.props.objParam
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: objectClassTreeProps,
      objectSearchQueryParam: objectClassTreeProps,
      getContainer: this.props.container || this.refPartAlterLinkDataGrid.value?.getContainer(),
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]

        // 如果选中了自己
        if (targetParam.master.id == objParam.masterId) {
          KNotification.error({ title: '添加失败', content: '无法替代自身，请选择其他零部件' })
          event.cancel = true
          return
        }

        const result = await Api.post('part', 'PartAlterLink', 'create', {
          data: [
            {
              sourceId: objParam.id,
              targetId: targetParam.id
            }
          ]
        })
        if (result && result.success) {
          //刷新网格
          this.refPartAlterLinkDataGrid.value?.refresh()
          KNotification.success({ title: '系统提示', content: '添加成功' })
        } else {
          KNotification.error({ title: '添加失败', content: result.message, details: result.detail })
          return
        }
      }
    })
  }

  /**
   * 编辑零部件全局替代关系网格
   */
  async editPartAlterbateLink() {
    this.refPartAlterLinkDataGrid.value?.refresh()
    // 修改工具栏状态
    this.refPartAlterLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
    this.refPartAlterLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
    // 网格开启编辑
    this.refPartAlterLinkDataGrid.value?.beginEdit()
  }

  /**
   * 保存零部件全局替代关系网格
   */
  async savePartAlterbateLink() {
    this.refPartAlterLinkDataGrid.value?.endEdit(true)
    await CommonClientSrv.sleep(200)
    const changedRows = this.refPartAlterLinkDataGrid.value?.getChangedRows()

    const modifiedRows = changedRows?.modifiedRows
    if (modifiedRows && modifiedRows.length > 0) {
      const updateDataList = modifiedRows?.map(element => {
        let updateData = {}
        updateData = {
          id: element.id,
          description: element.description
        }
        return updateData
      })
      //更新数据
      const result = await Api.post('part', 'PartAlterLink', 'batchUpdate', { data: [updateDataList] })
      if (result && result.code == EnumRequestCode.SUCCESS) {
        KNotification.success({
          title: '系统提示',
          content: '修改成功'
        })
        this.refPartAlterLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, true)
        this.refPartAlterLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
        this.refreshPartAlterLinkData()
      } else {
        KNotification.warn({
          message: '修改失败',
          description: result.message
        })
        this.refPartAlterLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
        this.refPartAlterLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
        this.refPartAlterLinkDataGrid.value?.beginEdit()
        return
      }
    }
  }

  /**
   * 移除零部件全局替换关系
   */
  async removePartAlterLink() {
    const rows = this.refPartAlterLinkDataGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认删除吗？删除后不可恢复!',
      onOk: async () => {
        // 获取对象id
        const rowIds = rows!.map(row => row.id)
        const reqParam = {
          data: [rowIds]
        }

        Api.post('part', 'PartAlterLink', 'batchDelete', reqParam).then(result => {
          if (result && result.code == EnumRequestCode.SUCCESS) {
            // 刷新网格
            this.refPartAlterLinkDataGrid.value?.removeSelectRow()
            //this.refPartAlterLinkDataGrid.value?.refresh()
            KNotification.success({
              title: '系统提示',
              content: '删除成功'
            })
            return Promise.resolve({
              rows: result.data,
              total: result.data.length
            })
          } else {
            KNotification.error({
              title: '删除失败',
              content: result.message,
              details: result.detail
            })
            return
          }
        })
      }
    })
  }

  /**
   * 添加零部件局部替换关系
   */
  addBomSubstituteLink() {
    const objectClassTreeProps = {
      showObjClsCodes: ['Part', ''],
      defaultSelectClsCode: 'Part'
    }
    const bomLinkParam = this.props.attachParams[EnumObjectPanelTabParamType.BomLinkParam]
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: objectClassTreeProps,
      objectSearchQueryParam: objectClassTreeProps,
      getContainer: this.props.container || this.refBomSubstituteLinkDataGrid.value?.getContainer(),
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]

        const result = await Api.post('part', 'BomSubstituteLink', 'create', {
          data: [
            {
              bomLinkId: bomLinkParam.id,
              partId: targetParam.id
            }
          ]
        })
        if (result && result.success) {
          //刷新网格
          this.refBomSubstituteLinkDataGrid.value?.refresh()
          KNotification.success({ title: '系统提示', content: '添加成功' })
        } else {
          KNotification.error({ title: '添加失败', content: result.message, details: result.detail })
          return
        }
      }
    })
  }

  /**
   * 编辑零部件局部替代关系网格
   */
  async editBomSubstituteLink() {
    this.refBomSubstituteLinkDataGrid.value?.refresh()
    // 修改工具栏状态
    this.refBomSubstituteLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
    this.refBomSubstituteLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
    // 网格开启编辑
    this.refBomSubstituteLinkDataGrid.value?.beginEdit()
  }

  /**
   * 保存零部件局部替代关系网格
   */
  async saveBomSubstituteLink() {
    this.refBomSubstituteLinkDataGrid.value?.endEdit(true)
    await CommonClientSrv.sleep(200)
    const changedRows = this.refBomSubstituteLinkDataGrid.value?.getChangedRows()
    const modifiedRows = changedRows?.modifiedRows
    if (modifiedRows && modifiedRows.length > 0) {
      //校验preferredClass是否重复
      const allRows = this.refBomSubstituteLinkDataGrid.value?.getRows()
      const changedPriorities = new Set(modifiedRows.map(row => row.preferredClass))
      const reg = /^\d+$/
      let verifyPriority = false
      changedPriorities.forEach(item => {
        if (item && !reg.test(item)) {
          verifyPriority = true
        }
      })
      if (verifyPriority) {
        KNotification.warn({
          message: '修改失败',
          description: '优先级需是大于等于0的整数'
        })
        return
      }
      if (modifiedRows.length > changedPriorities.size) {
        KNotification.warn({
          message: '修改失败',
          description: '优先级不能重复'
        })
        this.refBomSubstituteLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
        this.refBomSubstituteLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
        this.refBomSubstituteLinkDataGrid.value?.beginEdit()
        return
      }
      // 从 allRows 中排除 modifiedRows
      const remainingRows = allRows?.filter(row => !modifiedRows?.some(modifiedRow => modifiedRow.id === row.id))
      // 检查 remainingRows 中是否存在与 changedPriorities 相同的 preferredClass
      const hasDuplicatePriority = remainingRows?.some(row => changedPriorities.has(row.preferredClass))
      if (hasDuplicatePriority) {
        KNotification.warn({
          message: '修改失败',
          description: '优先级不能重复'
        })
        this.refBomSubstituteLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
        this.refBomSubstituteLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
        this.refBomSubstituteLinkDataGrid.value?.beginEdit()
        return
      }

      const updateDataList = modifiedRows?.map(element => {
        let updateData = {}
        updateData = {
          id: element.id,
          preferredClass: element.preferredClass,
          substituteType: element.substituteType
        }
        return updateData
      })
      //更新数据
      const result = await Api.post('part', 'BomSubstituteLink', 'batchUpdate', { data: [updateDataList] })
      if (result && result.code == EnumRequestCode.SUCCESS) {
        KNotification.success({
          title: '系统提示',
          content: '修改成功'
        })
        this.refBomSubstituteLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, true)
        this.refBomSubstituteLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
        this.refreshPartAlterLinkData()
      } else {
        KNotification.warn({
          message: '修改失败',
          description: result.message
        })
        this.refBomSubstituteLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
        this.refBomSubstituteLinkDataGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
        this.refBomSubstituteLinkDataGrid.value?.beginEdit()
        return
      }
    }
  }

  /**
   * 移除零部件局部替换关系
   */
  async removeBomSubstituteLink() {
    const rows = this.refBomSubstituteLinkDataGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认删除吗？删除后不可恢复!',
      onOk: async () => {
        // 获取对象id
        const rowIds = rows!.map(row => row.id)
        const reqParam = {
          data: [rowIds]
        }

        Api.post('part', 'BomSubstituteLink', 'batchDelete', reqParam).then(result => {
          if (result && result.code == EnumRequestCode.SUCCESS) {
            // 刷新网格
            this.refBomSubstituteLinkDataGrid.value?.removeSelectRow()
            //this.refBomSubstituteLinkDataGrid.value?.refresh()
            KNotification.success({
              title: '系统提示',
              content: '删除成功'
            })
            return Promise.resolve({
              rows: result.data,
              total: result.data.length
            })
          } else {
            KNotification.error({
              title: '删除失败',
              content: result.message,
              details: result.detail
            })
            return
          }
        })
      }
    })
  }

  refreshAll() {
    this.refPartDocDescDataGrid.value?.refresh()
    this.refPartEDADocumentDataGrid.value?.refresh()
    this.refPartDocReferenceDataGrid.value?.refresh()
    this.refHistoryDataGrid.value?.refresh()
    this.refChangeLogDataGrid.value?.refresh()
    this.refManagedBaselineDataGrid.value?.refresh()
    this.refPartAlterLinkDataGrid.value?.refresh()
    this.refBomSubstituteLinkDataGrid.value?.refresh()
  }
}
