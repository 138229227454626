
import { defineView } from '@kmsoft/upf-core'
import KFolderManage from '../../folder-manage'
import KElectronicComponentsWorkspaceViewModel from './KElectronicComponentsWorkspaceViewModel'
import { KElectronicComponentsWorkspaceEventEmits, KElectronicComponentsWorkspacePropOptions } from './interface'
import { FolderTypeEnum } from '../../../../../ebf-common'
import KCommonFolderManage from '../../common-folder-manage'
import KFolderTree from '../../folder-manage/folder-tree'

export default defineView({
  name: 'KElectronicComponentsWorkspace',
  props: KElectronicComponentsWorkspacePropOptions,
  emits: KElectronicComponentsWorkspaceEventEmits,
  components: { KFolderTree, KFolderManage, KCommonFolderManage }, // 这里定义局部组件
  viewModel: KElectronicComponentsWorkspaceViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    //文件夹类型
    const folderType = FolderTypeEnum.ELECTRONIC_COMPONENTS
    //操作栏
    const operatorColumn = [
      { key: 'edit', title: '编辑' },
      { key: 'delete', title: '删除' }
    ]
    return {
      folderType,
      operatorColumn
    }
  }
})
