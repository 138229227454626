import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam, enumFactory } from '@kmsoft/ebf-common'

/** 参数 **/
export const KPartReportPropOptions = {
  ...BaseViewPropOptions,
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KPartReportPropType = ViewPropsTypeExtract<typeof KPartReportPropOptions>

/** 事件 */
export const KPartReportEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartReportEmitsType = ViewEmitsTypeExtract<typeof KPartReportEventEmits>

/** 采购类型 */
export const ProcurementType = enumFactory({
  F: '外购',
  E: '自制',
  X: '自制&外购'
})

/** 工厂 */
export const Factory = enumFactory({
  1001: '数控系统制造工厂',
  1002: '机器人制造工厂',
  1003: '注塑机制造工厂',
  1004: '减速器制造工厂',
  1005: '机床改制制造工厂',
  1011: '广州数控信息科技工厂',
  1031: '广数南海分公司工厂',
  Z001: '广数零价值工厂',
  1071: '精密注塑机工厂'
})

/** 物料类型 */
export const MaterialType = enumFactory({
  A001: '广州数控-电子材料',
  A002: '广州数控-机械材料',
  A003: '广州数控-包装材料',
  A004: '广州数控-辅助材料',
  A005: '广州数控-工具',
  A006: '广州数控-成品',
  A007: '广州数控-半成品'
})

/** 产品组 */
export const Devision = enumFactory({
  10: '系统',
  99: '通用',
  15: '伺服单元',
  14: '配件',
  13: '机床',
  12: '注塑机',
  11: '机器人'
})

/** 销售组织 */
export const SalesOrganization = enumFactory({
  1001: '系统销售组织',
  1002: '机器人销售组织',
  1003: '注塑机销售组织',
  1005: '减速器销售组织',
  1004: '机床销售组织',
  1011: '信息科技销售组织',
  1031: '南海分公司销售组织',
  1071: '精密注塑机销售组织'
})
