import {
  Api,
  CommonClientSrv,
  EnumRequestCode,
  EnumToolStripItemKeys,
  FolderTypeEnum,
  KFolderSelector,
  KFolderSelectorViewModel,
  KObjectClassGridViewModel,
  KObjectPanelPropType,
  ObjectClientSrv
} from '@kmsoft/ebf-common'
import {
  BaseViewModel,
  EnumDialogResult,
  EnumToolStripCompType,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  String,
  ToolStripItemClickedEventArgs,
  VNodeProps,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { ref, resolveComponent } from 'vue'
import { KPartObjManage } from '../../controls/part-obj-manage'
import KPartStructureManage from '../../controls/part-structure-manage'
import { KPartManageEmitsType, KPartManagePropType } from './interface'
import { KPartForm } from './part-form'
import KPartFormViewModel from './part-form/KPartFormViewModel'
import { KPartSaveAsForm } from './part-saveas-form'
import KPartSaveAsFormViewModel from './part-saveas-form/KPartSaveAsFormViewModel'
import KPartUsingStatistics from '../../controls/part-using-statistics'
import { KPartReport } from './part-report'

/** KPartManage */
export default class KPartManageViewModel extends BaseViewModel<KPartManageEmitsType, KPartManagePropType> {
  refObjGrid = ref<KObjectClassGridViewModel>()

  toolItem = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OBJ,
      title: '对象',
      icon: 'DropObjLife',
      visible: true,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      items: [
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEWOBJ,
          title: '创建',
          icon: 'NewObj',
          visible: true,
          compType: EnumToolStripCompType.BUTTON,
          shortcutKey: 'alt+n'
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE,
          title: '删除',
          icon: 'DeleteObj',
          visible: true,
          compType: EnumToolStripCompType.BUTTON,
          shortcutKey: 'delete'
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS,
          title: '另存',
          icon: 'ObjSaveAs',
          visible: true,
          compType: EnumToolStripCompType.BUTTON,
          shortcutKey: 'alt+s'
        },
        {
          name: 'edit',
          title: '编辑',
          icon: 'stop',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        }
      ]
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OPERATION,
      title: '操作',
      icon: 'DropObjOp',
      visible: true,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      items: [
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT,
          title: '检出(O)',
          icon: 'DocCheckOut',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT,
          title: '取消检出',
          icon: 'DocCancelCheckOut',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN,
          title: '检入(I)',
          icon: 'DocCheckIn',
          visible: true,
          compType: EnumToolStripCompType.BUTTON,
          shortcutKey: 'alt+i'
        },
        {
          name: 'struct',
          title: '结构管理',
          icon: 'PartStructure',
          visible: true,
          compType: EnumToolStripCompType.BUTTON,
          shortcutKey: 'ctrl+p'
        },
        {
          name: 'conversion',
          title: '转通用件',
          icon: 'add',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: 'usingStatistics',
          title: '使用统计',
          icon: 'table',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: 'report',
          title: '报表',
          icon: 'table',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        }
      ]
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_GRID_OPERATION,
      title: '列表',
      icon: 'DropGridOp',
      visible: true,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      items: [
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EXPORT_EXCEL,
          title: '导出Excel',
          icon: 'table',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        }
      ]
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  constructor(options: ViewModelOptions<KPartManagePropType>) {
    super(options)
  }

  viewDidMount() {}

  toolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEWOBJ:
        this.createPart()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE:
        this.deletePart()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN:
        this.checkinPart()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT:
        this.checkoutPart()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT:
        this.undoCheckoutPart()
        break
      case 'edit':
        this.updatePart()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refObjGrid.value?.refresh()
        break
      case 'struct':
        this.structManage()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS:
        this.saveAsPart()
        break
      case 'conversion':
        this.batchConvertUniversalparts()
        break
      case 'usingStatistics':
        this.usingStatisticsManage()
        break
      case 'report':
        this.report()
        break
      default:
        break
    }
  }

  loadData(qry: any) {
    return this.listPart(qry)
  }

  /**
   * 查询零部件列表
   *
   * @returns
   */
  async listPart(qry: any) {
    const param = {
      data: [
        {
          characterSet: 'UTF8',
          conditions: [{ conditionName: 'latest', operator: '=', conditionValues: [true] }],
          filter: {},
          decrypt: false,
          sort: 'DESC',
          orderBy: 'createTime',
          isNeedTotal: true,
          isPresentAll: true,
          publicData: 'INCLUDE_PUBLIC_DATA'
        },
        {
          maxPageSize: 1000,
          curPage: qry.page.pageIndex,
          pageSize: 50,
          totalRows: 0,
          totalPages: 0,
          startIndex: 0,
          endIndex: 0
        }
      ]
    }

    const result = await Api.post('part', 'Part', 'page', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return {
        rows: result.data.data,
        total: result.data.page.totalRows
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  /**
   * 新建零部件
   */
  createPart() {
    KDialog.show({
      title: '新建零部件',
      size: { width: 800, height: 600 },
      props: { classTreeSelectDisplay: true },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KPartForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KPartFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }

        // const formData = formViewModel.formData
        // formData.value.folder.id == formData.value.folder.id == "-1"

        const result = await formViewModel.save()
        if (result && result.code == EnumRequestCode.SUCCESS) {
          await this.refObjGrid.value?.refresh()

          KNotification.success({
            title: '系统提示',
            content: '新建成功'
          })
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '新建失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 编辑零部件
   */
  updatePart() {
    const rows = this.refObjGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    // 获取对象id
    const rowIds = rows!.map(row => row.master.id)
    // 获取对象
    const partData = this.getPart(rowIds[0])

    KDialog.show({
      title: '编辑零部件',
      size: { width: 800, height: 600 },
      props: { formValue: partData },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KPartForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KPartFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }

        const formData = formViewModel.getValue()
        const result = await Api.post('part', 'Part', 'create', { data: [formData] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          await this.refObjGrid.value?.refresh()

          KNotification.success({
            title: '系统提示',
            content: '更改成功'
          })
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '更改失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 批量转通用件
   */
  async batchConvertUniversalparts() {
    const rows = this.refObjGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }
    // 获取对象id
    const rowIds = rows!.map(row => row.branch.id)

    const objList = rows!.map(row => {
      return {
        branchId: row.branch.id,
        folderId: row.branch.folder.id,
        className: row.branch.rdmExtensionType,
        clazz: row.branch.className
      }
    })

    KDialog.show({
      title: '批量转通用件',
      size: { width: 500, height: 200 },
      props: {
        showWorkspaceType: [FolderTypeEnum.COMMON_PARTS]
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KFolderSelector,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KFolderSelectorViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const folder = formViewModel.getValue() as any

        const result = await Api.post('folder', 'Folder', 'copyFolderObjLink', { data: [folder.id, objList] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          await this.refObjGrid.value?.refresh()
          KNotification.success({
            title: '系统提示',
            content: '添加成功'
          })
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '添加失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 另存零部件
   */
  async saveAsPart() {
    const rows = this.refObjGrid.value?.getSelectedRows() as any
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    if (rows && rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '每次只能选择一条数据另存'
      })
      return
    }
    // 获取对象id
    const rowId = rows[0].id
    const reData = await this.getSaveAsPart(rowId)
    const folderId = reData?.folder.id

    KDialog.show({
      title: '另存零部件',
      size: { width: 800, height: 600 },
      props: {
        loadData: () => {
          return {
            rdmExtensionType: reData.rdmExtensionType,
            number: reData.number,
            name: reData.name,
            version: 'A',
            assemblyMode: reData.assemblyMode,
            partViewEnum: reData.partViewEnum,
            phase: reData.phase,
            defaultUnitEnum: reData.defaultUnitEnum,
            source: reData.source,
            lifecycleStateCode: reData.lifecycleStateCode,
            folder: {
              id: -1
            },
            sourceInstanceId: rowId
          }
        }
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KPartSaveAsForm,
      //content: KPartForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KPartSaveAsFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }
        let formData = formViewModel.getValue() as any
        formData = {
          ...formData,
          folder: {
            id: folderId === '' ? '-1' : folderId,
            clazz: 'Folder'
          }
        }

        const result = await Api.post('part', 'Part', 'saveAsPart', { data: [formData] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          await this.refObjGrid.value?.refresh()
          KNotification.success({
            title: '系统提示',
            content: '另存成功'
          })
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '另存失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 获取另存零部件
   * @param id 零部件id
   * @returns 另存零部件信息
   */
  async getSaveAsPart(id: string) {
    const result = (await Api.post('part', 'Part', 'get', { data: [id] })) as any
    if (!(result && result.code == EnumRequestCode.SUCCESS)) {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取零部件详情失败',
        details: result.detail
      })
      return
    }
    return result.data as any
  }

  /**
   * 获取零部件
   */
  async getPart(id: string) {
    const result = (await Api.post('part', 'Part', 'get', { data: [id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取零部件失败',
        details: result.detail
      })
      return
    }
  }

  /**
   * 删除零部件
   *
   * @returns
   */
  deletePart() {
    const rows = this.refObjGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认删除吗？删除后不可恢复!',
      onOk: () => {
        // 获取主对象id集合
        const rowIds = rows!.map(row => row.id)
        Api.post('part', 'Part', 'batchDelete', { data: [rowIds] }).then(res => {
          if (res.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '删除成功'
            })

            // 刷新网格
            this.refObjGrid.value?.refresh()
          } else {
            KNotification.error({
              title: '操作失败',
              content: res.message || '删除失败',
              details: res.detail
            })
            return
          }
        })
      }
    })
  }

  /**
   * 使用统计
   */
  async usingStatisticsManage() {
    const rows = this.refObjGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    if (rows && rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '每次只能选择一条数据统计'
      })
      return
    }
    // 获取对象id
    const rowIds = rows!.map(row => row.id)
    // 获取对象
    const partData = (await this.getPart(rowIds[0])) as any

    CommonClientSrv.openPage(
      '使用统计',
      KPartUsingStatistics,
      { objParam: { modelCode: partData.rdmExtensionType, modelGroup: 'part', id: partData.id } },
      partData.id
    )
  }

  /**
   * 报表
   */
  async report() {
    //const panelProps: VNodeProps<string> = '123123'
    //CommonClientSrv.openPage('test', () => resolveComponent('KObjectPanel'), panelProps, '123')

    CommonClientSrv.openPage('报表', KPartReport, {})
  }

  /**
   * 结构管理
   */
  async structManage() {
    const rows = this.refObjGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    // 获取对象id
    const rowIds = rows!.map(row => row.id)
    // 获取对象
    const partData = (await this.getPart(rowIds[0])) as any
    const title = '结构管理:' + ObjectClientSrv.getObjBusinessDesc(partData)
    CommonClientSrv.openPage(
      title,
      KPartStructureManage,
      {
        objParam: { modelCode: partData.rdmExtensionType, modelGroup: 'part', id: partData.id },
        partViewName: partData.partView.name
      },
      partData.id
    )
  }

  /**
   * 对象管理
   */
  async objManage() {
    const rows = this.refObjGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    if (rows && rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '每次只能选择一条数据对象管理'
      })
      return
    }

    // 获取对象id
    const rowIds = rows!.map(row => row.id)
    // 获取对象
    const partData = (await this.getPart(rowIds[0])) as any

    CommonClientSrv.openPage('对象管理', KPartObjManage, {
      objParam: { modelCode: partData.rdmExtensionType, modelGroup: 'part', id: partData.id },
      env: 'structure'
    })
  }

  /**
   * 检入零部件
   *
   * @returns 检入后的新对象
   */
  async checkinPart() {
    const rows = this.refObjGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认检入吗？',
      onOk: async () => {
        // 获取对象id
        const rowId = rows!.map(row => row.id)[0]
        const result = await Api.post('part', 'Part', 'checkin', { data: [rowId] })
        if (result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '检入成功'
          })

          // 检入后的新对象
          const data = result.data

          await this.refObjGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '检入失败',
            details: result.detail
          })
          return
        }
      }
    })
  }

  /**
   * 检出零部件
   *
   * @returns 检出后的副本对象
   */
  async checkoutPart() {
    const rows = this.refObjGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认检出吗？',
      onOk: async () => {
        // 获取对象id
        const rowId = rows!.map(row => row.id)[0]
        const result = await Api.post('part', 'Part', 'checkout', { data: [rowId] })
        if (result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '检出成功'
          })

          // 检出后的副本对象
          const data = result.data

          await this.refObjGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '检出失败',
            details: result.detail
          })
          return
        }
      }
    })
  }

  /**
   * 撤销检出零部件
   *
   * @returns 检出前的对象
   */
  async undoCheckoutPart() {
    const rows = this.refObjGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认撤销检出吗？',
      onOk: async () => {
        // 获取对象id
        const rowId = rows!.map(row => row.id)[0]
        const result = await Api.post('part', 'Part', 'undoCheckout', { data: [rowId] })
        if (result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '撤销检出成功'
          })

          // 检出前的对象
          const data = result.data

          await this.refObjGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '撤销检出失败',
            details: result.detail
          })
          return
        }
      }
    })
  }

  onDoubleClick() {
    const selectedRow = this.refObjGrid.value?.getSelectedRow()
    const param = { id: selectedRow?.id, modelCode: 'Part', modelGroup: 'part' }
    const name = selectedRow?.name
    const number = selectedRow?.number
    const partViewEnum = selectedRow?.partViewEnum === 'DESIGN' ? '设计' : '制造'
    const version = selectedRow?.version + '.' + selectedRow?.iteration
    const workingState =
      selectedRow?.workingState === 'INWORK' ? '工作中' : 'CHECKED_IN' ? '已检入' : 'CHECKED_OUT' ? '已检出' : '未知'

    /** 标签页标识 */
    const tabKey = `${param.id}#${param.modelCode}`
    /** 标题 */
    const title = `${number}` + ':' + `${name}` + ':' + `${partViewEnum}` + ':' + `${version}` + ':' + `${workingState}`
    /** 面板参数 */
    const panelProps: VNodeProps<KObjectPanelPropType> = { objParam: param }

    // 打开页面
    CommonClientSrv.openPage(title, () => resolveComponent('KObjectPanel'), panelProps, tabKey)
    //ObjectClientSrv.openObj(param)
  }
  operationClick(params: any) {
    switch (params.key) {
      case 'add':
        break
      case 'edit':
        break
      default:
        break
    }
  }
}
