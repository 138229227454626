
import { defineView, EnumDataGridRowModelType } from '@kmsoft/upf-core'
import { EnumLifecycleState, EnumActivityType, EnumLifecycleStateColor } from '@kmsoft/ebf-common'
import { KPersonalCreateChangeActivityGridPropOptions, KPersonalCreateChangeActivityGridEventEmits } from './interface'
import KPersonalCreateChangeActivityGridViewModel from './KPersonalCreateChangeActivityGridViewModel'
import { readonly } from 'vue'

export default defineView({
  name: 'KPersonalCreateChangeActivityGrid',
  props: KPersonalCreateChangeActivityGridPropOptions,
  emits: KPersonalCreateChangeActivityGridEventEmits,
  viewModel: KPersonalCreateChangeActivityGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      EnumLifecycleState,
      EnumLifecycleStateColor,
      EnumActivityType,
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      pagination: {
        pageIndex: 1,
        pageSize: 50
      },
      selectOptions: EnumLifecycleState._list.filter((item: any) => ['InWork', 'UnderReview', 'Released'].includes(item.value)),
      selectValuePlaceholder: '生命周期状态'
    }
  }
})
