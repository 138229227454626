import { EnumToolStripItemKeys, KObjectClassGridViewModel } from '@kmsoft/ebf-common'
import {
  EnumDialogResult,
  EnumToolStripCompType,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  KOrderAdjustPanel,
  SimpleViewModel,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import lodash from 'lodash'
import { ref, watch } from 'vue'
import { KDistributionRecordCreate, KDistributionRecordCreateViewModel } from './distribution-record-create'
import { KDistributionRecordObjGridEmitsType, KDistributionRecordObjGridPropType } from './interface'

/** KDistributionRecordObjGrid */
export default class KDistributionRecordObjGridViewModel extends SimpleViewModel<
  KDistributionRecordObjGridEmitsType,
  KDistributionRecordObjGridPropType
> {
  // 对象类网格
  refObjGrid = ref<KObjectClassGridViewModel>()
  originData = ref<any[]>([])
  isEdit = ref<boolean>(false)
  modified = ref<boolean>(false)
  statusOptions = ref<Record<string, any>[]>([
    {
      label: '已读',
      value: 'READ'
    },
    {
      label: '未读',
      value: 'UNREAD'
    },
    {
      label: '归档',
      value: 'FILE_AWAY'
    }
  ])
  toolItem = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW,
      title: '添加',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
      title: '移除',
      icon: 'minus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SORT,
      title: '排序',
      icon: 'ordered-list',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  gridData = ref<any[]>([])

  constructor(options: ViewModelOptions<KDistributionRecordObjGridPropType>) {
    super(options)
    watch(
      () => this.props.readonly,
      newValue => {
        !newValue ? this.refObjGrid.value?.beginEdit() : this.refObjGrid.value?.endEdit()
        // if (newValue) {
        //   this.refObjGrid.value?.setToolStripDisabled(newValue as boolean)
        // }
      }
    )
  }

  viewDidMount() {
    // todo 设置工具栏直读
  }

  cellEditingStarted(event: any) {
    this.isEdit.value = true
  }
  cellEditingStopped() {
    this.isEdit.value = false
  }

  /**
   * 工具栏按钮点击事件
   * @param event
   */
  toolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    if (this.props.isDesigner) return
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW:
        this.add()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.remove()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SORT:
        this.sort()
        break
      default:
        break
    }
  }

  /**
   * 刷新
   */
  public refresh() {
    this.refObjGrid.value?.refresh()
  }

  /**
   * 添加
   */
  add() {
    this.refObjGrid.value?.beginEdit()
    KDialog.show({
      title: '创建发放记录',
      size: { width: 800, height: 600 },
      props: {},
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KDistributionRecordCreate,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formViewModel = event.viewModel as KDistributionRecordCreateViewModel
        const validate = formViewModel.validate()
        if (!validate) {
          event.cancel = true
          return
        }
        let distributionRecordObject = formViewModel.getValue()
        distributionRecordObject = lodash.cloneDeep(distributionRecordObject)
        distributionRecordObject!.id = lodash.uniqueId()

        const existingReceiver = this.gridData.value.some(record => record.receiver === distributionRecordObject.receiver)
        if (existingReceiver) {
          KNotification.warn({
            message: '系统提示',
            description: '该接收人已存在，不能重复添加'
          })
          event.cancel = true
        } else {
          this.addGridData([distributionRecordObject])
        }
      }
    })
  }

  filterReleasedData(selectedRows: any, showWarn: boolean) {
    const filterReleasedRows: Array<Record<string, any>> = []
    if (selectedRows != null && selectedRows.length > 0) {
      selectedRows.forEach((item: any) => {
        // 选择文档时,  通过返回的lifecycleStateCode属性判断
        if (item.lifecycleStateCode == 'Released') {
          filterReleasedRows.push(item)
        }
      })
    }

    if (filterReleasedRows.length == 0 && showWarn) {
      KNotification.warn({
        message: '系统提示',
        description: '选择数据行中没有发布数据'
      })
      return []
    }
    return filterReleasedRows
  }

  setGridData(gridData: any[]) {
    this.gridData.value = [...gridData]
    this.gridDataFilter()
    if (!this.isEdit.value) {
      //没有结束编辑时不要将新的网格数据更新到原始数据中
      this.originData.value = lodash.cloneDeep(this.gridData.value)
    }
  }

  addGridData(gridData: any[]) {
    this.modified.value = true
    this.gridData.value.push(...gridData)
    this.gridDataFilter()
  }

  gridDataFilter() {
    // 去重
    const gridDataMap = this.gridData.value.reduce((acc, current) => {
      acc[current.id] = current
      return acc
    }, {})

    this.gridData.value = Object.values(gridDataMap)
    this.refresh()
  }

  /**
   * 移除零部件或者文档
   */
  remove() {
    const selectedRows = this.refObjGrid.value?.getSelectedRows() || []
    if (selectedRows.length > 0) {
      const rowIds = selectedRows.map((row: any) => row.id)
      this.modified.value = true
      this.gridData.value = this.gridData.value.filter(item => !rowIds.some((row: any) => row === item.id))
      this.refObjGrid.value?.removeRow(rowIds)
      // 底层存在800延时, 这里设置801延时, 如果不设置延时, 会出现删除双倍数据的问题
      // 但是存在延时时, 会存在两次刷新的问题, 属于正常现象
      setTimeout(() => {
        this.refresh()
      }, 801)
      // 第一次刷新为removeRow的效果, 第二次刷新为refresh的效果
    }
  }

  loadData(qry: any) {
    return this.listDistributionRecords(qry)
  }

  /**
   * 查询零部件列表
   *
   * @returns
   */
  async listDistributionRecords(qry: any) {
    // 先不要分页
    return this.gridData.value
  }

  isModified(): boolean {
    // 编辑时默认认为修改
    const data = this.getValue()
    const compare = this.compareData(data, this.originData.value)
    if (compare && !this.modified.value) {
      return false
    }
    return true
  }

  getModifiedValue() {
    return this.getValue()
  }

  compareData(newData: any, oldData: any) {
    return JSON.stringify(newData) == JSON.stringify(oldData)
  }

  public setValue(newValue: any, setChanged?: boolean) {
    this.setGridData(newValue)
  }

  public getValue() {
    this.refObjGrid.value?.endEdit()
    const allRows = this.refObjGrid.value?.getRows()
    !this.props.readonly ? this.refObjGrid.value?.beginEdit() : this.refObjGrid.value?.endEdit()
    allRows?.forEach((row: any) => {
      row.countOfCopies = row.countOfCopies ? row.countOfCopies : null
    })
    return allRows
  }

  /**
   * 排序
   */
  public async sort() {
    const rows = this.refObjGrid.value?.getRows()
    const { result } = await KOrderAdjustPanel.show({
      rowKey: 'id',
      list: rows,
      fields: [
        { title: '编码', id: 'number' },
        { title: '名称', id: 'name' }
      ]
    })

    const sortedItems = result.sortedItems
    this.modified.value = true
    this.gridData.value = Object.values(sortedItems)
    this.refresh()
  }

  /**
   * 强制刷新
   */
  public cleanAllForce() {
    this.setGridData([])
  }
}
